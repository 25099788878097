import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.svg";
import { useState, useEffect } from "react";
import Card from "../../Elements/Card";
import Footer, { FooterTab, MobileFooter } from "../GreenHome/Footer";
import useFindDevice from "../CustomHooks/FindDevice";

function Rulse() {
  const navigate = useNavigate();
  const { isMob, isTab, isLaptop, isLogedIn } = useFindDevice();

  const redirectToLogin = () => {
    navigate("/login");
  };

  const redirectToRegister = () => {
    navigate("/signup");
  };

  const redirectHome = () => {
    navigate("/home");
  };

  return (
    <>
      {isMob === true ? (
        <div
          className={`font-interFont overflow-auto ${
            isMob
              ? "bg-[url('../public/card/home-screen-mobile-bg.svg')]"
              : "bg-[url('../public/card/home-bg.svg')]"
          } bg-cover`}
        >
          <div className="mb-[50px] mobile-s:h-[750px] mobile-l:h-[850px] w-full p-5 font-Inter">
            <div className="flex w-full p-3">
              <div className="w-[50%]">
                <img src={Logo} alt="pickNdPrizes" onClick={redirectHome}></img>
              </div>
              <div className={`w-[50%] ml-[10%] mt-[4%] flex float-right`}>
                {!isLogedIn ? (
                  <button
                    className=" border-[1px] border-[#00C05B] ml-2 rounded-[4px] w-[95px] h-[37px] text-[14px] cursor-pointer text-[#00C05B]"
                    onClick={redirectToRegister}
                  >
                    Register
                  </button>
                ) : null}

                <button
                  className={`w-[90px] h-[35px] text-[14px] font-[500] bg-[#00C05B] text-[#002427] ${
                    !isLogedIn ? "ml-5" : "ml-14"
                  } rounded-[4px]`}
                  onClick={redirectToLogin}
                >
                  {!isLogedIn ? "Login" : "Play now"}
                </button>
              </div>
            </div>

            <hr className="w-[95%] opacity-[.2] m-auto"></hr>
            <div>
              <div className="mt-[2%]">
                <h2 className="text-[#FFFFFF] text-[32px] text-center">
                  Rules
                </h2>
              </div>

              <Card className="m-auto h-[65.6vh] overflow-auto mt-5 pl-8 pt-12 pb-12 pr-8 flex flex-col justify-between w-[100%] my-[2%] bg-[#FFFFFF] shadow-md rounded-[12px] relative">
                <h4 className="text-[22px] mt-[3%] text-[#000000] font-semibold">
                  Rules of MoneyBall (the game)
                </h4>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  1. By accepting the Terms and Conditions, you agree to the
                  Competition Rules, and it will be legally binding on you.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  2. The rules of MoneyBall are the same for all MoneyBall
                  games.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  3. The entry fee and the prize money for each round of
                  MoneyBall are pre-determined before the start of the round.
                  The aggregate of all the entry fees in a particular round
                  minus a 10% administrative fee will make up the prize money or
                  the jackpot of that round.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  4. Using each account a participant can join any MoneyBall
                  round with one or two picks. Two is the maximum per
                  participant in a single round.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  5. If a participant is playing with two picks each pick is
                  treated completely independently in terms of playing the game.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  6. The participant shall join any round (active/open round) of
                  the competition up until the deadline of that round. The
                  deadline of each round and each match week within the round is
                  set at 1 hour prior to the commencement of the first match in
                  that match week.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  7. The objective of the game is to assign each of your picks
                  to a team in that week’s fixture list who the participant
                  thinks they win their match. If the selected team wins their
                  match that pick progresses to the following match week. If the
                  selected team draws or loses their match, then that pick is
                  eliminated.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  Example: Pick 1: Arsenal, and Pick 2: Liverpool. Arsenal draw
                  and Liverpool win then Pick 1 is eliminated and Pick 2
                  progresses.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  If your pick is successful, then you progress to the following
                  week and must repeat the process.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  8. Once a team is picked it cannot be chosen again under the
                  same pick until the round is over.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  Example: Since Pick 2 above selected Liverpool and they won
                  their match, then that player can no longer choose Liverpool
                  under Pick 2 for the rest of the round.
                </p>

                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  9. Once again, and as indicated in point 5.4 above, if a
                  participant is playing in a round with two picks, then they
                  are treated independently.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  Using the same example in point 6 above, let’s assume both
                  Arsenal & Liverpool have won their respective matches, in that
                  case Pick 1 will no longer be able to choose Arsenal again
                  until the round is over, however Pick 2 can still use Arsenal
                  since it hasn’t been used before.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  Consequently, since the two entries are treated independently
                  a player can pick the same team for both their picks as long
                  as he hasn’t used them previously in the same round.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  10. If a pick is not submitted before the deadline, that pick
                  will automatically be assigned to the lowest ranked team in
                  the league table that is available for that pick. If a
                  participant is playing with two picks and misses the deadline
                  on both, then one pick will be assigned to the lowest ranked
                  team available from the league table while the second pick
                  will be assigned to the second to last available team.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  Example: Sheffield and Burnley are ranked 20th and 19th in the
                  Premier League coming into the match week. A participant who
                  is playing with two picks misses the deadline. In that case,
                  Pick 1 will automatically select Sheffield while Pick 2 will
                  automatically select Burnley. Let’s assume that participant
                  had already picked Burnley under Pick 2 in a prior match week
                  within that same round. In that case Pick 2 will automatically
                  select the 18th ranked team in the table and so on.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  11. Each pick is only allowed one default per round. The
                  second default within the same round will result in automatic
                  elimination.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  12. Once a pick is made it can be changed up till the deadline
                  of that match week.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  13. If a match is postponed prior to the deadline lapsing the
                  player must re-pick by choosing a team from the remaining
                  fixtures that match week. If the match is postponed after the
                  deadline has passed, then any picks for that fixture will be
                  deemed successful and the players involved will not be allowed
                  to pick those same teams again in that round.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  14. Any matches played out of the normal schedule or that were
                  previously postponed will not be part of the fixtures to
                  select from and will be ignored.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  15. A match week will only be considered for selection if it
                  has a minimum of 8 fixtures. Match weeks that have less will
                  be skipped in the schedule.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  16. The Company reserves the right, in its sole discretion, to
                  refuse to accept participation that is incomplete,
                  indecipherable, offensive, does not comply with these T&Cs, or
                  contravenes any applicable laws or regulation.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  17. The Company reserves the right, in its sole discretion, to
                  disqualify any participant's account who has breached any of
                  these T&Cs, has engaged in unlawful or improper conduct or
                  otherwise acts to cheat or undermine the fairness of the
                  Competition/s by, for example, tampering with, or using or
                  exploiting errors in, the participation process to obtain a
                  competitive advantage over other entrants.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  18. SureShotSports FZ-LLC, if necessary to do so, reserve the
                  right to suspend, amend, void, or even cancel a game. This may
                  be necessary for reasons beyond our reasonable control
                  including, but not limited to strike, labour dispute,
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  illness, act of God, natural disaster, adverse weather
                  conditions, war, riot, accident, public health concern in
                  relation to potential infection, epidemic or pandemic,
                  malicious damage, fire, flood and/or storm, compliance with
                  law or governmental order, rule, regulation or direction,
                  breakdown of plant, machinery, or transportation. SSS are not
                  responsible for any loss or damage caused to you as a result
                  of any of these occurrences.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  19. The eligibility to participate is solely at the discretion
                  of the Company and we reserve the right to block or not accept
                  any registration.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  20. The Company assumes no responsibility for any failure to
                  receive participation or for inaccurate information or any
                  loss, damage, or injury because of technical or
                  telecommunications problems, including security breaches. If
                  such problems arise, the Company may (where necessary with the
                  approval of the relevant authority) modify, cancel, terminate,
                  or suspend the Competition/s.
                </p>

                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  21. MoneyBall Champions League
                </p>

                <p className="text-[15px] mt-[1%] ml-[1%] text-[#0000005A] font-semibold">
                  21.1 With the exception of the below deviations, all other
                  rules from traditional MoneyBall apply.
                </p>

                <p className="text-[15px] mt-[1%] ml-[1%] text-[#0000005A] font-semibold">
                  21.2 Unlike normal league based MoneyBall competitions, the
                  Champions League version of MoneyBall will be initially based
                  on a league system. After the first round of 8 match weeks the
                  game will be based on a knock-out system..
                </p>

                <p className="text-[15px] mt-[1%] ml-[1%] text-[#0000005A] font-semibold">
                  21.3 During the first eight match weeks players will be able
                  to make their selections among the 36 clubs involved.
                </p>
                <p className="text-[15px] mt-[1%] ml-[1%] text-[#0000005A] font-semibold">
                  21.4 After match week eight, match week 9 & 10 will be the two
                  legs of the play-off which will be between the teams who
                  finished 9th -24th in the table. This means that your
                  selections will be restricted to those 16 teams.
                </p>
                <p className="text-[15px] mt-[1%] ml-[1%] text-[#0000005A] font-semibold">
                  21.5 Match week 11 and beyond will be based on the 16 teams
                  that have qualified to the round of 16. These will include the
                  8 top teams after match week 8 in addition to the 8 teams
                  qualified from match week 9 & 10 play-off.
                </p>

                <p className="text-[15px] mt-[1%] ml-[1%] text-[#0000005A] font-semibold">
                  21.6 Regardless of how many picks remain alive the game ends
                  after the second leg of the quarter-finals. The semi-finals
                  and final will not be included in the game.
                </p>
                <p className="text-[15px] mt-[1%] ml-[1%] text-[#0000005A] font-semibold">
                  21.7 Each leg of each play-off & knock out stage will be
                  considered a separate match week. For example leg 1 of the
                  round of 16 is one match week while leg 2 is another.
                </p>

                <p className="text-[15px] mt-[1%] ml-[1%] text-[#0000005A] font-semibold">
                  21.8 Should we have more than one pick remaining after the
                  second leg of the quarter final the jackpot will be
                  distributed equally among the remaining picks
                </p>
                <p className="text-[15px] mt-[1%] ml-[1%] text-[#0000005A] font-semibold">
                  21.9 There could be a situation where players are still alive
                  at the quarter final stage and have no teams to pick from. For
                  Example, the 8 teams who qualified to the quarter final had
                  already been selected in prior match weeks by the player. In
                  this case when a player has no teams available to pick from
                  he/she will be automatically eliminated. Accordingly players
                  must be very careful in making their selections early so as to
                  ensure they have options during the quarter finals.
                </p>
                <p className="text-[15px] mt-[1%] ml-[1%] text-[#0000005A] font-semibold">
                  21.10 Defaults at every stage will be based on the table
                  ranking in the first 8 match weeks. During the play-off &
                  knock-out a default will be assigned based on the lowest rank
                  team playing in these round as per the ranking after match
                  week 8.
                </p>
              </Card>
            </div>
          </div>

          <MobileFooter />
        </div>
      ) : isTab ? (
        <div className="overflow-auto bg-[url('../public/card/tab-bg.svg')] bg-cover h-[100vh]">
          <div className="h-[100vh] w-full p-5 font-Inter">
            <div className="flex w-full items-center px-3 pb-5 font-interFont">
              <div className="w-[44%] ">
                <img src={Logo} alt="pickNdPrizes" onClick={redirectHome} />
              </div>
              <div className="w-[56%] justify-end mr-[1%] mt-[1%] flex float-right ">
                {!isLogedIn ? (
                  <>
                    {" "}
                    <p className="text-[#FFFFFF] mt-4">Not a member yet ?</p>
                    <p
                      className="text-[#00C05B] font-[18px] mt-4 ml-2 cursor-pointer"
                      onClick={redirectToRegister}
                    >
                      Register Now
                    </p>
                  </>
                ) : null}

                <button
                  className="w-[90px] h-[35px] mt-2 text-[14px] font-[500] bg-[#00C05B] text-[#002427] ml-5 rounded-[4px]"
                  onClick={redirectToLogin}
                >
                  {!isLogedIn ? "Login" : "Play now"}
                </button>
              </div>
            </div>

            <hr className="w-[95%] opacity-[.2] m-auto"></hr>
            {/* <div> */}
            <div className="mt-[2%]">
              <h2 className="text-[#FFFFFF] text-[48px] text-center font-interFont">
                Rules
              </h2>
            </div>

            <Card className="m-auto h-[70vh] font-interFont overflow-auto pl-12 pt-12 pb-12 pr-12 flex flex-col justify-between w-[95%] my-[2%] bg-[#FFFFFF] shadow-md rounded-[12px]">
              <h4 className="text-[22px] mt-[3%] text-[#000000] font-semibold">
                Rules of MoneyBall (the game)
              </h4>
              <p className="text-[15px] mt-[1%] font-interFont text-[#0000005A] font-semibold">
                1. By accepting the Terms and Conditions, you agree to the
                Competition Rules, and it will be legally binding on you.
              </p>
              <p className="text-[15px] mt-[1%] font-interFont text-[#0000005A] font-semibold">
                2. The rules of MoneyBall are the same for all MoneyBall games.
              </p>
              <p className="text-[15px] mt-[1%] font-interFont text-[#0000005A] font-semibold">
                3. The entry fee and the prize money for each round of MoneyBall
                are pre-determined before the start of the round. The aggregate
                of all the entry fees in a particular round minus a 10%
                administrative fee will make up the prize money or the jackpot
                of that round.
              </p>
              <p className="text-[15px] mt-[1%] font-interFont text-[#0000005A] font-semibold">
                4. Using each account a participant can join any MoneyBall round
                with one or two picks. Two is the maximum per participant in a
                single round.
              </p>
              <p className="text-[15px] mt-[1%] font-interFont text-[#0000005A] font-semibold">
                5. If a participant is playing with two picks each pick is
                treated completely independently in terms of playing the game.
              </p>
              <p className="text-[15px] mt-[1%] font-interFont text-[#0000005A] font-semibold">
                6. The participant shall join any round (active/open round) of
                the competition up until the deadline of that round. The
                deadline of each round and each match week within the round is
                set at 1 hour prior to the commencement of the first match in
                that match week.
              </p>
              <p className="text-[15px] mt-[1%] font-interFont text-[#0000005A] font-semibold">
                7. The objective of the game is to assign each of your picks to
                a team in that week’s fixture list who the participant thinks
                they win their match. If the selected team wins their match that
                pick progresses to the following match week. If the selected
                team draws or loses their match, then that pick is eliminated.
              </p>
              <p className="text-[15px] mt-[1%] font-interFont text-[#0000005A] font-semibold">
                Example: Pick 1: Arsenal, and Pick 2: Liverpool. Arsenal draw
                and Liverpool win then Pick 1 is eliminated and Pick 2
                progresses.
              </p>
              <p className="text-[15px] mt-[1%] font-interFont text-[#0000005A] font-semibold">
                If your pick is successful, then you progress to the following
                week and must repeat the process.
              </p>
              <p className="text-[15px] mt-[1%] font-interFont text-[#0000005A] font-semibold">
                8. Once a team is picked it cannot be chosen again under the
                same pick until the round is over.
              </p>
              <p className="text-[15px] mt-[1%] font-interFont text-[#0000005A] font-semibold">
                Example: Since Pick 2 above selected Liverpool and they won
                their match, then that player can no longer choose Liverpool
                under Pick 2 for the rest of the round.
              </p>

              <p className="text-[15px] mt-[1%] font-interFont text-[#0000005A] font-semibold">
                9. Once again, and as indicated in point 5.4 above, if a
                participant is playing in a round with two picks, then they are
                treated independently.
              </p>
              <p className="text-[15px] mt-[1%] font-interFont text-[#0000005A] font-semibold">
                Using the same example in point 6 above, let’s assume both
                Arsenal & Liverpool have won their respective matches, in that
                case Pick 1 will no longer be able to choose Arsenal again until
                the round is over, however Pick 2 can still use Arsenal since it
                hasn’t been used before.
              </p>
              <p className="text-[15px] mt-[1%] font-interFont text-[#0000005A] font-semibold">
                Consequently, since the two entries are treated independently a
                player can pick the same team for both their picks as long as he
                hasn’t used them previously in the same round.
              </p>
              <p className="text-[15px] mt-[1%] font-interFont text-[#0000005A] font-semibold">
                10. If a pick is not submitted before the deadline, that pick
                will automatically be assigned to the lowest ranked team in the
                league table that is available for that pick. If a participant
                is playing with two picks and misses the deadline on both, then
                one pick will be assigned to the lowest ranked team available
                from the league table while the second pick will be assigned to
                the second to last available team.
              </p>
              <p className="text-[15px] mt-[1%] font-interFont text-[#0000005A] font-semibold">
                Example: Sheffield and Burnley are ranked 20th and 19th in the
                Premier League coming into the match week. A participant who is
                playing with two picks misses the deadline. In that case, Pick 1
                will automatically select Sheffield while Pick 2 will
                automatically select Burnley. Let’s assume that participant had
                already picked Burnley under Pick 2 in a prior match week within
                that same round. In that case Pick 2 will automatically select
                the 18th ranked team in the table and so on.
              </p>
              <p className="text-[15px] mt-[1%] font-interFont text-[#0000005A] font-semibold">
                11. Each pick is only allowed one default per round. The second
                default within the same round will result in automatic
                elimination.
              </p>
              <p className="text-[15px] mt-[1%] font-interFont text-[#0000005A] font-semibold">
                12. Once a pick is made it can be changed up till the deadline
                of that match week.
              </p>
              <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                13. If a match is postponed prior to the deadline lapsing the
                player must re-pick by choosing a team from the remaining
                fixtures that match week. If the match is postponed after the
                deadline has passed, then any picks for that fixture will be
                deemed successful and the players involved will not be allowed
                to pick those same teams again in that round.
              </p>
              <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                14. Any matches played out of the normal schedule or that were
                previously postponed will not be part of the fixtures to select
                from and will be ignored.
              </p>
              <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                15. A match week will only be considered for selection if it has
                a minimum of 8 fixtures. Match weeks that have less will be
                skipped in the schedule.
              </p>
              <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                16. The Company reserves the right, in its sole discretion, to
                refuse to accept participation that is incomplete,
                indecipherable, offensive, does not comply with these T&Cs, or
                contravenes any applicable laws or regulation.
              </p>
              <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                17. The Company reserves the right, in its sole discretion, to
                disqualify any participant's account who has breached any of
                these T&Cs, has engaged in unlawful or improper conduct or
                otherwise acts to cheat or undermine the fairness of the
                Competition/s by, for example, tampering with, or using or
                exploiting errors in, the participation process to obtain a
                competitive advantage over other entrants.
              </p>
              <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                18. SureShotSports FZ-LLC, if necessary to do so, reserve the
                right to suspend, amend, void, or even cancel a game. This may
                be necessary for reasons beyond our reasonable control
                including, but not limited to strike, labour dispute,
              </p>
              <p className="text-[15px] mt-[1%] font-interFont text-[#0000005A] font-semibold">
                illness, act of God, natural disaster, adverse weather
                conditions, war, riot, accident, public health concern in
                relation to potential infection, epidemic or pandemic, malicious
                damage, fire, flood and/or storm, compliance with law or
                governmental order, rule, regulation or direction, breakdown of
                plant, machinery, or transportation. SSS are not responsible for
                any loss or damage caused to you as a result of any of these
                occurrences.
              </p>
              <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                19. The eligibility to participate is solely at the discretion
                of the Company and we reserve the right to block or not accept
                any registration.
              </p>
              <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                20. The Company assumes no responsibility for any failure to
                receive participation or for inaccurate information or any loss,
                damage, or injury because of technical or telecommunications
                problems, including security breaches. If such problems arise,
                the Company may (where necessary with the approval of the
                relevant authority) modify, cancel, terminate, or suspend the
                Competition/s.
              </p>

              <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  21. MoneyBall Champions League
                </p>

                <p className="text-[15px] mt-[1%] ml-[1%] text-[#0000005A] font-semibold">
                  21.1 With the exception of the below deviations, all other
                  rules from traditional MoneyBall apply.
                </p>

                <p className="text-[15px] mt-[1%] ml-[1%] text-[#0000005A] font-semibold">
                  21.2 Unlike normal league based MoneyBall competitions, the
                  Champions League version of MoneyBall will be initially based
                  on a league system. After the first round of 8 match weeks the
                  game will be based on a knock-out system..
                </p>

                <p className="text-[15px] mt-[1%] ml-[1%] text-[#0000005A] font-semibold">
                  21.3 During the first eight match weeks players will be able
                  to make their selections among the 36 clubs involved.
                </p>
                <p className="text-[15px] mt-[1%] ml-[1%] text-[#0000005A] font-semibold">
                  21.4 After match week eight, match week 9 & 10 will be the two
                  legs of the play-off which will be between the teams who
                  finished 9th -24th in the table. This means that your
                  selections will be restricted to those 16 teams.
                </p>
                <p className="text-[15px] mt-[1%] ml-[1%] text-[#0000005A] font-semibold">
                  21.5 Match week 11 and beyond will be based on the 16 teams
                  that have qualified to the round of 16. These will include the
                  8 top teams after match week 8 in addition to the 8 teams
                  qualified from match week 9 & 10 play-off.
                </p>

                <p className="text-[15px] mt-[1%] ml-[1%] text-[#0000005A] font-semibold">
                  21.6 Regardless of how many picks remain alive the game ends
                  after the second leg of the quarter-finals. The semi-finals
                  and final will not be included in the game.
                </p>
                <p className="text-[15px] mt-[1%] ml-[1%] text-[#0000005A] font-semibold">
                  21.7 Each leg of each play-off & knock out stage will be
                  considered a separate match week. For example leg 1 of the
                  round of 16 is one match week while leg 2 is another.
                </p>

                <p className="text-[15px] mt-[1%] ml-[1%] text-[#0000005A] font-semibold">
                  21.8 Should we have more than one pick remaining after the
                  second leg of the quarter final the jackpot will be
                  distributed equally among the remaining picks
                </p>
                <p className="text-[15px] mt-[1%] ml-[1%] text-[#0000005A] font-semibold">
                  21.9 There could be a situation where players are still alive
                  at the quarter final stage and have no teams to pick from. For
                  Example, the 8 teams who qualified to the quarter final had
                  already been selected in prior match weeks by the player. In
                  this case when a player has no teams available to pick from
                  he/she will be automatically eliminated. Accordingly players
                  must be very careful in making their selections early so as to
                  ensure they have options during the quarter finals.
                </p>
                <p className="text-[15px] mt-[1%] ml-[1%] text-[#0000005A] font-semibold">
                  21.10 Defaults at every stage will be based on the table
                  ranking in the first 8 match weeks. During the play-off &
                  knock-out a default will be assigned based on the lowest rank
                  team playing in these round as per the ranking after match
                  week 8.
                </p>
            </Card>
            {/* </div> */}
          </div>
          <FooterTab />
        </div>
      ) : (
        <div className="overflow-auto bg-[url('../public/card/home-bg.svg')] bg-cover h-[100vh]">
          <div
            className="h-[100vh] w-full p-5 
          laptop-min:[1200px] mb-[50px] laptop-l:h-[857px] laptop-s:h-[800px] font-Inter"
          >
            <div className="flex w-full p-3">
              <div className="w-[75%] ml-[1.5%] laptop-l:w-[70%] laptop-s:w-[58%] ">
                <img src={Logo} alt="pickNdPrizes" onClick={redirectHome}></img>
              </div>
              <div className="w-[25%] justify-end mr-[2%] mt-[1%] flex float-right laptop-l:w-[30%] laptop-s:w-[42%]">
                {!isLogedIn ? (
                  <>
                    {window.innerWidth <= 500 ? (
                      ""
                    ) : (
                      <p className="text-[#FFFFFF] mt-2">Not a member yet ?</p>
                    )}
                    {/* <p className='text-[#FFFFFF] mt-2'>Not a member yet ?</p> */}

                    {window.innerWidth <= 500 ? (
                      <button className=" border-[1px] border-[#00C05B] w-[95px] h-[37px] text-[14px] cursor-pointer text-[#00C05B]">
                        Register
                      </button>
                    ) : (
                      <p
                        className="text-[#00C05B] font-[18px] mt-2 ml-2 cursor-pointer"
                        onClick={redirectToRegister}
                      >
                        Register Now
                      </p>
                    )}
                  </>
                ) : null}
                {/* <p className='text-[#00C05B] font-[18px] mt-2 ml-2 cursor-pointer'>Register Now</p> */}
                <button
                  className="w-[90px] h-[35px] text-[18px] font-[500] bg-[#00C05B] text-[#002427] ml-5 rounded-[4px]"
                  onClick={redirectToLogin}
                >
                  {!isLogedIn ? "Login" : "Play now"}
                </button>
              </div>
            </div>

            <hr className="w-[95%] opacity-[.2] m-auto"></hr>
            <div>
              <div>
                <h2 className="text-[#FFFFFF] text-[48px] text-center">
                  Rules
                </h2>
              </div>

              <Card className="m-auto laptop-s:h-[600px] laptop-l:h-[65vh] desktop:h-[80vh] overflow-auto  pl-16 pt-12 pb-12 pr-16 flex flex-col justify-between w-[95%] my-[1%] bg-[#FFFFFF] shadow-md rounded-[12px] ">
                <h4 className="text-[22px] mt-[3%] text-[#000000] font-semibold">
                  Rules of MoneyBall (the game)
                </h4>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  1. By accepting the Terms and Conditions, you agree to the
                  Competition Rules, and it will be legally binding on you.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  2. The rules of MoneyBall are the same for all MoneyBall
                  games.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  3. The entry fee and the prize money for each round of
                  MoneyBall are pre-determined before the start of the round.
                  The aggregate of all the entry fees in a particular round
                  minus a 10% administrative fee will make up the prize money or
                  the jackpot of that round.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  4. Using each account a participant can join any MoneyBall
                  round with one or two picks. Two is the maximum per
                  participant in a single round.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  5. If a participant is playing with two picks each pick is
                  treated completely independently in terms of playing the game.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  6. The participant shall join any round (active/open round) of
                  the competition up until the deadline of that round. The
                  deadline of each round and each match week within the round is
                  set at 1 hour prior to the commencement of the first match in
                  that match week.
                </p>

                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  7. The objective of the game is to assign each of your picks
                  to a team in that week’s fixture list who the participant
                  thinks they win their match. If the selected team wins their
                  match that pick progresses to the following match week. If the
                  selected team draws or loses their match, then that pick is
                  eliminated.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  Example: Pick 1: Arsenal, and Pick 2: Liverpool. Arsenal draw
                  and Liverpool win then Pick 1 is eliminated and Pick 2
                  progresses.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  If your pick is successful, then you progress to the following
                  week and must repeat the process.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  8. Once a team is picked it cannot be chosen again under the
                  same pick until the round is over.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  Example: Since Pick 2 above selected Liverpool and they won
                  their match, then that player can no longer choose Liverpool
                  under Pick 2 for the rest of the round.
                </p>

                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  9. Once again, and as indicated in point 5.4 above, if a
                  participant is playing in a round with two picks, then they
                  are treated independently.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  Using the same example in point 6 above, let’s assume both
                  Arsenal & Liverpool have won their respective matches, in that
                  case Pick 1 will no longer be able to choose Arsenal again
                  until the round is over, however Pick 2 can still use Arsenal
                  since it hasn’t been used before.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  Consequently, since the two entries are treated independently
                  a player can pick the same team for both their picks as long
                  as he hasn’t used them previously in the same round.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  10. If a pick is not submitted before the deadline, that pick
                  will automatically be assigned to the lowest ranked team in
                  the league table that is available for that pick. If a
                  participant is playing with two picks and misses the deadline
                  on both, then one pick will be assigned to the lowest ranked
                  team available from the league table while the second pick
                  will be assigned to the second to last available team.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  Example: Sheffield and Burnley are ranked 20th and 19th in the
                  Premier League coming into the match week. A participant who
                  is playing with two picks misses the deadline. In that case,
                  Pick 1 will automatically select Sheffield while Pick 2 will
                  automatically select Burnley. Let’s assume that participant
                  had already picked Burnley under Pick 2 in a prior match week
                  within that same round. In that case Pick 2 will automatically
                  select the 18th ranked team in the table and so on.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  11. Each pick is only allowed one default per round. The
                  second default within the same round will result in automatic
                  elimination.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  12. Once a pick is made it can be changed up till the deadline
                  of that match week.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  13. If a match is postponed prior to the deadline lapsing the
                  player must re-pick by choosing a team from the remaining
                  fixtures that match week. If the match is postponed after the
                  deadline has passed, then any picks for that fixture will be
                  deemed successful and the players involved will not be allowed
                  to pick those same teams again in that round.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  14. Any matches played out of the normal schedule or that were
                  previously postponed will not be part of the fixtures to
                  select from and will be ignored.
                </p>
                <p className="text-[17px] mt-[1%]%] text-[#0000005A] font-semibold">
                  15. A match week will only be considered for selection if it
                  has a minimum of 8 fixtures. Match weeks that have less will
                  be skipped in the schedule.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  16. The Company reserves the right, in its sole discretion, to
                  refuse to accept participation that is incomplete,
                  indecipherable, offensive, does not comply with these T&Cs, or
                  contravenes any applicable laws or regulation.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  17. The Company reserves the right, in its sole discretion, to
                  disqualify any participant's account who has breached any of
                  these T&Cs, has engaged in unlawful or improper conduct or
                  otherwise acts to cheat or undermine the fairness of the
                  Competition/s by, for example, tampering with, or using or
                  exploiting errors in, the participation process to obtain a
                  competitive advantage over other entrants.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  18. SureShotSports FZ-LLC, if necessary to do so, reserve the
                  right to suspend, amend, void, or even cancel a game. This may
                  be necessary for reasons beyond our reasonable control
                  including, but not limited to strike, labour dispute,
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  illness, act of God, natural disaster, adverse weather
                  conditions, war, riot, accident, public health concern in
                  relation to potential infection, epidemic or pandemic,
                  malicious damage, fire, flood and/or storm, compliance with
                  law or governmental order, rule, regulation or direction,
                  breakdown of plant, machinery, or transportation. SSS are not
                  responsible for any loss or damage caused to you as a result
                  of any of these occurrences.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  19. The eligibility to participate is solely at the discretion
                  of the Company and we reserve the right to block or not accept
                  any registration.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  20. The Company assumes no responsibility for any failure to
                  receive participation or for inaccurate information or any
                  loss, damage, or injury because of technical or
                  telecommunications problems, including security breaches. If
                  such problems arise, the Company may (where necessary with the
                  approval of the relevant authority) modify, cancel, terminate,
                  or suspend the Competition/s.
                </p>

                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  21. MoneyBall Champions League
                </p>

                <p className="text-[17px] mt-[1%] ml-[1%] text-[#0000005A] font-semibold">
                  21.1 With the exception of the below deviations, all other
                  rules from traditional MoneyBall apply.
                </p>

                <p className="text-[17px] mt-[1%] ml-[1%] text-[#0000005A] font-semibold">
                  21.2 Unlike normal league based MoneyBall competitions, the
                  Champions League version of MoneyBall will be initially based
                  on a league system. After the first round of 8 match weeks the
                  game will be based on a knock-out system..
                </p>

                <p className="text-[17px] mt-[1%] ml-[1%] text-[#0000005A] font-semibold">
                  21.3 During the first eight match weeks players will be able
                  to make their selections among the 36 clubs involved.
                </p>
                <p className="text-[17px] mt-[1%] ml-[1%] text-[#0000005A] font-semibold">
                  21.4 After match week eight, match week 9 & 10 will be the two
                  legs of the play-off which will be between the teams who
                  finished 9th -24th in the table. This means that your
                  selections will be restricted to those 16 teams.
                </p>
                <p className="text-[17px] mt-[1%] ml-[1%] text-[#0000005A] font-semibold">
                  21.5 Match week 11 and beyond will be based on the 16 teams
                  that have qualified to the round of 16. These will include the
                  8 top teams after match week 8 in addition to the 8 teams
                  qualified from match week 9 & 10 play-off.
                </p>

                <p className="text-[17px] mt-[1%] ml-[1%] text-[#0000005A] font-semibold">
                  21.6 Regardless of how many picks remain alive the game ends
                  after the second leg of the quarter-finals. The semi-finals
                  and final will not be included in the game.
                </p>
                <p className="text-[17px] mt-[1%] ml-[1%] text-[#0000005A] font-semibold">
                  21.7 Each leg of each play-off & knock out stage will be
                  considered a separate match week. For example leg 1 of the
                  round of 16 is one match week while leg 2 is another.
                </p>

                <p className="text-[17px] mt-[1%] ml-[1%] text-[#0000005A] font-semibold">
                  21.8 Should we have more than one pick remaining after the
                  second leg of the quarter final the jackpot will be
                  distributed equally among the remaining picks
                </p>
                <p className="text-[17px] mt-[1%] ml-[1%] text-[#0000005A] font-semibold">
                  21.9 There could be a situation where players are still alive
                  at the quarter final stage and have no teams to pick from. For
                  Example, the 8 teams who qualified to the quarter final had
                  already been selected in prior match weeks by the player. In
                  this case when a player has no teams available to pick from
                  he/she will be automatically eliminated. Accordingly players
                  must be very careful in making their selections early so as to
                  ensure they have options during the quarter finals.
                </p>
                <p className="text-[17px] mt-[1%] ml-[1%] text-[#0000005A] font-semibold">
                  21.10 Defaults at every stage will be based on the table
                  ranking in the first 8 match weeks. During the play-off &
                  knock-out a default will be assigned based on the lowest rank
                  team playing in these round as per the ranking after match
                  week 8.
                </p>
              </Card>
            </div>
          </div>
          <Footer componnent="rules" />
        </div>
      )}
    </>
  );
}

export default Rulse;
