import React, { useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import Countdown from "react-countdown";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Card from "../../Elements/Card";
import Button from "../../Elements/Button";
import AuthLayout from "../AuthLayout/AuthLayout";
import { resendOTPCode, verifyOTPCode, activateAccount } from "../../services/auth/verification";

function VerificationCode() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const [resent, setResent] = useState(true);
  const [verifyBtn, setVerifyBtn] = useState(false);
  const [isServerErrorMsg, setServerIsErrorMsg] = useState(false);
  const [resendOtpSucc, setResendOtpSucc] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Please Enter OTP");
  const [isErrorMsg, setIsErrorMsg] = useState(false);
  const [serverError, setServerError] = useState("");
  const [countdownTime, setCountdownTime] = useState(Date.now() + 300000);

  useEffect(() => {
    const storedEndTime = localStorage.getItem("countdownEndTime");
    if (storedEndTime) {
      const remainingTime = new Date(storedEndTime).getTime() - Date.now();
      if (remainingTime > 0) {
        setCountdownTime(new Date(storedEndTime).getTime());
        setResent(true);
      } else {
        setResent(false);
      }
    } else {
      const newEndTime = Date.now() + 300000; // 5 minutes from now
      localStorage.setItem("countdownEndTime", new Date(newEndTime).toISOString());
      setCountdownTime(newEndTime);
      setResent(true);
    }
  }, []);

  const handleChange = (enteredOtp) => {
    setIsErrorMsg(false);
    setServerError("");
    setOtp(enteredOtp);
    setVerifyBtn(enteredOtp.length === 4);
  };

  const OtpCounter = ({ minutes, seconds, completed }) => {
    if (completed) {
      setResent(false);
      return null;
    } else {
      return (
        <span>
          {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </span>
      );
    }
  };

  const renderInput = (inputProps, index) => {
    const handleInputChange = (event, currentIndex) => {
      const { value } = event.target;
      const newValue = value.replace(/\D/g, "");
      setOtp((prevOtp) => {
        const newOtp =
          prevOtp.slice(0, currentIndex) +
          newValue.slice(-1) +
          prevOtp.slice(currentIndex + 1);
        return newOtp;
      });
      if (newValue.length === 1 && currentIndex < 3) {
        const nextIndex = currentIndex + 1;
        const nextInput = document.getElementById(`otp-input-${nextIndex}`);
        if (nextInput) {
          nextInput.focus();
          nextInput.select();
        }
      }
    };

    return (
      <input
        {...inputProps}
        maxLength={1}
        id={`otp-input-${index}`}
        className="otp-input w-12 h-12 py-2 border border-gray-300 rounded text-center focus:outline-none focus:border-blue-500 mx-[5%]"
        style={{ width: "40px", height: "40px", border: "1px solid black" }}
        key={index}
        autoFocus={index === 0}
        onChange={(e) => handleInputChange(e, index)}
      />
    );
  };

  const verifyEmail = localStorage.getItem("registerEmail");
  let pageName = window.location.pathname.split("/")[2];

  const handleResend = async () => {
    setOtp("");
    const newEndTime = Date.now() + 300000;
    localStorage.setItem("countdownEndTime", new Date(newEndTime).toISOString());
    setCountdownTime(newEndTime);
    setVerifyBtn(true);
    setServerIsErrorMsg(false);
    setResent(true);
    let res = await dispatch(resendOTPCode("/accounts/resendotp", { email: verifyEmail }));
    setResendOtpSucc(res?.status === "Success");
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setServerIsErrorMsg(false);
    if (otp.length === 4) {
      setIsErrorMsg(false);
      if (verifyEmail && otp) {
        let res;
        if (pageName === "forgotPassword") {
          res = await dispatch(verifyOTPCode("/accounts/verifyotp", { otp, email: verifyEmail }));
        } else if (pageName === "activateAccount") {
          res = await dispatch(activateAccount("/accounts/activate", { otp, email: verifyEmail }));
        }

        if (res?.status === "Success") {
          if (pageName === "forgotPassword") {
            navigate("/resetPassword");
          } else if (pageName === "activateAccount") {
            localStorage.removeItem("registerEmail");
            navigate("/login");
          }
        } else {
          setOtp("");
          setServerIsErrorMsg(true);
          setServerError("OTP Invalid");
        }
      } else {
        setIsErrorMsg(true);
      }
    } else {
      setIsErrorMsg(true);
    }
  };

  return (
    <AuthLayout>
      <Card className="m-auto bg-[#FFFFFF] mobile-s:w-[90%] mobile-s:mt-[10%] font-[inter] mobile-l:mt-[30%] mobile-l:w-[80%] mobile-s:h-[50%] shadow-md rounded-[12px] tablet:h-[45%] tablet:mx-auto tablet:my-auto tablet:w-[65%] laptop-s:h-[60%] laptop-s:w-[45%] laptop-s:mt-[10%] laptop-l:!mt-[10%] laptop-min:h-[70%] laptop-min:w-[35%] laptop-l:w-[28%] laptop-l:h-[55%] desktop:w-[20%] desktop:h-[60%] flex flex-col justify-center">
        {resendOtpSucc && <p className="text-green-500 text-center">Resend OTP sent to your email</p>}
        <div>
          <div className="justify-center">
            <h2 className="text-center text-[28px] laptop-min:!text-[28px] desktop:text-[32px] font-semibold mb-5">
              {pageName === "forgotPassword" ? "Enter Code" : "Your account has not yet been verified"}
            </h2>
            <p className="text-center laptop-s:text-[18px] laptop-l:text-[18px] text-nowrap text-[15px] desktop:text-[22px] text-[#000000] text-opacity-50 mb-6">
              Please enter the verification code that <br />
              <span className="mobile-s:text-[13px] laptop-l:text-[18px] gap-2 mobile-m:text-[15px] laptop-s:text-[18px] desktop:text-[22px] justify-center text-[#000000] flex text-opacity-50">
                we sent to <p className="text-[#000000]"> {verifyEmail}</p>
              </span>
            </p>
          </div>
          <form onSubmit={submitHandler}>
            <div className="flex items-center tablet:ml-8 laptop-s:ml-0 mb-2">
              <div className="flex w-[80%] laptop-s:w-[90%] desktop:w-[90%] items-center justify-center space-x-2 mb-4">
                <OtpInput
                  name="otp"
                  value={otp}
                  onChange={handleChange}
                  numInputs={4}
                  separator={<span>-</span>}
                  renderInput={renderInput}
                />
              </div>
            </div>
            <div className="flex justify-center">
              {isServerErrorMsg && <p className="text-red-500 text-center">{serverError}</p>}
              {isErrorMsg && <p className="text-red-500">{errorMsg}</p>}
            </div>
            <div className="mt-1 text-center">
              <Button className="bg-blue-500 w-[80%] hover:bg-blue-700 p-2 py-3 text-white font-bold rounded focus:outline-none focus:shadow-outline mt-6 mb-4" type="submit">
                Verify Code
              </Button>
            </div>
          </form>
          <div className="mt-1 text-center">
            <p className="text-[#000000] mobile-s:text-[13px] text-[15px] px-2">
              Didn’t receive code? Check your spam or resend code after {" "}
              {!resent && (
                <a className="text-blue-500 underline focus:outline-none cursor-pointer" onClick={handleResend}>
                  Resend code
                </a>
              )}
              {resent && <Countdown key={countdownTime} date={countdownTime} renderer={OtpCounter} />}
            </p>
          </div>
        </div>
      </Card>
    </AuthLayout>
  );
}

export default VerificationCode;
