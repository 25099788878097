let currencyShort = [
  {
    s_code: "en-IN",
    c_code: "INR",
  },
  {
    s_code: "en-AE",
    c_code: "AED",
  },
  {
    s_code: "en-AE",
    c_code: "ANG",
  },
];

export function formatAsIndianCurrency(amount, currencyCode) {
  if (currencyCode !== " ") {
    currencyCode = currencyCode;
  } else {
    currencyCode = "AED";
  }
  const res = currencyShort.filter((currency) => {
    if (currencyCode === currency?.c_code) {
      return currency;
    }
  });

  const formatter = new Intl.NumberFormat(res[0]?.s_code, {
    style: "currency",
    currency: currencyCode,
  });
  return formatter.format(amount).split('.')[0];
}
