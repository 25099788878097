import React, { useEffect, useState } from "react";
import PlayerLayout from "../PlayerLayout/PlayerLayout";

import ActiveTeam from "../ViewDetails/ActiveTeam/ActiveTeam";
import { useDispatch, useSelector } from "react-redux";
import { fetchMyWinnings } from "../../services/myPicks/myPicks";
import { utcTimetoLocalTimeWithzone } from "../../utils/convertTime";

import useFindDevice from "../CustomHooks/FindDevice";
import { fetchTransactionHistory } from "../../services/transaction/transaction";
import TeamDetailHOC from "../TeamDetailHOC/TeamDetailHOC";

import greenDat from "../../assets/card/green-dat.svg";
import redDat from "../../assets/card/red-dat.svg";

const tableHeader = [
  {
    id: 1,
    cellName: "S.No",
    value: "sNo",
  },
  {
    id: 2,
    cellName: "Payment ID",
    value: "paymentId",
  },
  {
    id: 3,
    cellName: "Payment Date",
    value: "paymentDate",
  },

  {
    id: 4,
    cellName: "Payment Amount",
    value: "paymentAmount",
  },
  {
    id: 5,
    cellName: "Payment Status",
    value: "paymentStatus",
  },
];

function TransactionHistory({ isMobile, isLaptop }) {
  const dispatch = useDispatch();
  // const { isMob, isTab, isLaptop, isLogedIn } = useFindDevice();

  const [transaction, setTransaction] = useState({
    transactionHistory: [],
    pagination: {},
    transactionHistoryMobile: [],
  });

  const transactionInfo = useSelector((state) => {
    return {
      transactionHistory: state?.transaction?.transactionInfo,
      pagination: state?.transaction?.pagination,
      transactionHistoryMobile: state?.transaction?.transactionMobiles,
    };
  });

  useEffect(() => {
    setTransaction((prev) => ({
      transactionHistory: transactionInfo.transactionHistory,
      pagination: transactionInfo.pagination,
      transactionHistoryMobile: transactionInfo.transactionHistoryMobile,
    }));
  }, [
    transactionInfo?.transactionHistory,
    transactionInfo?.pagination,
    transactionInfo?.transactionHistoryMobile,
  ]);

  useEffect(() => {
    if (transaction?.pagination?.currentPage) {
      dispatch(
        fetchTransactionHistory(
          `/pot/payouts?limit=10&page=${transactionInfo?.pagination?.currentPage}`
        )
      );
    }
  }, [transaction?.pagination?.currentPage]);

  useEffect(() => {
    if (transactionInfo?.transactionHistory?.length > 0) {
      const transactionInfo1 = transactionInfo?.transactionHistory.map(
        (ele, i) => {
          const date = ele?.updated_at
            ? utcTimetoLocalTimeWithzone(ele?.updated_at || "").split(" ")
            : "";
          if (ele?.updated_at) {
            const time = `${date[3]?.split(":")[0]}:${date[3]?.split(":")[1]}`;
            let concatDate = `${date[1]} ${date[2]} ${time} ${date[4]}`;
            return {
              sNo: i + 1,
              paymentId: ele?.order_no,
              paymentDate: concatDate,
              paymentAmount: (
                <div className="flex basis-1/2 justify-center gap-2">
                  <div className="flex justify-start">
                    <p>{ele.amount_payable}</p>
                  </div>{" "}
                  <div className="flex justify-self-start">
                    {ele.currency_code}
                  </div>
                </div>
              ),
              // paymentStatus: ele?.payment_status,
              paymentStatus: (
                <p
                  className={`text-wh font-semibold p-1 w-[25%] flex items-center gap-2 ml-36 rounded-[6px] ${
                    ele?.payment_status === "Failure"
                      ? "text-[#FF4956] bg-[#FF49560F]"
                      : ele?.payment_status === "Success"
                      ? "text-[#00A34D] bg-[#00A34D0F]"
                      : null
                  }`}
                >
                  {ele?.payment_status === "Success" ? (
                    <img src={greenDat} className="h-2 w-2" />
                  ) : ele?.payment_status === "Failure" ? (
                    <img src={redDat} className="h-2 w-2" />
                  ) : null}{" "}
                  {ele?.payment_status ? ele?.payment_status : "Idle"}
                </p>
              ),
            };
          }
        }
      );
      setTransaction((prev) => ({
        ...prev,
        transactionHistory: transactionInfo1,
        pagination: transactionInfo?.pagination,
      }));
    } else {
      setTransaction((prev) => ({
        ...prev,
        pagination: transactionInfo?.pagination,
      }));
    }
  }, [transactionInfo?.transactionHistory, transactionInfo.pagination]);

  return (
    <div>
      <PlayerLayout>
        <div className="mb-[5%]">
          <ActiveTeam
            isMobile={isMobile}
            isLabtop={isLaptop}
            tableHeader={tableHeader}
            tableBody={transaction?.transactionHistory}
            mobileTransactionHistory={transaction?.transactionHistoryMobile}
            pagination={transaction?.pagination}
            component="widthdrawHistory"
          />
        </div>
      </PlayerLayout>
    </div>
  );
}

export default TeamDetailHOC(TransactionHistory);
