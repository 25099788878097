import React, { useState, useEffect } from "react";
import logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import sitLogo from "../../assets/images/site-logo.png";

function AuthLayout(props) {
  const navigate = useNavigate();
  const URL = window.location.pathname;
  const handleChange = () => {
    // localStorage.removeItem("countdownEndTime");
    // localStorage.removeItem("countdownExpiration");
    // localStorage.removeItem("registerEmail");
    navigate("/home");
  };
  let hight = URL === "/verification" ? "min-h-screen" : "h-[100vh]";

  const [isMob, setIsMob] = useState(false);
  const [isTab, setIsTab] = useState(false);
  const [isLaptop, setIsLaptop] = useState(false);
  useEffect(() => {
    if (window.innerWidth <= 767) {
      setIsMob(true);
    } else {
      setIsMob(false);
    }
    if (window.innerWidth >= 768 && window.innerWidth <= 1057) {
      setIsTab(true);
    }
    if (window.innerWidth >= 1024) {
      setIsLaptop(true);
    }
  }, []);

  return (
    // bg-[url('../public/card/login-sceen-mobile.svg')]
    <div
      className={`flex m-0 p-0 ${
        isMob
          ? "bg-[url('../public/card/new-mobile-bg.svg')]"
          : "bg-[url('../public/images/login-bg.png')]"
      } bg-cover ${hight} items-center justify-center mobile-s:relative overflow-auto mobile-l:block mobile-s:flex mobile-s:flex-col tablet:flex h-[100vh]`}
    >
      <p className="flex justify-center">
        <img
          src={sitLogo}
          className="w-22 h-[4rem] cursor-pointer mobile-s:top-4 tablet:top-7 tablet:left-7 mobile-s:relative tablet:absolute"
          alt="Logo"
          onClick={handleChange}
        />
      </p>
      {props.children}
    </div>
  );
}

export default AuthLayout;
