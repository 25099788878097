import axiosClient, { baseURL } from "./axiosConfig";
import axios from "axios";

// GET request with async/await
export const getData = async (url, params = {}) => {
  try {
    const response = await axiosClient.get(url, { params });
    return response.data;
  } catch (error) {
    if (
      error.response.status === 401 &&
      error?.response?.data?.type === "Access"
    ) {
      refreshTocken();
    }
    console.log("error", error);
    throw error;
  }
};

// POST request with async/await
export const postData = async (url, data) => {
  try {
    const response = await axiosClient.post(url, data);
    return response.data;
  } catch (error) {
    if (
      error.response.status === 401 &&
      error?.response?.data?.type === "Access"
    ) {
      refreshTocken();
    }
    console.log("error", error);
    throw error;
  }
};

// PUT request with async/await
export const putData = async (url, data) => {
  try {
    const response = await axiosClient.put(url, data);
    return response.data;
  } catch (error) {
    if (
      error.response.status === 401 &&
      error?.response?.data?.type === "Access"
    ) {
      refreshTocken();
    }
    throw error;
  }
};

// DELETE request with async/await
export const deleteData = async (url) => {
  try {
    const response = await axiosClient.delete(url);
    return response.data;
  } catch (error) {
    if (
      error.response.status === 401 &&
      error?.response?.data?.type === "Access"
    ) {
      refreshTocken();
    }
    throw error;
  }
};

const refreshTocken = async () => {
  try {
    let refresh = localStorage.getItem("refresh");
    refresh = refresh.replace(/^"+|"+$/g, "");
    const res = await axios.post(`${baseURL}/accounts/token/refresh/`, {
      refresh: refresh,
    });
    if (res.status === 200) {
      localStorage.setItem("token", JSON.stringify(res.data.access));
      localStorage.setItem("refresh", JSON.stringify(res.data.refresh));
      window.location.href = window.location.href;
    }
  } catch (e) {
    localStorage.removeItem("token");
    localStorage.removeItem("isAuthUser");
    localStorage.removeItem("isAdminUser");
    localStorage.removeItem("userInfo");
    window.location.href = "/";
  }
};
