import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PlayerLayout from "../PlayerLayout/PlayerLayout";
import { Flip, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import PotCard from "./PotCard";
import { fetchDashboardData } from "../../services/backenApiCall";
import PickTeamModel from "../Models/PickTeamModel";
import SelectPicks from "../Models/SelectPicks";
import JoinPotModel from "../Models/JoinPotModel";
import JackPotVoteNowPopUp from "../Models/JackpotVotePopup";
import { potUpdateWithSocket, updateErrorValues } from "./dashboard.slice";

import { wsURL } from "../../services/axiosConfig";
import { updateTokenDetail } from "../PlayerLayout/playerlayout.slice";
import { RotatingLines } from "react-loader-spinner";

function GreenDashboard(props) {
  const dispatch = useDispatch();
  const potTypeDB = useSelector((state) => state.dashboard.potType);
  const isLoading = useSelector((state) => state.dashboard.loading);
  const erorrFromServer = useSelector((state) => state.dashboard.error);
  const succFromServer = useSelector((state) => state?.dashboard.succMsg);

  const handleToastClose = () => {
    dispatch(updateErrorValues());
  };

  useEffect(() => {
    toast.error(erorrFromServer, {
      onClose: handleToastClose,
      autoClose: 4000,
    });
    toast.success(succFromServer, {
      onClose: handleToastClose,
      autoClose: 2000,
    });
  }, [erorrFromServer, succFromServer]);

  useEffect(() => {
    const socket = new WebSocket(`${wsURL}/joinpot/pot`);

    socket.addEventListener("open", () => {
      console.log("WebSocket connection opened");
    });

    socket.addEventListener("message", (event) => {
      const res = JSON.parse(event?.data);
      if (res.data.message !== "Connection established") {
        dispatch(potUpdateWithSocket({ fields: res.data.fields }));
      }
    });

    return () => {
      if (socket.readyState === WebSocket.OPEN) {
        socket.close();
      }
    };
  }, []);

  useEffect(() => {
    const jackpotSocket = new WebSocket(`${wsURL}/joinpot/pot`);

    jackpotSocket.addEventListener("open", () => {
      console.log("WebSocket connection opened");
    });

    jackpotSocket.addEventListener("message", (event) => {
      const res = JSON.parse(event?.data);
      if (res.data.message !== "Connection established") {
        dispatch(potUpdateWithSocket({ fields: res.data.fields }));
      }
    });

    return () => {
      if (jackpotSocket.readyState === WebSocket.OPEN) {
        jackpotSocket.close();
      }
    };
  }, []);

  let localTab = localStorage.getItem("dashboardTab") || "newpot";
  let [potType, setPotType] = useState(localTab);
  const [activeTab, setActiveTab] = useState("tab1");

  const [activeTabs, setActiveTabs] = useState(potTypeDB);

  const tabs = [
    { label: "New Rounds", value: "newpot" },
    { label: "My Rounds", value: "activepot" },
  ];

  const [picks, setPicks] = useState({
    selectPick: false,
    joinPick: false,
    isBuyNewToken: false,
    isConfirmPopUp: false,
    choosePick: false,
  });
  const [isJackPotPopup, setIsJackPotPopup] = useState(false);

  const selectedPick = useSelector((state) => {
    return {
      selectPick: state.dashboard.selectPick,
      joinNewPick: state.dashboard.joinPotDetail.checkAndBuyToken,
      buyNewToken: state.dashboard.isBuyNewToken,
      confirmPopUp: state.dashboard.confirmPopUp,
      choosePick: state.dashboard.choosePick.choosePick,
    };
  });

  const jackPotPopup = useSelector((state) => state.jackPot.isJackpotPopup);

  useEffect(() => {
    setPicks((prev) => ({
      ...prev,
      selectPick: selectedPick.selectPick,
      joinPick: selectedPick.joinNewPick,
      isBuyNewToken: selectedPick.buyNewToken,
      isConfirmPopUp: selectedPick.confirmPopUp,
      choosePick: selectedPick.choosePick,
    }));
  }, [
    selectedPick.selectPick,
    selectedPick.joinNewPick,
    selectedPick.buyNewToken,
    selectedPick.confirmPopUp,
    selectedPick.choosePick,
  ]);

  useEffect(() => {
    setIsJackPotPopup(jackPotPopup);
  }, [jackPotPopup]);

  const [potInfo, setPotInfo] = useState({
    pot: [],
    user: {},
    tokenInfo: {},
  });

  useEffect(() => {
    if (potTypeDB == "newpot") {
      setActiveTab("tab1");
      setActiveTabs("newpot");
      localStorage.setItem("dashboardTab", "newpot");
      setPotType(potTypeDB);
    } else if (potTypeDB == "activepot") {
      setActiveTab("tab2");
      setActiveTabs("activepot");
      localStorage.setItem("dashboardTab", "activepot");
      setPotType(potTypeDB);
    }
  }, [potTypeDB]);

  const showTab = (tabId) => {
    if (potType !== tabId) {
      setPotType(tabId);
      setActiveTab(tabId);
    }
  };

  useEffect(() => {
    if (potType) {
      dispatch(fetchDashboardData(`/dashboard/?type=${potType}`, potType));
    }
  }, [potType]);

  const userPot = useSelector((state) => state.dashboard);

  useEffect(() => {
    if (userPot?.data) {
      const user = userPot.data.user;

      if (user && Object.keys(user).length > 0) {
        localStorage.setItem("amountPerToken", user?.amount_per_token);
        dispatch(
          updateTokenDetail({
            available_tokens: userPot?.data?.user?.available_tokens,
            won_tokens: userPot?.data?.user?.won_tokens,
          })
        );
      }
      setPotInfo((prev) => ({
        ...prev,
        pot: userPot?.data?.pots,
        user: userPot?.data?.user,
        tokenInfo: {
          gainlostamount: userPot?.data?.user?.gainlostamount,
          tokenRemaining: userPot?.data?.user?.available_tokens,
          amount: userPot?.data?.user?.gainlostamount,
          played: userPot?.data?.user?.won_pots,
          totalGame: userPot?.data?.user?.total_played,
        },
      }));
    }
  }, [userPot]);

  return (
    <PlayerLayout>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ zIndex: 9999 }}
        className="mobile-s:w-[70%] mobile-s:ml-[15%] tablet:w-[40%] laptop-s:w-[25%] mobile-s:text-[12px] mobile-m:!text-[14px] tablet:ml-[1%]"
      />
      <div className="w-full flex flex-col mt-[0px]">
        <div
          className={`w-[98%]  mobile-s:my-[5%] tablet:m-[2%] mx-auto text-center  tablet:border-b-[1px] 
           text-[#FFFFFF]`}
        >
          <div className="toggle-tabs">
            <div className="tabs-container ">
              {tabs.map(({ label, value }) => (
                <button
                  key={value}
                  className={`tab1 mobile-s:text-[16px] ${
                    activeTabs === value ? "active" : ""
                  }`}
                  onClick={() => {
                    setActiveTabs(value);
                    showTab(value);
                  }}
                >
                  {label}
                </button>
              ))}
            </div>
          </div>
        </div>
        {isLoading ? (
          <div className="flex justify-center my-auto">
            <RotatingLines
              visible={true}
              height="96"
              width="96"
              color="grey"
              strokeWidth="5"
              animationDuration="0.75"
              ariaLabel="rotating-lines-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        ) : (
          <div className="w-[98%] mt-[10px] flex justify-center">
            {activeTab === "tab1" && (
              <div
                id="tab1"
                className={`flex tablet:ml-[4%] tablet:gap-[4%] laptop-l:ml-[3%] 
              laptop-s:ml-[3.5%] mobile-s:ml-[15px] mobile-s:mr-[8px] 
              mobile-m:ml-[18px] mobile-m:mr-[10px] mobile-l:ml-[18px] 
              laptop-min:ml-[3%] laptop-min-s:ml-[3.5%] laptop-min-l:ml-[18px] 
              laptop-min:gap-[2%] flex-wrap gap-4 laptop-l:gap-[2%] 
              laptop-s:gap-[4%] tab-content w-[98%] ${
                activeTab !== "tab1" && "hidden"
              }`}
              >
                {potInfo?.pot?.length > 0 ? (
                  potInfo?.pot?.map((pot, i) => {
                    return <PotCard pot={pot} potType="newPot" key={i} />;
                  })
                ) : (
                  <h2 className="flex text-white text-center mx-auto items-center justify-center">
                    No new rounds are available right now. Stay tuned for
                    upcoming rounds
                  </h2>
                )}
              </div>
            )}
            {activeTab === "tab2" && (
              <div
                id="tab2"
                className={`flex tablet:ml-[4%] tablet:gap-[4%] laptop-l:ml-[3%] 
      laptop-s:ml-[3.5%] mobile-s:ml-[15px] mobile-s:mr-[8px] 
      mobile-m:ml-[18px] mobile-m:mr-[10px] mobile-l:ml-[18px] 
      laptop-min:ml-[3%] laptop-min-s:ml-[3.5%] laptop-min-l:ml-[18px] 
      laptop-min:gap-[2%] flex-wrap gap-4 laptop-l:gap-[2%] 
      laptop-s:gap-[4%] tab-content w-[98%] ${
        activeTab !== "tab2" && "hidden"
      }`}
              >
                {potInfo?.pot?.length > 0 ? (
                  potInfo?.pot?.map((pot) => {
                    if (pot.status === "JackPot" && pot.pot) {
                      if (pot.pick_id) {
                        return pot.pick_id.map((pickId, i) => {
                          let totalPickCounts = pot?.pick_id?.length;
                          let currPickId = i + 1 + "/" + totalPickCounts;
                          return (
                            <PotCard
                              key={pickId?.pick_id}
                              pot={pot}
                              rounds={pickId?.round}
                              pickId={pickId?.pick_id}
                              potType="jackPot"
                              status={pickId?.status}
                              currPickId={currPickId}
                              adminFree={pot?.admin_fee}
                            />
                          );
                        });
                      }
                      return null;
                    } else {
                      if (pot?.pick_id) {
                        return pot?.pick_id.map((pickId, i) => {
                          return (
                            <PotCard
                              key={pickId.pick_id}
                              pot={pot}
                              pickId={pickId?.pick_id}
                              rounds={pickId?.round}
                              status={pickId?.status}
                              currPickId={pickId?.pick_number}
                              systemPrediction={pickId?.prediction?.system}
                              adminFree={pot?.admin_fee}
                              potType="activePot"
                            />
                          );
                        });
                      }
                      return null;
                    }
                  })
                ) : (
                  <h2 className="flex text-center text-white mx-auto justify-center">
                    No data available. Please join the new round and start
                    playing.
                  </h2>
                )}
              </div>
            )}
          </div>
        )}
        {picks?.selectPick ? <SelectPicks /> : null}
        {picks?.joinPick ? <JoinPotModel /> : null}
        {picks?.choosePick ? <PickTeamModel /> : null}
        {isJackPotPopup ? <JackPotVoteNowPopUp /> : null}
      </div>
      <div className="footer"></div>
    </PlayerLayout>
  );
}

export default GreenDashboard;
