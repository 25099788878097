import {
  fetchDashboardStart,
  fetchDashboardFailure,
  updateSelectedPickTeam,
} from "../../Components/GreenDashboard/dashboard.slice";

import {
  fetchTeamStart,
  fetchTeamSuccess,
  fetchTeamFailure,
  fetchPotFixturesSuccess,
  fechSelectedPrevWeekTeamSucc,
} from "../../Components/GreenDashboard/team.slice";

import { postData, getData, putData } from "../api";

export const fetchTeamForPick = (URL, tokenInfo) => async (dispatch) => {
  try {
    dispatch(fetchTeamStart());
    const response = await getData(URL);
    if (response.status === "Success") {
      dispatch(fetchPotFixturesSuccess(response.data));
    }
  } catch (error) {
    dispatch(fetchTeamFailure(error.message));
  }
};

export const putTeamForPick = (URL, fixtureInfo) => async (dispatch) => {
  try {
    const response = await putData(URL, fixtureInfo);
    if (response.status === "Success") {
      response.data.fixtureInfo = fixtureInfo;
      dispatch(updateSelectedPickTeam(response.data));
    } else {
      // dispatch(fetchDashboardFailure(response.data.non_field_errors[0]))
      dispatch(fetchDashboardFailure(response.data.message));
    }
  } catch (error) {
    dispatch(fetchDashboardFailure(error.message));
  }
};

export const postTeamForPick = (URL, fixtureInfo) => async (dispatch) => {
  try {
    const response = await postData(URL, fixtureInfo);
    if (response.status === "Success") {
      response.data.fixtureInfo = fixtureInfo;
      dispatch(updateSelectedPickTeam(response.data));
    } else {
      // dispatch(fetchDashboardFailure(response.data.non_field_errors[0]))
      dispatch(fetchDashboardFailure(response.data.error[0]));
    }
  } catch (error) {
    dispatch(fetchDashboardFailure(error.message));
  }
};

export const fechSelectedPrevWeekTeam =
  (URL, fixtureInfo) => async (dispatch) => {
    try {
      const response = await getData(URL);
      if (response.status === "Success") {
        dispatch(fechSelectedPrevWeekTeamSucc(response.data));
      }
    } catch (error) {
      dispatch(fetchDashboardFailure(error.message));
    }
  };

export const fechPieChatMatchWeeks = (URL) => async (dispatch) => {
  try {
    const response = await getData(URL);
    if (response.status === "Success") {
      return response.data;
    }
  } catch (error) {
    return error.message;
  }
};

export const fetchChatDetail = (URL) => async (dispatch) => {
  try {
    const response = await getData(URL);
    if (response.status === "Success") {
      return response.data;
    }
  } catch (error) {
    return error.message;
  }
};
