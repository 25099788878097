import React from "react";

export function ProgressBar({ pot }) {
  let color;
  let progress = (pot.current_pot_value / pot.max_pot_value) * 100;
  let minPotPersantage = (pot.min_pot_value / pot.max_pot_value) * 100;
  let maxPotPersantage = 100;
  if (progress < minPotPersantage && progress !== minPotPersantage && pot?.status !== 'Active') {
    color = "bg-red-500";
  } else if (
    pot.min_pot_value <= pot.max_pot_value &&
    progress < maxPotPersantage
  ) {
    color = "bg-yellow-500";
  } else if (progress <= maxPotPersantage) {
    color = "bg-yellow-500";
  }
  else if (progress === maxPotPersantage) {
    color = "bg-yellow-500";
  }
  else if (pot?.status === 'Active') {
    color = "bg-yellow-500";
  }


  return (
    <div className="relative w-full mb-2">
      <div
        className="flex w-full h-1.5 bg-gray-200 rounded-full overflow-hidden dark:bg-neutral-700"
        role="progressbar"
        aria-valuenow={progress}
        aria-valuemin={0}
        aria-valuemax={100}
      >
        <div
          className={`flex flex-col justify-center rounded-full overflow-hidden ${color} text-xs text-white text-center whitespace-nowrap transition duration-500 dark:bg-${color}`}
          style={{ width: `${progress}%` }}
        ></div>
      </div>
    </div>
  );
}
