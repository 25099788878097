import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchPotInfoMyPotView,
  fetchMatchWeek,
  fetchMyPicks,
} from "../../services/myPotView/myPotView";
import useFindDevice from "../CustomHooks/FindDevice";
import { useLocation } from "react-router-dom";

function TeamDetailHOC(WrappedComponent) {
  return function WithTeamDetailHOC(props) {
    const dispatch = useDispatch();
    const location = useLocation();
    const { isMob, isTab, isLaptop, isLogedIn } = useFindDevice();
    const [currencyType, setCurrencyType] = useState("");

    const [fetchPotInfo, setFetchPotInfo] = useState([
      {
        pot_id: "",
        PickId: "",
        potCategory: "",
        matchWeek: "",
        potValue: "",
        noOfActivePicks: "",
        potStatus: "",
      },
    ]);

    const [leagueInfo, setLeagueInfo] = useState({
      league: "",
      deadLine: "",
    });

    const [, , , , potId, pickId, seasonId, matchWeek] =
      window.location.href.split("/"); //


    // const { potId, pickId, seasonId, matchWeekId: matchWeek, potType } = data;

    useEffect(() => {
      if (potId || seasonId) {
        fetchInfo();
      }
    }, [potId, seasonId]);

    async function fetchInfo() {
      let pot = Number(potId);
      let season = Number(seasonId);
      if ((pot, season)) {
        await dispatch(fetchPotInfoMyPotView(`/pot/pots/${pot}`));
        await dispatch(
          fetchMatchWeek(`/pot/seasonmatchweeks?season=${season}`)
        );
        // await dispatch(fetchMyPicks(`/pot/pickmatchpredictions/${pick}/${matchWeekNumber}`))
      }
    }

    const potDetails = useSelector((state) => {
      return state.myPot.viewPotInfo;
    });

    useEffect(() => {
   
      if (Object.keys(potDetails).length>0) {
        setLeagueInfo((prev) => ({
          ...prev,
          league: potDetails.league,
        }));
        let obj = {
          pot_id: potDetails.id,
          pick_id: pickId,
          category: potDetails.category,
          matchWeek: potDetails?.match_weeks?.current_match_week,
          // potValue: `${potDetails?.current_pot_value} ${potDetails.pot_currency}`,
          potValue: potDetails.current_pot_value,
          no_of_picks: potDetails?.active_picks,
          pick_status: potDetails?.status,
        };
        if (isMob) {
          obj.potCurrency = potDetails.pot_currency;
        }
        setFetchPotInfo([obj]);
        setCurrencyType(potDetails.pot_currency);
      }
    }, [potDetails.id]);

    return (
      <WrappedComponent
        {...props}
        isMobile={isMob}
        isLaptop={isLaptop}
        leagueInfo={leagueInfo}
        fetchPotInfo={fetchPotInfo}
        currencyType={currencyType}
      />
    );
  };
}

export default TeamDetailHOC;
