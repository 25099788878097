import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "../../../Elements/Card";
import { fetchStandingData } from "../../../services/myPotView/myPotView";
import { fetchOtherPlayerPredictionMyPot } from "../../../services/jackPotView/jackPotView";
import Table from "../../CommonComponent/Table";

import newBallImg from "../../../assets/card/new-ball-img.svg";
import newUserIcon from "../../../assets/card/new-user-icon.svg";
import Chart from "./Chart";
import useFindDevice from "../../CustomHooks/FindDevice";

import noPickOtherplayers from "../../../assets/card/no-picks-otherplayers.svg";
import { useLocation } from 'react-router-dom';

function Standing({ potType, leagueInfo, isMobile }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const { isMob, isTab, isLaptop, isLogedIn } = useFindDevice();
  const [standing, setStanding] = useState([]);
  const [selectTab, setSelectTab] = useState("standing");
  const [otherPlayersStanding, setOtherPlayersStanding] = useState([]);

  const [otherPlayerDetails, setOtherPlayerDetails] = useState([]);

  const [otherPlayerTableInfo, setOtherPlayerTableInfo] = useState({
    header: [],
    body: [],
  });

  // const [, , , , potId, pickId, seasonId, matchWeekId] =
  //   window.location.href.split("/"); //

  const data = location.state;
  const { potId, pickId, seasonId, matchWeekId, potType:pot } = data;

  useEffect(() => {
    const season = Number(seasonId);
    if (season && pickId) {
      dispatch(
        fetchStandingData(`/pot/standings/${season}?pick=${Number(pickId)}`)
      );
    }
  }, [seasonId, pickId]);

  const standingInfo = useSelector((state) => {
    return state.myPot.standings;
  });

  const otherPlayerInfo = useSelector((state) => {
    return state?.myPot?.otherPlayesStanding;
  });

  useEffect(() => {
    if (standingInfo.length > 0) {
      setStanding(standingInfo);
    }
  }, [standingInfo]);

  useEffect(() => {
    if (otherPlayerInfo.length > 0) {
      setOtherPlayersStanding(otherPlayerInfo);
    }
  }, [otherPlayerInfo]);

  useEffect(() => {
    fetchData();
  }, [potId]);

  async function fetchData() {
    await dispatch(
      fetchOtherPlayerPredictionMyPot(
        `/pot/otherplayerpredictions/${Number(potId)}`
      )
    );
  }

  const standingHandler = () => {
    setSelectTab("standing");
    const season = Number(seasonId);
    if (season && pickId) {
      dispatch(
        fetchStandingData(`/pot/standings/${season}?pick=${Number(pickId)}`)
      );
    }
  };

  const OtherPlayersHandler = () => {
    setSelectTab("otherPlayers");
    dispatch(
      fetchOtherPlayerPredictionMyPot(
        `/pot/otherplayerpredictions/${Number(potId)}`
      )
    );
  };

  const otherPlayerPrediction = useSelector(
    (state) => state?.myPot?.jackPotOtherPlayerPrediction
  );

  useEffect(() => {
    if (Object.keys(otherPlayerPrediction).length > 0) {
      let allKeysSet = new Set();
      Object.values(otherPlayerPrediction).forEach((obj) => {
        Object.keys(obj).forEach((key) => {
          if (key !== "vote" && key !== "pick_id") {
            allKeysSet.add(key);
          }
        });
      });
      let allKeysArray = Array.from(allKeysSet);

      let playerIndex = allKeysArray.indexOf("player");
      if (playerIndex !== -1) {
        allKeysArray.splice(playerIndex, 1);
      }
      allKeysArray.unshift("player");

      let otherPlayerHeader = allKeysArray.map((key, index) => {
        return {
          id: index + 1,
          cellName: key,
          value: key.toLowerCase(),
          position: key === "player" ? 1 : null,
        };
      });

      let otherPlayerBody = Object.values(otherPlayerPrediction).map((obj) => {
        let row = {};

        allKeysArray.forEach((key) => {
          row[key] = obj[key] || "";
        });
        row["player"] = obj.player;
        return row;
      });
      setOtherPlayerTableInfo((prev) => ({
        ...prev,
        header: otherPlayerHeader,
        body: otherPlayerBody,
      }));
    } else {
      setOtherPlayerTableInfo((prev) => ({
        ...prev,
        header: [],
        body: [],
      }));
    }
  }, [otherPlayerPrediction]);


  return (
    <div
      className={`m-auto
       laptop-s:!w-[50%] tablet:!w-[97%] h-[100%] ${
         isMob ? "mt-[5%]" : isTab ? "mt-[4%]" : "mt-[1%]"
       }  
    mobile-s:h-auto mobile-s:mb-10 laptop-min-s:mt-[1.4%] laptop-min:mt-[1.4%]`}
    >
      <div className="flex">
        <div
          className={`cursor-pointer ${
            selectTab === "standing" ? "opacity-none" : "opacity-40"
          }
         bg-[url('../public/card/standing-header-img.svg')]
          ml-[3%] bg-cover
          mobile-s:w-[40%] mobile-s:h-[38px]
          tablet:!w-[34%] tablet:!h-[48px] tablet:!ml-[40px]
          laptop-s:!w-[33%] laptop-s:!ml-[50px]
          laptop-l:!w-[25%]
          laptop-l:!ml-[10%]
          relative top-[1px]
          desktop:!h-[70px]
          desktop:!ml-[10%]
            `}
          onClick={standingHandler}
        >
          <div className="flex flex-1 justify-center mt-[6%] items-center gap-[4%]">
            <img
              src={newBallImg}
              className="mobile-s:w-[15px] mobile-s:h-[15px] mobile-m:!w-[22px] mobile-m:!h-[22px]"
            />
            <p className="text-[12px] tablet:!text-[15px] font-semibold">
              Picks Summary
            </p>
          </div>
        </div>
        {potType != "jackPot" ? (
          <div
            className={`cursor-pointer ${
              selectTab !== "standing" ? "opacity-none" : "opacity-40"
            }  bg-[url('../public/card/standing-header-img.svg')] bg-cover 
            mobile-s:w-[50%] mobile-l:h-[39px]
            tablet:!w-[40%] tablet:!h-[48px]
            laptop-s:!w-[45%]
            laptop-l:!w-[35%]
            desktop:!h-[70px]
           `}
            onClick={OtherPlayersHandler}
          >
            <div className="flex items-center justify-center mt-[6%] gap-[4%]">
              <img
                src={newUserIcon}
                className="mobile-s:w-[15px] mobile-s:h-[15px] mobile-m:!w-[22px] mobile-m:mt-[-3px] mobile-m:!h-[22px]"
              />
              <p className="text-[12px] text-nowrap font-semibold tablet:!text-[15px]">
                Other Players Picks
              </p>
            </div>
          </div>
        ) : null}
      </div>

      <Card
        className={`m-auto h-[100%] overflow-y-auto mobile-s:mx-[2%] tablet:mx-[0.5%] p-[4px]
       bg-white rounded-[8px]`}
      >
        {selectTab === "standing" ? (
          <Chart potId={potId} />
        ) : (
          <>
            {" "}
            {otherPlayerTableInfo.header.length > 0 ? (
              <>
                <div className="m-auto h-[100%] overflow-x-auto">
                  <Table
                    tableName="otherUserPicks"
                    className="min-w-full m-auto rounded-lg overflow-hidden p-4"
                    tableHeader={otherPlayerTableInfo.header}
                    tableBody={otherPlayerTableInfo.body}
                    staticMessage="Other players' picks will appear after entry time ends"
                  />
                </div>
              </>
            ) : (
              <div className="flex w-[100%] gap-2 h-full p-2 rounded-[6px] ">
                <div className="flex mobile-s:flex-col tablet:flex-row w-full gap-2 p-2 rounded-[6px]">
                  <div
                    className={`flex flex-col  mobile-s:w-full mx-auto border border-[#70778299] bg-[#F7F7F7] rounded-[6px]`}
                  >
                    <div className="flex flex-col my-auto mx-auto pt-2">
                      <div className="flex flex-col mx-auto px-6">
                        <img src={noPickOtherplayers} className="h-12 w-12" />
                      </div>
                      <p className="m-2">
                       {pot === 'newPot'?'Other player’s picks will appear after entry time ends.':"Other player’s picks will appear after entry time ends"} 
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </Card>
    </div>
  );
}

export default Standing;
