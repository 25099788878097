import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.svg";
import { useDispatch } from "react-redux";
import Teams from "../../assets/images/teams1.png";
import Teamsmob from "../../assets/images/mobteams.png";
import { useState, useEffect } from "react";
import Card from "../../Elements/Card";
import Footer, { FooterTab, MobileFooter } from "../GreenHome/Footer";
import useFindDevice from "../CustomHooks/FindDevice";

import Input from "../../Elements/Input";

import Button from "../../Elements/Button";
import { createIsuue, getIsuueType } from "../../services/dashboard/token";

import { useFormik } from "formik";
import useFormikValidation from "../../vallidation/useFormikValidation";
import * as Yup from "yup";

import { Flip, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./contactus.css";

function ContactUs() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const options = ["iban", "bban"];
  const [issueType, setIssueType] = useState([]);
  const { isMob, isTab, isLaptop, isLogedIn, email } = useFindDevice();

  const [issueCreateSucc, setIssueCreateSuu] = useState("");
  const [issueCreateErr, setIssueCreateErr] = useState("");

  const [isTransaction, setIsTransaction] = useState(false);
  const [transactionID, setTransactionID] = useState("");
  const [transactionIDErr, setTransactionIDErr] = useState(false);
  const handleToastClose = () => {
    if (isLogedIn) {
      navigate("/dashboard");
    } else {
      navigate("/home");
    }
  };

  useEffect(() => {
    if (issueCreateSucc) {
      toast.success(issueCreateSucc, {
        onClose: handleToastClose,
        autoClose: 2000,
      });
    }
  }, [issueCreateSucc, issueCreateErr]);

  useEffect(
    (issueCreateErr) => {
      toast.error(issueCreateErr, {
        onClose: handleToastClose,
        autoClose: 2000,
      });
    },
    [issueCreateErr]
  );

  // // Define validation schema
  const profileSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Please enter email"),
    query: Yup.string().required("Please enter your query"),
    accType: Yup.string().required("Please select an issue type"),
  });

  const formik = useFormik({
    initialValues: {
      query: "",
      email: email ? email : "",
      accType: "",
    },
    validationSchema: profileSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const { query, email, accType } = values;
      console.log(transactionID);
      if (!transactionID) {
        setTransactionIDErr(true);
      }
      if (!isTransaction) {
        const res = await dispatch(
          createIsuue("/accounts/tickets", {
            email,
            issue_category: Number(accType),
            issue:query,
          })
        );
        if (res.status === "Success") {
          setIssueCreateSuu("Query raised");
        } else {
          setIssueCreateErr("Query not raised");
        }
        setSubmitting(false);
      }
      if (isTransaction && transactionID) {
        const res = await dispatch(
          createIsuue("/accounts/tickets", {
            email,
            transaction_id:transactionID.trim(),
            issue:query,
            issue_category: Number(accType),
          })
        );
        if (res.status === "Success") {
          setIssueCreateSuu("Query raised");
        } else {
          setIssueCreateErr("Query not raised");
        }
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    fetchIsuueType();
  }, []);

  async function fetchIsuueType() {
    const res = await dispatch(getIsuueType("/accounts/issuecategory"));
    if (res?.status === "Success") {
      setIssueType(res?.data);
      // setAccType(res?.data[0]?.id);
    }
  }
  const redirectToLogin = () => {
    navigate("/login");
  };

  const redirectToRegister = () => {
    navigate("/signup");
  };

  const redirectHome = () => {
    navigate("/home");
  };

  const transactionHandler = (e) => {
    if (e.target.value) {
      setTransactionIDErr(false);
      setTransactionID(e.target.value);
    } else {
      setTransactionIDErr(true);
    }
  };
  function renderInput() {
    return (
      <>
        <Card className="flex flex-col m-auto mobile-s:h-[95vh] mobile-l:h-[100vh] overflow-auto mobile-s:mt-5 mobile-s:pl-8 mobile-s:pt-12 mobile-s:pb-12 mobile-s:pr-8  mobile-s:w-[100%] mobile-s:my-[2%] bg-[#FFFFFF] shadow-md rounded-[12px] relative tablet:pl-12 tablet:pt-12 tablet:pb-12 tablet:pr-12 tablet:w-[95%] tablet:h-[60%] laptop-s:w-[70%] laptop-s:!h-[50%]">
          <form
            onSubmit={(e) => {
              if (!transactionID) {
                setTransactionIDErr(true);
              }
              e.preventDefault();
              formik.handleSubmit();
            }}
            className="w-full"
          >
            <div className="flex mobile-s:block tablet:mx-auto tablet:items-center tablet:w-full tablet:flex gap-4">
              <div className="relative w-full h-[50px]">
                <div className="flex justify-center w-full   tablet:rounded-[5px] tablet:w-[100%] tablet:mt-[2%] tablet:p-1">
                  <Input
                    text="email"
                    placeholder="Contact Email"
                    className={`text-gray-950 ${
                      isLogedIn ? "bg-gray-100" : ""
                    } w-[99%] p-2 rounded-[5px] border border-solid font-interFont
                     border-[#CACACA] text-[15px] outline-none input-focused`}
                    id="email"
                    name="email"
                    value={formik.values.email.toLowerCase()}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autocomplete="off"
                    readOnly={isLogedIn ? "readOnly" : ""}
                  />
                </div>
                {formik.touched.email && formik.errors.email && (
                  <span
                    className=" w-[40%] text-[#EF4444] mobile-s:text-[13px]
                  laptop-s:text-[15px] p-2"
                  >
                    {formik.errors.email}
                  </span>
                )}
              </div>

              <div className="h-[50px] rounded-[5px] tablet: w-full mt-[2%] mobile-s:mt-[10%] tablet:mt-[1.5%] p-1">
                <div className="relative w-full h-[45px]">
                  <div className="form-group w-full custom-select-wrapper">
                    <select
                      onChange={(event) => {
                        const selectedValue = event.target.value;
                        if (selectedValue == 3 || selectedValue == 4) {
                          setIsTransaction(true);
                        } else {
                          setIsTransaction(false);
                        }
                        let res = issueType.filter((ele)=> {
                          return ele.id == selectedValue
                        })
                        console.log(res[0]?.id)
                        formik.setFieldValue("accType", res[0]?.id);
                      }}
                      value={formik.values.accType}
                      className="text-gray-950 w-full p-2 rounded-[5px] border border-solid border-[#CACACA] text-[15px] outline-none input-focused custom-select"
                      style={{
                        padding: isMob
                          ? "10px 40px 10px 10px"
                          : "8px 40px 8px 8px", // Adjust padding to add space for the custom arrow
                      }}
                    >
                      <option value="" disabled hidden>
                        What do you need assistance with?
                      </option>
                      {issueType.map((ele) => (
                        <option key={ele?.id} value={ele?.id}>
                          {ele.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {formik.touched.accType && formik.errors.accType && (
                    <div
                      className="text-red-500 mobile-s:text-[13px]
                  laptop-s:text-[15px] p-2 pt-1"
                    >
                      {formik.errors.accType}
                    </div>
                  )}
                </div>
              </div>

              {isTransaction && (
                <div className="relative w-full h-[50px] mobile-s:mt-8 tablet:mt-0">
                  <div className="flex justify-center w-full   tablet:rounded-[5px] tablet:w-[100%] tablet:mt-[2%] tablet:p-1">
                    <Input
                      text="text"
                      placeholder="Transaction ID"
                      className={`text-gray-950  w-[99%] p-2 rounded-[5px] border border-solid font-interFont
       border-[#CACACA] text-[15px] outline-none input-focused`}
                      id="transactionID"
                      name="transactionID"
                      // value={formik.values.transactionID}
                      onChange={transactionHandler}
                      // onBlur={formik.handleBlur}
                      autocomplete="off"
                      // readOnly={isLogedIn ? "readOnly" : ""}
                    />
                  </div>

                  <span
                    className=" w-[40%] text-[#EF4444] mobile-s:text-[13px]
    laptop-s:text-[15px] p-2"
                  >
                    {transactionIDErr && "Transaction ID is required"}
                  </span>
                </div>
              )}
            </div>

            <div className="flex mobile-s:block mobile-s:mt-[10%] tablet:flex gap-4 tablet:mt-[0%]">
              <div className="h-[45px] p-1 rounded-[5px] tablet:mt-[4%] tablet:mx-auto  tablet:w-full ">
                <textarea
                  id="message"
                  rows="4"
                  className="text-gray-950 w-full p-2 rounded-[5px] border border-solid
                     border-[#CACACA] text-[15px] outline-none input-focused"
                  name="query"
                  value={formik.values.query}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Describe your issue"
                ></textarea>
                {formik.touched.query && formik.errors.query && (
                  <span
                    className=" w-[40%] text-[#EF4444] mobile-s:text-[13px]
                  laptop-s:text-[15px]"
                  >
                    {formik.errors.query}
                  </span>
                )}
              </div>
            </div>

            <div className="mobile-s:mt-[45%] mobile-s:ml-[%] tablet:mt-[15%] tablet:ml-[%]">
              <Button
                className="bg-blue-500 hover:bg-blue-700 mobile-s:px-6 
                mobile-s:py-4  mobile-s:text-[12px] mobile-m:text-[12px] tablet:text-[16px] mobile-m:!px-8 mobile-m:!py-4 text-[#FFFFFF] font-bold rounded-[7px] focus:outline-none 
              focus:shadow-outline mt-[4%] mb-4"
                type="submit"
              >
                Submit
              </Button>

              <Button
                className="bg-gray-500 hover:bg-gray-700 ml-4 mobile-s:px-6 
                mobile-s:py-4  mobile-s:text-[12px] mobile-m:text-[12px] tablet:text-[16px] mobile-m:!px-8 mobile-m:!py-4 text-[#FFFFFF] font-bold rounded-[7px] focus:outline-none focus:shadow-outline mt-[4%] mb-4"
                type="button"
                onClick={() => {
                  if (isLogedIn) {
                    navigate("/dashboard");
                  } else {
                    navigate("/home");
                  }
                }}
              >
                Cancel
              </Button>
            </div>
          </form>
        </Card>
      </>
    );
  }

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ zIndex: 9999 }}
        className="mobile-s:w-[70%] mobile-s:ml-[15%] tablet:w-[40%] laptop-s:w-[25%] mobile-s:text-[12px] mobile-m:!text-[14px] tablet:ml-[1%]"
      />
      {isMob === true ? (
        <div
          className={`font-interFont overflow-auto ${
            isMob
              ? "bg-[url('../public/card/home-screen-mobile-bg.svg')]"
              : "bg-[url('../public/card/home-bg.svg')]"
          } bg-cover`}
        >
          <div className=" mb-[50px] mobile-s:h-[850px] mobile-l:h-[0px] w-full p-5 font-Inter">
            <div className="flex w-full p-3">
              <div className="w-[50%]">
                <img src={Logo} alt="pickNdPrizes" onClick={redirectHome}></img>
              </div>
              <div className={`w-[50%] ml-[10%] mt-[4%] flex float-right`}>
                {!isLogedIn ? (
                  <button
                    className=" border-[1px] border-[#00C05B] ml-2 rounded-[4px] w-[95px] h-[37px] text-[14px] cursor-pointer text-[#00C05B]"
                    onClick={redirectToRegister}
                  >
                    Register
                  </button>
                ) : null}

                <button
                  className={`w-[90px] h-[35px] text-[14px] font-[500] bg-[#00C05B] text-[#002427] ${
                    !isLogedIn ? "ml-5" : "ml-14"
                  } rounded-[4px]`}
                  onClick={redirectToLogin}
                >
                  {!isLogedIn ? "Login" : "Play now"}
                </button>
              </div>
            </div>

            <hr className="w-[95%] opacity-[.2] m-auto"></hr>
            <div>
              <div className="mt-[2%]">
                <h2 className="text-[#FFFFFF] text-[32px] text-center">
                  How Can We Assist You?
                </h2>
                <p className="text-[20px] text-center text-[#FFFFFFB2]">
                  Please complete and submit the form below and our team will
                  promptly reach out to assist you.
                </p>
              </div>

              {renderInput()}
            </div>
          </div>

          <MobileFooter />
        </div>
      ) : isTab ? (
        <div className="overflow-auto bg-[url('../public/card/tab-bg.svg')] bg-cover h-[100vh]">
          <div className="h-[130vh] w-full p-5 font-interFont">
            <div className="flex w-full px-3 pb-5 items-center">
              <div className="w-[44%] ">
                <img src={Logo} alt="pickNdPrizes" onClick={redirectHome} />
              </div>
              <div className="w-[56%] justify-end mr-[1%] mt-[1%] flex float-right ">
                {!isLogedIn ? (
                  <>
                    {" "}
                    <p className="text-[#FFFFFF] mt-4">Not a member yet ?</p>
                    <p
                      className="text-[#00C05B] font-[18px] mt-4 ml-2 cursor-pointer"
                      onClick={redirectToRegister}
                    >
                      Register Now
                    </p>
                  </>
                ) : null}

                <button
                  className="w-[90px] mt-2 h-[35px] text-[14px] font-[500] bg-[#00C05B] text-[#002427] ml-5 rounded-[4px]"
                  onClick={redirectToLogin}
                >
                  {!isLogedIn ? "Login" : "Play now"}
                </button>
              </div>
            </div>

            <hr className="w-[95%] opacity-[.2] m-auto"></hr>

            <div className="mt-[2%]">
              <h2 className="text-[#FFFFFF] text-[48px] text-center">
                How Can We Assist You?
              </h2>
              <p className="text-[20px] text-center text-[#FFFFFFB2]">
                Please complete and submit the form below and our team will
                promptly reach out to assist you.
              </p>
            </div>

            {renderInput()}
          </div>
          <FooterTab />
        </div>
      ) : (
        <div className="overflow-auto bg-[url('../public/card/home-bg.svg')] bg-cover h-[100vh]">
          <div className="h-[100vh] w-full p-5 laptop-min:h-[820px] laptop-s:h-[820px]  laptop-l:h-[818px] mb-[50px] font-Inter">
            <div className="flex w-full p-3">
              <div className="w-[75%] ml-[1.5%] laptop-l:w-[70%] laptop-s:w-[58%] ">
                <img src={Logo} alt="pickNdPrizes" onClick={redirectHome}></img>
              </div>
              <div className="w-[25%] justify-end mr-[2%] mt-[1%] flex float-right laptop-l:w-[30%] laptop-s:w-[42%]">
                {!isLogedIn ? (
                  <>
                    {window.innerWidth <= 500 ? (
                      ""
                    ) : (
                      <p className="text-[#FFFFFF] mt-2">Not a member yet ?</p>
                    )}
                    {/* <p className='text-[#FFFFFF] mt-2'>Not a member yet ?</p> */}

                    {window.innerWidth <= 500 ? (
                      <button className=" border-[1px] border-[#00C05B] w-[95px] h-[37px] text-[14px] cursor-pointer text-[#00C05B]">
                        Register
                      </button>
                    ) : (
                      <p
                        className="text-[#00C05B] font-[18px] mt-2 ml-2 cursor-pointer"
                        onClick={redirectToRegister}
                      >
                        Register Now
                      </p>
                    )}
                  </>
                ) : null}
                {/* <p className='text-[#00C05B] font-[18px] mt-2 ml-2 cursor-pointer'>Register Now</p> */}
                <button
                  className="w-[90px] h-[35px] text-[18px] font-[500] bg-[#00C05B] text-[#002427] ml-5 rounded-[4px]"
                  onClick={redirectToLogin}
                >
                  {!isLogedIn ? "Login" : "Play now"}
                </button>
              </div>
            </div>

            <hr className="w-[95%] opacity-[.2] m-auto"></hr>
            <div>
              <div>
                <h2 className="text-[#FFFFFF] text-[48px] text-center">
                  How Can We Assist You?
                </h2>
                <p className="text-[20px] text-center text-[#FFFFFFB2]">
                  Please complete and submit the form below and our team will
                  promptly reach out to assist you.
                </p>
              </div>

              {renderInput()}
            </div>
          </div>
          <Footer componnent="rules" />
        </div>
      )}
    </>
  );
}

export default ContactUs;
