import { createSlice } from "@reduxjs/toolkit";

const myWinningsSlice = createSlice({
  name: "myWinnings",
  initialState: {
    loading: false,
    myWinnigsInfo: [],
    myWinnigsMobiles: [],
    pagination: {
      totalRecord: 0,
      pageSize: 10,
      currentPage: 1,
    },
    error: "",
  },
  reducers: {
    fetchMyWinningsStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchWinningsInformation: (state, action) => {
      state.pagination.totalRecord = action.payload.count;
      state.myWinnigsInfo = [];
      state.myWinnigsInfo = [...state.myWinnigsInfo, ...action.payload.results];

      let concatmyWinnig = [];
      let mobileData = JSON.parse(JSON.stringify(state.myWinnigsMobiles));
      if (mobileData.length > 0) {
        concatmyWinnig = [
          ...JSON.parse(JSON.stringify(state.myWinnigsMobiles)),
          ...action.payload.results,
        ];
      } else {
        concatmyWinnig = [...action.payload.results];
      }
      const res = [
        ...new Map(concatmyWinnig.map((ele) => [ele.id, ele])).values(),
      ];
      state.myWinnigsMobiles = res;
    },
    updateWinningPagination: (state, action) => {
      state.pagination.currentPage = action.payload.page;
    },
    fetchWinningsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchMyWinningsStart,
  fetchWinningsInformation,
  updateWinningPagination,
  fetchWinningsFailure,
} = myWinningsSlice.actions;
export default myWinningsSlice.reducer;
