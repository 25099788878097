import { Navigate } from "react-router-dom";

const PrivateRoute = ({ element: Component, isAuthenticated, ...rest }) => {
  const isAuthUser = localStorage.getItem("isAuthUser");

  return isAuthUser === 'true' ? (
    <Component {...rest} />
  ) : (
    <Navigate to="/home" replace />
  );
};

export default PrivateRoute;
