import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import Input from "../../Elements/Input";
import Button from "../../Elements/Button";
import AuthLayout from "../AuthLayout/AuthLayout";
import Card from "../../Elements/Card";
import { changePassword } from "../../services/auth/verification";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import icons

function ResetPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Define validation schema
  const loginValidationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required("Please enter password")
      .matches(
        /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+-])[A-Za-z0-9!@#$%^&*()_+-]{8,30}$/,
        "Password must be minimum 8 to 30 characters, at least one uppercase letter, one number, and one special character"
        // "password criteria not matched"
      ),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Please confirm your password"),
  });

  const handleSubmit = async (values) => {
    const registerEmail = JSON.parse(
      JSON.stringify(localStorage.getItem("registerEmail"))
    );
    const token = JSON.parse(JSON.stringify(localStorage.getItem("tocken")));
    // Handle form submission logic here
    const res = await dispatch(
      changePassword("/accounts/changepassword", {
        email: registerEmail,
        token: token,
        newpassword: values.newPassword,
        confirmpassword: values.confirmNewPassword,
      })
    );
    if (res?.status === "Success") {
      localStorage.removeItem("registerEmail");
      navigate("/login");
    }
  };

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: loginValidationSchema,
    onSubmit: handleSubmit,
  });

  const [showPassword, setShowPassword] = useState(true); // State variable
  const [showConfirmPassword, setShowConfirmPassword] = useState(true); // State variable

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState); // Toggle visibility
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevState) => !prevState); // Toggle visibility
  };
  return (
    <AuthLayout>
      <Card className="m-auto bg-[#FFFFFF] shadow-md rounded-[12px] mobile-s:h-[550px] mobile-s:w-[90%] laptop-l:h-[60%] laptop-l:w-[30%] laptop-s:h-[70%] laptop-s:w-[45%] tablet:w-[60%] tablet:h-[55%] desktop:w-[25%] desktop:h-[40%] flex flex-col justify-center align-middle font-interFont">
        <div className="w-[80%] m-auto ">
          <div>
            <h2 className="text-center text-[28px] font-semibold mb-5">
              Reset your password
            </h2>
            <p className="text-center text-[15px] text-[#000000] text-opacity-50 mb-6">
              Please enter a strong password with at least six characters
            </p>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="relative w-full h-[45px] mt-[10%]">
              <Input
                type={showPassword ? "password" : "text"}
                placeholder="New Password"
                className="text-gray-950 p-2 text-[15px] outline-none w-full rounded-[5px] border border-solid h-full border-[#CACACA] input-focused"
                id="newPassword"
                name="newPassword"
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                autoComplete="off"
                onPaste={(e) => {
                  e.preventDefault(); // Prevent pasting
                }}
                onSelectStart={(e) => e.preventDefault()} // Prevent text selection on start
              />
              <button
                type="button"
                className="absolute top-1/2 right-3 transform -translate-y-1/2"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
            {formik.touched.newPassword && formik.errors.newPassword && (
              <span className="error w-[80%] ml-3 text-[#EF4444]">
                {formik.errors.newPassword}
              </span>
            )}
            <div className="relative w-full h-[45px] mt-[7%]">
              <Input
                type={`${showConfirmPassword ? "password" : "text"}`}
                placeholder="Confirm New Password"
                className="text-gray-950 p-2 text-[15px] outline-none w-full rounded-[5px] border border-solid h-full border-[#CACACA] input-focused"
                id="confirmNewPassword"
                name="confirmNewPassword"
                value={formik.values.confirmNewPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                onPaste={(e) => {
                  e.preventDefault(); // Prevent pasting
                }}
                onSelectStart={(e) => e.preventDefault()} // Prevent text selection on start
              />
              <button
                type="button"
                className="absolute top-1/2 right-3 transform -translate-y-1/2"
                onClick={toggleConfirmPasswordVisibility} // Toggle visibility on click
              >
                {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
            {formik.touched.confirmNewPassword &&
              formik.errors.confirmNewPassword && (
                <span className="error w-[80%] ml-3 text-[#EF4444]">
                  {formik.errors.confirmNewPassword}
                </span>
              )}
            <div className="mt-1 text-center">
              <Button
                className="bg-blue-500 hover:bg-blue-700 p-2 w-full py-3 text-white font-bold rounded focus:outline-none focus:shadow-outline mt-8 mb-4"
                type="submit"
              >
                Reset Password
              </Button>
            </div>
          </form>
        </div>
      </Card>
    </AuthLayout>
  );
}

export default ResetPassword;
