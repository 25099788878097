import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.svg";
import Teams from "../../assets/images/teams1.png";
import Teamsmob from "../../assets/images/mobteams.png";
import { useState, useEffect } from "react";
import Card from "../../Elements/Card";
import Footer, { FooterTab, MobileFooter } from "../GreenHome/Footer";
import useFindDevice from "../CustomHooks/FindDevice";

function PrivacyPolicy() {
  const navigate = useNavigate();
  const { isMob, isTab, isLaptop, isLogedIn } = useFindDevice();
  const redirectToLogin = () => {
    navigate("/login");
  };

  const redirectToRegister = () => {
    navigate("/signup");
  };

  const redirectHome = () => {
    navigate("/home");
  };

  return (
    <>
      {isMob === true ? (
        <div
          className={`overflow-auto font-interFont ${
            isMob
              ? "bg-[url('../public/card/home-screen-mobile-bg.svg')]"
              : "bg-[url('../public/card/home-bg.svg')]"
          } bg-cover h-[100vh]`}
        >
          <div className="h-[100vh] w-full p-5 font-Inter">
            <div className="flex w-full p-3">
              <div className="w-[50%]">
                <img src={Logo} alt="pickNdPrizes" onClick={redirectHome}></img>
              </div>
              <div className={`w-[50%] ml-[10%] mt-[4%] flex float-right`}>
                {!isLogedIn ? (
                  <button
                    className=" border-[1px] border-[#00C05B] ml-2 rounded-[4px] w-[95px] h-[37px] text-[14px] cursor-pointer text-[#00C05B]"
                    onClick={redirectToRegister}
                  >
                    Register
                  </button>
                ) : null}

                <button
                  className={`w-[90px] h-[35px] text-[14px] font-[500] bg-[#00C05B] text-[#002427] ${
                    !isLogedIn ? "ml-5" : "ml-14"
                  } rounded-[4px]`}
                  onClick={redirectToLogin}
                >
                  {!isLogedIn ? "Login" : "Play now"}
                </button>
              </div>
            </div>

            <hr className="w-[95%] opacity-[.2] m-auto"></hr>

            <div>
              <div>
                <h2 className="text-[#FFFFFF] text-[32px] text-center font-semibold ">
                  Privacy Policy for PicksnPrizes
                </h2>
              </div>

              <Card className="m-auto h-[67.6vh] overflow-auto  pl-12 pt-12 pb-12 pr-12 flex flex-col justify-between w-[100%] my-[2%] bg-[#FFFFFF] shadow-md rounded-[12px] relative">
                <p className="text-[15px] mt-[2%] text-[#0000005A] font-semibold">
                  Welcome to PicksnPrizes! We value your privacy and are
                  committed to protecting your personal information. This
                  Privacy Policy explains how we collect, use, disclose, and
                  safeguard your data when you interact with our website and
                  services. PicksnPrizes is committed to protecting your privacy
                  policy. Please review the following detailed information about
                  your privacy rights and our data practices.
                </p>
                <h4 className="text-[15px] mt-[3%] text-[#000000] font-semibold">
                  1. Information We Collect:
                </h4>
                <p className="text-[15px] mt-[2%] text-[#0000005A] font-semibold">
                  When you create an account on PicksnPrizes, we may collect
                  various types of personal information, including your name,
                  email address, date of birth, payment details, and any other
                  information necessary for account setup and participation in
                  prediction games.
                </p>
                <p className="text-[15px] text-[#0000005A] font-semibold">
                  Additionally, we automatically gather non-personal information
                  such as your IP address, device type, browser information, and
                  cookies to enhance user experience, analyse trends, and ensure
                  the security of our platform.
                </p>

                <h4 className="text-[15px] mt-[3%] text-[#000000] font-semibold">
                  2. Use of Your Information:
                </h4>
                <p className="text-[15px] text-[#0000005A] font-semibold">
                  2·1 Your personal information is primarily used to manage your
                  account, process payments, facilitate game participation,
                  communicate importan
                </p>
                <p className="text-[15px] text-[#0000005A] font-semibold">
                  2.2 Non-personal information is utilised for statistical
                  analysis, website optimization, security monitoring, and
                  customization of content to better meet your preferences and
                  needs.
                </p>

                <h4 className="text-[15px] mt-[3%] text-[#000000] font-semibold">
                  3. Data Security Measures:
                </h4>
                <p className="text-[15px] text-[#0000005A] font-semibold">
                  3·1 PicksnPrizes implements robust security measures to
                  protect your personal data from unauthorised access,
                  disclosure, alteration, or destruction. Your information is
                  stored securely and accessed only by authorised personnel for
                  legitimate business purposes.
                </p>
                <p className="text-[15px] text-[#0000005A] font-semibold">
                  3·2 All payment transactions are encrypted and processed
                  through reputable payment gateways to ensure the
                  confidentiality and integrity of your financial details.
                </p>

                <h4 className="text-[15px] mt-[3%] text-[#000000] font-semibold">
                  4. Sharing of Information:
                </h4>
                <p className="text-[15px] text-[#0000005A] font-semibold">
                  4·1 PicksnPrizes do not sell, trade, or rent your personal
                  information to third parties for marketing purposes. However,
                  we may share your data with trusted service providers,
                  partners, or affiliates who assist us in delivering our
                  services, processing payments, or improving user experience.
                </p>
                <p className="text-[15px] text-[#0000005A] font-semibold">
                  4·2 These third parties are contractually obligated to
                  maintain the confidentiality of your information and adhere to
                  data protection regulations.
                </p>

                <h4 className="text-[15px] mt-[3%] text-[#000000] font-semibold">
                  5. Use of Cookies and Tracking Technologies:
                </h4>
                <p className="text-[15px] text-[#0000005A] font-semibold">
                  5·1 PicksnPrizes uses cookies, and similar tracking
                  technologies to enhance website functionality, personalise
                  content, track user interactions, and gather analytics data
                  for performance optimization.
                </p>
                <p className="text-[15px] text-[#0000005A] font-semibold">
                  5·2 You have the option to manage cookie preferences through
                  your browser settings and may choose to disable certain
                  cookies, although this may impact the functionality and
                  usability of our website.
                </p>

                <h4 className="text-[15px] mt-[3%] text-[#000000] font-semibold">
                  6. Data Retention:
                </h4>
                <p className="text-[15px] text-[#0000005A] font-semibold">
                  6·1 PicksnPrizes retain your personal information only for as
                  long as necessary to fulfil the purposes outlined in this
                  Privacy Policy unless a longer retention period is required or
                  permitted by law. The criteria used to determine the
                  appropriate retention period include the duration of your
                  account and our legal obligations.
                </p>

                <h4 className="text-[15px] mt-[3%] text-[#000000] font-semibold">
                  7. User Rights:
                </h4>
                <p className="text-[15px] text-[#0000005A] font-semibold">
                  7.1 You have certain rights regarding your personal data,
                  including the right to access, rectify, delete, or restrict
                  the processing of your information. You may also have the
                  right to data portability and the right to object to
                  processing under certain circumstances.
                </p>
                <p className="text-[15px] text-[#0000005A] font-semibold">
                  7·2 To exercise these rights or for any inquiries regarding
                  your personal data, please contact us using the information
                  provided in the "Contact Us" section below.
                </p>

                <h4 className="text-[15px] mt-[3%] text-[#000000] font-semibold">
                  8. Data Transfer:
                </h4>
                <p className="text-[15px] text-[#0000005A] font-semibold">
                  8·1 Your personal data may be transferred to and processed in
                  countries other than your own, where data protection laws may
                  differ. When PicksnPrizes transfers your information to third
                  parties or service providers outside your country,
                  PicksnPrizes takes appropriate safeguards to ensure that your
                  data is adequately protected in accordance with applicable
                  data protection laws.
                </p>

                <h4 className="text-[15px] mt-[3%] text-[#000000] font-semibold">
                  9. Children's Privacy:
                </h4>
                <p className="text-[15px] text-[#0000005A] font-semibold">
                  9.1 PicksnPrizes only permits players who are 21 years of age
                  or older. Access to the platform is not granted to childrens
                  and individuals under the age of 21.
                </p>

                <h4 className="text-[15px] mt-[3%] text-[#000000] font-semibold">
                  10. Legal Basis for Processing:
                </h4>
                <p className="text-[15px] text-[#0000005A] font-semibold">
                  10·1 PicksnPrizes relies on various legal bases to process
                  your personal data, including consent, contract performance,
                  legitimate interests, and compliance with legal obligations.
                  We may process your information for the purposes stated in
                  this Privacy Policy based on one or more of these legal
                  grounds.
                </p>

                <h4 className="text-[15px] mt-[3%] text-[#000000] font-semibold">
                  11. Data Breach Notification:
                </h4>
                <p className="text-[15px] text-[#0000005A] font-semibold">
                  11·1 In the event of a data breach that may compromise the
                  security of your personal information, PicksnPrizes will
                  notify you and the relevant authorities as required by
                  applicable law. We will also take appropriate measures to
                  mitigate the impact of the breach and prevent future
                  incidents.
                </p>
                <h4 className="text-[15px] mt-[3%] text-[#000000] font-semibold">
                  12. Third-Party Links and Services:
                </h4>
                <p className="text-[15px] text-[#0000005A] font-semibold">
                  12·1 Our website may contain links to third-party websites or
                  services for your convenience. Please be aware that we are not
                  responsible for the privacy practices or content of these
                  external sites. PicksnPrizes recommends reviewing the privacy
                  policies of these third parties before engaging with their
                  services.
                </p>
                <h4 className="text-[15px] mt-[3%] text-[#000000] font-semibold">
                  13. Updates to Privacy Policy:
                </h4>
                <p className="text-[15px] text-[#0000005A] font-semibold">
                  13·1 PicksnPrizes reserves the right to update and revise this
                  Privacy Policy as needed to reflect changes in our data
                  practices, legal requirements, or business operations. Any
                  modifications will be communicated to you through our website
                  or via email.
                </p>
              </Card>
            </div>
          </div>

          <MobileFooter />
        </div>
      ) : isTab ? (
        <div className="overflow-auto bg-[url('../public/card/tab-bg.svg')] bg-cover h-[100vh] w-full font-Inter">
          <div className="h-[100vh] w-full p-5 font-interFont">
            <div className="flex w-full px-3 pb-5 items-center">
              <div className="w-[44%] ">
                <img src={Logo} alt="pickNdPrizes" onClick={redirectHome} />
              </div>
              <div className="w-[56%] justify-end mr-[1%] mt-[1%] flex float-right ">
                {!isLogedIn ? (
                  <>
                    {" "}
                    <p className="text-[#FFFFFF] mt-2">Not a member yet ?</p>
                    <p
                      className="text-[#00C05B] font-[18px] mt-2 ml-2 cursor-pointer"
                      onClick={redirectToRegister}
                    >
                      Register Now
                    </p>
                  </>
                ) : null}

                <button
                  className="w-[90px] h-[35px] text-[14px] font-[500] bg-[#00C05B] text-[#002427] ml-5 rounded-[4px]"
                  onClick={redirectToLogin}
                >
                  {!isLogedIn ? "Login" : "Play now"}
                </button>
              </div>
            </div>
         
          <hr className="w-[95%] opacity-[.2] m-auto"></hr>
          <div>
            <div>
              <h2 className="text-[#FFFFFF] text-[48px] text-center font-semibold">
                Privacy Policy for PicksnPrizes
              </h2>
            </div>

            <Card className="m-auto h-[62.6vh] font-interFont overflow-auto  pl-12 pt-12 pb-12 pr-12 flex flex-col justify-between w-[95%] my-[2%] bg-[#FFFFFF] shadow-md rounded-[12px] relative">
              <p className="text-[17px] mt-[2%] text-[#0000005A] font-semibold">
                Welcome to PicksnPrizes! We value your privacy and are committed
                to protecting your personal information. This Privacy Policy
                explains how we collect, use, disclose, and safeguard your data
                when you interact with our website and services. PicksnPrizes is
                committed to protecting your privacy policy. Please review the
                following detailed information about your privacy rights and our
                data practices.
              </p>
              <h4 className="text-[22px] mt-[3%] text-[#000000] font-semibold">
                1. Information We Collect:
              </h4>
              <p className="text-[17px] mt-[2%] text-[#0000005A] font-semibold">
                When you create an account on PicksnPrizes, we may collect
                various types of personal information, including your name,
                email address, date of birth, payment details, and any other
                information necessary for account setup and participation in
                prediction games.
              </p>
              <p className="text-[17px] text-[#0000005A] font-semibold">
                Additionally, we automatically gather non-personal information
                such as your IP address, device type, browser information, and
                cookies to enhance user experience, analyse trends, and ensure
                the security of our platform.
              </p>

              <h4 className="text-[22px] mt-[3%] text-[#000000] font-semibold">
                2. Use of Your Information:
              </h4>
              <p className="text-[17px] text-[#0000005A] font-semibold">
                2·1 Your personal information is primarily used to manage your
                account, process payments, facilitate game participation,
                communicate importan
              </p>
              <p className="text-[17px] text-[#0000005A] font-semibold">
                2.2 Non-personal information is utilised for statistical
                analysis, website optimization, security monitoring, and
                customization of content to better meet your preferences and
                needs.
              </p>

              <h4 className="text-[22px] mt-[3%] text-[#000000] font-semibold">
                3. Data Security Measures:
              </h4>
              <p className="text-[17px] text-[#0000005A] font-semibold">
                3.1 PicksnPrizes implements robust security measures to protect
                your personal data from unauthorised access, disclosure,
                alteration, or destruction. Your information is stored securely
                and accessed only by authorised personnel for legitimate
                business purposes.
              </p>
              <p className="text-[17px] text-[#0000005A] font-semibold">
                3·3 All payment transactions are encrypted and processed through
                reputable payment gateways to ensure the confidentiality and
                integrity of your financial details.
              </p>

              <h4 className="text-[22px] mt-[3%] text-[#000000] font-semibold">
                4. Sharing of Information:
              </h4>
              <p className="text-[17px] text-[#0000005A] font-semibold">
                4·1 PicksnPrizes do not sell, trade, or rent your personal
                information to third parties for marketing purposes. However, we
                may share your data with trusted service providers, partners, or
                affiliates who assist us in delivering our services, processing
                payments, or improving user experience.
              </p>
              <p className="text-[17px] text-[#0000005A] font-semibold">
                4·2 These third parties are contractually obligated to maintain
                the confidentiality of your information and adhere to data
                protection regulations.
              </p>

              <h4 className="text-[22px] mt-[3%] text-[#000000] font-semibold">
                5. Use of Cookies and Tracking Technologies:
              </h4>
              <p className="text-[17px] text-[#0000005A] font-semibold">
                5·1 PicksnPrizes uses cookies, and similar tracking technologies
                to enhance website functionality, personalise content, track
                user interactions, and gather analytics data for performance
                optimization.
              </p>
              <p className="text-[17px] text-[#0000005A] font-semibold">
                5·2 You have the option to manage cookie preferences through
                your browser settings and may choose to disable certain cookies,
                although this may impact the functionality and usability of our
                website.
              </p>

              <h4 className="text-[22px] mt-[3%] text-[#000000] font-semibold">
                6. Data Retention:
              </h4>
              <p className="text-[17px] text-[#0000005A] font-semibold">
                6·1 PicksnPrizes retain your personal information only for as
                long as necessary to fulfil the purposes outlined in this
                Privacy Policy unless a longer retention period is required or
                permitted by law. The criteria used to determine the appropriate
                retention period include the duration of your account and our
                legal obligations.
              </p>

              <h4 className="text-[22px] mt-[3%] text-[#000000] font-semibold">
                7. User Rights:
              </h4>
              <p className="text-[17px] text-[#0000005A] font-semibold">
                7·1 You have certain rights regarding your personal data,
                including the right to access, rectify, delete, or restrict the
                processing of your information. You may also have the right to
                data portability and the right to object to processing under
                certain circumstances.
              </p>
              <p className="text-[17px] text-[#0000005A] font-semibold">
                7·2 To exercise these rights or for any inquiries regarding your
                personal data, please contact us using the information provided
                in the "Contact Us" section below.
              </p>

              <h4 className="text-[22px] mt-[3%] text-[#000000] font-semibold">
                8. Data Transfer:
              </h4>
              <p className="text-[17px] text-[#0000005A] font-semibold">
                8·1 Your personal data may be transferred to and processed in
                countries other than your own, where data protection laws may
                differ. When PicksnPrizes transfers your information to third
                parties or service providers outside your country, PicksnPrizes
                takes appropriate safeguards to ensure that your data is
                adequately protected in accordance with applicable data
                protection laws.
              </p>

              <h4 className="text-[22px] mt-[3%] text-[#000000] font-semibold">
                9. Children's Privacy:
              </h4>
              <p className="text-[17px] text-[#0000005A] font-semibold">
                9·1 PicksnPrizes only permits players who are 21 years of age or
                older. Access to the platform is not granted to childrens and
                individuals under the age of 21.
              </p>

              <h4 className="text-[22px] mt-[3%] text-[#000000] font-semibold">
                10. Legal Basis for Processing:
              </h4>
              <p className="text-[17px] text-[#0000005A] font-semibold">
                10·1 PicksnPrizes relies on various legal bases to process your
                personal data, including consent, contract performance,
                legitimate interests, and compliance with legal obligations. We
                may process your information for the purposes stated in this
                Privacy Policy based on one or more of these legal grounds.
              </p>

              <h4 className="text-[22px] mt-[3%] text-[#000000] font-semibold">
                11. Data Breach Notification:
              </h4>
              <p className="text-[17px] text-[#0000005A] font-semibold">
                11·1 In the event of a data breach that may compromise the
                security of your personal information, PicksnPrizes will notify
                you and the relevant authorities as required by applicable law.
                We will also take appropriate measures to mitigate the impact of
                the breach and prevent future incidents.
              </p>
              <h4 className="text-[22px] mt-[3%] text-[#000000] font-semibold">
                12. Third-Party Links and Services:
              </h4>
              <p className="text-[17px] text-[#0000005A] font-semibold">
                12·1 Our website may contain links to third-party websites or
                services for your convenience. Please be aware that we are not
                responsible for the privacy practices or content of these
                external sites. PicksnPrizes recommends reviewing the privacy
                policies of these third parties before engaging with their
                services.
              </p>
              <h4 className="text-[22px] mt-[3%] text-[#000000] font-semibold">
                13. Updates to Privacy Policy:
              </h4>
              <p className="text-[17px] text-[#0000005A] font-semibold">
                13·1 PicksnPrizes reserves the right to update and revise this
                Privacy Policy as needed to reflect changes in our data
                practices, legal requirements, or business operations. Any
                modifications will be communicated to you through our website or
                via email.
              </p>
            </Card>
          </div>
           </div>
          <FooterTab componnent="privacyPlolicy" />
        </div>
      ) : (
        <div className="bg-[url('../public/card/home-bg.svg')] bg-cover w-full overflow-auto h-[100vh]">
          <div className="p-5 font-Inter">
            <div className="flex w-full p-3">
              <div className="w-[75%] ml-[1.5%] laptop-l:w-[70%] laptop-s:w-[58%] ">
                <img src={Logo} alt="pickNdPrizes" onClick={redirectHome}></img>
              </div>
              <div className="w-[25%] justify-end mr-[2%] mt-[1%] flex float-right laptop-l:w-[30%] laptop-s:w-[42%]">
                {!isLogedIn ? (
                  <>
                    {window.innerWidth <= 500 ? (
                      ""
                    ) : (
                      <p className="text-[#FFFFFF] mt-2">Not a member yet ?</p>
                    )}
                    {/* <p className='text-[#FFFFFF] mt-2'>Not a member yet ?</p> */}

                    {window.innerWidth <= 500 ? (
                      <button className=" border-[1px] border-[#00C05B] w-[95px] h-[37px] text-[14px] cursor-pointer text-[#00C05B]">
                        Register
                      </button>
                    ) : (
                      <p
                        className="text-[#00C05B] font-[18px] mt-2 ml-2 cursor-pointer"
                        onClick={redirectToRegister}
                      >
                        Register Now
                      </p>
                    )}
                  </>
                ) : null}
                {/* <p className='text-[#00C05B] font-[18px] mt-2 ml-2 cursor-pointer'>Register Now</p> */}
                <button
                  className="w-[90px] h-[35px] text-[18px] font-[500] bg-[#00C05B] text-[#002427] ml-5 rounded-[4px]"
                  onClick={redirectToLogin}
                >
                  {!isLogedIn ? "Login" : "Play now"}
                </button>
              </div>
            </div>

            <hr className="w-[95%] opacity-[.2] m-auto"></hr>
            <div>
              <div>
                <h2 className="text-[#FFFFFF] text-[48px] text-center font-semibold">
                  Privacy Policy for PicksnPrizes
                </h2>
              </div>

              <Card className="m-auto h-[70vh] overflow-auto  pl-24 pt-12 pb-12 pr-24 flex flex-col justify-between w-[95%] my-[2%] bg-[#FFFFFF] shadow-md rounded-[12px] relative">
                <p className="text-[17px] mt-[2%] text-[#0000005A] font-semibold">
                  Welcome to PicksnPrizes! We value your privacy and are
                  committed to protecting your personal information. This
                  Privacy Policy explains how we collect, use, disclose, and
                  safeguard your data when you interact with our website and
                  services. PicksnPrizes is committed to protecting your privacy
                  policy. Please review the following detailed information about
                  your privacy rights and our data practices.
                </p>
                <h4 className="text-[22px] mt-[3%] text-[#000000] font-semibold">
                  1. Information We Collect:
                </h4>
                <p className="text-[17px] mt-[2%] text-[#0000005A] font-semibold">
                  1.1 When you create an account on PicksnPrizes, we may collect
                  various types of personal information, including your name,
                  email address, date of birth, payment details, and any other
                  information necessary for account setup and participation in
                  prediction games.
                </p>
                <p className="text-[17px] text-[#0000005A] font-semibold">
                  1.2 Additionally, we automatically gather non-personal
                  information such as your IP address, device type, browser
                  information, and cookies to enhance user experience, analyse
                  trends, and ensure the security of our platform.
                </p>

                <h4 className="text-[22px] mt-[3%] text-[#000000] font-semibold">
                  2. Use of Your Information:
                </h4>
                <p className="text-[17px] text-[#0000005A] font-semibold">
                  2·1 Your personal information is primarily used to manage your
                  account, process payments, facilitate game participation,
                  communicate importan
                </p>
                <p className="text-[17px] text-[#0000005A] font-semibold">
                  2.2 Non-personal information is utilised for statistical
                  analysis, website optimization, security monitoring, and
                  customization of content to better meet your preferences and
                  needs.
                </p>

                <h4 className="text-[22px] mt-[3%] text-[#000000] font-semibold">
                  3. Data Security Measures:
                </h4>
                <p className="text-[17px] text-[#0000005A] font-semibold">
                  3·1 PicksnPrizes implements robust security measures to
                  protect your personal data from unauthorised access,
                  disclosure, alteration, or destruction. Your information is
                  stored securely and accessed only by authorised personnel for
                  legitimate business purposes.
                </p>
                <p className="text-[17px] text-[#0000005A] font-semibold">
                  3·2 All payment transactions are encrypted and processed
                  through reputable payment gateways to ensure the
                  confidentiality and integrity of your financial details.
                </p>

                <h4 className="text-[22px] mt-[3%] text-[#000000] font-semibold">
                  4. Sharing of Information:
                </h4>
                <p className="text-[17px] text-[#0000005A] font-semibold">
                  4·1 PicksnPrizes do not sell, trade, or rent your personal
                  information to third parties for marketing purposes. However,
                  we may share your data with trusted service providers,
                  partners, or affiliates who assist us in delivering our
                  services, processing payments, or improving user experience.
                </p>
                <p className="text-[17px] text-[#0000005A] font-semibold">
                  4·2 These third parties are contractually obligated to
                  maintain the confidentiality of your information and adhere to
                  data protection regulations.
                </p>

                <h4 className="text-[22px] mt-[3%] text-[#000000] font-semibold">
                  5. Use of Cookies and Tracking Technologies:
                </h4>
                <p className="text-[17px] text-[#0000005A] font-semibold">
                  5·1 PicksnPrizes uses cookies, and similar tracking
                  technologies to enhance website functionality, personalise
                  content, track user interactions, and gather analytics data
                  for performance optimization.
                </p>
                <p className="text-[17px] text-[#0000005A] font-semibold">
                  5·2 You have the option to manage cookie preferences through
                  your browser settings and may choose to disable certain
                  cookies, although this may impact the functionality and
                  usability of our website.
                </p>

                <h4 className="text-[22px] mt-[3%] text-[#000000] font-semibold">
                  6. Data Retention:
                </h4>
                <p className="text-[17px] text-[#0000005A] font-semibold">
                  6·1 PicksnPrizes retain your personal information only for as
                  long as necessary to fulfil the purposes outlined in this
                  Privacy Policy unless a longer retention period is required or
                  permitted by law. The criteria used to determine the
                  appropriate retention period include the duration of your
                  account and our legal obligations.
                </p>

                <h4 className="text-[22px] mt-[3%] text-[#000000] font-semibold">
                  7. User Rights:
                </h4>
                <p className="text-[17px] text-[#0000005A] font-semibold">
                  7·1 You have certain rights regarding your personal data,
                  including the right to access, rectify, delete, or restrict
                  the processing of your information. You may also have the
                  right to data portability and the right to object to
                  processing under certain circumstances.
                </p>
                <p className="text-[17px] text-[#0000005A] font-semibold">
                  7·2 To exercise these rights or for any inquiries regarding
                  your personal data, please contact us using the information
                  provided in the "Contact Us" section below.
                </p>

                <h4 className="text-[22px] mt-[3%] text-[#000000] font-semibold">
                  8. Data Transfer:
                </h4>
                <p className="text-[17px] text-[#0000005A] font-semibold">
                  8·1 Your personal data may be transferred to and processed in
                  countries other than your own, where data protection laws may
                  differ. When PicksnPrizes transfers your information to third
                  parties or service providers outside your country,
                  PicksnPrizes takes appropriate safeguards to ensure that your
                  data is adequately protected in accordance with applicable
                  data protection laws.
                </p>

                <h4 className="text-[22px] mt-[3%] text-[#000000] font-semibold">
                  9. Children's Privacy:
                </h4>
                <p className="text-[17px] text-[#0000005A] font-semibold">
                  9·1 PicksnPrizes only permits players who are 21 years of age
                  or older. Access to the platform is not granted to childrens
                  and individuals under the age of 21.
                </p>

                <h4 className="text-[22px] mt-[3%] text-[#000000] font-semibold">
                  10. Legal Basis for Processing:
                </h4>
                <p className="text-[17px] text-[#0000005A] font-semibold">
                  10·1 PicksnPrizes relies on various legal bases to process
                  your personal data, including consent, contract performance,
                  legitimate interests, and compliance with legal obligations.
                  We may process your information for the purposes stated in
                  this Privacy Policy based on one or more of these legal
                  grounds.
                </p>

                <h4 className="text-[22px] mt-[3%] text-[#000000] font-semibold">
                  11. Data Breach Notification:
                </h4>
                <p className="text-[17px] text-[#0000005A] font-semibold">
                  11·1 In the event of a data breach that may compromise the
                  security of your personal information, PicksnPrizes will
                  notify you and the relevant authorities as required by
                  applicable law. We will also take appropriate measures to
                  mitigate the impact of the breach and prevent future
                  incidents.
                </p>
                <h4 className="text-[22px] mt-[3%] text-[#000000] font-semibold">
                  12. Third-Party Links and Services:
                </h4>
                <p className="text-[17px] text-[#0000005A] font-semibold">
                  12·1 Our website may contain links to third-party websites or
                  services for your convenience. Please be aware that we are not
                  responsible for the privacy practices or content of these
                  external sites. PicksnPrizes recommends reviewing the privacy
                  policies of these third parties before engaging with their
                  services.
                </p>
                <h4 className="text-[22px] mt-[3%] text-[#000000] font-semibold">
                  13. Updates to Privacy Policy:
                </h4>
                <p className="text-[17px] text-[#0000005A] font-semibold">
                  13·1 PicksnPrizes reserves the right to update and revise this
                  Privacy Policy as needed to reflect changes in our data
                  practices, legal requirements, or business operations. Any
                  modifications will be communicated to you through our website
                  or via email.
                </p>
              </Card>
            </div>
          </div>

          <Footer />
        </div>
      )}
    </>
  );
}

export default PrivacyPolicy;
