const moment = require('moment');

export function utcTimetoLocalTimeWithzone(time) {
  const utcTimeString = time;
  const utcTime = new Date(utcTimeString);

  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Convert UTC time to local time with the user's timezone
  const localTime = utcTime.toLocaleString("en-US", { timeZone: userTimezone });

  // Format the local time using Moment.js
  const outputFormat = "ddd D MMM hh:mm:ss a";
  const convertedTime = moment(localTime).format(outputFormat);

  return convertedTime;
}
