import {
  fetchTransactionHistoryStart,
  getTransactionHistory,
  updateTransactionHistoryPagination,
  fetchTransactionHistoryFailure,
} from "../../Components/TransactionHistory/transactionHistory.slice";

import { postData, getData, putData } from "../api";

export const fetchTransactionHistory = (URL) => async (dispatch) => {
  try {
    dispatch(fetchTransactionHistoryStart());
    const response = await getData(URL);
    if (response.results.length >= 0) {
      dispatch(getTransactionHistory(response));
    }
  } catch (error) {
    dispatch(fetchTransactionHistoryFailure(error.message));
  }
};

