import { useState } from "react";
import "./pagination.css";
import { useDispatch } from "react-redux";
import { updatePagination } from "../MyPicks/myPick.slice";
import { updatePurchasePagination } from "../myPurchases/myPurchases.slice";
import { updateWinningPagination } from "../MyWinnings/myWinnings.slice";

function Pagination({ pagination, component }) {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(pagination.currentPage);
  const totalPages = Math.ceil(pagination.totalRecord / pagination.pageSize);

  const handleChangePage = (newPage) => {
    setCurrentPage(newPage);
    if (component === "myPicksMenu") {
      dispatch(updatePagination({ page: newPage, pageSize: 10 }));
    } else if (component === "myPurchases") {
      dispatch(updatePurchasePagination({ page: newPage, pageSize: 10 }));
    } else if (component === "myWinnings") {
      dispatch(updateWinningPagination({ page: newPage, pageSize: 10 }));
    }
  };

  const renderPaginationNumbers = () => {
    const paginationNumbers = [];
    const maxVisiblePages = 5;

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        paginationNumbers.push(i);
      }
    } else {
      const halfMaxVisiblePages = Math.floor(maxVisiblePages / 2);
      const startPage = Math.max(1, currentPage - halfMaxVisiblePages);
      const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

      if (startPage > 1) {
        paginationNumbers.push(1);
        if (startPage > 2) paginationNumbers.push("...");
      }

      for (let i = startPage; i <= endPage; i++) {
        paginationNumbers.push(i);
      }

      if (endPage < totalPages) {
        if (endPage < totalPages - 1) paginationNumbers.push("...");
        paginationNumbers.push(totalPages);
      }
    }

    return paginationNumbers.map((pageNumber, index) => (
      <li key={index}>
        <button
          onClick={() => handleChangePage(pageNumber)}
          className={`flex items-center justify-center px-3 w-[50px] h-[50px] leading-tight ${
            currentPage === pageNumber
              ? "text-blue-600 border border-blue-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
              : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          }`}
        >
          {pageNumber}
        </button>
      </li>
    ));
  };

  return (
    <nav
      aria-label="Page navigation example"
      className="flex justify-end mt-[2%] mr-[3%]"
    >
      <ul className="flex items-center -space-x-px h-8 text-sm">
        <li>
          <button
            onClick={() => handleChangePage(currentPage - 1)}
            disabled={currentPage === 1}
            className="flex items-center w-[50px] h-[50px]
            justify-center px-3  leading-tight
             text-gray-500 bg-white border border-e-0
              border-gray-300 rounded-l-lg hover:bg-gray-100
               hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700
                dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          >
            <span className="sr-only">Previous</span>
            <svg
              className="w-2.5 h-2.5 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 6 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 1 1 5l4 4"
              />
            </svg>
          </button>
        </li>
        {renderPaginationNumbers()}
        <li>
          <button
            onClick={() => handleChangePage(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="flex items-center justify-center w-[50px] h-[50px] px-3 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400"
          >
            <span className="sr-only">Next</span>
            <svg
              className="w-2.5 h-2.5 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 6 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 9 4-4-4-4"
              />
            </svg>
          </button>
        </li>
      </ul>
    </nav>
  );
}

export default Pagination;
