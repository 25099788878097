import React, { useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import Countdown from "react-countdown";
import Card from "../../../Elements/Card";
import Button from "../../../Elements/Button";
import AuthLayout from "../../AuthLayout/AuthLayout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  resendOTPCode,
  verifyOTPCode,
  activateAccount,
} from "../../../services/auth/verification";
import PlayerLayout from "../../PlayerLayout/PlayerLayout";
import useFindDevice from "../../CustomHooks/FindDevice";
import { useLocation } from "react-router-dom";

function PaymentOtpVerification() {
  const location = useLocation();
  const state = location.state || {};
  const winnigAmount = state.amount || "0";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const [resent, setResent] = useState(true);
  const [verifyBtn, setVerifyBtn] = useState(false);
  const [isServerErrorMsg, setServerIsErrorMsg] = useState(false);
  const [resendOtpSucc, setResendOtpSucc] = useState(false); // resend otp success message

  const [errorMsg, setErrorMsg] = useState("Please Enter OTP");
  const [isErrorMsg, setIsErrorMsg] = useState(false);
  const [serverError, setServerError] = useState(""); // error message

  useEffect(() => {
    const storedEndTime = localStorage.getItem("countdownVerfication");
    if (storedEndTime) {
      const remainingTime = new Date(storedEndTime).getTime() - Date.now();
      if (remainingTime > 0) {
        setCountdownTime(new Date(storedEndTime).getTime());
        setResent(true);
      } else {
        setResent(false);
      }
    } else {
      const newEndTime = Date.now() + 300000; // 5 minutes from now
      localStorage.setItem(
        "countdownVerfication",
        new Date(newEndTime).toISOString()
      );
      setCountdownTime(newEndTime);
      setResent(true);
    }
  }, []);

  const [countdownTime, setCountdownTime] = useState(Date.now() + 300000); // Initial countdown time

  const handleChange = (enteredOtp) => {
    setIsErrorMsg(false);
    setServerError("");
    if (enteredOtp.length === 4) {
      setVerifyBtn(true);
    } else {
      setVerifyBtn(false);
    }
    setOtp(enteredOtp);
  };

  const OtpCounter = ({ minutes, seconds, completed }) => {
    if (completed) {
      setResent(false);
      return null;
    } else {
      return (
        <span>
          {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </span>
      );
    }
  };

  const renderInput = (inputProps, index) => {
    let mx = "";
    if (index > -1) {
      mx = "mx-[5%]";
    } else if (index === 1) {
      mx = "mx-[15%]";
    } else {
      mx = null;
    }

    const handleInputChange = (event, currentIndex) => {
      const { value } = event.target;
      
      // Allow only numeric input
      const newValue = value.replace(/\D/g, "");
      
      // Update OTP state
      setOtp((prevOtp) => {
        const newOtp =
          prevOtp.slice(0, currentIndex) +
          newValue.slice(-1) +
          prevOtp.slice(currentIndex + 1);
        return newOtp;
      });
      
      // Move focus to the next input if it's a single digit and not the last input
      if (newValue.length === 1 && currentIndex < 3) {
        const nextIndex = currentIndex + 1;
        const nextInput = document.getElementById(`otp-input-${nextIndex}`);
        if (nextInput) {
          nextInput.focus();
          nextInput.select(); // Optionally select the text in the input for better UX
        }
      }
    };

    return (
      <input
      {...inputProps}
      maxLength={1}
      id={`otp-input-${index}`}
      className={`otp-input w-12 h-12 py-2 border border-gray-300 rounded text-center focus:outline-none focus:border-blue-500 ${mx}`}
      style={{
        width: "40px",
        height: "40px",
        border: "1px solid black", // Uncomment this line to apply the border
      }}
      key={index}
      autoFocus={index === 0}
      onChange={(e) => handleInputChange(e, index)}
    />
    );
  };

  const verifyEmail = localStorage.getItem("registerEmail");
  let pageName = window.location.pathname.split("/")[2];

  const handleResend = async () => {
    setOtp("");
    const newEndTime = Date.now() + 300000;
    localStorage.setItem(
      "countdownVerfication",
      new Date(newEndTime).toISOString()
    );
    setCountdownTime(newEndTime);
    setVerifyBtn(true);
    setServerIsErrorMsg(false);
    setResent(true);
    let res = await dispatch(
      resendOTPCode("/accounts/resendotp", { email: email })
    );
    if (res?.status === "Success") {
      setResendOtpSucc(true);
    } else {
      setResendOtpSucc(false);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setServerIsErrorMsg(false);
    if (otp.length === 4) {
      setIsErrorMsg(false);
      if (email && otp) {
        let res;
        res = await dispatch(
          verifyOTPCode("/accounts/verifyotp", {
            otp: otp,
            email: email,
          })
        );
        if (res?.status === "Success") {
          navigate("/verificationLoading");
        } else {
          setOtp("");
          setServerIsErrorMsg(true);
          setServerError("OTP Invalid");
        }
      } else {
        setIsErrorMsg(true);
      }
    } else {
      setIsErrorMsg(true);
    }
  };

  const { email } = useFindDevice();
  return (
    <PlayerLayout>
      <Card
        className="m-auto bg-[#FFFFFF] mobile-s:w-[90%] mobile-s:mt-[10%] font-[inter]
        mobile-l:mt-[30%] mobile-l:w-[80%]
      mobile-s:h-[50%] shadow-md rounded-[12px] tablet:h-[45%] tablet:mt-[20%] 
         tablet:w-[45%] laptop-s:h-[60%] laptop-s:w-[40%] 
       laptop-s:mt-[1%] laptop-l:!mt-[10%]
       laptop-min:h-[70%]  laptop-min:w-[35%] laptop-l:w-[25%] laptop-l:h-[55%] 
       desktop:w-[20%] desktop:h-[60%]
          flex flex-col justify-center"
      >
        {/* {resendOtpSucc ? (
          <p className="text-green-500 text-center">
            Resend OTP sent to your email
          </p>
        ) : null} */}
        <div className="p-20">
          <div>
            <h2 className="text-center text-[28px] laptop-min:!text-[28px] desktop:text-[32px] font-semibold mb-5">
              Enter OTP
            </h2>
            <p className="text-center laptop-s:text-[18px] laptop-l:text-[18px] text-nowrap text-[15px] desktop:text-[22px] text-[#000000] text-opacity-50 mb-6">
              Withdraw Amount :{" "}
              <span className="text-[#000000]">{winnigAmount} AED</span>
            </p>
            <p className="text-center laptop-s:text-[18px] laptop-l:text-[18px] text-nowrap text-[15px] desktop:text-[22px] text-[#000000] text-opacity-50 mb-6">
              Please enter the verification code that <br />{" "}
              <span
                className="mobile-s:text-[13px] laptop-l:text-[18px] gap-2 mobile-m:text-[15px] laptop-s:text-[18px] desktop:text-[22px]
               justify-center text-[#000000] flex text-opacity-50"
              >
                we sent to <p className="text-[#000000]"> {email}</p>
              </span>
            </p>
          </div>
          <form onSubmit={submitHandler}>
            <div className="flex items-center mb-2">
              <div className="flex w-[80%] laptop-s:w-[90%] desktop:w-[90%] items-center justify-center space-x-2 mb-4">
                <OtpInput
                  name="otp"
                  value={otp}
                  onChange={handleChange}
                  numInputs={4}
                  separator={<span>-</span>}
                  renderInput={renderInput}
                />
              </div>
            </div>
            <div className="flex justify-center">
              {isServerErrorMsg ? (
                <p className="text-red-500 text-center">{serverError}</p>
              ) : null}
              {isErrorMsg ? <p className="text-red-500">{errorMsg}</p> : null}
            </div>

            <div className="mt-1 text-center">
              <Button
                className="bg-blue-500 w-full hover:bg-blue-700 
                p-2 py-3 text-white font-bold rounded focus:outline-none
                 focus:shadow-outline mt-6 mb-4"
                type="submit"
              >
                Verify OTP
              </Button>
            </div>
          </form>
          <div className=" mt-1 text-nowrap text-[14px]">
            <p className="flex text-[#000000] gap-2">
              Didn’t receive code? <span>Check your spam or</span>{" "}
              {!resent ? (
                <a
                  className="text-blue-500 underline focus:outline-none cursor-pointer"
                  onClick={handleResend}
                >
                  Resend code
                </a>
              ) : null}{" "}
              {resent ? (
                <div className="text-[#0081F8]">
                  <Countdown
                    key={countdownTime}
                    date={countdownTime}
                    renderer={OtpCounter}
                  />
                </div>
              ) : null}
            </p>
          </div>
        </div>
      </Card>
    </PlayerLayout>
  );
}

export default PaymentOtpVerification;
