import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import MainRouter from "./Router/MainRouter.js";
import "./App.css";
import usePreventBounce from "./Components/CustomHooks/PreventBounce.js";

function App() {
  useEffect(()=>{
    document.documentElement.scrollTo({
      top: 0,
      left: 0
    })
  },[]);
  
  usePreventBounce();
  const isAuthUser = localStorage.getItem("isAuthUser");
  return (
    <div className="App">
      <MainRouter isAuthenticated={isAuthUser} />
    </div>
  );
}

export default App;
