import { createSlice } from "@reduxjs/toolkit";

const myPicksHistorySlice = createSlice({
  name: "myPicksHistory",
  initialState: {
    loading: false,
    potInfo: {},
    myPicksPrediction: [],
    error: "",
  },
  reducers: {
    fetchMyPickHistoryStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchMypickHistoryInformation: (state, action) => {
      state.myPicksPrediction = [];
      state.myPicksPrediction = action.payload.data;
    },
    fetchPotInfo: (state, action) => {
      state.potInfo = action.payload.data;
    },
    fetchMyPickHistoryFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchMyPickHistoryStart,
  fetchMypickHistoryInformation,
  fetchMyPickHistoryFailure,
  fetchPotInfo,
} = myPicksHistorySlice.actions;
export default myPicksHistorySlice.reducer;
