import { createSlice } from "@reduxjs/toolkit";

const transactionHistory = createSlice({
  name: "transactionHistory",
  initialState: {
    loading: false,
    transactionInfo: [],
    transactionMobiles: [],
    pagination: {
      totalRecord: 0,
      pageSize: 10,
      currentPage: 1,
    },
    error: "",
  },
  reducers: {
    fetchTransactionHistoryStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    getTransactionHistory: (state, action) => {
      state.pagination.totalRecord = action.payload.count;
      state.transactionInfo = [];
      state.transactionInfo = [
        ...state.transactionInfo,
        ...action.payload.results,
      ];

      let concatTransaction = [];
      let mobileData = JSON.parse(JSON.stringify(state.transactionMobiles));
      if (mobileData.length > 0) {
        concatTransaction = [
          ...JSON.parse(JSON.stringify(state.transactionMobiles)),
          ...action.payload.results,
        ];
      } else {
        concatTransaction = [...action.payload.results];
      }
      const res = [
        ...new Map(concatTransaction.map((ele) => [ele.id, ele])).values(),
      ];
      state.transactionMobiles = res;
    },
    updateTransactionHistoryPagination: (state, action) => {
      state.pagination.currentPage = action.payload.page;
    },
    fetchTransactionHistoryFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchTransactionHistoryStart,
  getTransactionHistory,
  updateTransactionHistoryPagination,
  fetchTransactionHistoryFailure,
} = transactionHistory.actions;
export default transactionHistory.reducer;
