import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "signup",
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {
    fetchSingUserStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    postSingUserSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    fetchSingUserFailure: (state, action) => {
      state.loading = false;
      state.error = action?.payload[0];
    },
    clearUserFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateErrorValues: (state, action) => {
      state.loading = false;
      state.error = null;
    },
  },
});

export const {
  fetchSingUserStart,
  postSingUserSuccess,
  fetchSingUserFailure,
  clearUserFailure,
  updateErrorValues
} = userSlice.actions;
export default userSlice.reducer;
