export const jackpotTableHeader = [
  {
    id: 1,
    cellName: "Round ID",
    value: "potId",
  },
  {
    id: 2,
    cellName: "Pick ID",
    value: "pickId",
  },
  {
    id: 3,
    cellName: "Round Category",
    value: "potCategory",
  },
  {
    id: 4,
    cellName: "Match week",
    value: "matchWeek",
  },
  {
    id: 5,
    cellName: "Round Value",
    value: "potValue",
  },
  {
    id: 6,
    cellName: "No. of Picks",
    value: "noOfActivePicks",
  },
  {
    id: 7,
    cellName: "Round Status",
    value: "potStatus",
  },
];

export const myTableHeader = [
  {
    id: 1,
    cellName: "Round ID",
    value: "pot_id",
  },
  {
    id: 1,
    cellName: "Pick ID",
    value: "pick_id",
  },
  // {
  //   id: 2,
  //   cellName: "Round Category",
  //   value: "category",
  // },
  {
    id: 2,
    cellName: "League",
    value: "leaguename",
  },
  {
    id: 3,
    cellName: "Last MW",
    value: "last_mw",
  },
  {
    id: 4,
    cellName: "Round Value",
    value: "current_pot_value",
  },

  {
    id: 5,
    cellName: "Round Status",
    value: "status",
  },
  {
    id: 6,
    cellName: "No. of Picks",
    value: "no_of_picks",
  },
  {
    id: 7,
    cellName: "My Status",
    value: "pick_status",
  },
  {
    id: 8,
    cellName: "Action",
    value: "action",
  },
];

export let mobileTableHeader = [
  {
    id: 1,
    cellName: "Round ID",
    value: "potId",
    with: "33%",
  },
  {
    id: 2,
    cellName: "Round Value",
    value: "potValue",
    with: "33%",
  },
  {
    id: 3,
    cellName: "My Status",
    value: "myStatus",
    with: "34%",
  },
];

export const mobilesecondTableHeader = [
  {
    id: 1,
    cellName: "Match Week",
    value: "matchWeek",
    with: "25%",
  },
  {
    id: 3,
    cellName: "My Pick",
    value: "matchPick",
    with: "25%",
  },
  {
    id: 4,
    cellName: "My Status",
    value: "matchStatus",
    with: "25%",
  },
];

export const myPurchachesesHeader = [
  {
    id: 1,
    cellName: "Transaction ID",
    value: "purchasesID",
  },
  // {
  //   id: 2,
  //   cellName: "Payment Mode",
  //   value: "paymentMode",
  // },
  {
    id: 3,
    cellName: "Purchase Date",
    value: "purchaseDate",
  },
  {
    id: 4,
    cellName: "Amount Paid",
    value: "amountPaid",
  },

  {
    id: 5,
    cellName: "Tokens Purchased",
    value: "tokensPurchased",
  },
  {
    id: 6,
    cellName: "Payment Status",
    value: "paymentStatus",
  },
];
