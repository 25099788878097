import { Navigate } from "react-router-dom";

const AuthRouter = ({ isAuthenticated, ...rest }) => {
  return isAuthenticated.isAuthenticated ? (
    <Navigate to="/dashboard" replace />
  ) : (
    <Navigate to="/home" replace />
  );
};

export default AuthRouter;
