import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.svg";
import Teams from "../../assets/images/teams1.png";
import Teamsmob from "../../assets/images/mobteams.png";
import { useState, useEffect } from "react";
import Card from "../../Elements/Card";
import Footer, { FooterTab, MobileFooter } from "../GreenHome/Footer";
import useFindDevice from "../CustomHooks/FindDevice";

function TermsAndConditions() {
  const navigate = useNavigate();
  const { isMob, isTab, isLaptop, isLogedIn } = useFindDevice();

  const redirectToLogin = () => {
    navigate("/login");
  };

  const redirectToRegister = () => {
    navigate("/signup");
  };

  const redirectHome = () => {
    navigate("/home");
  };

  return (
    <>
      {isMob === true ? (
        <div
          className={`font-interFont overflow-auto ${
            isMob
              ? "bg-[url('../public/card/home-screen-mobile-bg.svg')]"
              : "bg-[url('../public/card/home-bg.svg')]"
          } bg-cover h-[100vh]`}
        >
          <div className="h-[100vh] mb-[50px] mobile-s:h-[750px] mobile-l:h-[850px] w-full p-5 font-Inter">
            <div className="flex w-full p-3">
              <div className="w-[50%]">
                <img src={Logo} alt="pickNdPrizes" onClick={redirectHome}></img>
              </div>
              <div className={`w-[50%] ml-[10%] mt-[4%] flex float-right`}>
                {!isLogedIn ? (
                  <button
                    className=" border-[1px] border-[#00C05B] ml-2 rounded-[4px] w-[95px] h-[37px] text-[14px] cursor-pointer text-[#00C05B]"
                    onClick={redirectToRegister}
                  >
                    Register
                  </button>
                ) : null}

                <button
                  className={`w-[90px] h-[35px] text-[14px] font-[500] bg-[#00C05B] text-[#002427] ${
                    !isLogedIn ? "ml-5" : "ml-14"
                  } rounded-[4px]`}
                  onClick={redirectToLogin}
                >
                  {!isLogedIn ? "Login" : "Play now"}
                </button>
              </div>
            </div>

            <hr className="w-[95%] opacity-[.2] m-auto"></hr>
            <div>
              <div className="mt-[2%]">
                <h2 className="text-[#FFFFFF] text-[32px] text-center">
                  Terms & Conditions
                </h2>
              </div>

              <Card className="m-auto h-[65.6vh] overflow-auto mt-5 pl-8 pt-12 pb-12 pr-8 flex flex-col justify-between w-[100%] my-[2%] bg-[#FFFFFF] shadow-md rounded-[12px] relative">
                <div className="text-[15px] text-[#000000] font-semibold">
                  <h4>1. Introduction </h4>
                  <h4>2. Terms & Conditions</h4>
                  <h4>3. Eligibility & Registration</h4>
                  <h4>4. Game Announcement & Entry</h4>
                  <h4>5. Rules of MoneyBall</h4>
                  <h4>6. How MoneyBall is Won</h4>
                  <h4>7. Taxes</h4>
                  <h4>8. Use of Personal Information</h4>
                  <h4>9. Intellectual Property</h4>
                  <h4>10. General Conditions</h4>
                  <h4>11. Governing Law & Jurisdiction</h4>
                </div>
                <h4 className="text-[15px] mt-[4%] text-[#000000] font-semibold">
                  1.Introduction
                </h4>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  PicksnPrizes (‘games’, or ‘MoneyBall’) are brought to you by
                  SureShotSports FZ-LLC ( ‘SSS’, ’the Company’, ‘we’, ‘us’ or
                  ‘our’).
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  SureShotSports FZ-LLC is a limited liability company
                  incorporated under the laws , rules and regulations of Ras Al
                  Khaimah Economic Zone Authority under trade license No.
                  17002810 with a registered office situated at FDAM0767 Compass
                  Building, Al Shohada Road, Al Hamra Industrial Zone, Ras Al
                  Khaimah, UAE.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  These Terms and Conditions of Service (‘Terms and Conditions’,
                  ‘Terms’) lay out the Terms on which you are allowed to access
                  and play PicksnPrizes products (games which are playable on
                  our website)
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  Reference to ‘you’ or ‘your’ or ‘customer’ or ‘participant’ or
                  ‘player’ is reference to any person that uses the services of
                  SureShotSports FZ-LLC or any registered customer of SSS.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  The Terms can be accessed via our website at
                  www.picksnprizes.com (the ‘website’)
                </p>
                <h4 className="text-[15px] mt-[2%] text-[#000000] font-semibold">
                  2.Terms and Conditions
                </h4>
                <p className="text-[15px] mt-[2%] text-[#0000005A] font-semibold">
                  2.1 By registering on our website and taking part in any of
                  the games via the website/mobile application ‘App’ (including,
                  but not limited to sub domains and/or API’s ) you confirm that
                  you have read and are legally bound by:
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  (i) our Terms and Conditions of the games;
                </p>
                <p className="text-[15px] mt-[1%]  text-[#0000005A] font-semibold">
                  (ii) our Privacy Policy;
                </p>
                <p className="text-[15px] mt-[1%]  text-[#0000005A] font-semibold">
                  (iii) our Cookies Policy and
                </p>
                <p className="text-[15px] mt-[1%]  text-[#0000005A] font-semibold">
                  (iv) the rules of our games
                </p>
                <p className="text-[15px] mt-[1%]  text-[#0000005A] font-semibold">
                  (v) you have the mental capacity to take responsibility for
                  you own actions and decision making, and can enter into this
                  contract with us.
                </p>
                <p className="text-[15px] mt-[1%]  text-[#0000005A] font-semibold">
                  (vi) you accept full responsibility for playing in these games
                  in the knowledge that winning is not guaranteed and tokens
                  have to be purchased with money to be able to play.
                </p>
                <p className="text-[15px] mt-[1%]  text-[#0000005A] font-semibold">
                  (vii) You will not use this service in any way which could
                  cause offence, be considered defamatory, obscene, abusive,
                  unlawful, racist, sexist or in any way discriminatory to any
                  of SSS’s staff or other customers.
                </p>
                <p className="text-[15px] mt-[1%]  text-[#0000005A] font-semibold">
                  Please read these Terms carefully, and pay attention to all
                  the terms before registering with us and playing any of the
                  games on our website.
                </p>
                <p className="text-[15px] mt-[1%]  text-[#0000005A] font-semibold">
                  2.2 These Terms are effective from 1st May 2024. SSS reserves
                  the right to amend, modify, and/or change the Terms at any
                  time for any number of reasons, including but not restricted
                  to comply with changes to applicable laws and regulations and
                  any other regulatory requirements. An up to date set of terms
                  and conditions will be available on our website and you are
                  advised to review the Terms on a frequent basis. If for any
                  reason any changes in Terms are not acceptable to you, then
                  you should close your account with us and cease using our
                  services. If you continue to use our service and participate
                  in any of the games on our website after the date that the
                  changes of the Terms come into effect then you will be deemed
                  to have accepted these changes and are happy to continue.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  2.3 It is your sole responsibility to review the Terms and
                  Conditions (including the Game Rules) and any amendments to
                  them each time you use the website and/or mobile Application.
                </p>
                <h4 className="text-[15px] mt-[2%] text-[#000000] font-semibold">
                  3. Eligibility & Registration
                </h4>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  3.1 In order to participate in the games that SSS provide you
                  will need to register on our website. You must be at least
                  Twenty-One (21) years of age to be able to register.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  3.2 When registering, you will be required to provide personal
                  details such as, Your name, Date of birth, address, telephone
                  number and an email address. You warrant that all the personal
                  details you provide are accurate, correct and complete. If the
                  details are incomplete or inaccurate, we may suspend your
                  account. If any of your personal details change, please inform
                  us as soon as possible so that we may maintain accurate and up
                  to date records. You can change the details provided at
                  registration at any time by editing your profile after you log
                  in.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  3.3 The Company reserves the right, at any time, to request
                  verification of the age, identity, residential address, or any
                  other information relevant to participation in the
                  Competition/s of all participants. The Company reserves the
                  right to disqualify any participant who provides false
                  information or fails to provide information that is reasonably
                  requested by the Company.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  3.4 A customer is only permitted to have one account to use
                  our service and the personal details that are provided will be
                  confirmed through verification checks. We may use third-party
                  companies to carry out these checks on the company’s behalf.
                  This process involves checking the details that you disclose
                  against certain databases, both public and private. By
                  entering this process you agree that we may use, record and
                  disclose such personal information, and that the data may be
                  recorded by us or the third party company.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  Customers may not have more than one Account. If we have
                  reason to believe two or more accounts are held by the same
                  person, they will be deemed ‘Duplicate Accounts’. The Account
                  most recently registered will be closed. If any winnings have
                  been obtained via a duplicate account SureShotSports FZ-LLC
                  reserves the right to withhold these winnings
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  3.5 All transactions where your username and password have
                  been entered correctly will be regarded as valid, whether or
                  not authorized by you, and we shall not be liable for any
                  claims in the event that you disclose them to anyone. It is
                  your sole responsibility to maintain the confidentiality of
                  your password and you are responsible for all activity that
                  occurs under your username and password. You should notify us
                  of any unauthorized use of your password and any breach of
                  security as soon as you become aware of it.
                </p>
                <h4 className="text-[15px] mt-[2%] text-[#000000] font-semibold">
                  4. Games Announcement & Entry
                </h4>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  4.1 The Company or SSS announces that new games or rounds are
                  available via any of the following:
                </p>
                {/* <p className="text-[15px] text-[#0000005A] font-semibold">
                  (i) Our website www.picksnprizes.com
                </p> */}
                <div className="flex mt-[1%] gap-2">
                  <p className="text-[15px] text-[#0000005A] font-semibold">
                    (i) Our website{" "}
                    <a
                      href="http://www.picksnprizes.com"
                      alt="picksnprizes"
                      className="text-[#4f779b]"
                      style={{ textDecoration: "underline" }}
                    >
                      www.picksnprizes.com
                    </a>
                  </p>
                </div>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  (ii) The Company’s account on social media platforms
                  “Facebook”, “Instagram”, “Twitter”, “Tik Tok”
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  (iii) Emails
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  4.2 Customers can play in any game or round once their account
                  is active by the use of Tokens. Tokens can be purchased on the
                  website and each round will have a specified number of Tokens
                  to enter.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  4.3 Any purchased tokens will not be available for withdrawal
                  at any point and must be used towards playing the game. Only
                  winnings can be withdrawn.
                </p>
                <h4 className="text-[22px] mt-[2%] text-[#000000] font-semibold">
                  5. Rules of MoneyBall (the game)
                </h4>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  5.1 By accepting the Terms and Conditions, you agree to the
                  Competition Rules, and it will be legally binding on you.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  5.2 The rules of MoneyBall are the same for all MoneyBall
                  games.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  5.3 The entry fee and the prize money for each round of
                  MoneyBall are pre-determined before the start of the round.
                  The aggregate of all the entry fees in a particular round
                  minus a 10% administrative fee will make up the prize money or
                  the jackpot of that round.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  5.4 Using each account a participant can join any MoneyBall
                  round with one or two picks. Two is the maximum per
                  participant in a single round.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  5.5 If a participant is playing with two picks each pick is
                  treated completely independently in terms of playing the game.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  5.6 The participant shall join any round (active/open round)
                  of the competition up until the deadline of that round. The
                  deadline of each round and each match week within the round is
                  set at 1 hour prior to the commencement of the first match in
                  that match week.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  5.7 The objective of the game is to assign each of your picks
                  to a team in that week’s fixture list who the participant
                  thinks they win their match. If the selected team wins their
                  match that pick progresses to the following match week. If the
                  selected team draws or loses their match then that pick is
                  eliminated.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  Example: Pick 1: Arsenal, and Pick 2: Liverpool. Arsenal draw
                  and Liverpool win then Pick 1 is eliminated and Pick 2
                  progresses.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  If your pick is successful then you progress to the following
                  week and must repeat the process.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  5.8 Once a team is picked it cannot be chosen again under the
                  same pick until the round is over.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  Example: Since Pick 2 above selected Liverpool and they won
                  their match, then that player can no longer choose Liverpool
                  under Pick 2 for the rest of the round.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  5.9 Once again, and as indicated in point 5.4 above, if a
                  participant is playing in a round with two picks then they are
                  treated independently.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  Using the same example in point 6 above, let’s assume both
                  Arsenal & Liverpool have won their respective matches, in that
                  case Pick 1 will no longer be able to choose Arsenal again
                  until the round is over, however Pick 2 can still use Arsenal
                  since it hasn’t been used before.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  Consequently, since the two entries are treated independently
                  a player can pick the same team for both their picks as long
                  as he hasn’t used them previously in the same round.
                </p>

                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  5.10 If a pick is not submitted before the deadline, that pick
                  will automatically be assigned to the lowest ranked team in
                  the league table that is available for that pick. If a
                  participant is playing with two picks and misses the deadline
                  on both, then one pick will be assigned to the lowest ranked
                  team available from the league table while the second pick
                  will be assigned to the second to last available team.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  Example: Sheffield and Burnley are ranked 20th and 19th in the
                  Premier League coming into the match week. A participant who
                  is playing with two picks misses the deadline. In that case,
                  Pick 1 will automatically select Sheffield while Pick 2 will
                  automatically select Burnley. Let’s assume that participant
                  had already picked Burnley under Pick 2 in a prior match week
                  within that same round. In that case Pick 2 will automatically
                  select the 18th ranked team in the table and so on.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  5.11 Each pick is only allowed one default per round. The
                  second default within the same round will result in automatic
                  elimination.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  5.12 Once a pick is made it can be changed up till the
                  deadline of that match week.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  5.13 If a match is postponed prior to the deadline lapsing the
                  player must re-pick by choosing a team from the remaining
                  fixtures that match week. If the match is postponed after the
                  deadline has passed then any picks for that fixture will be
                  deemed successful and the players involved will not be allowed
                  to pick those same teams again in that round.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  5.14 Any matches played out of the normal schedule or that
                  were previously postponed will not be part of the fixtures to
                  select from and will be ignored.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  5.15 A match week will only be considered for selection if it
                  has a minimum of 8 fixtures. Match weeks that have less will
                  be skipped in the schedule.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  5.16 The Company reserves the right, in its sole discretion,
                  to refuse to accept participation that is incomplete,
                  indecipherable, offensive, does not comply with these T&Cs, or
                  contravenes any applicable laws or regulation.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  5.17 The Company reserves the right, in its sole discretion,
                  to disqualify any participant's account who has breached any
                  of these T&Cs, has engaged in unlawful or improper conduct or
                  otherwise acts to cheat or undermine the fairness of the
                  Competition/s by, for example, tampering with, or using or
                  exploiting errors in, the participation process to obtain a
                  competitive advantage over other entrants.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  5.18 SureShotSports FZ-LLC, if necessary to do so, reserve the
                  right to suspend, amend, void or even cancel a game. This may
                  be necessary for reasons beyond our reasonable control
                  including, but not limited to strike, labour dispute, illness,
                  act of God, natural disaster, adverse weather conditions, war,
                  riot, accident, public health concern in relation to potential
                  infection, epidemic or pandemic, malicious damage, fire, flood
                  and/or storm, compliance with law or governmental order, rule,
                  regulation or direction, breakdown of plant, machinery or
                  transportation. SSS are not responsible for any loss or damage
                  caused to you as a result of any of these occurrences.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  5.19 The eligibility to participate is solely at the
                  discretion of the Company and we reserve the right to block or
                  not accept any registration.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  5.20 The Company assumes no responsibility for any failure to
                  receive participation or for inaccurate information or any
                  loss, damage, or injury because of technical or
                  telecommunications problems, including security breaches. If
                  such problems arise, the Company may (where necessary with the
                  approval of the relevant authority) modify, cancel, terminate,
                  or suspend the Competition/s.
                </p>

                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  5.21 MoneyBall Champions League
                </p>

                <p className="text-[15px] ml-[1%] mt-[1%] text-[#0000005A] font-semibold">
                5.21.1 With the exception of the below deviations, all other rules from traditional MoneyBall apply.
                </p>

                <p className="text-[15px] ml-[1%] mt-[1%] text-[#0000005A] font-semibold">
                5.21.2 Unlike normal league based MoneyBall competitions, the Champions League version of MoneyBall will be initially based on a league system. After the first round of 8 match weeks the game will be based on a knock-out system.
                </p>

                <p className="text-[15px] ml-[1%] mt-[1%] text-[#0000005A] font-semibold">
                5.21.3 During the first eight match weeks players will be able to make their selections among the 36 clubs involved.
                </p>
                <p className="text-[15px] ml-[1%] mt-[1%] text-[#0000005A] font-semibold">
                5.21.4 After match week eight, match week 9 & 10 will be the two legs of the play-off which will be between the teams who finished 9th -24th in the table. This means that your selections will be restricted to those 16 teams.
                </p>
                <p className="text-[15px] ml-[1%] mt-[1%] text-[#0000005A] font-semibold">
                5.21.5 Match week 11 and beyond will be based on the 16 teams that have qualified to the round of 16. These will include the 8 top teams after match week 8 in addition to the 8 teams qualified from match week 9 & 10 play-off.
                </p>
                <p className="text-[15px] ml-[1%] mt-[1%] text-[#0000005A] font-semibold">
                5.21.6 Regardless of how many picks remain alive the game ends after the second leg of the quarter-finals. The semi-finals and final will not be included in the game.
                </p>
                <p className="text-[15px] ml-[1%] mt-[1%] text-[#0000005A] font-semibold">
                5.21.7 Each leg of each play-0ff & knock out stage will be considered a separate match week. For example leg 1 of the round of 16 is one match week while leg 2 is another.
                </p>
                <p className="text-[15px] ml-[1%] mt-[1%] text-[#0000005A] font-semibold">
                5.21.8 Should we have more than one pick remaining after the second leg of the quarter final the jackpot will be distributed equally among the remaining picks.
                </p>
                <p className="text-[15px] ml-[1%] mt-[1%] text-[#0000005A] font-semibold">
                5.21.9 There could be a situation where players are still alive at the quarter final stage and have no teams to pick from. For Example, the 8 teams who qualified to the quarter final had already been selected in prior match weeks by the player. In this case when a player has no teams available to pick from he/she will be automatically eliminated. Accordingly players must be very careful in making their selections early so as to ensure they have options during the quarter finals.
                </p>
                <p className="text-[15px] ml-[1%] mt-[1%] text-[#0000005A] font-semibold">
                5.21.10 Defaults at every stage will be based on the table ranking in the first 8 match weeks. During the play-off & knock-out a default will be assigned based on the lowest rank team playing in these round as per the ranking after match week 8.
                </p>

                <h4 className="text-[15px] mt-[2%] text-[#000000] font-semibold">
                  6. How MoneyBall (the game) is Won
                </h4>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  6.1 The game is won by the participant(s) who outlasts
                  everyone else. If we have one pick remaining after a match
                  week then that pick is the sole winner of the jackpot.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  6.2 If all the players remaining are all eliminated at the
                  same time then the jackpot is split equally amongst them.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  6.3 Once the number of picks remaining in a round is down to 5
                  picks or less a vote is automatically triggered on whether the
                  participants would like to equally share the prize among them
                  or continue playing.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  6.4 A split vote is successful only if it is unanimous,
                  otherwise we play on! If the vote is in favour of playing on,
                  the split/play on vote keeps getting triggered at the end of
                  each match week thereafter until we have a winner or a split
                  decision
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  6.5 When the game has gone to the maximum number of weeks
                  without a winner then the jackpot is split equally among the
                  remaining players. Maximum number of weeks is dependent on how
                  many teams are playing in that league.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  For example, for a league with 20 teams the maximum number of
                  weeks we can play without a winner is 20. For a league with 18
                  teams then the maximum number of weeks we can play is 18.
                </p>
                <h4 className="text-[22px] mt-[2%] text-[#000000] font-semibold">
                  7. Taxes
                </h4>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  7.1 If prizes are subject to any local tax law it is directly
                  deducted from the prize value.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  7.2 The amount paid towards purchasing token package are
                  inclusive of any local applicable taxes.
                </p>
                <h4 className="text-[15px] mt-[2%] text-[#000000] font-semibold">
                  8. Use of Personal Information
                </h4>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  8.1 Personal information including the eligible Participant’s
                  name, address, telephone number, and email will be collected
                  and used to conduct the Competitions. This may require
                  disclosure to third parties, including local regulatory
                  authorities and the Company’s agents or third-party service
                  providers, to conduct the Competition/s, or for promotional
                  and marketing purposes (including direct marketing). By
                  registering on our website the customer consents to the use of
                  their personal information.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  8.2 The participant may access, edit and/or update his
                  personal information under the privacy policy of the Company.
                </p>
                <h4 className="text-[22px] mt-[2%] text-[#000000] font-semibold">
                  9. Intellectual Property
                </h4>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  9.1 Players and participants acknowledge and agree that all
                  copyright, trademarks and any other intellectual property
                  rights contained in any material or any content supplied
                  (including any software) by us via the website or the mobile
                  application to you shall at all times remain vested in us or
                  our licensors. These intellectual property rights include,
                  without limitation, the design, graphics, layout, look and
                  feel and structure of our website, database rights, design
                  rights, domain names and rights to goodwill and/or to sue for
                  passing off. You are permitted to use this material and
                  content only as expressly authorized by us or our licensors.
                </p>

                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  9.2 By entering the Competition(s), participants agree to deal
                  with their entry content in any way that may otherwise
                  infringe the participants' moral rights and thus participants
                  agree to waive their claim of their moral rights (wherever
                  such rights are recognized) against the Company or its
                  assigns, licensees, or successors.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  9.3 Players/participants acknowledge and agree that the
                  material and content contained within our website and/or
                  mobile application are utilized in the provision of the
                  Services is made available by us to you for your own personal
                  non-commercial use only. Any other use of such material and
                  content is strictly prohibited. You agree not to (and agree
                  not to assist or facilitate any third party to) copy,
                  reproduce, transmit, publish, display, distribute,
                  commercially exploit, tamper with or create derivative works
                  of such material and content.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  9.4 The names, images and logos identifying us, our partners
                  or other third parties and our/their products and services
                  contained on our website and or mobile application are
                  proprietary marks and may not be reproduced or otherwise used
                  without our consent.
                </p>

                <h4 className="text-[22px] mt-[2%] text-[#000000] font-semibold">
                  10. General Conditions
                </h4>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  10.1 If for any reason beyond the reasonable control of the
                  Company the competition or game is not capable of running as
                  planned, the Company reserves the right in its sole discretion
                  to take any action that may be available to it, and to cancel,
                  terminate, modify, postpone, or suspend the competition(s) or
                  game(s), unless to do so would be prohibited by law.
                </p>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  10.2 The Company reserves the right to cancel, terminate,
                  modify, postpone, or suspend the Competition/s or amend these
                  terms and conditions, subject to any directions from a
                  regulatory authority.
                </p>
                <h4 className="text-[22px] mt-[2%] text-[#000000] font-semibold">
                  11. Governing Law and Jurisdiction
                </h4>
                <p className="text-[15px] mt-[1%] text-[#0000005A] font-semibold">
                  11.1 The games on our website and any dispute or claim arising
                  out of or in connection with it or its subject matter or
                  formation shall be governed by and construed under the law,
                  rules and regulations of Ras Al Khaimah Economic Zone
                  Authority and the participant irrevocably agrees that the UAE
                  courts shall have exclusive jurisdiction to settle any dispute
                  or claim that arises out of or in connection with the
                  Competition/s or its subject matter or formation.
                </p>
              </Card>
            </div>
          </div>

          <MobileFooter />
        </div>
      ) : isTab ? (
        <div className="overflow-auto bg-[url('../public/card/tab-bg.svg')] bg-cover h-[100vh]">
          <div className="h-[100vh] w-full p-5 font-Inter">
            <div className="flex items-center w-full px-3 pb-5 font-interFont">
              <div className="w-[44%] ">
                <img src={Logo} alt="pickNdPrizes" onClick={redirectHome} />
              </div>
              <div className="w-[56%] justify-end mr-[1%] mt-[1%] flex float-right ">
                {!isLogedIn ? (
                  <>
                    {" "}
                    <p className="text-[#FFFFFF] mt-4">Not a member yet ?</p>
                    <p
                      className="text-[#00C05B] font-[18px] mt-4 ml-2 cursor-pointer"
                      onClick={redirectToRegister}
                    >
                      Register Now
                    </p>
                  </>
                ) : null}

                <button
                  className="w-[90px] h-[35px] text-[14px] mt-3 font-[500] bg-[#00C05B] text-[#002427] ml-5 rounded-[4px]"
                  onClick={redirectToLogin}
                >
                  {!isLogedIn ? "Login" : "Play now"}
                </button>
              </div>
            </div>

            <hr className="w-[95%] opacity-[.2] m-auto"></hr>
            {/* <div> */}
            <div className="mt-[2%]">
              <h2 className="text-[#FFFFFF] text-[48px] text-center font-interFont">
                Terms & Conditions
              </h2>
            </div>

            <Card className="m-auto font-interFont h-[70vh] overflow-auto pl-12 pt-12 pb-12 pr-12 flex flex-col justify-between w-[95%] my-[2%] bg-[#FFFFFF] shadow-md rounded-[12px]">
              <div className="text-[22px] text-[#000000] font-semibold">
                <h4>1. Introduction </h4>
                <h4>2. Terms & Conditions</h4>
                <h4>3. Eligibility & Registration</h4>
                <h4>4. Game Announcement & Entry</h4>
                <h4>5. Rules of MoneyBall</h4>
                <h4>6. How MoneyBall is Won</h4>
                <h4>7. Taxes</h4>
                <h4>8. Use of Personal Information</h4>
                <h4>9. Intellectual Property</h4>
                <h4>10. General Conditions</h4>
                <h4>11. Governing Law & Jurisdiction</h4>
              </div>
              <h4 className="text-[22px] mt-[4%] text-[#000000] font-semibold">
                1.Introduction
              </h4>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                PicksnPrizes (‘games’, or ‘MoneyBall’) are brought to you by
                SureShotSports FZ-LLC ( ‘SSS’, ’the Company’, ‘we’, ‘us’ or
                ‘our’).
              </p>

              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                SureShotSports FZ-LLC is a limited liability company
                incorporated under the laws , rules and regulations of Ras Al
                Khaimah Economic Zone Authority under trade license No. 17002810
                with a registered office situated at FDAM0767 Compass Building,
                Al Shohada Road, Al Hamra Industrial Zone, Ras Al Khaimah, UAE.
              </p>

              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                These Terms and Conditions of Service (‘Terms and Conditions’,
                ‘Terms’) lay out the Terms on which you are allowed to access
                and play PicksnPrizes products (games which are playable on our
                website)
              </p>

              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                Reference to ‘you’ or ‘your’ or ‘customer’ or ‘participant’ or
                ‘player’ is reference to any person that uses the services of
                SureShotSports FZ-LLC or any registered customer of SSS.
              </p>

              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                The Terms can be accessed via our website at
                www.picksnprizes.com (the ‘website’)
              </p>
              <h4 className="text-[22px] mt-[2%] text-[#000000] font-semibold">
                2.Terms and Conditions
              </h4>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                2.1 By registering on our website and taking part in any of the
                games via the website/mobile application ‘App’ (including, but
                not limited to sub domains and/or API’s ) you confirm that you
                have read and are legally bound by:
              </p>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                (i) our Terms and Conditions of the games;
              </p>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                (ii) our Privacy Policy;
              </p>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                (iii) our Cookies Policy and
              </p>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                (iv) the rules of our games
              </p>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                (v) you have the mental capacity to take responsibility for you
                own actions and decision making, and can enter into this
                contract with us.
              </p>
              <p className="text-[17px] text-[#0000005A] font-semibold">
                (vi) you accept full responsibility for playing in these games
                in the knowledge that winning is not guaranteed and tokens have
                to be purchased with money to be able to play.
              </p>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                (vii) You will not use this service in any way which could cause
                offence, be considered defamatory, obscene, abusive, unlawful,
                racist, sexist or in any way discriminatory to any of SSS’s
                staff or other customers.
              </p>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                Please read these Terms carefully, and pay attention to all the
                terms before registering with us and playing any of the games on
                our website.
              </p>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                2.2 These Terms are effective from 1st May 2024. SSS reserves
                the right to amend, modify, and/or change the Terms at any time
                for any number of reasons, including but not restricted to
                comply with changes to applicable laws and regulations and any
                other regulatory requirements. An up to date set of terms and
                conditions will be available on our website and you are advised
                to review the Terms on a frequent basis. If for any reason any
                changes in Terms are not acceptable to you, then you should
                close your account with us and cease using our services. If you
                continue to use our service and participate in any of the games
                on our website after the date that the changes of the Terms come
                into effect then you will be deemed to have accepted these
                changes and are happy to continue.
              </p>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                2.3 It is your sole responsibility to review the Terms and
                Conditions (including the Game Rules) and any amendments to them
                each time you use the website and/or mobile Application
              </p>
              <h4 className="text-[22px] mt-[2%] text-[#000000] font-semibold">
                3. Eligibility & Registration
              </h4>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                3.1 In order to participate in the games that SSS provide you
                will need to register on our website. You must be at least
                Twenty-One (21) years of age to be able to register.
              </p>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                3.2 When registering, you will be required to provide personal
                details such as, Your name, Date of birth, address, telephone
                number and an email address. You warrant that all the personal
                details you provide are accurate, correct and complete. If the
                details are incomplete or inaccurate, we may suspend your
                account. If any of your personal details change, please inform
                us as soon as possible so that we may maintain accurate and up
                to date records. You can change the details provided at
                registration at any time by editing your profile after you log
                in.
              </p>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                3.3 The Company reserves the right, at any time, to request
                verification of the age, identity, residential address, or any
                other information relevant to participation in the Competition/s
                of all participants. The Company reserves the right to
                disqualify any participant who provides false information or
                fails to provide information that is reasonably requested by the
                Company.
              </p>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                3.4 A customer is only permitted to have one account to use our
                service and the personal details that are provided will be
                confirmed through verification checks. We may use third-party
                companies to carry out these checks on the company’s behalf.
                This process involves checking the details that you disclose
                against certain databases, both public and private. By entering
                this process you agree that we may use, record and disclose such
                personal information, and that the data may be recorded by us or
                the third party company.
              </p>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                Customers may not have more than one Account. If we have reason
                to believe two or more accounts are held by the same person,
                they will be deemed ‘Duplicate Accounts’. The Account most
                recently registered will be closed. If any winnings have been
                obtained via a duplicate account SureShotSports FZ-LLC reserves
                the right to withhold these winnings.
              </p>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                3.5 All transactions where your username and password have been
                entered correctly will be regarded as valid, whether or not
                authorized by you, and we shall not be liable for any claims in
                the event that you disclose them to anyone. It is your sole
                responsibility to maintain the confidentiality of your password
                and you are responsible for all activity that occurs under your
                username and password. You should notify us of any unauthorized
                use of your password and any breach of security as soon as you
                become aware of it.
              </p>
              <h4 className="text-[22px] mt-[2%] text-[#000000] font-semibold">
                4. Games Announcement & Entry
              </h4>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                4.1 The Company or SSS announces that new games or rounds are
                available via any of the following:
              </p>
              {/* <p className="text-[17px] text-[#0000005A] font-semibold">
                (i) Our website www.picksnprizes.com
              </p> */}
              <div className="flex mt-[1%] gap-2">
                <p className="text-[17px] text-[#0000005A] font-semibold">
                  (i) Our website{" "}
                  <a
                    href="http://www.picksnprizes.com"
                    alt="picksnprizes"
                    className="text-[#4f779b]"
                    style={{ textDecoration: "underline" }}
                  >
                    www.picksnprizes.com
                  </a>
                </p>
              </div>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                (ii) The Company’s account on social media platforms “Facebook”,
                “Instagram”, “Twitter”, “Tik Tok”
              </p>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                (iii) Emails
              </p>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                4.2 Customers can play in any game or round once their account
                is active by the use of Tokens. Tokens can be purchased on the
                website and each round will have a specified number of Tokens to
                enter.
              </p>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                4.3 Any purchased tokens will not be available for withdrawal at
                any point and must be used towards playing the game. Only
                winnings can be withdrawn.
              </p>
              <h4 className="text-[22px] mt-[2%] text-[#000000] font-semibold">
                5. Rules of MoneyBall(the game)
              </h4>
              <p className="text-[17px] text-[#0000005A] mt-[1%] font-semibold">
                5.1 By accepting the Terms and Conditions, you agree to the
                Competition Rules, and it will be legally binding on you.
              </p>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                5.2 The rules of MoneyBall are the same for all MoneyBall games.
              </p>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                5.3 The entry fee and the prize money for each round of
                MoneyBall are pre-determined before the start of the round. The
                aggregate of all the entry fees in a particular round minus a
                10% administrative fee will make up the prize money or the
                jackpot of that round.
              </p>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                5.4 Using each account a participant can join any MoneyBall
                round with one or two picks. Two is the maximum per participant
                in a single round.
              </p>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                5.5 If a participant is playing with two picks each pick is
                treated completely independently in terms of playing the game.
              </p>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                5.6 The participant shall join any round (active/open round) of
                the competition up until the deadline of that round. The
                deadline of each round and each match week within the round is
                set at 1 hour prior to the commencement of the first match in
                that match week.
              </p>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                5.7 The objective of the game is to assign each of your picks to
                a team in that week’s fixture list who the participant thinks
                they win their match. If the selected team wins their match that
                pick progresses to the following match week. If the selected
                team draws or loses their match then that pick is eliminated.
              </p>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                Example: Pick 1: Arsenal, and Pick 2: Liverpool. Arsenal draw
                and Liverpool win then Pick 1 is eliminated and Pick 2
                progresses.
              </p>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                If your pick is successful then you progress to the following
                week and must repeat the process.
              </p>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                5.8 Once a team is picked it cannot be chosen again under the
                same pick until the round is over.
              </p>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                Example: Since Pick 2 above selected Liverpool and they won
                their match, then that player can no longer choose Liverpool
                under Pick 2 for the rest of the round.
              </p>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                5.9 Once again, and as indicated in point 5.4 above, if a
                participant is playing in a round with two picks then they are
                treated independently.
              </p>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                Using the same example in point 6 above, let’s assume both
                Arsenal & Liverpool have won their respective matches, in that
                case Pick 1 will no longer be able to choose Arsenal again until
                the round is over, however Pick 2 can still use Arsenal since it
                hasn’t been used before.
              </p>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                Consequently, since the two entries are treated independently a
                player can pick the same team for both their picks as long as he
                hasn’t used them previously in the same round.
              </p>

              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                5.10 If a pick is not submitted before the deadline, that pick
                will automatically be assigned to the lowest ranked team in the
                league table that is available for that pick. If a participant
                is playing with two picks and misses the deadline on both, then
                one pick will be assigned to the lowest ranked team available
                from the league table while the second pick will be assigned to
                the second to last available team.
              </p>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                Example: Sheffield and Burnley are ranked 20th and 19th in the
                Premier League coming into the match week. A participant who is
                playing with two picks misses the deadline. In that case, Pick 1
                will automatically select Sheffield while Pick 2 will
                automatically select Burnley. Let’s assume that participant had
                already picked Burnley under Pick 2 in a prior match week within
                that same round. In that case Pick 2 will automatically select
                the 18th ranked team in the table and so on.
              </p>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                5.11 Each pick is only allowed one default per round. The second
                default within the same round will result in automatic
                elimination.
              </p>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                5.12 Once a pick is made it can be changed up till the deadline
                of that match week.
              </p>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                5.13 If a match is postponed prior to the deadline lapsing the
                player must re-pick by choosing a team from the remaining
                fixtures that match week. If the match is postponed after the
                deadline has passed then any picks for that fixture will be
                deemed successful and the players involved will not be allowed
                to pick those same teams again in that round.
              </p>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                5.14 Any matches played out of the normal schedule or that were
                previously postponed will not be part of the fixtures to select
                from and will be ignored.
              </p>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                5.15 A match week will only be considered for selection if it
                has a minimum of 8 fixtures. Match weeks that have less will be
                skipped in the schedule.
              </p>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                5.16 The Company reserves the right, in its sole discretion, to
                refuse to accept participation that is incomplete,
                indecipherable, offensive, does not comply with these T&Cs, or
                contravenes any applicable laws or regulation.
              </p>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                5.17 The Company reserves the right, in its sole discretion, to
                disqualify any participant's account who has breached any of
                these T&Cs, has engaged in unlawful or improper conduct or
                otherwise acts to cheat or undermine the fairness of the
                Competition/s by, for example, tampering with, or using or
                exploiting errors in, the participation process to obtain a
                competitive advantage over other entrants.
              </p>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                5.18 SureShotSports FZ-LLC, if necessary to do so, reserve the
                right to suspend, amend, void or even cancel a game. This may be
                necessary for reasons beyond our reasonable control including,
                but not limited to strike, labour dispute, illness, act of God,
                natural disaster, adverse weather conditions, war, riot,
                accident, public health concern in relation to potential
                infection, epidemic or pandemic, malicious damage, fire, flood
                and/or storm, compliance with law or governmental order, rule,
                regulation or direction, breakdown of plant, machinery or
                transportation. SSS are not responsible for any loss or damage
                caused to you as a result of any of these occurrences.
              </p>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                5.19 The eligibility to participate is solely at the discretion
                of the Company and we reserve the right to block or not accept
                any registration.
              </p>
             

              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                5.20 The Company assumes no responsibility for any failure to
                receive participation or for inaccurate information or any loss,
                damage, or injury because of technical or telecommunications
                problems, including security breaches. If such problems arise,
                the Company may (where necessary with the approval of the
                relevant authority) modify, cancel, terminate, or suspend the
                Competition/s.
              </p>

              <p className="text-[17px]  mt-[1%] text-[#0000005A] font-semibold">
              5.21 MoneyBall Champions League
              </p>

              <p className="text-[17px] ml-[1%] mt-[1%] text-[#0000005A] font-semibold">
              5.21.1 With the exception of the below deviations, all other rules from traditional MoneyBall apply.
              </p>

              <p className="text-[17px] ml-[1%] mt-[1%] text-[#0000005A] font-semibold">
              5.21.2 Unlike normal league based MoneyBall competitions, the Champions League version of MoneyBall will be initially based on a league system. After the first round of 8 match weeks the game will be based on a knock-out system.
              </p>

              <p className="text-[17px] ml-[1%] mt-[1%] text-[#0000005A] font-semibold">
              5.21.3 During the first eight match weeks players will be able to make their selections among the 36 clubs involved
              </p>
              <p className="text-[17px] ml-[1%] mt-[1%] text-[#0000005A] font-semibold">
              5.21.4 After match week eight, match week 9 & 10 will be the two legs of the play-off which will be between the teams who finished 9th -24th in the table. This means that your selections will be restricted to those 16 teams.
              </p>

              <p className="text-[17px] ml-[1%] mt-[1%] text-[#0000005A] font-semibold">
              5.21.5 Match week 11 and beyond will be based on the 16 teams that have qualified to the round of 16. These will include the 8 top teams after match week 8 in addition to the 8 teams qualified from match week 9 & 10 play-off.
              </p>

              <p className="text-[17px] ml-[1%] mt-[1%] text-[#0000005A] font-semibold">
              5.21.6 Regardless of how many picks remain alive the game ends after the second leg of the quarter-finals. The semi-finals and final will not be included in the game.
              </p>

              <p className="text-[17px] ml-[1%] mt-[1%] text-[#0000005A] font-semibold">
              5.21.7 Each leg of each play-off & knock out stage will be considered a separate match week. For example leg 1 of the round of 16 is one match week while leg 2 is another.
              </p>

              <p className="text-[17px] ml-[1%] mt-[1%] text-[#0000005A] font-semibold">
              5.21.8 Should we have more than one pick remaining after the second leg of the quarter final the jackpot will be distributed equally among the remaining picks.
              </p>

              
              <p className="text-[17px] ml-[1%] mt-[1%] text-[#0000005A] font-semibold">
              5.21.9 There could be a situation where players are still alive at the quarter final stage and have no teams to pick from. For Example, the 8 teams who qualified to the quarter final had already been selected in prior match weeks by the player. In this case when a player has no teams available to pick from he/she will be automatically eliminated. Accordingly players must be very careful in making their selections early so as to ensure they have options during the quarter finals.
              </p>

              <p className="text-[17px] ml-[1%] mt-[1%] text-[#0000005A] font-semibold">
              5.21.10 Defaults at every stage will be based on the table ranking in the first 8 match weeks. During the play-off & knock-out a default will be assigned based on the lowest rank team playing in these round as per the ranking after match week 8.
              </p>

              <h4 className="text-[22px] mt-[2%] text-[#000000] font-semibold">
                6. How MoneyBall (the game) is Won
              </h4>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                6.1 The game is won by the participant(s) who outlasts everyone
                else. If we have one pick remaining after a match week then that
                pick is the sole winner of the jackpot.
              </p>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                6.2 If all the players remaining are all eliminated at the same
                time then the jackpot is split equally amongst them.
              </p>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                6.3 Once the number of picks remaining in a round is down to 5
                picks or less a vote is automatically triggered on whether the
                participants would like to equally share the prize among them or
                continue playing.
              </p>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                6.4 A split vote is successful only if it is unanimous,
                otherwise we play on! If the vote is in favour of playing on,
                the split/play on vote keeps getting triggered at the end of
                each match week thereafter until we have a winner or a split
                decision.
              </p>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                6.5 When the game has gone to the maximum number of weeks
                without a winner then the jackpot is split equally among the
                remaining players. Maximum number of weeks is dependent on how
                many teams are playing in that league.
              </p>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                For example, for a league with 20 teams the maximum number of
                weeks we can play without a winner is 20. For a league with 18
                teams then the maximum number of weeks we can play is 18.
              </p>
              <h4 className="text-[22px] mt-[2%] text-[#000000] font-semibold">
                7. Taxes
              </h4>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                7.1 If prizes are subject to any local tax law it is directly
                deducted from the prize value.
              </p>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                7.2 The amount paid towards purchasing token package are
                inclusive of any local applicable taxes.
              </p>
              <h4 className="text-[22px] mt-[2%] text-[#000000] font-semibold">
                8. Use of Personal Information
              </h4>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                8.1 Personal information including the eligible Participant’s
                name, address, telephone number, and email will be collected and
                used to conduct the Competitions. This may require disclosure to
                third parties, including local regulatory authorities and the
                Company’s agents or third-party service providers, to conduct
                the Competition/s, or for promotional and marketing purposes
                (including direct marketing). By registering on our website the
                customer consents to the use of their personal information.
              </p>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                8.2 The participant may access, edit and/or update his personal
                information under the privacy policy of the Company.
              </p>
              <h4 className="text-[22px] mt-[2%] text-[#000000] font-semibold">
                9. Intellectual Property
              </h4>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                9.1 Players and participants acknowledge and agree that all
                copyright, trademarks and any other intellectual property rights
                contained in any material or any content supplied (including any
                software) by us via the website or the mobile application to you
                shall at all times remain vested in us or our licensors. These
                intellectual property rights include, without limitation, the
                design, graphics, layout, look and feel and structure of our
                website, database rights, design rights, domain names and rights
                to goodwill and/or to sue for passing off. You are permitted to
                use this material and content only as expressly authorized by us
                or our licensors.
              </p>

              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                9.2 By entering the Competition(s), participants agree to deal
                with their entry content in any way that may otherwise infringe
                the participants' moral rights and thus participants agree to
                waive their claim of their moral rights (wherever such rights
                are recognized) against the Company or its assigns, licensees,
                or successors.
              </p>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                9.3 Players/participants acknowledge and agree that the material
                and content contained within our website and/or mobile
                application are utilized in the provision of the Services is
                made available by us to you for your own personal non-commercial
                use only. Any other use of such material and content is strictly
                prohibited. You agree not to (and agree not to assist or
                facilitate any third party to) copy, reproduce, transmit,
                publish, display, distribute, commercially exploit, tamper with
                or create derivative works of such material and content.
              </p>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                9.4 The names, images and logos identifying us, our partners or
                other third parties and our/their products and services
                contained on our website and or mobile application are
                proprietary marks and may not be reproduced or otherwise used
                without our consent.
              </p>

              <h4 className="text-[22px] mt-[3%] text-[#000000] font-semibold">
                10. General Conditions
              </h4>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                10.1 If for any reason beyond the reasonable control of the
                Company the competition or game is not capable of running as
                planned, the Company reserves the right in its sole discretion
                to take any action that may be available to it, and to cancel,
                terminate, modify, postpone, or suspend the competition(s) or
                game(s), unless to do so would be prohibited by law.
              </p>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                10.2 The Company reserves the right to cancel, terminate,
                modify, postpone, or suspend the Competition/s or amend these
                terms and conditions, subject to any directions from a
                regulatory authority.
              </p>
              <h4 className="text-[22px] mt-[2%] text-[#000000] font-semibold">
                11. Governing Law and Jurisdiction
              </h4>
              <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                11.1 The games on our website and any dispute or claim arising
                out of or in connection with it or its subject matter or
                formation shall be governed by and construed under the law,
                rules and regulations of Ras Al Khaimah Economic Zone Authority
                and the participant irrevocably agrees that the UAE courts shall
                have exclusive jurisdiction to settle any dispute or claim that
                arises out of or in connection with the Competition/s or its
                subject matter or formation.
              </p>
            </Card>
            {/* </div> */}
          </div>
          <FooterTab />
        </div>
      ) : (
        <div className="overflow-auto bg-[url('../public/card/home-bg.svg')] bg-cover h-[100vh]">
          <div
            className="h-[100vh] mb-[50px] w-full p-5 
          laptop-min:[1200px] laptop-l:h-[857px] laptop-s:h-[800px] font-Inter"
          >
            <div className="flex w-full p-3">
              <div className="w-[44%] ">
                <img src={Logo} alt="pickNdPrizes" onClick={redirectHome} />
              </div>
              <div className="w-[56%] justify-end mr-[1%] mt-[1%] flex float-right ">
                {!isLogedIn ? (
                  <>
                    {" "}
                    <p className="text-[#FFFFFF] mt-2">Not a member yet ?</p>
                    <p
                      className="text-[#00C05B] font-[18px] mt-2 ml-2 cursor-pointer"
                      onClick={redirectToRegister}
                    >
                      Register Now
                    </p>
                  </>
                ) : null}

                <button
                  className="w-[90px] h-[35px] text-[14px] font-[500] bg-[#00C05B] text-[#002427] ml-5 rounded-[4px]"
                  onClick={redirectToLogin}
                >
                  {!isLogedIn ? "Login" : "Play now"}
                </button>
              </div>
            </div>

            <hr className="w-[95%] opacity-[.2] m-auto"></hr>
            <div>
              <div>
                <h2 className="text-[#FFFFFF] text-[48px] text-center">
                  Terms & Conditions
                </h2>
              </div>

              <Card className="m-auto laptop-s:h-[600px] laptop-l:h-[70vh] desktop:h-[80vh] overflow-auto  pl-16 pt-12 pb-12 pr-16 flex flex-col justify-between w-[95%] my-[1%] bg-[#FFFFFF] shadow-md rounded-[12px] ">
                <div className="text-[22px] text-[#000000] font-semibold">
                  <h4>1. Introduction </h4>
                  <h4>2. Terms & Conditions</h4>
                  <h4>3. Eligibility & Registration</h4>
                  <h4>4. Game Announcement & Entry</h4>
                  <h4>5. Rules of MoneyBall</h4>
                  <h4>6. How MoneyBall is Won</h4>
                  <h4>7. Taxes</h4>
                  <h4>8. Use of Personal Information</h4>
                  <h4>9. Intellectual Property</h4>
                  <h4>10. General Conditions</h4>
                  <h4>11. Governing Law & Jurisdiction</h4>
                </div>
                <h4 className="text-[22px] mt-[2%] text-[#000000] font-semibold">
                  1.Introduction
                </h4>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  PicksnPrizes (‘games’, or ‘MoneyBall’) are brought to you by
                  SureShotSports FZ-LLC ( ‘SSS’, ’the Company’, ‘we’, ‘us’ or
                  ‘our’).
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  SureShotSports FZ-LLC is a limited liability company
                  incorporated under the laws , rules and regulations of Ras Al
                  Khaimah Economic Zone Authority under trade license No.
                  17002810 with a registered office situated at FDAM0767 Compass
                  Building, Al Shohada Road, Al Hamra Industrial Zone, Ras Al
                  Khaimah, UAE.
                </p>

                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  These Terms and Conditions of Service (‘Terms and Conditions’,
                  ‘Terms’) lay out the Terms on which you are allowed to access
                  and play PicksnPrizes products (games which are playable on
                  our website).
                </p>

                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  Reference to ‘you’ or ‘your’ or ‘customer’ or ‘participant’ or
                  ‘player’ is reference to any person that uses the services of
                  SureShotSports FZ-LLC or any registered customer of SSS.
                </p>

                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  The Terms can be accessed via our website at
                  www.picksnprizes.com (the ‘website’)
                </p>

                <h4 className="text-[22px] mt-[2%] text-[#000000] font-semibold">
                  2.Terms and Conditions
                </h4>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  2.1 By registering on our website and taking part in any of
                  the games via the website/mobile application ‘App’ (including,
                  but not limited to sub domains and/or API’s ) you confirm that
                  you have read and are legally bound by:
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  (i) our Terms and Conditions of the games;
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  (ii) our Privacy Policy;
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  (iii) our Cookies Policy and
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  (iv) the rules of our games
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  (v) you have the mental capacity to take responsibility for
                  you own actions and decision making, and can enter into this
                  contract with us.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  (vi) you accept full responsibility for playing in these games
                  in the knowledge that winning is not guaranteed and tokens
                  have to be purchased with money to be able to play.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  (vii) You will not use this service in any way which could
                  cause offence, be considered defamatory, obscene, abusive,
                  unlawful, racist, sexist or in any way discriminatory to any
                  of SSS’s staff or other customers.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  Please read these Terms carefully, and pay attention to all
                  the terms before registering with us and playing any of the
                  games on our website
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  2.2 These Terms are effective from 1st May 2024. SSS reserves
                  the right to amend, modify, and/or change the Terms at any
                  time for any number of reasons, including but not restricted
                  to comply with changes to applicable laws and regulations and
                  any other regulatory requirements. An up to date set of terms
                  and conditions will be available on our website and you are
                  advised to review the Terms on a frequent basis. If for any
                  reason any changes in Terms are not acceptable to you, then
                  you should close your account with us and cease using our
                  services. If you continue to use our service and participate
                  in any of the games on our website after the date that the
                  changes of the Terms come into effect then you will be deemed
                  to have accepted these changes and are happy to continue.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  2.3 It is your sole responsibility to review the Terms and
                  Conditions (including the Game Rules) and any amendments to
                  them each time you use the website and/or mobile Application.
                </p>
                <h4 className="text-[22px] mt-[2%] text-[#000000] font-semibold">
                  3. Eligibility & Registration
                </h4>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  3.1 In order to participate in the games that SSS provide you
                  will need to register on our website. You must be at least
                  Twenty-One (21) years of age to be able to register.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  3.2 When registering, you will be required to provide personal
                  details such as, Your name, Date of birth, address, telephone
                  number and an email address. You warrant that all the personal
                  details you provide are accurate, correct and complete. If the
                  details are incomplete or inaccurate, we may suspend your
                  account. If any of your personal details change, please inform
                  us as soon as possible so that we may maintain accurate and up
                  to date records. You can change the details provided at
                  registration at any time by editing your profile after you log
                  in.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  3.3 The Company reserves the right, at any time, to request
                  verification of the age, identity, residential address, or any
                  other information relevant to participation in the
                  Competition/s of all participants. The Company reserves the
                  right to disqualify any participant who provides false
                  information or fails to provide information that is reasonably
                  requested by the Company.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  3.4 A customer is only permitted to have one account to use
                  our service and the personal details that are provided will be
                  confirmed through verification checks. We may use third-party
                  companies to carry out these checks on the company’s behalf.
                  This process involves checking the details that you disclose
                  against certain databases, both public and private. By
                  entering this process you agree that we may use, record and
                  disclose such personal information, and that the data may be
                  recorded by us or the third party company.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  Customers may not have more than one Account. If we have
                  reason to believe two or more accounts are held by the same
                  person, they will be deemed ‘Duplicate Accounts’. The Account
                  most recently registered will be closed. If any winnings have
                  been obtained via a duplicate account SureShotSports FZ-LLC
                  reserves the right to withhold these winnings.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  3.5 All transactions where your username and password have
                  been entered correctly will be regarded as valid, whether or
                  not authorized by you, and we shall not be liable for any
                  claims in the event that you disclose them to anyone. It is
                  your sole responsibility to maintain the confidentiality of
                  your password and you are responsible for all activity that
                  occurs under your username and password. You should notify us
                  of any unauthorized use of your password and any breach of
                  security as soon as you become aware of it.
                </p>
                <h4 className="text-[22px] mt-[2%] text-[#000000] font-semibold">
                  4. Games Announcement & Entry
                </h4>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  4.1 The Company or SSS announces that new games or rounds are
                  available via any of the following:
                </p>
                <div className="flex mt-[1%] gap-2">
                  <p className="text-[17px] text-[#0000005A] font-semibold">
                    (i) Our website{" "}
                    <a
                      href="http://www.picksnprizes.com"
                      alt="picksnprizes"
                      className="text-[#4f779b]"
                      style={{ textDecoration: "underline" }}
                    >
                      www.picksnprizes.com
                    </a>
                  </p>
                </div>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  (ii) The Company’s account on social media platforms
                  “Facebook”, “Instagram”, “Twitter”, “Tik Tok”
                </p>
                <p className="text-[17px] text-[#0000005A] font-semibold">
                  (iii) Emails
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  4.2 Customers can play in any game or round once their account
                  is active by the use of Tokens. Tokens can be purchased on the
                  website and each round will have a specified number of Tokens
                  to enter.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  4.3 Any purchased tokens will not be available for withdrawal
                  at any point and must be used towards playing the game. Only
                  winnings can be withdrawn.
                </p>
                <h4 className="text-[22px] mt-[2%] text-[#000000] font-semibold">
                  5. Rules of MoneyBall (the game)
                </h4>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  5.1 By accepting the Terms and Conditions, you agree to the
                  Competition Rules, and it will be legally binding on you.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  5.2 The rules of MoneyBall are the same for all MoneyBall
                  games.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  5.3 The entry fee and the prize money for each round of
                  MoneyBall are pre-determined before the start of the round.
                  The aggregate of all the entry fees in a particular round
                  minus a 10% administrative fee will make up the prize money or
                  the jackpot of that round.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  5.4 Using each account a participant can join any MoneyBall
                  round with one or two picks. Two is the maximum per
                  participant in a single round.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  5.5 If a participant is playing with two picks each pick is
                  treated completely independently in terms of playing the game.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  5.6 The participant shall join any round (active/open round)
                  of the competition up until the deadline of that round. The
                  deadline of each round and each match week within the round is
                  set at 1 hour prior to the commencement of the first match in
                  that match week.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  5.7 The objective of the game is to assign each of your picks
                  to a team in that week’s fixture list who the participant
                  thinks they win their match. If the selected team wins their
                  match that pick progresses to the following match week. If the
                  selected team draws or loses their match then that pick is
                  eliminated.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  Example: Pick 1: Arsenal, and Pick 2: Liverpool. Arsenal draw
                  and Liverpool win then Pick 1 is eliminated and Pick 2
                  progresses.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  If your pick is successful then you progress to the following
                  week and must repeat the process.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  5.8 Once a team is picked it cannot be chosen again under the
                  same pick until the round is over.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  Example: Since Pick 2 above selected Liverpool and they won
                  their match, then that player can no longer choose Liverpool
                  under Pick 2 for the rest of the round.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  5.9 Once again, and as indicated in point 5.4 above, if a
                  participant is playing in a round with two picks then they are
                  treated independently.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  Using the same example in point 6 above, let’s assume both
                  Arsenal & Liverpool have won their respective matches, in that
                  case Pick 1 will no longer be able to choose Arsenal again
                  until the round is over, however Pick 2 can still use Arsenal
                  since it hasn’t been used before.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  Consequently, since the two entries are treated independently
                  a player can pick the same team for both their picks as long
                  as he hasn’t used them previously in the same round.
                </p>

                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  5.10 If a pick is not submitted before the deadline, that pick
                  will automatically be assigned to the lowest ranked team in
                  the league table that is available for that pick. If a
                  participant is playing with two picks and misses the deadline
                  on both, then one pick will be assigned to the lowest ranked
                  team available from the league table while the second pick
                  will be assigned to the second to last available team.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  Example: Sheffield and Burnley are ranked 20th and 19th in the
                  Premier League coming into the match week. A participant who
                  is playing with two picks misses the deadline. In that case,
                  Pick 1 will automatically select Sheffield while Pick 2 will
                  automatically select Burnley. Let’s assume that participant
                  had already picked Burnley under Pick 2 in a prior match week
                  within that same round. In that case Pick 2 will automatically
                  select the 18th ranked team in the table and so on.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  5.11 Each pick is only allowed one default per round. The
                  second default within the same round will result in automatic
                  elimination.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  5.12 Once a pick is made it can be changed up till the
                  deadline of that match week.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  5.13 If a match is postponed prior to the deadline lapsing the
                  player must re-pick by choosing a team from the remaining
                  fixtures that match week. If the match is postponed after the
                  deadline has passed then any picks for that fixture will be
                  deemed successful and the players involved will not be allowed
                  to pick those same teams again in that round.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  5.14 Any matches played out of the normal schedule or that
                  were previously postponed will not be part of the fixtures to
                  select from and will be ignored.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  5.15 A match week will only be considered for selection if it
                  has a minimum of 8 fixtures. Match weeks that have less will
                  be skipped in the schedule.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  5.16 The Company reserves the right, in its sole discretion,
                  to refuse to accept participation that is incomplete,
                  indecipherable, offensive, does not comply with these T&Cs, or
                  contravenes any applicable laws or regulation.
                </p>
                <p className="text-[17px]  mt-[1%] text-[#0000005A] font-semibold">
                  5.17 The Company reserves the right, in its sole discretion,
                  to disqualify any participant's account who has breached any
                  of these T&Cs, has engaged in unlawful or improper conduct or
                  otherwise acts to cheat or undermine the fairness of the
                  Competition/s by, for example, tampering with, or using or
                  exploiting errors in, the participation process to obtain a
                  competitive advantage over other entrants.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  5.18 SureShotSports FZ-LLC, if necessary to do so, reserve the
                  right to suspend, amend, void or even cancel a game. This may
                  be necessary for reasons beyond our reasonable control
                  including, but not limited to strike, labour dispute, illness,
                  act of God, natural disaster, adverse weather conditions, war,
                  riot, accident, public health concern in relation to potential
                  infection, epidemic or pandemic, malicious damage, fire, flood
                  and/or storm, compliance with law or governmental order, rule,
                  regulation or direction, breakdown of plant, machinery or
                  transportation. SSS are not responsible for any loss or damage
                  caused to you as a result of any of these occurrences.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  5.19 The eligibility to participate is solely at the
                  discretion of the Company and we reserve the right to block or
                  not accept any registration.
                </p>
                <p className="text-[17px] mt-[1%]  text-[#0000005A] font-semibold">
                  5.20 The Company assumes no responsibility for any failure to
                  receive participation or for inaccurate information or any
                  loss, damage, or injury because of technical or
                  telecommunications problems, including security breaches. If
                  such problems arise, the Company may (where necessary with the
                  approval of the relevant authority) modify, cancel, terminate,
                  or suspend the Competition/s.
                </p>

                <p className="text-[17px] mt-[1%]  text-[#0000005A] font-semibold">
                  5.21 MoneyBall Champions League
                </p>

                <p className="text-[17px] ml-[1%] mt-[1%]  text-[#0000005A] font-semibold">
                5.21.1 With the exception of the below deviations, all other rules from traditional MoneyBall apply.
                </p>

                <p className="text-[17px] ml-[1%] mt-[1%]  text-[#0000005A] font-semibold">
                  5.21.2 Unlike normal league based MoneyBall competitions, the
                  Champions League version of MoneyBall will be initially based
                  on a league system. After the first round of 8 match weeks the
                  game will be based on a knock-out system.
                </p>

                <p className="text-[17px] ml-[1%] mt-[1%]  text-[#0000005A] font-semibold">
                  5.21.3 During the first eight match weeks players will be able
                  to make their selections among the 36 clubs involved.
                </p>

                <p className="text-[17px] ml-[1%] mt-[1%]  text-[#0000005A] font-semibold">
                  5.21.4 After match week eight, match week 9 & 10 will be the
                  two legs of the play-off which will be between the teams who
                  finished 9th -24th in the table. This means that your
                  selections will be restricted to those 16 teams.
                </p>

                <p className="text-[17px] ml-[1%] mt-[1%]  text-[#0000005A] font-semibold">
                  5.21.5 Match week 11 and beyond will be based on the 16 teams
                  that have qualified to the round of 16. These will include the
                  8 top teams after match week 8 in addition to the 8 teams
                  qualified from match week 9 & 10 play-off.
                </p>

                <p className="text-[17px] ml-[1%] mt-[1%]  text-[#0000005A] font-semibold">
                  5.21.6 Regardless of how many picks remain alive the game ends
                  after the second leg of the quarter-finals. The semi-finals
                  and final will not be included in the game.
                </p>

                <p className="text-[17px] ml-[1%] mt-[1%]  text-[#0000005A] font-semibold">
                  5.21.7 Each leg of each play-off & knock out stage will be
                  considered a separate match week. For example leg 1 of the
                  round of 16 is one match week while leg 2 is another.
                </p>

                <p className="text-[17px] ml-[1%] mt-[1%]  text-[#0000005A] font-semibold">
                  5.21.8 Should we have more than one pick remaining after the
                  second leg of the quarter final the jackpot will be
                  distributed equally among the remaining picks
                </p>

                <p className="text-[17px] ml-[1%] mt-[1%]  text-[#0000005A] font-semibold">
                  5.21.9 There could be a situation where players are still
                  alive at the quarter final stage and have no teams to pick
                  from. For Example, the 8 teams who qualified to the quarter
                  final had already been selected in prior match weeks by the
                  player. In this case when a player has no teams available to
                  pick from he/she will be automatically eliminated. Accordingly
                  players must be very careful in making their selections early
                  so as to ensure they have options during the quarter finals.
                </p>

                <p className="text-[17px] ml-[1%] mt-[1%]  text-[#0000005A] font-semibold">
                  5.21.10 Defaults at every stage will be based on the table
                  ranking in the first 8 match weeks. During the play-off &
                  knock-out a default will be assigned based on the lowest rank
                  team playing in these round as per the ranking after match
                  week 8.
                </p>

                <h4 className="text-[22px] mt-[2%] text-[#000000] font-semibold">
                  6. How MoneyBall (the game) is Won
                </h4>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  6.1 The game is won by the participant(s) who outlasts
                  everyone else. If we have one pick remaining after a match
                  week then that pick is the sole winner of the jackpot
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  6.2 If all the players remaining are all eliminated at the
                  same time then the jackpot is split equally amongst them.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  6.3 Once the number of picks remaining in a round is down to 5
                  picks or less a vote is automatically triggered on whether the
                  participants would like to equally share the prize among them
                  or continue playing.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  6.4 A split vote is successful only if it is unanimous,
                  otherwise we play on! If the vote is in favour of playing on,
                  the split/play on vote keeps getting triggered at the end of
                  each match week thereafter until we have a winner or a split
                  decision.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  6.5 When the game has gone to the maximum number of weeks
                  without a winner then the jackpot is split equally among the
                  remaining players. Maximum number of weeks is dependent on how
                  many teams are playing in that league.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  For example, for a league with 20 teams the maximum number of
                  weeks we can play without a winner is 20. For a league with 18
                  teams then the maximum number of weeks we can play is 18.
                </p>
                <h4 className="text-[22px] mt-[2%] text-[#000000] font-semibold">
                  7. Taxes
                </h4>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  7.1 If prizes are subject to any local tax law it is directly
                  deducted from the prize value.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  7.2 The amount paid towards purchasing token package are
                  inclusive of any local applicable taxes.
                </p>
                <h4 className="text-[22px] mt-[2%] text-[#000000] font-semibold">
                  8. Use of Personal Information
                </h4>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  8.1 Personal information including the eligible Participant’s
                  name, address, telephone number, and email will be collected
                  and used to conduct the Competitions. This may require
                  disclosure to third parties, including local regulatory
                  authorities and the Company’s agents or third-party service
                  providers, to conduct the Competition/s, or for promotional
                  and marketing purposes (including direct marketing). By
                  registering on our website the customer consents to the use of
                  their personal information.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  8.2 The participant may access, edit and/or update his
                  personal information under the privacy policy of the Company.
                </p>
                <h4 className="text-[22px] mt-[2%] text-[#000000] font-semibold">
                  9. Intellectual Property
                </h4>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  9.1 Players and participants acknowledge and agree that all
                  copyright, trademarks and any other intellectual property
                  rights contained in any material or any content supplied
                  (including any software) by us via the website or the mobile
                  application to you shall at all times remain vested in us or
                  our licensors. These intellectual property rights include,
                  without limitation, the design, graphics, layout, look and
                  feel and structure of our website, database rights, design
                  rights, domain names and rights to goodwill and/or to sue for
                  passing off. You are permitted to use this material and
                  content only as expressly authorized by us or our licensors.
                </p>

                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  9.2 By entering the Competition(s), participants agree to deal
                  with their entry content in any way that may otherwise
                  infringe the participants' moral rights and thus participants
                  agree to waive their claim of their moral rights (wherever
                  such rights are recognized) against the Company or its
                  assigns, licensees, or successors.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  9.3 Players/participants acknowledge and agree that the
                  material and content contained within our website and/or
                  mobile application are utilized in the provision of the
                  Services is made available by us to you for your own personal
                  non-commercial use only. Any other use of such material and
                  content is strictly prohibited. You agree not to (and agree
                  not to assist or facilitate any third party to) copy,
                  reproduce, transmit, publish, display, distribute,
                  commercially exploit, tamper with or create derivative works
                  of such material and content.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  9.4 The names, images and logos identifying us, our partners
                  or other third parties and our/their products and services
                  contained on our website and or mobile application are
                  proprietary marks and may not be reproduced or otherwise used
                  without our consent.
                </p>

                <h4 className="text-[22px] mt-[2%] text-[#000000] font-semibold">
                  10. General Conditions
                </h4>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  10.1 If for any reason beyond the reasonable control of the
                  Company the competition or game is not capable of running as
                  planned, the Company reserves the right in its sole discretion
                  to take any action that may be available to it, and to cancel,
                  terminate, modify, postpone, or suspend the competition(s) or
                  game(s), unless to do so would be prohibited by law.
                </p>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  10.2 The Company reserves the right to cancel, terminate,
                  modify, postpone, or suspend the Competition/s or amend these
                  terms and conditions, subject to any directions from a
                  regulatory authority.
                </p>
                <h4 className="text-[22px] mt-[2%] text-[#000000] font-semibold">
                  11. Governing Law and Jurisdiction
                </h4>
                <p className="text-[17px] mt-[1%] text-[#0000005A] font-semibold">
                  11.1 The games on our website and any dispute or claim arising
                  out of or in connection with it or its subject matter or
                  formation shall be governed by and construed under the law,
                  rules and regulations of Ras Al Khaimah Economic Zone
                  Authority and the participant irrevocably agrees that the UAE
                  courts shall have exclusive jurisdiction to settle any dispute
                  or claim that arises out of or in connection with the
                  Competition/s or its subject matter or formation.
                </p>
              </Card>
            </div>
          </div>
          <Footer componnent="temsAndCondition" />
        </div>
      )}
    </>
  );
}

export default TermsAndConditions;
