import React, { useEffect, useState } from "react";
import "./pagination.css";
import { useSelector } from "react-redux";

function Table({
  tableName,
  className,
  tableHeader,
  tableBody,
  component,
  staticMessage,
}) {
  const paginationData = useSelector((state) => state.myPicks.pagination);

  const [isLoading, setIsLoading] = useState(true); // Set initial loading state to true
  // const [pageSize, setPageSize] = useState(15);
  // const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    // setCurrentPage(paginationData.currentPage);
    // setPageSize(paginationData.pageSize);
  }, [paginationData.pageSize, paginationData.currentPage]);

  useEffect(() => {
    if (tableName === "otherUser") {
      const delayTimeout = setTimeout(() => {
        setIsLoading(false);
      }, 1000);

      return () => clearTimeout(delayTimeout);
    } else {
      setIsLoading(false);
    }
  }, [tableName]);

  function isImage(str) {
    try {
      new URL(str);
      return true;
    } catch (error) {
      return false;
    }
  }

  return (
    <>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <div className="overflow-x-auto">
          <table className={`${className}`}>
            <thead
              className={`header-container ${
                component === "activeTeam" ? "laptop-s:!h-[70px]" : ""
              } laptop-s:!h-[50px] h-16 p-3 bg-[#00AB26] text-white
               rounded-bl-6 rounded-br-6 rounded-tl-[6px] rounded-tr-[6px]`}
            >
              <tr className="w-[150px]">
                {tableHeader.map((head) => (
                  <th
                    key={head.id}
                    className={`text-[#F8F8F8] w-[250px] ${
                      component === "activeTeam" ? "p-3" : "p-5"
                    } text-nowrap`}
                  >
                    {head.cellName.charAt(0).toUpperCase() +
                      head.cellName.slice(1)}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className={`${tableName === "otherUser" ? "" : null}`}>
              {tableBody?.length > 0 ? (
                tableBody?.map((rowData, rowIndex) => {
                  if (rowData) {
                    return (
                      <tr key={rowIndex} className="text-center w-[150px] p-2">
                        {Object.entries(rowData).map(
                          ([key, cellData], cellIndex) => {
                            let systemPrediction;
                            let prediction;
                            if (
                              (tableName === "otherUserPicks" ||
                                tableName === "otherUser") &&
                              key !== "player"
                            ) {
                              systemPrediction = cellData.system;
                              prediction = cellData.pick_result;
                              cellData = cellData?.logo
                                ? cellData?.logo
                                : cellData;
                            }
                            return (
                              <td
                                key={cellIndex}
                                className={`text-nowrap ${
                                  tableName === "otherUserPicks"
                                    ? "border-b border-gray-300"
                                    : null
                                } ${
                                  component === "myPicksMenu" ||
                                  component === "historyPicks"
                                    ? ""
                                    : "p-2"
                                } ${
                                  key === "action" ? "w-[250px]" : "w-[10%]"
                                }`}
                              >
                                {cellData ? (
                                  isImage(cellData) ? (
                                    <div className="flex items-center justify-center">
                                      <img
                                        src={cellData}
                                        alt="image"
                                        className={`flex h-10 w-10 p-1 ${
                                          systemPrediction &&
                                          prediction === "Win"
                                            ? "bg-green-500"
                                            : systemPrediction &&
                                              prediction === "Lost"
                                            ? "bg-red-500"
                                            : systemPrediction &&
                                              prediction == null
                                            ? "bg-blue-500"
                                            : prediction === "Lost"
                                            ? "bg-red-500"
                                            : null
                                        }`}
                                      />
                                    </div>
                                  ) : typeof cellData === "object" &&
                                    cellData !== null ? (
                                    cellData
                                  ) : (
                                    <div
                                      className={`${
                                        key === "vote" && cellData === "Yes"
                                          ? "text-green-500 font-semibold text-[15px]"
                                          : key === "vote" && cellData === "No"
                                          ? "text-red-500 font-semibold text-[15px]"
                                          : ""
                                      } justify-start`}
                                    >
                                      {cellData}
                                    </div>
                                  )
                                ) : (
                                  <>
                                    {tableName === "otherUserPicks" ||
                                    tableName === "otherUser" ? (
                                      <p className="text-red-500">X</p>
                                    ) : (
                                      "-"
                                    )}
                                  </>
                                )}
                              </td>
                            );
                          }
                        )}
                      </tr>
                    );
                  }
                })
              ) : (
                <tr>
                  <td colSpan={9} className="text-center">
                    {staticMessage ? staticMessage : "No data found"}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}

export default Table;
