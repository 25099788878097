import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "../../Elements/Card";
import eplLeagueImg from "../../assets/images/epl-img.png";

import newDashCardUser from "../../assets/images/new-dash-card-user-1.png";
import newDashCardClock from "../../assets/images/new-dah-card-clock.svg";
import newCardCrownImg from "../../assets/images/new-card-grown.svg";

import newCardArrowRed from "../../assets/images/down-arrow-img-red.svg";
import { selectedPicks } from "./dashboard.slice";
import PotSelectButton from "./PotSelectButton";
import { formatAsIndianCurrency } from "../../utils/currencyFormat";

import { ProgressBar } from "./ProgressBar";
import { compareTime } from "../../utils/campareUtcTime";
import { isFuture } from "../../utils/calculateRemainingTime";

import userArrow from "../../assets/card/user-arrow.svg";
import matchWeekCard from "../../assets/card/match-week-card.svg";

import activeIcon from "../../assets/status/active.svg";
import waitlistIcon from "../../assets/status/waitlist.svg";
import jackpotIcon from "../../assets/status/jackpot.svg";
import compleated from "../../assets/status/compleated.svg";
import lostIcon from "../../assets/status/lost.svg";

import CountdownWithLocalTime from "./newFlipTimer/CountdownWithLocalTime";

import { Hourglass } from "react-loader-spinner";

import userIcon from "../../assets/card/user.svg";
import { CountdownTimer } from "./Cards/CountdownTimer";

function PotCard({
  pot,
  pickId,
  rounds,
  potType,
  status,
  currPickId,
  systemPrediction,
  adminFree,
}) {
  const dispatch = useDispatch();

  const [isTimeValid, setIsTimeValid] = useState(
    compareTime(pot?.dead_line?.dead_line)
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setIsTimeValid(compareTime(pot?.dead_line?.dead_line));
    }, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [pot?.dead_line?.dead_line]);

  const dashboardData = useSelector((state) => {
    return state.dashboard;
  });

  function joinHandler(potId) {
    if (dashboardData?.data?.user?.id) {
      dispatch(selectedPicks({ potId: potId, selectPick: true }));
    }
  }

  const input = formatAsIndianCurrency(pot.max_pot_value, pot.pot_currency);
  const parts = input.split(/\s+/);

  const maxPotValue = Number(parts[1].replace(/,/g, ""));
  const adminFee = Number(pot?.admin_fee);
  const finalValue = maxPotValue - (maxPotValue / 100) * adminFee;
  const currPot = formatAsIndianCurrency(
    pot.current_pot_value,
    pot.pot_currency
  );
  const currPotParts = currPot.split(/\s+/);

  function checkPickActive(lastEntryTime) {
    const isActive = compareTime(lastEntryTime);
    return isActive;
  }

  const ProgressRender = () => {
    return <ProgressBar pot={pot} />;
  };

  let isDisabled = pot?.max_pot_value == pot?.current_pot_value;

  return (
    <Card
      className="bg-[#00AB26] relative bg-cover w-full mobile-s:mb-[3%] text-white mobile-m:mb-[3%]
       mobile-l:mb-[3%] tablet:w-[48%] tablet:mb-[3%] laptop-l:mb-[3%] laptop-s:mb-[3%]
    laptop-s:w-[30.5%] laptop-l:w-[31.8%] laptop-min:w-[31.8%]
    laptop-min:mb-[3%] desktop:mb-[2%] shadow-md rounded-[30px] desktop:w-[23.5%] bg-no-repeat"
    >
      <div className="flex flex-col gap-4 m-auto px-[5%] py-[5%]">
        <div>
          <div className="flex">
            <img
              src={pot.season_logo ? pot.season_logo : eplLeagueImg}
              className="laptop-s:h-12 laptop-s:w-12 
            laptop-l:h-[60px] laptop-l:w-[60px]
            mobile-s:h-[55px]  mobile-s:w-[55px]
            bg-[white] rounded-[10px]"
            />

            <div className="ml-[3%] flex justify-between w-[100%]">
              <div className="flex flex-col gap-2">
                <h2
                  className={`tablet:text-[18px] ${
                    potType === "activePot" && ""
                  } font-[800] mobile-s:text-[16px] laptop-s:!text-[18px] laptop-l:!text-[22px] font-lato`}
                >
                  {pot.season_name}
                </h2>
                <div className="flex w-full">
                  <div className="relative w-full text-center bg-cover justify-between">
                    <img
                      src={matchWeekCard}
                      className="laptop-l:w-[90px] laptop-l:h-[17px] laptop-s:w-[90px] laptop-s:[15px] object-cover"
                      alt="Description of image"
                    />
                    <p
                      className={`absolute laptop-s:text-[12px] laptop-l:text-[12px] top-0 ${
                        potType === "activePot" ? "left-[20%]" : "left-[20%]"
                      } text-[12px] text-[#001731] font-lato`}
                    >
                      MW-{pot?.dead_line?.match_week}
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-2 mt-2">
                {potType !== "newPot" &&
                  pot?.pick_id?.map((ele) => {
                    if (ele.status === "Lost" && ele.pick_id === pickId) {
                      return (
                        <div className="flex justify-end">
                          <div className="flex justify-center w-20 bg-white rounded-[15px]">
                            <img src={lostIcon} className="p-1 h-6 w-6" />
                            <p className="text-[#FF4956] p-1 pl-0 text-[12px] font-lato font-bold">
                              Lost
                            </p>
                          </div>
                        </div>
                      );
                    }
                    if (ele.status === "Win" && ele.pick_id === pickId) {
                      return (
                        <div className="flex justify-end">
                          <div className="flex justify-center w-20 bg-white rounded-[15px]">
                            <img src={compleated} className="p-1 h-6 w-6" />
                            <p className="text-[#00AB26] p-1 pl-0 text-[12px] font-lato font-bold">
                              Win
                            </p>
                          </div>
                        </div>
                      );
                    } else {
                      if (ele.pick_id === pickId) {
                        return (
                          <div className="flex justify-end items-center">
                            {pot.status === "Active" &&
                            (status !== "Win" || status === "Lost") ? (
                              <div className="flex bg-text-white justify-center bg-white rounded-[15px] px-[5px]">
                                <img src={activeIcon} className="p-1 h-6 w-6" />
                                <p className="text-[#00AB26] p-1 pl-0 justify-center text-[12px] font-lato font-bold">
                                  Active
                                </p>
                              </div>
                            ) : pot.status === "Waitlist" ? (
                              <div className="flex bg-text-white bg-white rounded-[15px] px-[5px]">
                                <img
                                  src={waitlistIcon}
                                  className="p-1 h-6 w-6"
                                />
                                <p className="text-[#FA8800] p-1 pl-0 text-[12px] font-lato font-bold">
                                  Waitlist
                                </p>
                              </div>
                            ) : pot.status === "JackPot" ? (
                              <div
                                className="flex bg-text-white justify-center
                             bg-white rounded-[15px] px-2"
                              >
                                <img
                                  src={jackpotIcon}
                                  className="p-1 h-6 w-6"
                                />
                                <p className="text-[#007FFF] p-1 pl-0 justify-center text-[12px] font-lato font-bold">
                                  Vote
                                </p>
                              </div>
                            ) : (
                              <div className="flex bg-text-white justify-center bg-white rounded-[15px]">
                                <img
                                  src={compleated}
                                  className="p-2 pr-0 h-6 w-6"
                                />
                                <p className="text-[#00AB26]  p-1 pl-0 justify-center text-[12px] font-lato font-bold">
                                  Completed
                                </p>
                              </div>
                            )}
                          </div>
                        );
                      }
                    }
                  })}
                <div className="flex flex-col gap-2 font-lato">
                  {potType === "jackPot" ? (
                    <div className="flex gap-2">
                      <p className="mobile-s:text-[12px] font-semibold text-[500] tablet:text-[12px] laptop-s:text-[16px] gap-2 flex self-end ml-12">
                        RND:{pot.pot}
                      </p>
                      <span className="mobile-s:text-[12px] font-semibold text-[500] tablet:text-[12px] laptop-s:text-[16px]">
                        Pick:{currPickId}
                      </span>
                    </div>
                  ) : potType === "activePot" ? (
                    <div className="flex ">
                      <p className="mobile-s:text-[12px] font-semibold text-[500] tablet:text-[12px] laptop-s:text-[16px] gap-2 flex self-end ml-12">
                        RND:{pot.id} 
                        <span className="mobile-s:text-[12px] font-semibold text-[500] tablet:text-[12px] laptop-s:text-[16px]">
                          Pick:{currPickId}
                        </span>
                        
                      </p>
                      {/* <p className="text-[16px] gap-2 flex self-end ml-12">
                      
                    </p> */}
                    </div>
                  ) : (
                    <p className="mobile-s:text-[12px] tablet:text-[12px] laptop-s:text-[16px] ml-[0%] font-semibold">RND:{pot.id}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          {potType === "newPot" ? (
            <div className="flex flex-col">
              <div className="flex items-center justify-end w-[100%] gap-[1%]">
                <div className="flex">
                  <div className="flex mt-2">
                    <div className="flex items-center mb-[1px]">
                      {potType === "activePot" ? (
                        <h1 className="laptop-l:text-[46px] mobile-s:text-[32px] tablet:text-[32px] laptop-s:text-[34px] font-bebasNeue px-[5px]">
                          {checkPickActive(pot.last_entry_time) &&
                          pot.status != "Active"
                            ? Number(parts[1].replace(/,/g, "")) -
                              (Number(parts[1].replace(/,/g, "")) / 100) *
                                Number(pot.admin_fee)
                            : currPotParts[1]}
                        </h1>
                      ) : (
                        <h1 className="flex laptop-l:text-[46px] mobile-s:text-[38px] tablet:text-[42px] laptop-s:text-[42px]  font-bebasNeue">
                          {Number(parts[1].replace(/,/g, "")) -
                            (Number(parts[1].replace(/,/g, "")) / 100) *
                              Number(pot?.admin_fee)}
                        </h1>
                      )}

                      <p className="laptop-l:text-[24px] mobile-s:text-[16px] laptop-s:text-[16px] ml-[2%] mt-[10%] font-bebasNeue ">
                        {" "}
                        {pot.pot_currency}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-full">{ProgressRender()}</div>
            </div>
          ) : null}

          {potType === "activePot" ? (
            <>
              <div className="flex items-center justify-end">
                <div className="flex">
                  <div className="flex mt-2">
                    <div className="flex items-center mb-[1px]">
                      {checkPickActive(pot.last_entry_time) ? (
                        <h1 className="laptop-l:text-[46px] mobile-s:text-[32px] tablet:text-[32px] laptop-s:text-[34px]  font-bebasNeue">
                          {finalValue}
                        </h1>
                      ) : (
                        <h1 className="laptop-l:text-[46px] mobile-s:text-[32px] tablet:text-[32px] laptop-s:text-[34px]  font-bebasNeue">
                          {Number(parts[1].replace(/,/g, "")) -
                            (Number(parts[1].replace(/,/g, "")) / 100) *
                              Number(pot?.admin_fee)}
                        </h1>
                      )}
                      <p className="laptop-l:text-[24px] laptop-s:text-[16px] ml-[2%] mt-[10%] font-bebasNeue ">
                        {"  "}
                        {pot.pot_currency}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-full self-end mb-[4%]">
                {ProgressRender()}
              </div>
            </>
          ) : null}

          {potType === "jackPot" ? (
            <div className="flex items-end justify-between py-3">
              {pot?.ready_to_split?.yescount || pot?.ready_to_split?.votes ? (
                <p className="pb-2">
                  {pot?.ready_to_split?.yescount}/{pot?.ready_to_split?.votes}{" "}
                  Ready to Split
                </p>
              ) : (
                <p className="pb-2">0/0 Ready to Split</p>
              )}
              <div className="flex items-end">
                <div className="flex mt-2 items-end">
                  <div className="flex items-center mb-[1px]">
                    <h1 className="laptop-l:text-[46px] mobile-s:text-[32px] tablet:text-[32px] laptop-s:text-[24px] font-bebasNeue">
                      {finalValue}
                    </h1>

                    <p className="laptop-l:text-[24px] laptop-s:text-[16px] ml-[2%] mt-[10%] font-bebasNeue ">
                      {"  "}
                      {pot.pot_currency}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          <div
            className={` flex flex-col gap-y-1 justify-start items-start ${
              potType !== "jackPot" && ProgressRender() ? "mt-1" : null
            }`}
          >
            <PotSelectButton
              pot={pot}
              potType={potType}
              pickId={pickId}
              status={status}
              potStatus={pot.status}
              systemPrediction={systemPrediction}
            />
             {isDisabled && potType === "newPot" ? (
              <p className="mobile-s:text-[12px] mobile-m:!text-[14px] laptop-s:text-[12px] laptop-l:text-[14px] ml-1">
               Maximum Round Value Reached
              </p>
            ) : (
              <>
                {potType === "newPot" ? (
                  <p className="mobile-s:text-[12px] mobile-m:!text-[14px] laptop-s:text-[12px] laptop-l:text-[14px] ml-1">
                    {!compareTime(pot?.last_entry_time)
                      ? null
                      : `Need ${pot.pot_token} tokens to play now`}
                  </p>
                ) : null}
              </>
            )}
          </div>
        </div>
        <div
          className={`flex mt-10 gap-2  w-full justify-between items-center`}
        >
          <div className="flex absolute bottom-2 gap-[7px]">
            <div className="bg-[#FFFFFF2A] h-8 w-8 rounded-[50%] px-2 py-1 ">
              <img src={newDashCardUser} className="h-4 w-4 mt-1" />
            </div>
            <div className="flex gap-1">
              <img src={userArrow} className="h-3 w-3 mt-2" />
              <p className="laptop-l:text-[18px] laptop-s:text-[14px]  mt-1">
                {pot?.active_picks_count??0}
              </p>
            </div>
            {/* {potType === "activePot" ? ( */}
              <>
                <div className="flex gap-1">
                  <img src={newCardArrowRed} className="h-3 w-3 mt-2.5" />
                  <p className="laptop-l:text-[18px] laptop-s:text-[14px]  mt-1">
                    {pot?.lost_picks_count??0}
                  </p>
                </div>
              </>
            {/* ) : null} */}
          </div>

          <div
            className="absolute  bottom-0 right-0 bg-[#FFD600] 
          rounded-br-[30px] rounded-tl-[30px] px-6"
          >
            <div className="flex py-1 px-1 justify-center items-center font-lato">
              <p className="laptop-l:text-[18px]  laptop-s:text-[14px]  text-[white]">
                {potType === "activePot" ? (
                  <CountdownTimer targetDate={pot?.dead_line?.dead_line} />
                ) : potType === "newPot" ? (
                  <>
                    <CountdownTimer targetDate={pot?.last_entry_time} />
                  </>
                ) : (
                  <CountdownTimer targetDate={pot?.jackpot_deadline} />
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}

export default PotCard;
