import React, { useEffect, useState } from "react";
import PlayerLayout from "../PlayerLayout/PlayerLayout";

import ActiveTeam from "../ViewDetails/ActiveTeam/ActiveTeam";
import TeamDetailHOC from "../TeamDetailHOC/TeamDetailHOC";
import { useDispatch, useSelector } from "react-redux";
import { fetchMyWinnings } from "../../services/myPicks/myPicks";
import { utcTimetoLocalTimeWithzone } from "../../utils/convertTime";


const tableHeader = [
  {
    id: 1,
    cellName: "Pick ID",
    value: "pickId",
  },
  {
    id: 2,
    cellName: "Round ID",
    value: "potId",
  },
  // {
  //   id: 3,
  //   cellName: "Payment Mode",
  //   value: "paymentMode",
  // },
  {
    id: 4,
    cellName: "Amount Won",
    value: "amountWon",
  },
  // {
  //   id: 5,
  //   cellName: "Payment Date",
  //   value: "paymentDate",
  // },

  // {
  //   id: 6,
  //   cellName: "Payment Status",
  //   value: "paymentStatus",
  // },
];

function MyWinnings({ isMobile, isLaptop }) {
  const dispatch = useDispatch();

  const [myWinnings, setMyWinnings] = useState({
    myWinnigsData: [],
    pagination: {},
  });
  const [myWinningMobileInfo, setMyWinningMobileInfo] = useState([]);

  const myWinnigsInfo = useSelector((state) => {
    return {
      myWinnigs: state?.myWinnings?.myWinnigsInfo,
      pagination: state?.myWinnings?.pagination,
    };
  });

  const myWinningsMobileData = useSelector((state) => {
    return state.myWinnings.myWinnigsMobiles;
  });
  useEffect(() => {
    if (myWinningsMobileData?.length > 0) {
      setMyWinningMobileInfo(myWinningsMobileData);
    }
  }, [myWinningsMobileData]);

  useEffect(() => {
    if (myWinnings?.pagination?.currentPage) {
      dispatch(
        fetchMyWinnings(
          `/pot/winnings?limit=10&page=${myWinnings?.pagination?.currentPage}`
        )
      );
    }
  }, [myWinnings?.pagination?.currentPage]);

  useEffect(() => {
    if (myWinnigsInfo?.myWinnigs.length > 0) {
      const myWinnings = myWinnigsInfo.myWinnigs.map((ele) => {
        const date = utcTimetoLocalTimeWithzone(ele.updated_at).split(" ");
        const time = `${date[3].split(":")[0]}:${date[3].split(":")[1]}`;
        let concatDate = `${date[1]} ${date[2]} ${time} ${date[4]}`;
        return {
          pickId: ele.pick_id,
          potId: ele.round_id,
          amountWon: (
            <div className="flex basis-1/2 justify-center gap-2">
              <div className="flex justify-start">
                <p>{ele.amount}</p>
              </div>{" "}
              <div className="flex justify-self-start">{ele.currency_code}</div>
            </div>
          ),
        };
      });
      setMyWinnings((prev) => ({
        ...prev,
        myWinnigsData: myWinnings,
        pagination: myWinnigsInfo.pagination,
      }));
    } else {
      setMyWinnings((prev) => ({
        ...prev,
        pagination: myWinnigsInfo.pagination,
      }));
    }
  }, [myWinnigsInfo.myWinnigs, myWinnigsInfo.pagination]);

  return (
    <div>
      <PlayerLayout>
        <div className="mb-[5%]">
          <ActiveTeam
            isMobile={isMobile}
            isLabtop={isLaptop}
            tableHeader={tableHeader}
            tableBody={myWinnings?.myWinnigsData}
            mobileMyWinning={myWinningMobileInfo}
            pagination={myWinnings?.pagination}
            component="myWinnings"
          />
        </div>
      </PlayerLayout>
    </div>
  );
}

export default TeamDetailHOC(MyWinnings);
