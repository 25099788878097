import React, { useState, useEffect } from "react";
import PlayerLayout from "../PlayerLayout/PlayerLayout";

import ActiveTeam from "../ViewDetails/ActiveTeam/ActiveTeam";
import TeamDetailHOC from "../TeamDetailHOC/TeamDetailHOC";
import { useDispatch, useSelector } from "react-redux";
import { fetchMyPurchases } from "../../services/myPicks/myPicks";
import { myPurchachesesHeader } from "../TableHeader/TableHeader";
import { utcTimetoLocalTimeWithzone } from "../../utils/convertTime";
import { formatAsIndianCurrency } from "../../utils/currencyFormat";

import greenDat from "../../assets/card/green-dat.svg";
import redDat from "../../assets/card/red-dat.svg";

function MyPurchases({ isMobile, isLaptop }) {
  const [myPurchase, setMyPurchase] = useState({
    myPurchase: [],
    pagination: {},
  });

  const [myPurchaseMobileInfo, setMyPurchaseMobileInfo] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (myPurchase?.pagination?.currentPage) {
      dispatch(
        fetchMyPurchases(
          `/pot/purchases?limit=10&page=${myPurchase?.pagination?.currentPage}`
        )
      );
    }
  }, [myPurchase?.pagination?.currentPage]);

  const myPurchases = useSelector((state) => {
    return {
      purchaseInfo: state.myPurchases.myPurchases,
      pagination: state.myPurchases.pagination,
    };
  });

  const myPurchaseMobile = useSelector((state) => {
    return state.myPurchases.myPurchasesMobiles;
  });

  useEffect(() => {
    if (myPurchaseMobile?.length > 0) {
      setMyPurchaseMobileInfo(myPurchaseMobile);
    }
  }, [myPurchaseMobile]);

  useEffect(() => {
    if (myPurchases?.purchaseInfo?.length > 0) {
      const myPurchasesBody = myPurchases.purchaseInfo.map((ele) => {
        const date = utcTimetoLocalTimeWithzone(ele.updated_at).split(" ");
        const time = `${date[3].split(":")[0]}:${date[3].split(":")[1]}`;
        let concatDate = `${date[1]} ${date[2]} ${time} ${date[4]}`;
        return {
          transactionID: ele?.transaction_id,
          // paymentMode: ele.transfer_type,
          purchaseDate: concatDate,
          amountPaid: formatAsIndianCurrency(ele.amount, ele.currency_code),
          tokensPurchased: ele.token,
          paymentStatus: (
            <p
              className={`flex items-center rounded-[6px] gap-1 ml-[34%] text-[14px] ${
                ele.transaction_status === "Success"
                  ? "bg-[#00A34D0F] px-4 w-[120px] rounded-[5px] text-[14px] font-interFont text-[#00A34D]"
                  : ele.transaction_status === "Failure"
                  ? "bg-[#FF49560F] px-4 w-[120px] rounded-[5px] text-[#FF4956] text-[14px] font-interFont"
                  : "text-[14px] font-interFont ml-[43%]"
              } font-semibold text-nowrap`}
            >
              {ele.transaction_status === "Success" ? (
                <img src={greenDat} className="h-2 w-2" />
              ) : ele.transaction_status === "Failure" ? (
                <img src={redDat} className="h-2 w-2" />
              ) : (
                ""
              )}
              {ele.transaction_status}
            </p>
          ),
        };
      });
      setMyPurchase((prev) => ({
        ...prev,
        myPurchase: myPurchasesBody,
        pagination: myPurchases.pagination,
      }));
    } else {
      setMyPurchase((prev) => ({
        ...prev,
        pagination: myPurchases.pagination,
      }));
    }
  }, [myPurchases.purchaseInfo, myPurchases.pagination]);

  return (
    <PlayerLayout>
      <div className="mb-[5%]">
        <ActiveTeam
          isMobile={isMobile}
          isLabtop={isLaptop}
          tableHeader={myPurchachesesHeader}
          tableBody={myPurchase?.myPurchase}
          mobileMyPurchase={myPurchaseMobileInfo}
          pagination={myPurchase?.pagination}
          component="myPurchases"
        />
      </div>
    </PlayerLayout>
  );
}

export default TeamDetailHOC(MyPurchases);
