import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import PlayerLayout from "../PlayerLayout/PlayerLayout";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Card from "../../Elements/Card";
import leftArrowImgLeague from "../../assets/images/left-arrow-mg-league.png";

import { fetchProfile, updateProfile } from "../../services/auth/verification";
import useFindDevice from "../CustomHooks/FindDevice";
import ibanLogo from "../../assets/card/iban-logo.jpg";
import Button from "../../Elements/Button";

import VectorNew from "../../assets/card/Vector-new.svg";

function Profile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [bankData, setBankData] = useState({});

  const [payment, setPayment] = useState("");

  const options = ["iban", "bban"];

  const {
    isMob: isMobile,
    isTab: isTablet,
    isLaptop: isLaptop,
  } = useFindDevice();

  const [isEdit, setIsEdit] = useState(true);
  const [accType, setAccType] = useState("iban");

  const [countryName, setCountryName] = useState("");

  const backBtnHandler = () => {
    navigate("/dashboard");
  };

  // Define validation schema
  const profileSchema = Yup.object().shape({
    firstName: Yup.string().required("Please enter first name"),
    lastName: Yup.string().required("Please enter last name"),
    userName: Yup.string()
      .required("Please enter user name")
      .max(10, "Display name max 10 characters."),
    holderName: Yup.string().required("Please enter account holder name"),
    swiftCode: Yup.string().required("Please enter swift code"),
    countryCode: Yup.string().required("Please enter country code"),
    [accType]: Yup.string().required(`Please enter account number`),
    mobileNumber: Yup.string()
      .required("Mobile Number is required")
      .matches(/^[0-9]{10}$/, "Invalid mobile number"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Please enter email"),
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsEdit(false);
    const {
      firstName,
      lastName,
      mobileNumber,
      email,
      userName,
      holderName,
      countryCode,
      swiftCode,
      accNumber,
    } = formik.values;
    if (
      !isEdit &&
      firstName &&
      lastName &&
      email &&
      userName &&
      userName.length <= 10
    ) {
      const res = await dispatch(
        updateProfile("/accounts/user/me", {
          username: userName,
          email: email,
          mobile_number: mobileNumber,
          first_name: firstName,
          last_name: lastName,
        })
      );
      if (res.status === "Success") {
        setIsEdit(true);
        navigate("/profile");
      }
    }
  };

  const userProfileInfo = JSON.parse(localStorage.getItem("userProfileInfo"));
  const cancelSubmit = async () => {
    setIsEdit(true);

    if (userProfileInfo) {
      formik.setValues(userProfileInfo);
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      userName: "",
      mobileNumber: "",
      email: "",
    },
    validationSchema: profileSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    fetchAPI();
  }, []);

  const fetchAPI = async () => {
    const res = await dispatch(fetchProfile("/accounts/user/me"));
    let data = {
      firstName: res.first_name,
      lastName: res.last_name,
      userName: res.username,
      holderName: res.first_name,
      accType: res?.iban ? "iban" : "bban",
      mobileNumber: res.mobile_number,
      swiftCode: res?.swiftcode ? res?.swiftcode : "",
      email: res.email,
      countryCode: res.country_code,
      accNumber: res.iban ? res.iban : res.bban,
    };
    if (res) {
      setPayment(res.iban ? "iban" : "bban");
      formik.setValues(data);
      setBankData(data);
    }
  };

  const wrapText = (text) => {
    if (text && text.length > (isMobile ? 15 : 20)) {
      const match = text.match(new RegExp(`.{1,${isMobile ? 15 : 20}}`, "g"));
      return match ? match.join("\n") : text;
    }
    return text;
  };

  function paymentType() {
    return (
      <>
        <div className="mt-[2%] mobile-s:w-full tablet:w-full laptop-s:w-[80%] border border-[#0000003A] rounded-[6px]">
          <div className="flex justify-between pl-5 pt-3 pr-6 ">
            {bankData?.accType === "iban" ? (
              <img src={ibanLogo} className="h-6 w-14" />
            ) : (
              "BBAN"
            )}
          </div>
          <div className="p-6 pt-4">
            <div className="flex w-full gap-4 mt-4">
              <h4 className=" mobile-s:text-[14px] w-[50%]">Transfer Type</h4>:
              <p className="mobile-s:text-[14px] w-[50%]">
                {bankData?.accType === "bban"
                  ? "BBAN-Beneficiary Bank Account Number"
                  : "IBAN Bank Account Number"}
              </p>
            </div>
            <div className="flex gap-2 w-full mt-2">
              <h4 className=" mobile-s:text-[14px] w-[50%]">Account Number</h4>:
              <p className="mobile-s:text-[14px] w-[50%]">
                {/* {bankData?.accNumber} */}
                {wrapText(bankData?.accNumber || "")}
              </p>
            </div>
            {paymentType === "bban" && (
              <div className="flex gap-2 w-full mt-2">
                <h4 className="w-[50%] tablet:text-nowrap mobile-s:text-[14px]">
                  Swift Code
                </h4>
                :
                <p className="mobile-s:text-[14px] w-[50%]">
                  {bankData?.swiftCode}
                </p>
              </div>
            )}
            <div className="flex gap-2 w-full mt-2">
              <h4 className="w-[50%] tablet:text-nowrap mobile-s:text-[14px]">
                Account Name
              </h4>
              :
              <p className="mobile-s:text-[14px] w-[50%]">
                {bankData?.holderName}
              </p>
            </div>

            {/* <div className="flex gap-2 mt-2">
              <h4 className="w-[35%] mobile-s:text-[14px]">Address</h4>:
              <p className="mobile-s:text-[14px]">bban</p>
            </div> */}
          </div>
        </div>
      </>
    );
  }

  return (
    <div>
      <PlayerLayout>
        <div className="mb-[5%]">
          <div className="mt-[3%] mb-[3%] flex gap-4 items-center">
            <img
              src={VectorNew}
              className="h-[20px] cursor-pointer w-[15px] ml-[3%]"
              onClick={backBtnHandler}
            />
            <p
              className="text-[#fff] cursor-pointer text-[18px] text-center justify-center"
              onClick={backBtnHandler}
            >
              {isEdit ? "My" : "Edit"} Profile
            </p>
          </div>
          <div className="mb-[5%]">
            <Card
              className="w-[95%] m-auto mobile-s:mb-[3%] mobile-m:mb-[3%] mobile-l:mb-[3%] 
       tablet:mb-[3%] laptop-l:mb-[1px] laptop-s:mb-[1px]
      laptop-min:mb-[3%] desktop:mb-[2%] bg-[#FFFFFF]
      rounded-[6px] desktop:w-full"
            >
              <div className="m-auto">
                <div
                  className="flex header-container
                 p-[5px]
                rounded-tl-[6px] rounded-tr-[6px]"
                >
                  <div
                    className={`${isMobile ? "flex" : ""} items-center 
                bg-[#00AB26] ${
                  isMobile ? "h-[180px]" : isTablet ? "h-[150px]" : "h-[100px]"
                } w-full rounded-tl-[6px] rounded-tr-[6px]`}
                  >
                    {isMobile ? (
                      <div className="ml-10">
                        <div className="h-[90px] w-[90px] m-auto">
                          <div className="flex h-[90px] w-[90px] relative"></div>
                        </div>
                        <div className="text-[#FFFFFF] mt-[30px] text-[18px] mb-[15%]">
                          <p className="text-[20px] mobile-s:text-[18px]">
                            {formik.values.firstName}
                          </p>
                          <p className="text-[12px] mobile-s:text-[14px]">
                            {formik.values.email}
                          </p>
                        </div>
                      </div>
                    ) : isTablet ? (
                      <div className="flex items-center">
                        <div className="flex h-[90px] w-[90px] relative"></div>
                        <div className="text-[#FFFFFF] text-[18px] mt-[8%]">
                          <p className="text-[20px] mobile-s:text-[18px]">
                            {formik.values.firstName}
                          </p>
                          <p className="text-[12px] mobile-s:text-[14px]">
                            {formik.values.email}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="flex m-auto">
                        <div className="flex h-[90px] w-[90px] ml-[3%] justify-center relative"></div>

                        <div className="text-[#FFFFFF] text-[18px] mt-[30px]">
                          <p className="text-[20px] ml-[20%] text-nowrap">
                            {isEdit
                              ? formik.values.firstName
                              : userProfileInfo.firstName}
                          </p>
                          <p className="text-[12px] ml-[20%]">
                            {formik.values.email}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="">
                    <h2 className="ml-[10%] mt-[2%] text-[18px] text-[#000000] font- font-semibold">
                      Profile Details
                    </h2>
                    <div
                      className={`${
                        isMobile
                          ? "w-[80%] m-auto"
                          : isTablet
                          ? "flex w-[80%] ml-[10%] mt-[1%] mb-[1%] gap-[20px]"
                          : "flex w-[60%] ml-[10%] mt-[1%] mb-[1%] gap-[30px]"
                      }`}
                    >
                      <div className="w-full">
                        <label
                          className="bg-white px-2 py-1
                        relative top-3 left-3
                        text-[#000000805A] text-[10px]"
                        >
                          First Name
                        </label>
                        <input
                          className={`border ${
                            isEdit ? "border-gray-400" : "border-[#007FFF]"
                          } 
                          focus:border-[#007FFF] rounded w-full py-2 px-3 text-[#000000805A] leading-tight 
                          outline-none text-[14px]`}
                          id="first-name"
                          type="text"
                          name="firstName"
                          placeholder="First Name"
                          value={formik.values.firstName}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          autoComplete="off"
                          readOnly={isEdit}
                        />
                        {formik.touched.firstName &&
                          formik.errors.firstName && (
                            <span className="error w-[80%] ml-3 text-[#EF4444]">
                              {formik.errors.firstName}
                            </span>
                          )}
                      </div>

                      <div className="w-full">
                        <label
                          className="bg-white px-2 py-1
                       relative top-3 left-3
                        text-[black] text-[10px]"
                        >
                          Last Name
                        </label>
                        <input
                          className={`border ${
                            isEdit ? "border-gray-400" : "border-[#007FFF]"
                          } 
                          focus:border-[#007FFF] rounded w-full py-2 px-3 text-[#000000805A] leading-tight 
                          outline-none text-[14px]`}
                          id="last-name"
                          type="text"
                          name="lastName"
                          placeholder="Last Name"
                          value={formik.values.lastName}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          autoComplete="off"
                          readOnly={isEdit}
                        />
                        {formik.touched.lastName && formik.errors.lastName && (
                          <span className="error w-[80%] ml-3 text-[#EF4444]">
                            {formik.errors.lastName}
                          </span>
                        )}
                      </div>

                      <div className="w-full">
                        <label
                          className="bg-white px-2 py-1
                       relative top-3 left-3
                        text-[black] text-[10px]"
                        >
                          Display Name
                        </label>
                        <input
                          className={`border ${
                            isEdit ? "border-gray-400" : "border-[#007FFF]"
                          } 
                          focus:border-[#007FFF] rounded w-full py-2 px-3 text-[#000000805A] leading-tight 
                          outline-none text-[14px]`}
                          id="user-name"
                          type="text"
                          name="userName"
                          placeholder="User Name"
                          value={formik.values.userName}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          autoComplete="off"
                          readOnly={isEdit}
                        />
                        {formik.touched.userName && formik.errors.userName && (
                          <span className="error w-[80%] ml-3 text-[#EF4444]">
                            {formik.errors.userName}
                          </span>
                        )}
                      </div>
                    </div>

                    <div
                      className={`${
                        isMobile
                          ? "w-[80%] m-auto"
                          : isTablet
                          ? "flex w-[80%] ml-[10%] mt-[1%]  gap-[20px]"
                          : "flex w-[60%] ml-[10%] mt-[1%] gap-[30px]"
                      }`}
                    >
                      <div className="w-full mt-[8px] mb-[50px]">
                        <div></div>
                        <PhoneInput
                          className={`top-8`}
                          enableSearch={true}
                          countryCodeEditable={false}
                          inputStyle={{
                            borderColor: isEdit ? "gray" : "#007FFF",
                            width: isLaptop ? "100%" : "100%",
                          }}
                          country={"ae"}
                          onChange={(phone, country) => {
                            if (phone.startsWith("+")) {
                              const [countryCode, phoneNumber] =
                                phone.split(" ");
                              formik.setFieldValue(
                                "mobileNumber",
                                countryCode + " " + phoneNumber,
                                true
                              );
                              setCountryName(country.name);
                            } else {
                              formik.setFieldValue("mobileNumber", phone, true);
                              setCountryName(country.name);
                            }
                          }}
                          disabled={isEdit}
                          name="mobileNumber"
                          value={formik.values.mobileNumber}
                          countryCallingCodeEditable={false}
                        />
                      </div>
                      {isMobile ? (
                        <div
                          className={`
                          ${
                            isMobile
                              ? "w-[100%] m-auto"
                              : isTablet
                              ? "flex w-[80%] ml-[10%] mt-[1%] mb-[5%] gap-[20px]"
                              : "flex w-[60%] ml-[10%] mb-[3%] gap-[30px]"
                          }
                          
                          `}
                        >
                          {formik.values.mobileNumber.length > 10 ? null : (
                            <p className="error w-[80%] ml-3 text-[#EF4444]">
                              Please enter valid mobile number
                            </p>
                          )}
                        </div>
                      ) : null}

                      <div
                        className={`w-full ${
                          isLaptop
                            ? "mt-[18px]"
                            : isTablet
                            ? "mt-[18px]"
                            : "mt-[33px]"
                        }`}
                      >
                        <label
                          className="bg-gray-100 px-2 py-1
                       relative top-3 left-3
                        text-[black] text-[10px]"
                        >
                          Email
                        </label>
                        <input
                          className="border border-gray-400 rounded
                         w-full py-2 px-3 text-black leading-tight
                         outline-none
                         text-[14px] bg-gray-100"
                          id="email"
                          type="text"
                          name="email"
                          placeholder="Email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          autoComplete="off"
                          readOnly
                        />
                        {formik.touched.email && formik.errors.email && (
                          <span className="error w-[80%] ml-3 text-[#EF4444]">
                            {formik.errors.email}
                          </span>
                        )}
                      </div>

                      <div
                        className={`w-full ${
                          isLaptop
                            ? "mt-[22px]"
                            : isTablet
                            ? "mt-[22px]"
                            : "mt-[33px]"
                        }`}
                      ></div>
                    </div>

                    {!isMobile ? (
                      <div
                        className={`${
                          isMobile
                            ? "w-[100%] m-auto"
                            : isTablet
                            ? "flex w-[80%] ml-[8%] mb-[5%] gap-[20px]"
                            : "flex w-[60%] ml-[10%] mb-[3%] gap-[30px]"
                        }`}
                      >
                        {formik.values.mobileNumber.length > 10 ? null : (
                          <p className="error w-[80%] ml-3 text-[#EF4444]">
                            Please enter valid mobile number
                          </p>
                        )}
                      </div>
                    ) : null}

                    <div
                      className={`${
                        isMobile
                          ? " ml-[10%] mt-[5%] mb-[2%] pb-[7%]"
                          : isTablet
                          ? "flex w-[45%] ml-[10%] pb-[2%]"
                          : "flex w-[25%] ml-[10%] pb-[2%]"
                      }`}
                    >
                      <button
                        className="bg-[#0081F8] mobile-s:w-[37%]
                     mobile-l:w-[38%] h-[45px] w-[125px] rounded-[7px]
                      text-white text-[18px]"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        {isEdit ? "Edit" : "Save"}
                      </button>
                      {!isEdit ? (
                        <button
                          className="ml-4 bg-gray-300 mobile-s:w-[37%]
                     h-[45px] w-[125px] rounded-[7px]
                      text-gray-700 text-[18px]"
                          onClick={cancelSubmit}
                          type="button"
                        >
                          Cancel
                        </button>
                      ) : null}
                    </div>

                    <hr className="w-[100%] m-auto"></hr>
                    <h2 className="ml-[10%] mt-[2%] text-[18px] text-[#000000] font- font-semibold">
                      Bank Details
                    </h2>
                    <div className="flex justify-around pb-8">
                      {paymentType()}
                    </div>
                  </div>
                </form>
              </div>
            </Card>
          </div>
        </div>
      </PlayerLayout>
    </div>
  );
}

export default Profile;
