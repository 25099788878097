import React, { useState, useEffect } from "react";
import Button from "../../../../Elements/Button";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../CommonComponent/Table";
import {
  fetchJackPotMyPick,
  fetchJackPotVoteInfo,
  fetchOtherPlayerPrediction,
} from "../../../../services/jackPotView/jackPotView";
import {
  changeVotePopUpstatus,
  updateWebSocketVotePopUpstatus,
} from "../../../JackPotViewDetail/jackpot.slice";
import dotGreen from "../../../../assets/images/dot-green.png";
import { compareTime } from "../../../../utils/campareUtcTime";
import { wsURL } from "../../../../services/axiosConfig";
import { useLocation } from "react-router-dom";

function MyPicks() {
  const location = useLocation();
  const dispatch = useDispatch();

  const [payerDetail, setPlayerDetail] = useState({
    myPicks: [],
    voteDetail: {},
    otherPlayerPredictions: {
      header: [],
      body: [],
    },
  });

  const [otherPlayerDetails, setOtherPlayerDetails] = useState([]);

  // state value update on component
  const jackpotPlayerVoteAndPick = useSelector((state) => {
    const { playerVote, playerMatchPicks, otherPlayerPrediction } =
      state.jackPot;
    return {
      playerVote,
      playerPicks: playerMatchPicks,
      otherPlayerPrediction,
    };
  });

  // URL path value get
  // const [, , , , potId, pickId, seasonId, matchWeek] =
  //   window.location.href.split("/");

  const data = location.state;
  const { potId, pickId, seasonId, matchWeekId:matchWeek, potType } = data;

  const otherPlayerPrediction = useSelector(
    (state) => state.jackPot.otherPlayerPrediction
  );

  useEffect(() => {
    const { playerVote, playerPicks } = jackpotPlayerVoteAndPick;
    if (playerVote.length > 0 || Object.keys(playerPicks).length > 0) {
      setPlayerDetail((prev) => ({
        ...prev,
        voteDetail: playerVote,
        myPicks: playerPicks,
      }));
    }
  }, [
    jackpotPlayerVoteAndPick.playerVote,
    jackpotPlayerVoteAndPick.playerPicks,
    jackpotPlayerVoteAndPick.otherPlayerPrediction,
  ]);

  useEffect(() => {
    if (Object.keys(otherPlayerPrediction).length > 0) {
      setOtherPlayerDetails(otherPlayerPrediction);
    }
  }, [otherPlayerPrediction]);

  // api dispatch
  useEffect(() => {
    dispatch(
      fetchJackPotVoteInfo(`/pot/jackpotupdate/${Number(potId)}`, {
        pickId: pickId,
      })
    );
    dispatch(fetchJackPotMyPick(`/pot/activepredictions/${Number(pickId)}`));
    fetchData();
  }, [potId]);

  async function fetchData() {
    await dispatch(
      fetchOtherPlayerPrediction(`/pot/jackpotpredictions/${Number(potId)}`)
    );
  }

  const voteNowHandler = () => {
    dispatch(changeVotePopUpstatus(true));
  };

  // vote other player table dynamically created
  useEffect(() => {
    if (Object.keys(payerDetail.voteDetail).length > 0) {
      // this pice of code created for vote not in the other player predication so I added using another api
      let allKeysSet = new Set();

      Object.values(otherPlayerDetails).forEach((obj) => {
        Object.keys(obj).forEach((key) => allKeysSet.add(key));
      });

      let allKeysArray = Array.from(allKeysSet);

      allKeysArray = allKeysArray.map((key) =>
        key === "pick_id" ? "vote" : key
      );

      let playerIndex = allKeysArray.indexOf("player");
      if (playerIndex !== -1) {
        allKeysArray.splice(playerIndex, 1);
      }
      allKeysArray.unshift("player");

      let voteIndex = allKeysArray.indexOf("vote");
      if (voteIndex !== -1) {
        allKeysArray.splice(voteIndex, 1);
        allKeysArray.splice(1, 0, "vote");
      }

      // other player picks header and body
      let otherPlayerHeader = allKeysArray.map((key, index) => ({
        id: index + 1,
        cellName: key,
        value: key.toLowerCase(),
        position: key === "vote" ? 2 : key === "player" ? 1 : null,
      }));

      let otherPlayerbody = Object.values(otherPlayerDetails).map((obj) => {
        let res = payerDetail.voteDetail?.data?.voted.filter((ele) => {
          return ele?.pick_id === obj.pick_id;
        });
        let row = {};
        allKeysArray.length > 0 &&
          allKeysArray.forEach((key) => {
            row[key] = key === "vote" ? res && res[0]?.vote : obj[key] || "";
          });
        row.player = row.player.charAt(0).toUpperCase() + row.player.slice(1);
        row.vote = row.vote
          ? row.vote.charAt(0).toUpperCase() + row.vote.slice(1)
          : "-";
        return row;
      });

      setPlayerDetail((prev) => ({
        ...prev,
        otherPlayerPredictions: {
          header: otherPlayerHeader,
          body: otherPlayerbody,
        },
      }));
    }
  }, [otherPlayerDetails, payerDetail.voteDetail, payerDetail.myPicks]);

  // socket for pot's
  useEffect(() => {
    const jackpotSocket = new WebSocket(`${wsURL}/joinpot/jackpot`);

    // Ensure the connection is not closed before it's established
    jackpotSocket.addEventListener("open", () => {
      console.log("WebSocket connection opened");
    });

    // Listen for messages
    jackpotSocket.addEventListener("message", (event) => {
      const res = JSON.parse(event?.data);
      if (res.data.message !== "Connection established") {
        dispatch(
          updateWebSocketVotePopUpstatus({ fields: res.data.fields.votes })
        );
      }
    });

    return () => {
      // Make sure the connection is established before closing
      if (jackpotSocket.readyState === WebSocket.OPEN) {
        jackpotSocket.close();
      }
    };
  }, []);

  let jockPotTime = localStorage.getItem("jockPotDeadline");

  return (
    <div>
      <div className="flex mobile-s:mt-[2%] items-center">
        <p
          className="text-[#000000] mobile-s:text-[13px]
         tablet:text-[22px] laptop-s:text-[20px] laptop-min:text-[22px] w-[20%]"
        >
          My Vote :
        </p>
        <div className="flex rounded-[6px] w-[40%] mobile-s:text-[11px] mobile-s:ml-[2%] text-[#00000080] bg-[#0000000F] p-3 justify-self-start">
          <div className="flex items-center gap-2">
            {payerDetail?.voteDetail?.is_vote === "yes" ? (
              <img src={dotGreen} className={`bg-[#00A34D] h-2 w-2`} />
            ) : null}
            <p
              className={` ${
                payerDetail?.voteDetail?.is_vote === "yes"
                  ? "text-green-500"
                  : payerDetail?.voteDetail?.is_vote
                  ? "text-red-500"
                  : ""
              }
          text-center 
          text-[15px]
          `}
            >
              {payerDetail?.voteDetail?.is_vote
                ? payerDetail.voteDetail.is_vote.charAt(0).toUpperCase() +
                  payerDetail.voteDetail.is_vote.slice(1)
                : "- Not Yet Voted"}
            </p>
          </div>
        </div>
        <Button
          className={`${
            !compareTime(jockPotTime)
              ? "bg-gray-300"
              : "bg-[#0AB159] hover:bg-[#0AB159]"
          }  ml-[5%] w-[35%] basis-1/3 text-[14px]
            px-[3%] py-3 text-white font-bold 
           focus:outline-none focus:shadow-outline rounded-[10px]`}
          type="button"
          disabled={!compareTime(jockPotTime)}
          onClick={voteNowHandler}
        >
          <p className="flex items-center justify-center gap-2">
            <span
              className="mobile-s:text-[10px] 
            mobile-m:text-[12px] laptop-min:text-[14px]
             text-[14px] laptop-l:text-[14px] tablet:text-[12px]"
            >
              {payerDetail?.voteDetail?.is_vote !== null &&
              payerDetail?.voteDetail?.is_vote !== undefined
                ? "Change Vote"
                : "Vote Now"}
            </span>
          </p>
        </Button>
      </div>
      <div
        className="bg-[#F4F8FC] mt-[3%] p-5 border border-solid bordwr-[#0000008A]
        rounded-lg rounded-bl-[6px] rounded-br-[6px] shadow-lg"
      >
        <p className="mb-[5%]">My Picks</p>
        <div className="border border-solid border-[#0000005A] border-opacity-20"></div>
        <div
          className="flex gap-[5%] overflow-auto relative pb-4"
          style={{ maxHeight: "calc(100vh - 2rem - 4rem)" }}
        >
          {payerDetail.myPicks.length > 0 ? (
            payerDetail.myPicks.map((pick, i) => {
              return (
                <div className="mt-[5%]">
                  <p className="text-[13px] text-nowrap">
                    MW {pick.matchweek.split("-")[1]}
                  </p>
                  <img
                    src={pick.logo}
                    className={`mt-[30px] h-10 w-10 p-1 ${
                      pick?.pick_type === "System"
                        ? "bg-red-200"
                        : null
                    }`}
                  />
                </div>
              );
            })
          ) : (
            <h2 className="mt-[5%]">No pick's selected yet</h2>
          )}
          <div className="absolute bottom-0 w-full h-2 bg-transparent"></div>
        </div>
      </div>
      <div className="m-auto overflow-x-auto">
        <Table
          tableName="otherUser"
          className="min-w-full mt-5 m-auto rounded-lg overflow-hidden p-4"
          tableHeader={payerDetail.otherPlayerPredictions.header}
          tableBody={payerDetail.otherPlayerPredictions.body}
        />
      </div>
    </div>
  );
}

export default MyPicks;
