import * as Yup from "yup";
import { parsePhoneNumberFromString } from "libphonenumber-js";

export const loginValidationSchema = {
  email: Yup.string()
    .email("Invalid email address")
    // .test("ends-with-com", 'Email must end with ".com"', function (value) {
    //   if (!value) return false;
    //   return value.endsWith(".com");
    // })
    .required("Please enter email"),
  password: Yup.string().required("Please enter password"),
};

export const sinUpValidationSchema = {
  firstName: Yup.string().required("Please enter firstname"),
  lastName: Yup.string().required("Please enter lastname"),
  userName: Yup.string()
    .required("Please enter displayname")
    .max(10, "Display name max 10 characters."),
  mobileNumber: Yup.string()
    .required("Please enter mobile number")
    .test("valid-phone", "Invalid phone number", function (value) {
      if (!value) return false;
      const phoneNumber = parsePhoneNumberFromString(`+${value}`);
      if (!phoneNumber) return false;
      return phoneNumber.isValid();
    }),
  email: Yup.string()
    .email("Invalid email address")
    // .test("ends-with-com", 'Email must end with ".com"', function (value) {
    //   if (!value) return false;
    //   return value.endsWith(".com");
    // })
    .required("Please enter email"),
  // password: Yup.string()
  //   .min(6, "Password must be at least 6 characters long")
  //   .required("Please enter password"),
  password: Yup.string()
    .required("Please enter password")
    .matches(
      /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+-])[A-Za-z0-9!@#$%^&*()_+-]{8,30}$/,
      "Password must be minimum 8 to 30 characters, at least one uppercase letter, one number, and one special character"
      // "password criteria not matched"
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Please enter confirm password"),
};

export const forgotPasswordValidationSchema = {
  email: Yup.string()
    .email("Invalid email address")
    .test("ends-with-com", 'Email must end with ".com"', function (value) {
      if (!value) return false;
      return value.endsWith(".com");
    })
    .required("Please enter email"),
};
