// usePreventBounce.js
import { useEffect } from 'react';

const usePreventBounce = () => {
  useEffect(() => {
    const isIPhone = /iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    if (isIPhone) {
      const preventBounce = (e) => {
        const target = e.target;

        // If the target element is scrollable
        if (target.scrollHeight > target.clientHeight) {
          const scrollTop = target.scrollTop;
          const scrollHeight = target.scrollHeight;
          const clientHeight = target.clientHeight;

          if ((scrollTop === 0 && e.deltaY < 0) || (scrollTop + clientHeight >= scrollHeight && e.deltaY > 0)) {
            e.preventDefault();
          }
        }
      };

      // Add event listener to the document
      document.addEventListener('wheel', preventBounce, { passive: false });

      // Cleanup function to remove the event listener
      return () => {
        document.removeEventListener('wheel', preventBounce);
      };
    }
  }, []);
};

export default usePreventBounce;
