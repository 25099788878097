import React from "react";
import Card from "../../../Elements/Card";

import Matchweek from "./Mypot/Matchweek/Matchweek";
import Players from "./Mypot/Players/Players";
import MyPicks from "./Jackpot/MyPicks";
import { useLocation } from "react-router-dom";

function Picks({ isMobile, potType, leagueInfo, deadLine }) {
  const location = useLocation();
  const data = location.state;
  const {
    potId,
    pickId,
    seasonId,
    matchWeekId: matchWeek,
    potType: type,
  } = data;
  return (
    <div className="tablet:!w-[100%] laptop-s:!w-[50%]">
      <div
        className={`
          bg-[url('../public/card/standing-header-img.svg')]
          ml-[20%] bg-cover
          mobile-s:w-[60%] mobile-s:h-[38px] tablet:!w-[32%] tablet:!h-[48px] tablet:!ml-[10%]
          laptop-s:!relative laptop-s:!top-[1px] laptop-s:!w-[33%]  laptop-l:!w-[25%]
          desktop:!h-[70px]`}
      >
        <div className="flex mt-[8%] items-center">
          <p className="text-[15px] mt-3 mx-auto text-center font-semibold">
            {potType === "jackPot"
              ? "Vote"
              : type === "myPot"
              ? "My Picks"
              : "Fixtures"}
          </p>
        </div>
      </div>
      <Card
        className="m-auto w-[97%] bg-[#FFFFFF] overflow-y-auto
      h-[100%] rounded-[8px]  px-[2%] "
      >
        <div className="tablet:!mt-[3%] ">
          {potType === "myPot" ? (
            <div>
              <div
                className={`sticky top-0 bg-[#FFFFFF] z-10`}
              >
                <Matchweek isMobile={isMobile} />
                <div className="border border-[#64748B996A] tablet:!mt-[3%]"></div>
              </div>
              <Players isMobile={isMobile} deadLine={deadLine} />
            </div>
          ) : (
            <MyPicks />
          )}
        </div>
      </Card>
    </div>
  );
}

export default Picks;
