// useFormikValidation.js
import { useFormik } from "formik";
import * as Yup from "yup";

const useFormikValidation = (initialValues, validationSchema, onSubmit) => {
  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  return formik;
};

export default useFormikValidation;
