import {
  fetchDashboardStart,
  fetchDashboardSuccess,
  fetchDashboardFailure,
  buyNewTokenUsingPayment,
  popupClose,
  popupClosed
} from "../../Components/GreenDashboard/dashboard.slice";
import { postData, getData } from "../api";


export const buyTocken = (URL, tokenInfo) => async (dispatch) => {
  let response = {};
  try {
    const response = await postData(URL, tokenInfo);
    if (response.status === "Success") {
      if (response?.data?.payment_details.error) {
        dispatch(fetchDashboardFailure(response?.data?.payment_details.error));
      }
      if (response?.data?.payment_details?.url) {
        dispatch(popupClosed({url:response?.data?.payment_details?.url}))
      }
    }
  } catch (error) {
    dispatch(fetchDashboardFailure(error.message));
  }
};

export const getIsuueType = (URL) => async (dispatch) => {
  try {
    const res = await getData(URL);
    return res;
  } catch (e) {
    console.log(e);
  }
};

export const createIsuue = (URL, data) => async (dispatch) => {
  try {
    const res = await postData(URL, data);
    return res;
  } catch (e) {
    console.log(e);
  }
};
