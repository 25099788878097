import { getData, postData, putData, deleteData } from "../api";

export const verifyOTPCode = (URL, otpCode) => async (dispatch) => {
  let response = {};
  try {
    response = await postData(URL, otpCode);
    if (response.status === "Success") {
      localStorage.setItem("tocken", response.data.token);
      return response;
    }
  } catch (error) {
    return response;
  }
};

export const resendOTPCode = (URL, email) => async (dispatch) => {
  let response = {};
  try {
    response = await postData(URL, email);
    return response;
  } catch (error) {
    return response;
  }
};

export const forgotPassword = (URL, forgotEmail) => async (dispatch) => {
  let response = {};
  try {
    response = await postData(URL, forgotEmail);
    response.status = "Success";
    if (response.status === "Success") {
      return response;
    }
  } catch (error) {
    response.status = "Email does not exsit";
    return response;
  }
};

export const changePassword = (URL, forgotEmail) => async (dispatch) => {
  let response = {};
  try {
    response = await putData(URL, forgotEmail);
    response.status = "Success";
    if (response.status === "Success") {
      return response;
    }
  } catch (error) {
    response.status = "Failed";
    return response;
  }
};

export const updateProfile = (URL, userInfo) => async (dispatch) => {
  let response = {};
  try {
    response = await putData(URL, userInfo);

    if (response.status === "Success") {
      let userInfo = {
        firstName: response.data.first_name,
        lastName: response.data.last_name,
        mobileNumber: response.data.mobile_number,
        userName: response.data.username,
        email: response.data.email,
      };
      const info = JSON.parse(localStorage.getItem("userInfo"));
      let userDetail = {
        email: info.email,
        isAdmin: info.isAdmin,
        userId: info.userId,
        userName: response.data.first_name,
      };
      localStorage.setItem("userInfo", JSON.stringify(userDetail));
      localStorage.setItem("userProfileInfo", JSON.stringify(userInfo));
      return response;
    }
  } catch (error) {
    return response;
  }
};

export const fetchProfile = (URL) => async (dispatch) => {
  try {
    let response = await getData(URL);
    return response;
  } catch (error) {
    return error;
  }
};

export const activateAccount = (URL, otpCode) => async (dispatch) => {
  let response = {};
  try {
    response = await postData(URL, otpCode);
    if (response.status === "Success") {
      return response;
    }
  } catch (error) {
    return response;
  }
};
