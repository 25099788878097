import React from "react";

const Input = ({
  className,
  type,
  id,
  placeholder,
  name,
  value,
  onChange,
  maxlength,
  onPaste,
  onSelectStart,
  readOnly
}) => {
  return (
    <input
      placeholder={placeholder}
      className={className}
      type={type}
      id={`input-${id}`}
      name={name}
      value={value}
      onChange={onChange}
      maxLength={maxlength}
      onPaste={onPaste}
      onSelectStart={onSelectStart}
      readOnly={readOnly}
    />
  );
};

export default Input;
