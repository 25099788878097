import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PlayerLayout from "../PlayerLayout/PlayerLayout";
import { useDispatch, useSelector } from "react-redux";
import ActiveTeam from "../ViewDetails/ActiveTeam/ActiveTeam";
import Standing from "../ViewDetails/Standing/Standing";
import Picks from "../ViewDetails/Picks/Picks";
import TeamDetailHOC from "../TeamDetailHOC/TeamDetailHOC";

import { useLocation } from "react-router-dom";

import {
  fetchPotInfoMyPotView,
  fetchMatchWeek,
} from "../../services/myPotView/myPotView";
import { formatAsIndianCurrency } from "../../utils/currencyFormat";

import newBackArrow from "../../assets/card/new-back-arrow.svg";
import PotDetail from "./PotDetail";

import { Flip, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const tableHeader = [
  {
    id: 1,
    cellName: "Round ID",
    value: "potId",
  },
  {
    id: 2,
    cellName: "Pick ID",
    value: "pickId",
  },
  {
    id: 4,
    cellName: "Match week",
    value: "matchWeek",
  },
  {
    id: 5,
    cellName: "Round Value",
    value: "potValue",
  },
  {
    id: 6,
    cellName: "No. of Picks",
    value: "noOfActivePicks",
  },
  {
    id: 7,
    cellName: "Round Status",
    value: "potStatus",
  },
];

function MyPotViewDetail({ isMobile, isLaptop }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [fetchPotInfo, setFetchPotInfo] = useState([
    {
      pot_id: "",
      PickId: "",
      potCategory: "",
      matchWeek: "",
      potValue: "",
      noOfActivePicks: "",
      potStatus: "",
    },
  ]);
  const [potDeadLine, setPotDeadLine] = useState(0);

  const [leagueInfo, setLeagueInfo] = useState({
    league: "",
    deadLine: "",
  });

  const dispatch = useDispatch();

  const data = location.state;

  const { potId, pickId, seasonId, matchWeekId: matchWeek, potType } = data;

  useEffect(() => {
    fetchInfo();
  }, [potId, seasonId]);

  async function fetchInfo() {
    let pot = Number(potId);
    let pick = Number(pickId);
    let season = Number(seasonId);
    await dispatch(fetchPotInfoMyPotView(`/pot/pots/${pot}`));
    if (season) {
      await dispatch(
        fetchMatchWeek(`/pot/seasonmatchweeks?season=${season}&pot_id=${pot}`)
      );
    }
  }

  const potDetails = useSelector((state) => state.myPot.viewPotInfo);

  const error = useSelector((state) => state.myPot.error);

  useEffect(() => {
    if (potDetails.id) {
      let adminFee = (potDetails?.max_pot_value / 100) * potDetails.admin_fee;
      setPotDeadLine(potDetails?.deadline_predictions);
      setLeagueInfo((prev) => ({
        ...prev,
        league: potDetails.league,
      }));
      setFetchPotInfo([
        {
          pot_id: potDetails.id,
          pick_id: pickId,
          matchWeek: potDetails.match_weeks.current_match_week,
          potValue: formatAsIndianCurrency(
            potDetails.max_pot_value - adminFee,
            potDetails.pot_currency
          ),
          max_pot_value: potDetails?.max_pot_value,
          admin_fee: potDetails?.admin_fee,
          no_of_picks: potDetails.joined_picks,
          pick_status: potDetails.status,
        },
      ]);
    }
  }, [potDetails.id]);

  const backBtnHandler = () => {
    navigate("/dashboard");
  };

  const handleToastClose = () => {
    // dispatch(updateErrorValues());
  };

  useEffect(() => {
    if (error) {
      toast.error(error, {
        onClose: handleToastClose,
        autoClose: 2000,
      });
    }
  }, [error]);

  return (
    <PlayerLayout>
      {/* {alert(error)} */}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ zIndex: 9999 }}
        className="mobile-s:w-[70%] mobile-s:ml-[15%] tablet:w-[40%] laptop-s:w-[25%] mobile-s:text-[12px] mobile-m:!text-[14px] tablet:ml-[1%]"
      />
      <div className="bg-[#203725] p-4 sticky top-0 mt-6 z-30">
        <nav className="flex ml-[2.5%]" aria-label="Breadcrumb">
          <img
            src={newBackArrow}
            className="h-5 w-5 cursor-pointer"
            onClick={backBtnHandler}
          />
          <ol className="inline-flex ml-5 items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
            <li className="inline-flex text-[#FFFFFF] items-center">
              {potType == "newPot" ? "New Round" : "My Round"}
            </li>
            <li className="text-[#FFFFFF]">
              <div className="flex items-center">/{leagueInfo?.league}</div>
            </li>
          </ol>
        </nav>
      </div>
      {potType == "newPot" ? null : <PotDetail fetchPotInfo={fetchPotInfo} />}

      <div className="">
        {isLaptop ? (
          <div className="flex px-[1.5%] gap-2 h-[100%] mb-10 overflow-hidden">
            <Picks isMobile={isMobile} potType="myPot" deadLine={potDeadLine} />
            <Standing
              isMobile={isMobile}
              potType="myPot"
              leagueInfo={leagueInfo}
            />
          </div>
        ) : (
          <>
            <Picks
              isMobile={isMobile}
              potType="myPot"
              leagueInfo={leagueInfo}
              deadLine={potDeadLine}
            />
            <Standing isMobile={isMobile} leagueInfo={leagueInfo} />
          </>
        )}
      </div>
    </PlayerLayout>
  );
}

export default TeamDetailHOC(MyPotViewDetail);
