import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import smsIcon from "../../assets/images/sms.png";
import Verticalline from "../../assets/images/Vector.svg";
import { useFormik } from "formik";
import Input from "../../Elements/Input";
import Button from "../../Elements/Button";
import Card from "../../Elements/Card";
import AuthLayout from "../AuthLayout/AuthLayout";
import { forgotPassword } from "../../services/auth/verification";
import { useDispatch } from "react-redux";

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address').required('Email is required'),
});

function ForgotPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const res = await dispatch(
        forgotPassword("/accounts/forgot_password", {
          email: values.email.toLowerCase(),
        })
      );
      setSubmitting(false);
      if (res?.status === "Success") {
        localStorage.setItem("registerEmail", values.email.toLowerCase());
        localStorage.removeItem("countdownEndTime");
        navigate(
          `/verificationCode/forgotPassword/${values.email.toLowerCase()}`
        );
      } else {
        setErrorMessage(res.status);
      }
    },
  });

  return (
    <AuthLayout>
      <Card
        className="m-auto bg-[#FFFFFF] shadow-md rounded-[12px] 
        laptop-l:h-[50%] laptop-l:w-[35%] laptop-l-min:w-[35%] laptop-min:h-[65%] laptop-s:h-[45%]
        laptop-s:w-[45%] tablet:w-[60%] tablet:h-[45%] 
        desktop:w-[25%] desktop:h-[50%] flex flex-col 
        justify-center mobile-s:w-[90%] mobile-s:mt-[20%]
        mobile-s:h-[60%] tablet:mx-auto tablet:my-auto
        font-interFont"
      >
        <div className="w-[80%] m-auto">
          <div>
            <h2 className="text-center mobile-s:text-[22px] laptop-s:text-[22px] font-semibold mb-5">
              Forgot your password?
            </h2>
            <p className="text-center text-[15px] text-[#000000] text-opacity-50 mb-6">
              Please enter the email address you would like to reset password
            </p>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <p className="text-red-500 text-center">{errorMessage}</p>
            <div className="relative w-full h-[45px] mt-[7%]">
              <img
                className="h-[20px] w-[20px] absolute top-1/2 transform -translate-y-1/2 left-3"
                alt="mail"
                src={smsIcon}
              />
              <img
                className="h-[20px] w-[20px] absolute top-1/2 transform -translate-y-1/2 left-10"
                alt="vertical-line"
                src={Verticalline}
              />
              <Input
                placeholder="Email"
                className="pl-14 text-gray-950 p-2 text-[15px] outline-none w-full rounded-[5px] border border-solid h-full border-[#CACACA] input-focused"
                id="email"
                name="email"
                autoComplete="off"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="text-red-500 text-sm mt-1">{formik.errors.email}</div>
              ) : null}
            </div>

            <div className="mt-1 text-center">
              <Button
                className="bg-blue-500 hover:bg-blue-700 
                p-2 w-full py-3 text-white font-bold rounded 
                focus:outline-none focus:shadow-outline mt-8 mb-4"
                type="submit"
                disabled={formik.isSubmitting}
              >
                Send Verification Code
              </Button>
              <a className="text-[#0081F8]" href="/login">
                Back to login
              </a>
            </div>
          </form>
        </div>
      </Card>
    </AuthLayout>
  );
}

export default ForgotPassword;
