import { Navigate } from "react-router-dom";

const LoginRouter = ({ element: Component, ...rest }) => {
  const isAuthUser = localStorage.getItem("isAuthUser");
  return isAuthUser === "true" ? (
    <Navigate to="/dashboard" replace />
  ) : (
    <Component {...rest} />
  );
};

export default LoginRouter;
