import {
  fetchMyPickHistoryStart,
  fetchMypickHistoryInformation,
  fetchMyPickHistoryFailure,
  fetchPotInfo,
} from "../../Components/HistoryOfPicks/history.slice";

import {
  fetchMyPickStart,
  fetchMyPickFailure,
  fetchMypickInformation,
} from "../../Components/MyPicks/myPick.slice";

import {
  fetchMyPurchasestart,
  fetchMyPurchaseInformation,
  fetchMyPurchaseFailure,
} from "../../Components/myPurchases/myPurchases.slice";

import {
  fetchWinningsInformation,
  fetchMyWinningsStart,
  fetchWinningsFailure,
} from "../../Components/MyWinnings/myWinnings.slice";

import { getData } from "../api";

export const fetchMyPickData = (URL) => async (dispatch) => {
  try {
    dispatch(fetchMyPickStart());
    const response = await getData(URL);
    if (response?.results?.length > 0) {
      dispatch(fetchMypickInformation(response));
    }
  } catch (error) {
    dispatch(fetchMyPickFailure(error.message));
  }
};

export const fetchMyPotInfo = (URL) => async (dispatch) => {
  try {
    dispatch(fetchMyPickHistoryStart());
    const response = await getData(URL);
    if (response?.status === "Success") {
      dispatch(fetchPotInfo(response));
    }
  } catch (error) {
    dispatch(fetchMyPickHistoryFailure(error.message));
  }
};

export const fetchMyPickHistory = (URL) => async (dispatch) => {
  try {
    dispatch(fetchMyPickHistoryStart());
    const response = await getData(URL);
    if (response?.status === "Success") {
      dispatch(fetchMypickHistoryInformation(response));
    }
  } catch (error) {
    dispatch(fetchMyPickHistoryFailure(error.message));
  }
};

export const fetchMyPurchases = (URL) => async (dispatch) => {
  try {
    dispatch(fetchMyPurchasestart());
    const response = await getData(URL);
    if (response?.count > 0) {
      dispatch(fetchMyPurchaseInformation(response));
    }
  } catch (error) {
    dispatch(fetchMyPurchaseFailure(error.message));
  }
};

export const fetchMyWinnings = (URL) => async (dispatch) => {
  try {
    dispatch(fetchMyWinningsStart());
    const response = await getData(URL);
    if (response?.count > 0) {
      dispatch(fetchWinningsInformation(response));
    }
  } catch (error) {
    dispatch(fetchWinningsFailure(error.message));
  }
};
