import React, { useState, useEffect } from "react";
import Button from "../../Elements/Button";
import { useDispatch, useSelector } from "react-redux";
import modelX from "../../assets/images/model-x.svg";
import { confirmPopUpChange } from "../MyPotViewDetail/myPot.slice";
import {
  putTeamForPickMyPot,
  postTeamForPickMyPot,
} from "../../services/myPotView/myPotView";

function ChangeTeamPopUp({ playerMatchData, pickId }) {
  const dispatch = useDispatch();

  const [confrimPotDetails, setPotConfrimPotDetails] = useState({
    isConfrim: "",
    selectedDetail: {},
  });

  const confrimPotPopDetail = useSelector((state) => {
    return {
      isConfirm: state?.myPot?.confirmPopUp,
      matchDetail: state?.myPot?.selectedTeamDetails,
    };
  });
  const [isOpen, setIsOpen] = useState(confrimPotPopDetail.isConfirm);

  useEffect(() => {
    setPotConfrimPotDetails((prev) => ({
      ...prev,
      isConfrim: confrimPotPopDetail?.isConfirm,
      selectedDetail: confrimPotPopDetail?.matchDetail,
    }));
  }, [confrimPotPopDetail?.isConfirm, confrimPotPopDetail?.matchDetail]);

  const togglePopup = () => {
    // dispatch(newTokenBuy(!isOpen));
    dispatch(confirmPopUpChange(false));
    setIsOpen(!isOpen);
  };

  const [clickedButton, setClickedButton] = useState(null);

  const handleClick = (buttonIndex) => {
    setClickedButton(buttonIndex);
  };

  const cancelConfirmPopHandler = () => {
    dispatch(confirmPopUpChange(false));
    setIsOpen(!isOpen);
  };

  const okConfirmPopHandler = () => {
    setIsOpen(!isOpen);
    const { pickId, matchId, prediction, pot, team, userAction } =
      confrimPotDetails.selectedDetail;
    if (pickId && matchId && prediction && pot && team && userAction) {
      if (userAction === "create") {
        dispatch(
          postTeamForPickMyPot(`/pot/selectpicks`, {
            pick: pickId,
            fixture: matchId,
            prediction: prediction,
            pot: pot,
            team: team === "home" ? "hometeam" : "awayteam",
          })
        );
      } else {
        dispatch(
          putTeamForPickMyPot(`/pot/selectpicks/${pickId}`, {
            pick: pickId,
            fixture: matchId,
            prediction: prediction,
            pot: pot,
            team: team === "home" ? "hometeam" : "awayteam",
          })
        );
      }
    } else {
      dispatch(confirmPopUpChange(false));
    }
  };

  return (
    <div style={{ position: "sticky", zIndex: 20 }}>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center">
          <div className="absolute inset-0 bg-gray-800 opacity-75 p-8"></div>
          <div
            className="relative mobile-s:w-[90%] mobile:w-[95%] tablet:w-[75%] 
    laptop-s:w-[35%] laptop-l:w-[25%]
    desktop:w-[25%] bg-white p-8 shadow-lg rounded-[8px]"
          >
            <button
              className="absolute top-0 p-4 right-0 mt-2 mb-2 mr-2 text-gray-600 hover:text-gray-800"
              onClick={togglePopup}
            >
              <img src={modelX} />
            </button>
            <div className="flex flex-col items-center">
              <p className="font-semibold text-[20px] text-center">
                Do you want change your team?
              </p>
              <div className="flex justify-center gap-2">
                <Button
                  className="bg-[#0000001A] font-bold focus:outline-none hover:border-blue-500 
                  text-gray-500 rounded-[8px] py-3 px-10 
                  focus:shadow-outline mt-6 mb-2 border"
                  type="button"
                  onClick={okConfirmPopHandler}
                >
                  Ok
                </Button>
                <Button
                  className={`sm:w-1/2 rounded-[8px] py-3 px-6 font-bold focus:outline-none focus:shadow-outline mt-6 mb-2 border border-gray-300 hover:border-blue-500
                   text-gray-500 ${
                    clickedButton === 1 ? "bg-blue-100" : "bg-[#0000001A]"
                  }`}
                  type="submit"
                  onClick={cancelConfirmPopHandler}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ChangeTeamPopUp;
