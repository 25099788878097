import { createSlice } from "@reduxjs/toolkit";

const teamsSlice = createSlice({
  name: "teams",
  initialState: {
    loading:false,
    isBuyNewToken: false,
    selectTeamPot: [],
    potFixtures: [],
    selectedPickId: 0,
    selectedPickTeamDetail: {
      teamName: "",
      logoURL: "",
    },
    predictionHistory: [],
  },
  reducers: {
    fetchTeamStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchTeamSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    fetchPotFixturesSuccess: (state, action) => {
      state.loading = false;
      state.potFixtures = action.payload;
    },
    fetchTeamFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.confirmPopUp = false;
    },
    updateSelectedTeamPot: (state, action) => {
      state.selectTeamPot = action.payload.pot;
      state.selectedPickId = action.payload.pickId;
    },
    // updateSelectedPickTeam: (state, action) => {
    //   state.selectedPickTeamDetail.teamName = action.payload.team_name;
    //   state.selectedPickTeamDetail.logoURL = action.payload.logourl;
    // },
    fechSelectedPrevWeekTeamSucc: (state, action) => {
      state.predictionHistory = action.payload;
    },
    updatePrevWeekStatus: (state, action) => {
      // const f = JSON.parse(JSON.stringify(state.predictionHistory));
      state.predictionHistory.forEach((ele) => {
        if (ele.current_match_week === true) {
          ele.current_match_week = null;
        }
      });
    },
  },
});

export const {
  fetchTeamStart,
  updateSelectedTeamPot,
  fetchTeamSuccess,
  fetchTeamFailure,
  fetchPotFixturesSuccess,
  fechSelectedPrevWeekTeamSucc,
  updatePrevWeekStatus,
} = teamsSlice.actions;
export default teamsSlice.reducer;
