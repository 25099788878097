import React, { useState, useEffect } from "react";
import Button from "../../Elements/Button";
import { useDispatch, useSelector } from "react-redux";
import modelX from "../../assets/images/model-x.svg";
import { changeVotePopUpstatus } from "../JackPotViewDetail/jackpot.slice";
import { JackPotVoteNow } from "../../services/jackPotView/jackPotView";

import { useLocation } from "react-router-dom";

function VotePopUp() {
  const location = useLocation();
  const dispatch = useDispatch();

  const [confrimPotDetails, setPotConfrimPotDetails] = useState({
    isConfrim: "",
  });

  const [isVoted, setIsVoted] = useState(false);

  const confrimVote = useSelector((state) => {
    return {
      isConfrim: state.jackPot.isVoteNow,
    };
  });

  const altreadyVoted = useSelector((state) => state.jackPot.playerVote.isVote);

  const [isOpen, setIsOpen] = useState(confrimVote.isConfrim);

  useEffect(() => {
    setPotConfrimPotDetails((prev) => ({
      ...prev,
      isConfrim: confrimVote.isConfrim,
    }));
  }, [confrimVote.isConfrim]);

  useEffect(() => {
    setIsVoted(altreadyVoted);
  }, [altreadyVoted]);

  const togglePopup = () => {
    dispatch(dispatch(changeVotePopUpstatus(false)));
    setIsOpen(!isOpen);
  };

  const [clickedButton, setClickedButton] = useState(null);

  const handleClick = (buttonIndex) => {
    setClickedButton(buttonIndex);
  };

  const cancelHandler = (voteStatus) => {
    dispatch(
      JackPotVoteNow(`/pot/jackpotupdate/${Number(potId)}`, {
        pick_id: Number(pickId),
        vote: "no",
      })
    );
    dispatch(changeVotePopUpstatus(false));
  };

  // const [, , , , potId, pickId, seasonId, matchWeek] =
  //   window.location.href.split("/"); //

  const data = location.state;
  const { potId, pickId, seasonId, matchWeekId:matchWeek, potType } = data;

  const voteNowHandler = (voteStatus) => {
    dispatch(
      JackPotVoteNow(`/pot/jackpotupdate/${Number(potId)}`, {
        pick_id: Number(pickId),
        vote: "yes",
      })
    );
    dispatch(changeVotePopUpstatus(false));
  };
  return (
    <div>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center">
          <div className="absolute inset-0 bg-gray-800 opacity-75 p-8"></div>
          <div
            className="relative mobile-s:w-[90%] mobile:w-[95%] tablet:w-[75%] 
    laptop-s:w-[55%] laptop-l:w-[35%]
    desktop:w-[25%] bg-white p-8 shadow-lg rounded-[8px]"
          >
            <button
              className="absolute top-0 p-4 right-0 mt-2 mr-2 text-gray-600 hover:text-gray-800"
              onClick={togglePopup}
            >
              <img src={modelX} />
            </button>
            <div className="flex flex-col items-center">
              <p className="text-[#0000007A]">Voting for spliting the pot</p>
              <p className="font-semibold text-[24px] text-center">
                <span>Are you sure,</span> <div>You want to split the pot</div>
              </p>
              <div className="flex justify-center gap-2">
                <Button
                  className="bg-[#3688D5]
                  hover:bg-[#3688D5]
                   text-white rounded-[8px] py-3 px-10 font-bold focus:outline-none focus:shadow-outline mt-6 mb-2 border]"
                  type="button"
                  onClick={voteNowHandler}
                >
                  Yes
                </Button>
                <Button
                  className={`sm:w-1/2 rounded-[8px] py-3 px-10 font-bold 
    focus:outline-none focus:shadow-outline mt-6 mb-2 border
    border-gray-300 hover:border-[#DDDDDD]
    text-gray-500 ${clickedButton !== 1 ? "bg-[#DDDDDD]" : ""}`}
                  type="submit"
                  onClick={cancelHandler}
                >
                  No
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default VotePopUp;
