import { createSlice } from "@reduxjs/toolkit";

const jackPotSlice = createSlice({
  name: "jackPot",
  initialState: {
    loading: false,
    isVoteNow: false,
    isJackpotPopup: false,
    playerMatchPicks: [],
    otherPlayerPrediction: [],
    // jackPotOtherPlayerPrediction: [],
    playerVote: {},
    jackPotTabVoteNowPotInfo: {},
    error: "",
  },
  reducers: {
    fetchJackPotStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    myPicksOnJackPot: (state, action) => {
      state.playerMatchPicks = action.payload;
    },
    changeVotePopUpstatus: (state, action) => {
      state.isVoteNow = action.payload;
    },
    createVotePopUpstatus: (state, action) => {
      state.playerVote = action.payload;
    },
    mypotOtherPlayerPrediction: (state, action) => {
      state.otherPlayerPrediction = action.payload;
    },
    // jackpotOtherPlayerPrediction: (state, action) => {
    //   state.jackPotOtherPlayerPrediction = action.payload;
    // },
    jackpotVoteNowPopup: (state, action) => {
      state.isJackpotPopup = action.payload.voteNowPopup;
      //
      state.jackPotTabVoteNowPotInfo.potId = action.payload.potId;
      state.jackPotTabVoteNowPotInfo.pickId = action.payload.pickId;
    },
    fetchJackPotFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateWebSocketVotePopUpstatus: (state, action) => {
      let jackpotPlayerVoteDetails = JSON.parse(
        JSON.stringify(state.playerVote)
      );
      if (jackpotPlayerVoteDetails?.data?.voted?.length > 0) {
        jackpotPlayerVoteDetails?.data?.voted?.forEach((ele) => {
          const res = action.payload.fields.filter(
            (pick) => pick.pick_id === ele.pick_id
          );
          if (res.length > 0) {
            ele["vote"] = res[0].vote;
          }
        });
        state.playerVote = jackpotPlayerVoteDetails;
      }
    },
    updateJackpotVote: (state, action) => {
      const res = JSON.parse(JSON.stringify(state.playerVote));
      res.is_vote = action.payload.vote;
      state.playerVote = res;
    },
    // Redux reducer function handling WebSocket vote pop-up status
  },
});

export const {
  fetchJackPotStart,
  fetchJackPotFailure,
  changeVotePopUpstatus,
  myPicksOnJackPot,
  createVotePopUpstatus,
  mypotOtherPlayerPrediction,
  jackpotVoteNowPopup,
  updateWebSocketVotePopUpstatus,
  updateJackpotVote,
} = jackPotSlice.actions;
export default jackPotSlice.reducer;
