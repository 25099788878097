import React, { useEffect, useState } from "react";
import moment from "moment";

export function CountdownTimer({ targetDate }) {
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  useEffect(() => {
    const intervalId = setInterval(() => {
      const timeLeft = calculateTimeRemaining();
      setTimeRemaining(timeLeft);

      // Clear interval if countdown is over
      if (
        timeLeft.days <= 0 &&
        timeLeft.hours <= 0 &&
        timeLeft.minutes <= 0 &&
        timeLeft.seconds <= 0
      ) {
        clearInterval(intervalId);
      }
    }, 1000);

    // Cleanup interval when the component is unmounted or if `targetDate` changes
    return () => clearInterval(intervalId);
  }, [targetDate]);

  function calculateTimeRemaining() {
    const targetMoment = moment(targetDate);
    const currentTime = moment();
    const difference = moment.duration(targetMoment.diff(currentTime));
  
    const formatWithLeadingZero = (number) => number < 10 ? `0${number}` : number;
    const setToDoubleZeroIfNegative = (number) => number < 0 ? "00" : formatWithLeadingZero(number);
  
    return {
      days: setToDoubleZeroIfNegative(difference.days()),
      hours: setToDoubleZeroIfNegative(difference.hours()),
      minutes: setToDoubleZeroIfNegative(difference.minutes()),
      seconds: setToDoubleZeroIfNegative(difference.seconds()),
    };
  }


  return (
    <div>
      <div className="flex gap-2 items-center justify-center basis-1/4">
        <div className="flex flex-col items-center">
          <p className="text-[#00387A] laptop-s:text-[24px] tablet:text-[18px]  mobile-s:text-[15px] font-bebasNeue">
            {timeRemaining?.days?timeRemaining?.days:"00"}
          </p>
          <p className="text-[#0000005A] mt-[-5px] text-[16px] tablet:text-[14px] mobile-s:text-[12px] font-lato">
            Days
          </p>
        </div>
        <div className="flex flex-col items-center">
          <p className="text-[#00387A] laptop-s:text-[24px] tablet:text-[18px] mobile-s:text-[15px] font-bebasNeue">
            {timeRemaining?.hours ?timeRemaining?.hours: "00"}
          </p>
          <p className="text-[#0000005A] mt-[-5px] text-[16px] tablet:text-[14px] mobile-s:text-[12px] font-lato">
            Hours
          </p>
        </div>
        <div className="flex flex-col items-center">
          <p className="text-[#00387A] laptop-s:text-[24px] tablet:text-[18px] mobile-s:text-[15px] font-bebasNeue">
            {timeRemaining?.minutes?timeRemaining?.minutes:"00"}
          </p>
          <p className="text-[#0000005A] mt-[-5px] text-[16px] mobile-s:text-[12px] tablet:text-[14px] font-lato">
            Mins
          </p>
        </div>
        <div className="flex flex-col items-center">
          <p className="text-[#00387A] laptop-s:text-[24px] tablet:text-[18px] mobile-s:text-[15px] font-bebasNeue">
            {timeRemaining?.seconds?timeRemaining?.seconds:"00"}
          </p>
          <p className="text-[#0000005A] mt-[-5px] text-[16px] mobile-s:text-[12px] tablet:text-[14px] font-lato">
            Secs
          </p>
        </div>
      </div>
      {/* {convertDateTime ? <p>{convertDateTime}</p> : null} */}
    </div>
  );
}
