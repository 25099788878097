import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import useFormikValidation from "../../vallidation/useFormikValidation";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser, loginUser } from "../../services/backenApiCall";

import smsIcon from "../../assets/images/sms.png";
import lockIcon from "../../assets/images/lock.png";
import Verticalline from "../../assets/images/Vector.svg";

import Input from "../../Elements/Input";
import Button from "../../Elements/Button";
import { loginValidationSchema } from "../../vallidation/loginSchema";
import AuthLayout from "../AuthLayout/AuthLayout";
import { fetchUserFailure, updateErrorValues } from "./login.slice";

import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import icons

import { Flip, toast, ToastContainer } from "react-toastify";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [serverError, setServerError] = useState("");

  const [emailFocused, setEmailFocused] = useState(false);
  const [passwordFocused, setPasswordFocused] = useState(false);

  const userInfoError = useSelector((state) => {
    return state?.user?.error;
  });

  // useEffect(() => {
  //   setServerError(userInfoError);
  // }, [userInfoError]);

  useEffect(() => {
    if (userInfoError) {
      toast.error(userInfoError, {
        onClose: handleToastClose,
        autoClose: 2000,
      });
      // toast.success(succFromServer, {
      //   onClose: handleToastClose,
      //   autoClose: 2000,
      // });
    }
  }, [userInfoError]);

  const handleToastClose = () => {
    dispatch(updateErrorValues());
  };

  const handleSubmit = async (values) => {
    const { email, password } = values;
    let trimValues = {
      email: email.trim().toLowerCase(),
      password: password.trim(),
    };
    if (email && password) {
      const res = await dispatch(loginUser("/accounts/token/", trimValues));
      if (res?.is_verified === false) {
        localStorage.setItem("registerEmail", trimValues.email);
        localStorage.removeItem("countdownEndTime");
        navigate(`/verificationCode/activateAccount/${trimValues.email}`);
      } else {
        if (res?.username && !res?.isAdmin) {
          navigate("/dashboard");
        }
      }
    }
  };

  const formik = useFormikValidation(
    {
      email: "",
      password: "",
    },
    loginValidationSchema,
    handleSubmit
  );

  const handleFieldChange = () => {
    dispatch(fetchUserFailure(""));
    // setServerError("");
  };

  const [showPassword, setShowPassword] = useState(true); // State variable

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState); // Toggle visibility
  };

  const divRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setEmailFocused(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [divRef]);

  const divPassRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (divPassRef.current && !divPassRef.current.contains(event.target)) {
        setPasswordFocused(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [divPassRef]);

  return (
    <AuthLayout>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ zIndex: 9999 }}
        className="mobile-s:w-[70%] mobile-s:ml-[15%] tablet:w-[40%] laptop-s:w-[25%] mobile-s:text-[12px] mobile-m:!text-[14px] tablet:ml-[1%]"
      />
      <div
        className="flex flex-col justify-center items-center  bg-[#FFFFFF] shadow-md rounded-[12px]   m-auto mobile-s:h-[450px]  mobile-s:font-lato 
         mobile-s:w-[85%] tablet:h-[520px] tablet:w-[50%] mobile-s:mt-[20%] mobile-m:mt-[25%] 
      laptop-l:!mt-[5%] tablet:!mx-auto tablet:!my-auto 
      laptop-s:!mt-[7%] laptop-s:!mb-[2%]
       laptop-s:!w-[38%] laptop-m:w-[20%] laptop-min:w-[40%] laptop-l:!w-[28%] w-[480px]
       "
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
          }}
          className="w-[80%]"
        >
          <div>
            <h2 className="text-center text-[28px] font-[600] font-interFont mb-5">
              Login
            </h2>
            <p className="text-center text-[15px] text-[#000000] text-opacity-50 mb-6">
              Please login with your email
            </p>
          </div>
          {/* <p className="text-center text-[15px] text-red-500 font-semibold mb-6">
            {serverError}
          </p> */}
          <div
            ref={divRef}
            className={`flex border w-full p-3 h-[45px] rounded-[5px]
        mt-[10%] tablet:w-[100%] ${
          emailFocused
            ? "border border-1 solid border-[#3498db]"
            : "border-1 solid border-[#CACACA]"
        }`}
            onClick={() => {
              setEmailFocused(true);
            }}
          >
            <img
              className="h-[20px] mobile-s:mr-[5px] w-[20px]"
              alt="mail"
              src={smsIcon}
            />
            <img
              className="h-[20px] w-[20px] mobile-s:mr-[5px]"
              alt="vertical-line"
              src={Verticalline}
            />
            <Input
              placeholder="Email"
              className="text-gray-950 w-full text-[15px] outline-none"
              id="email"
              name="email"
              value={formik.values.email.toLowerCase()}
              onChange={(e) => {
                formik.handleChange(e);
                handleFieldChange(); // Clear server error on field change
              }}
              onBlur={formik.handleBlur}
              autocomplete="off" // Disable auto-suggestion
            />
          </div>
          {formik.touched.email && formik.errors.email && (
            <span className="error w-[80%] ml-3 mt-5 text-[#EF4444]">
              {formik.errors.email}
            </span>
          )}
          <div
            className={`flex w-full h-[45px] rounded-[5px] 
           mt-4 p-3 tablet:tablet:w-[100%] ${
             passwordFocused
               ? "border border-1 solid border-[#3498db]"
               : "border border-1 solid border-[#CACACA]"
           }`}
            ref={divPassRef}
            onClick={() => {
              setPasswordFocused(true);
            }}
          >
            <img
              className="h-[20px] w-[20px] mobile-s:mr-[5px]"
              alt="lock"
              src={lockIcon}
            />
            <img
              className="h-[20px] w-[20px] mobile-s:mr-[5px]"
              alt="vertical-line"
              src={Verticalline}
            />
            <Input
              type={`${showPassword ? "password" : "text"}`}
              className="text-gray-950 w-full text-[15px] outline-none"
              id="password"
              name="password"
              placeholder="Password"
              value={formik.values.password}
              onChange={(e) => {
                formik.handleChange(e);
                handleFieldChange(); // Clear server error on field change
              }}
              onBlur={formik.handleBlur}
              autocomplete="off" // Disable auto-suggestion
              onPaste={(e) => {
                e.preventDefault(); // Prevent pasting
              }}
              onSelectStart={(e) => e.preventDefault()} // Prevent text selection on start
            />

            <button
              type="button"
              className="" // Adding padding for easier click
              onClick={togglePasswordVisibility} // Toggle visibility on click
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          {formik.touched.password && formik.errors.password && (
            <span className="error w-[80%] mt-5 ml-3 text-[#EF4444]">
              {formik.errors.password}
            </span>
          )}
          <div className="flex justify-end">
            <a
              className="align-baseline mobile-s:text-[13px] mobile-s:font-[500] font-bold text-sm text-blue-500 hover:text-blue-800 mt-4 text-[
                13px]"
              href="/forgotPassword"
            >
              Forgot Password?
            </a>
          </div>
          <div className="">
            <Button
              className="bg-blue-500 hover:bg-blue-700 p-2 w-full py-3 text-white rounded focus:outline-none focus:shadow-outline mt-12 mb-4 font-semibold"
              type="submit"
            >
              Login
            </Button>
            <p className="text-center mobile-s:text-[12px] mobile-m:!text-[15px]">
              Don’t have an account?{" "}
              <a href="/signup" className="text-[#00A34D] underline">
                {" "}
                Sign Up
              </a>
            </p>
          </div>
        </form>
      </div>
    </AuthLayout>
  );
}

export default Login;
