import React, { useState, useEffect } from "react";
import Button from "../../Elements/Button";
import { useDispatch, useSelector } from "react-redux";
import { RotatingLines } from "react-loader-spinner";

function Loading() {
  const dispatch = useDispatch();
  const paymentLoading = useSelector((state)=>state.dashboard.isPaymentLoading)
  // const [isOpen, setIsOpen] = useState(true);

  return (
    <div>
      {paymentLoading && (
        <div className="fixed inset-0 flex items-center justify-center z-30">
          <div className="absolute inset-0 bg-gray-800 opacity-75 p-8"></div>
          <div
            className="relative mobile-s:w-[90%] mobile:w-[95%] tablet:w-[75%] 
    laptop-s:w-[55%] laptop-l:w-[35%]
    desktop:w-[25%]  p-8 shadow-lg rounded-[8px]"
          >
            <div className="flex justify-center">
              {" "}
              <RotatingLines
                visible={true}
                height="96"
                width="96"
                color="red"
                strokeWidth="5"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Loading;
