import React, { useState, useEffect } from "react";
import PlayerLayout from "../PlayerLayout/PlayerLayout";

import ActiveTeam from "../ViewDetails/ActiveTeam/ActiveTeam";
import TeamDetailHOC from "../TeamDetailHOC/TeamDetailHOC";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMyPickHistory,
  fetchMyPotInfo,
} from "../../services/myPicks/myPicks";
import { formatAsIndianCurrency } from "../../utils/currencyFormat";


const tableHeader = [
  {
    id: 1,
    cellName: "Round ID",
    value: "potId",
    with: "10%",
  },
  {
    id: 2,
    cellName: "League",
    value: "league",
    with: "10%",
  },
  {
    id: 3,
    cellName: "Last MW",
    value: "lastMw",
    with: "10%",
  },
  {
    id: 4,
    cellName: "Round Value",
    value: "potValue",
    with: "10%",
  },

  {
    id: 5,
    cellName: "Round Status",
    value: "potStatus",
    with: "10%",
  },
  {
    id: 6,
    cellName: "No. of Picks",
    value: "noOfPicks",
    with: "10%",
  },
  {
    id: 7,
    cellName: "My Status",
    value: "myStatus",
    with: "10%",
  },
];

const secondTableHeader = [
  {
    id: 1,
    cellName: "Match Week",
    value: "matchWeek",
    with: "25%",
  },
  {
    id: 2,
    cellName: "Match Selected",
    value: "matchSelected",
    with: "25%",
  },
  {
    id: 3,
    cellName: "My Pick",
    value: "matchPick",
    with: "25%",
  },
  {
    id: 4,
    cellName: "My Status",
    value: "matchStatus",
    with: "25%",
  },
];

function HistoryOfPicks({ isMobile, isLaptop }) {
  const dispatch = useDispatch();

  const [potInfo, setPotInfo] = useState([]);
  const [mobilePotInfo, setMobilePotInfo] = useState([]);

  const [prediction, setPrediction] = useState([]);
  const [mobilePrediction, setMobilePrediction] = useState([]);

  const activepredictions = useSelector((state) => {
    return {
      potInfo: state.myPicksHistory.potInfo,
      prediction: state.myPicksHistory.myPicksPrediction,
    };
  });

  useEffect(() => {
    if (Object.keys(activepredictions?.potInfo).length > 0) {
      const status = window.location.href.split("/")[6];
      let adminFee = (activepredictions?.potInfo.max_pot_value * activepredictions?.potInfo.admin_fee) / 100;
      let obj = {
        potId: activepredictions?.potInfo.id,
        league: activepredictions?.potInfo.league,
        lastMw: activepredictions?.potInfo?.match_weeks?.current_match_week,
        potValue: formatAsIndianCurrency(
          activepredictions?.potInfo.max_pot_value - adminFee,
          activepredictions?.potInfo.pot_currency
        ),
        potStatus: activepredictions?.potInfo.status,
        noOfPicks: activepredictions?.potInfo.joined_picks,
        myStatus: status,
      };
      let mobileObj = {
        potId: activepredictions?.potInfo.id,
        potValue: formatAsIndianCurrency(
          activepredictions?.potInfo.max_pot_value - adminFee,
          activepredictions?.potInfo.pot_currency
        ),
        myStatus: status,
      };
      setPotInfo([obj]);
      setMobilePotInfo([mobileObj]);
    }
    if (activepredictions.prediction.length > 0) {
      let history = [];
      let mobileHistory = [];
      activepredictions.prediction.forEach((ele) => {
        const homeTeamCode = ele.team_details.filter(
          (ele) => ele.team_type === "home"
        )[0].code;
        const awayTeamCode = ele.team_details.filter(
          (ele) => ele.team_type === "away"
        )[0].code;
        const myPickTeamCode = ele.team_details.filter(
          (ele) => ele.predicited === true
        )[0].code;

        let obj = {
          matchWeek: ele?.matchweek.split("-")[1].trim(),
          matchSelected: (
            <div className="flex gap-1 ml-[2%] w-[100%]">
              <div className="flex items-center gap-5 w-[40%]">
                <img src={ele.home_logo} className="h-10 w-10" />
                <p>{homeTeamCode}</p>
              </div>
              <div
                className="flex p-[1%] justify-center w-[20%] gap-5"
                // style={{ flex: "20%" }}
              >
                <div
                  className="flex tablet:!text-[10px] laptop-s:!text-[12px] laptop-l:!text-[15px] justify-center"
                  style={{ flex: "10%" }}
                >
                  <div className="flex p-2 laptop-l:!w-[130px] tablet-l:!w-[130px] bg-[#9491911a] justify-center items-center rounded-[4px]">
                    <p className="flex justify-center text-nowrap">
                      {ele.home_goal} | {ele.away_goal}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-1 w-[40%] justify-end">
                <div className="flex w-[80%] justify-end">
                  <p className="flex w-[50%] justify-end">{awayTeamCode}</p>
                </div>

                <div className="flex w-[30%] justify-center">
                  <img src={ele.away_logo} className="h-10 w-10" />
                </div>
              </div>
            </div>
          ),
          matchPick: (
            <div className={`flex items-center justify-center gap-5`}>
              <div
                className={`flex items-center gap-2 p-1 rounded-[5px] ${
                  ele.pick_type === "System" ? "bg-red-200" : null
                }`}
              >
                <img src={ele.logo} className={`h-10 w-10 `} />
                <p>{myPickTeamCode}</p>
              </div>
            </div>
          ),
          status: (
            <div
              className={`rounded-[10px] w-[35%] ml-[35%] h-[22px] flex items-center gap-[10%] text-[12px]
               px-[6%] py-[6%] font-bold focus:outline-none focus:shadow-outline mt-[3%]
              ${
                ele?.pick_result === "Lost"
                  ? "bg-[#FF49560F] hover-[#f5ccda10]"
                  : ele?.pick_result === "Win"
                  ? "bg-[#00A34D0F] hover-[#00A34D10]"
                  : null
              }`}
              type="button"
            >
              <p
                className={`text-wh ml-[35%] ${
                  ele?.pick_result === "Lost"
                    ? "text-[#FF4956]"
                    : ele?.pick_result === "Win"
                    ? "text-[#00A34D]"
                    : null
                }`}
              >
                {ele?.pick_result ? ele.pick_result : "Open"}
              </p>
            </div>
          ),
        };
        history.push(obj);
      });

      activepredictions.prediction.forEach((ele) => {
        const myPickTeamCode = ele.team_details.filter(
          (ele) => ele.predicited === true
        )[0].code;
        let mobileObj = {
          matchWeek: ele?.matchweek.split("-")[1].trim(),
          matchPick: (
            <div
              className={`flex items-center justify-center gap-2 ml-[10%] p-1 rounded-[5px] ${
                ele.pick_type === "System" ? "bg-red-200" : null
              }`}
            >
              <img src={ele.logo} className="h-5 w-5" />
              <p>{myPickTeamCode}</p>
            </div>
          ),
          status: (
            <div
              className={`rounded-[10px] w-[35%] ml-[25%] h-[22px] flex items-center gap-[10%] text-[12px]
             px-[6%] py-[6%] font-bold focus:outline-none focus:shadow-outline mt-[3%]
            ${
              ele?.pick_result === "Lost"
                ? "bg-[#FF49560F] hover-[#f5ccda10]"
                : ele?.pick_result === "Win"
                ? "bg-[#00A34D0F] hover-[#00A34D10] w-[50px]"
                : null
            }`}
              type="button"
            >
              <p
                className={`text-wh ml-[35%] ${
                  ele?.pick_result === "Lost"
                    ? "text-[#FF4956]"
                    : ele?.pick_result === "Win"
                    ? "text-[#00A34D]"
                    : null
                }`}
              >
               {ele?.pick_result ? ele.pick_result : "Open"}
              </p>
            </div>
          ),
        };
        mobileHistory.push(mobileObj);
      });
      setMobilePrediction(mobileHistory);
      setPrediction(history);
    } else {
      setPrediction([]);
      setMobilePrediction([]);
    }
  }, [activepredictions.potInfo, activepredictions.prediction]);

  const potId = window.location.href.split("/")[4];
  const pickId = window.location.href.split("/")[5];

  useEffect(() => {
    fetchHistoryData();
  }, [potId, pickId]);

  async function fetchHistoryData() {
    const potId = window.location.href.split("/")[4];
    const pickId = window.location.href.split("/")[5];

    await dispatch(fetchMyPotInfo(`/pot/pots/${Number(potId)}`));

    await dispatch(
      fetchMyPickHistory(`/pot/activepredictions/${Number(pickId)}`)
    );
  }

  return (
    <PlayerLayout>
      <div className="mb-[3%]">
        <ActiveTeam
          isMobile={isMobile}
          isLabtop={isLaptop}
          tableHeader={tableHeader}
          tableBody={potInfo}
          secondTableHeader={secondTableHeader}
          secondTableBody={prediction}
          potInfoMobileTable={mobilePotInfo}
          historyMobileTable={mobilePrediction}
          component="history"
          potId={potId}
        />
      </div>
    </PlayerLayout>
  );
}

export default TeamDetailHOC(HistoryOfPicks);
