import moment from "moment";

export function checkMatchWeekTime(currentMatchWeek, deadLine) {
  let date;
  if (
    typeof currentMatchWeek === "string" ||
    currentMatchWeek instanceof String
  ) {
    date = new Date(currentMatchWeek);
  } else {
    date = currentMatchWeek;
  }

  if (!(date instanceof Date) || isNaN(date)) {
    throw new Error("Invalid date");
  }

  date.setTime(date.getTime() - deadLine * 60 * 60 * 1000);

  let options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  };

  let formattedDate = date.toLocaleString("en-US", options);
  let isFuture = checkMatchTime(formattedDate);
  return isFuture;
}

function checkMatchTime(formattedDate) {
  let givenDateTime = moment(
    formattedDate,
    "dddd, MMMM D, YYYY [at] hh:mm:ss A"
  );
  let currentDateTime = moment();
  let isFuture = givenDateTime.isAfter(currentDateTime);
  return isFuture;
}
