import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute.js";
import { Navigate } from "react-router-dom";

import {
  Login,
  Dashboard,
  Home,
  Signup,
  ForgotPassword,
  VerificationCode,
  ResetPassword,
  Verification,
  LeaguePickTeam,
  JackPotViewDetail,
  Withdraw,
  PaymentOtpVerification,
  VerificationLoading
} from "./RouteImports";

import AuthRouter from "./AuthRouter.js";
import LoginRouter from "./LoginRouter.jsx";
import MyPicks from "../Components/MyPicks/MyPicks.jsx";
import HistoryOfPicks from "../Components/HistoryOfPicks/HistoryOfPicks.jsx";
import MyPurchases from "../Components/myPurchases/myPurchases.jsx";
import MyWinnings from "../Components/MyWinnings/MyWinnings.jsx";
import Profile from "../Components/Profile/Profile.jsx";
import TermsAndConditions from "../Components/Terms&Conditions/Terms&Conditions.jsx";

import PrivacyPolicy from "../Components/PrivacyPolicy/PrivacyPolicy.jsx";
import Rules from "../Components/Rules/Rules.jsx";
import ContactUs from "../Components/ContactUs/ContactUs.jsx";
import TransactionHistory from "../Components/TransactionHistory/TransactionHistory.jsx";



function MainRouter(isAuthenticated) {
  const isAuthUser = localStorage.getItem("isAuthUser");
  return (
    <div>
      <Router>
        <Routes>
          <Route
            path="/"
            element={<AuthRouter isAuthenticated={isAuthenticated} />}
          />
          <Route
            path="/login"
            element={
              <LoginRouter element={Login} isAuthenticated={isAuthenticated} />
            }
          />
          <Route
            path="/signup"
            element={
              <LoginRouter element={Signup} isAuthenticated={isAuthenticated} />
            }
          />

          <Route
            path="/verificationCode/:pageName/:email"
            element={<VerificationCode />}
          />

          <Route path="/resetPassword" element={<ResetPassword />} />
          <Route path="/verification" element={<Verification />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/home" element={<Home />} />
          <Route path="/termsAndConditions" element={<TermsAndConditions />} />
          <Route path="/contactUs" element={<ContactUs />} />
          <Route path="/rules" element={<Rules />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute
                element={Dashboard}
                isAuthenticated={isAuthenticated}
              />
            }
          />
          <Route
            path="/myRoundView"
            element={
              <PrivateRoute
                element={LeaguePickTeam}
                isAuthenticated={isAuthenticated}
              />
            }
          />
          <Route
            // path="/jackpotView"
            // path="/prizePollView/:potId/:pickId/:seasonId"
            path="/prizePollView"
            element={
              <PrivateRoute
                element={JackPotViewDetail}
                isAuthenticated={isAuthenticated}
              />
            }
          />
          <Route
            path="/myPicks"
            element={
              <PrivateRoute
                element={MyPicks}
                isAuthenticated={isAuthenticated}
              />
            }
          />
          <Route
            path="/myHistories/:potId/:pickId/:status"
            element={
              <PrivateRoute
                element={HistoryOfPicks}
                isAuthenticated={isAuthenticated}
              />
            }
          />
          <Route
            path="/historyPicks"
            element={
              <PrivateRoute
                element={HistoryOfPicks}
                isAuthenticated={isAuthenticated}
              />
            }
          />
          <Route
            path="/myWinnings"
            element={
              <PrivateRoute
                element={MyWinnings}
                isAuthenticated={isAuthenticated}
              />
            }
          />
          <Route
            path="/widthdrawHistory"
            element={
              <PrivateRoute
                element={TransactionHistory}
                isAuthenticated={isAuthenticated}
              />
            }
          />
          <Route
            path="/withdraw"
            element={
              <PrivateRoute
                element={Withdraw}
                isAuthenticated={isAuthenticated}
              />
            }
          />
          <Route
            path="/paymentOtpVerification"
            element={
              <PrivateRoute
                element={PaymentOtpVerification}
                isAuthenticated={isAuthenticated}
              />
            }
          />
           <Route
            path="/verificationLoading"
            element={
              <PrivateRoute
                element={VerificationLoading}
                isAuthenticated={isAuthenticated}
              />
            }
          />
          <Route
            path="/myPurchases"
            element={
              <PrivateRoute
                element={MyPurchases}
                isAuthenticated={isAuthenticated}
              />
            }
          />
          <Route
            path="/profile"
            element={
              <PrivateRoute
                element={Profile}
                isAuthenticated={isAuthenticated}
              />
            }
          />
          <Route
            path="*"
            element={
              isAuthUser === "true" ? (
                <Navigate to="/dashboard" replace />
              ) : (
                <Navigate to="/home" replace />
              )
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default MainRouter;
