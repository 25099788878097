import React, { useState, useEffect } from "react";
import Button from "../../Elements/Button";
import { useDispatch, useSelector } from "react-redux";
import modelX from "../../assets/images/model-x.svg";
import money from "../../assets/images/moneys.png";
import {
  closeNewPot,
  confrimPopUp,
  newPotJoinNow,
  newTokenBuy,
  selectedPicks,
} from "../GreenDashboard/dashboard.slice";

import securityIcon from "../../assets/images/security-model.png";
import { joinNewPotDashboard } from "../../services/backenApiCall";


function JoinPotModel() {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(true);
  const [joinToken, setJoinToken] = useState({
    needTokenNewPick: 0,
    totalTokenUser: 0,
    joinPotDetails: {},
  });

  const token = useSelector((state) => {
    return {
      needToken: state?.dashboard?.joinPotDetail?.needToken || 0,
      totalToken:
        state?.dashboard?.data?.user?.available_tokens +
          state?.dashboard?.data?.user?.won_tokens || 0,
      joinPot: {
        potId: state?.dashboard?.joinPotDetail,
        userId: state?.dashboard?.data?.user?.id,
      },
    };
  });

  useEffect(() => {
    setJoinToken((prev) => ({
      ...prev,
      needTokenNewPick: token?.needToken,
      totalTokenUser: token?.totalToken,
      joinPotDetails: token?.joinPot,
    }));
  }, [
    token.needToken,
    token.totalToken,
    token.joinPot.userId,
    token.joinPot.potId,
  ]);

  const togglePopup = () => {
    dispatch(closeNewPot(!isOpen));
    setIsOpen(!isOpen);
  };

  const joinPotHandler = () => {
    dispatch(closeNewPot({ closeNewPot: false }));
    dispatch(
      joinNewPotDashboard("/pot/joinpot", {
        pot: joinToken?.joinPotDetails?.potId.potId,
        player: joinToken?.joinPotDetails?.userId,
        number_of_picks: joinToken?.joinPotDetails.potId?.numOfPick,
        default: 1,
      })
    );
  };

  const buyTokenHandler = () => {
    dispatch(closeNewPot(false));
    dispatch(newTokenBuy(true));
  };

  const cancelConfirmPopHandler = () => {
    dispatch(closeNewPot({ closeNewPot: false }));
  };

  return (
    <div>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center">
          <div className="absolute inset-0 bg-gray-800 opacity-75 p-8"></div>
          <div
            className="relative mobile-s:w-[90%] mobile:w-[95%] tablet:w-[75%] 
      laptop-s:w-[55%] laptop-l:w-[35%]
      desktop:w-[25%] bg-white p-8 shadow-lg rounded-[8px]"
          >
            <button
              className="absolute top-0 p-4 right-0 mt-2 mr-2 text-gray-600 hover:text-gray-800"
              onClick={togglePopup}
            >
              <img src={modelX} />
            </button>
            <div className="flex flex-col items-center">
              <p className="font-semibold text-[24px] text-center">
                To join this pot, you need {joinToken?.needTokenNewPick} tokens
              </p>
              {joinToken?.totalTokenUser >= joinToken?.needTokenNewPick ? (
                <div className="flex justify-center gap-2">
                  <Button
                    className={`sm:w-1/2 rounded-[8px] py-3 px-6 font-bold focus:outline-none focus:shadow-outline mt-6 mb-2 border border-gray-300 hover:border-blue-500 text-gray-500 `}
                    type="submit"
                    onClick={cancelConfirmPopHandler}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="bg-green-500 hover:bg-green-700 
                   text-white rounded-[8px] py-3 px-6 font-bold focus:outline-none focus:shadow-outline mt-6 mb-2 border]"
                    type="button"
                    onClick={joinPotHandler}
                  >
                    Redeem
                  </Button>
                </div>
              ) : (
                <Button
                  className="bg-[#0AB159] flex items-center justify-center hover:bg-[#0AB159] rounded-[8px] w-[60%] py-3 px-6 text-white font-bold focus:outline-none focus:shadow-outline mt-12 mb-4"
                  type="submit"
                  onClick={buyTokenHandler}
                >
                  <span>Buy Tokens</span> <img src={money} className="ml-2" />
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default JoinPotModel;
