import { getData, postData, putData, deleteData } from "./api";
import {
  fetchUserStart,
  fetchUserSuccess,
  fetchUserFailure,
} from "../Components/Login/login.slice";

import {
  fetchDashboardStart,
  fetchDashboardSuccess,
  fetchDashboardFailure,
  joinNewPotSuccess,
} from "../Components/GreenDashboard/dashboard.slice";

import {
  fetchSingUserStart,
  postSingUserSuccess,
  fetchSingUserFailure,
} from "../Components/Signup/signup.slice";

import {
  fetchPlayLayOutStart,
  fetchUserTokenDetail,
  fetchPlayLayOutFailure,
} from "../Components/PlayerLayout/playerlayout.slice";

export const fetchUser = (URL, userId) => async (dispatch) => {
  try {
    dispatch(fetchUserStart());
    const response = await getData(URL);
    if (response?.data?.token) {
      localStorage.setItem("token", JSON.stringify(response.data.token));
      localStorage.setItem("isAuthUser", "true");
    }
    dispatch(fetchUserSuccess(response.data));
  } catch (error) {
    dispatch(fetchUserFailure(error.message));
  }
};

export const loginUser = (URL, values) => async (dispatch) => {
  try {
    const response = await postData(URL, values);
    if (response?.access && !response.isAdmin) {
      const userInfo = {
        email: values?.email,
        isAdmin: response.isAdmin,
        userName: response.username,
        userId: response.user_uuid,
        userLogedIn: true,
      };
      localStorage.setItem("token", JSON.stringify(response.access));
      localStorage.setItem("refresh", JSON.stringify(response.refresh));
      localStorage.setItem(
        "isAuthUser",
        JSON.stringify(response.access) ? true : false
      );
      localStorage.setItem("isAdminUser", response.isAdmin);
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
    }
    dispatch(fetchUserSuccess(response));
    return response;
  } catch (error) {
    dispatch(
      fetchUserFailure(
        error?.response?.data?.detail
          ? error?.response?.data?.detail
          : error?.response?.data?.non_field_errors[0]
      )
    );
  }
};

export const fetchDashboardData = (URL, potType) => async (dispatch) => {
  try {
    dispatch(fetchDashboardStart());
    let response;
    if (potType === "newpot") {
      response = await getData(URL);
    } else {
      response = await getData("/dashboard/?type=activepot");
      let jackpotRes = await getData("/dashboard/?type=jackpot");

      let changeOrderArr = response.data.pots.reduce((acc, cru) => {
        if (cru.pick_id.length <= 1) {
          let sortOrderStatus =
            cru.pick_id[0].prediction.team == "NPY" ? "NPY" : "edited";
          cru.sortOrderStatus = sortOrderStatus;
          acc.push(cru);
        } else {
          cru.pick_id.forEach((ele) => {
            let newObj = { ...cru, pick_id: [ele] };
            if (ele.prediction.team === "NPY") {
              newObj.sortOrderStatus = "NPY";
            } else if (
              ele.status === "Active" &&
              ele.prediction.team !== "NPY"
            ) {
              newObj.sortOrderStatus = "edited";
            } else if (ele.status === "Win") {
              newObj.sortOrderStatus = "Win";
            } else if (ele.status === "Lost") {
              newObj.sortOrderStatus = "Lost";
            } else {
              newObj.sortOrderStatus = "Active";
            }
            acc.push(newObj);
          });
        }
        return acc;
      }, []);

      let mergedData = [...changeOrderArr, ...jackpotRes.data.pots];

      // Function to get sort priority
      const getSortPriority = (item) => {
        if (item.status === "JackPot") {
          return 1;
        } else if (item.sortOrderStatus === "NPY") {
          return 2;
        } else if (
          item.sortOrderStatus === "edited" &&
          item.status !== "Completed"
        ) {
          return 3;
        } else if (item.sortOrderStatus === "Win") {
          return 4;
        } else {
          return 5;
        }
      };
      mergedData.sort((a, b) => {
        const priorityA = getSortPriority(a);
        const priorityB = getSortPriority(b);

        if (priorityA !== priorityB) {
          return priorityA - priorityB;
        } else {
          // If priorities are equal, secondary sort based on status
          if (a.sortOrderStatus === "NPY" && b.sortOrderStatus !== "NPY") {
            return -1;
          } else if (
            a.sortOrderStatus !== "NPY" &&
            b.sortOrderStatus === "NPY"
          ) {
            return 1;
          } else {
            return 0;
          }
        }
      });

      response.data.pots = mergedData;
    }

    if (response.status === "Success") {
      let userInfo = {
        firstName: response.data.user.first_name,
        lastName: response.data.user.last_name,
        mobileNumber: response.data.user.mobile_number,
        userName: response.data.user.username,
        email: response.data.user.email,
        initialToken: response.data.user.available_tokens,
      };
      localStorage.setItem("userProfileInfo", JSON.stringify(userInfo));
      response.data.potType = potType;
      dispatch(fetchDashboardSuccess(response.data));
    }
  } catch (error) {
    dispatch(fetchDashboardFailure("Connection timeout"));
  }
};

export const joinNewPotDashboard = (URL, potInfo) => async (dispatch) => {
  try {
    dispatch(fetchDashboardStart());
    const response = await postData(URL, potInfo);
    if (response.status === "Success") {
      response.data.potType = "activepot";
      dispatch(fetchDashboardSuccess(response.data));
    } else {
      dispatch(fetchDashboardFailure(response?.data.error[0]));
    }
  } catch (error) {
    dispatch(fetchDashboardFailure(error.message));
  }
};

export const signupNewUser = (URL, potInfo) => async (dispatch) => {
  try {
    dispatch(fetchSingUserStart());
    const response = await postData(URL, potInfo);
    if (response.status === "Success") {
      localStorage.setItem(
        "registerEmail",
        JSON.stringify(response?.data?.email)
      );
      dispatch(postSingUserSuccess(response.data));
    }
  } catch (error) {
    dispatch(
      fetchSingUserFailure(
        error?.response?.data?.data.errors ?? "User Not created Try again"
      )
    );
  }
};

export const fetchUserDetail = (URL, potInfo) => async (dispatch) => {
  try {
    dispatch(fetchPlayLayOutStart());
    const response = await getData(URL);
    if (Object.keys(response).length > 0) {
      dispatch(fetchUserTokenDetail(response));
    } else {
      dispatch(fetchPlayLayOutFailure(response));
    }
  } catch (error) {
    dispatch(fetchPlayLayOutFailure(error.message));
  }
};
