import React, { useState, useEffect } from "react";
import Button from "../../Elements/Button";
import { useDispatch, useSelector } from "react-redux";
import modelX from "../../assets/images/model-x.svg";
import money from "../../assets/images/moneys.png";
import { confrimPopUp, newTokenBuy, popupClose } from "../GreenDashboard/dashboard.slice";
import { joinNewPotDashboard } from "../../services/backenApiCall";
import { buyTocken } from "../../services/dashboard/token";


function ConfrimPopUp() {
  const dispatch = useDispatch();
  const [isBtndisabled, setIsBtndisabled] = useState(false)

  const [confrimPotDetails, setPotConfrimPotDetails] = useState({
    isConfrim: "",
    totalTokenToBuy: 0,
  });

  const confrimPotPopDetail = useSelector((state) => {
    return {
      isConfrim: state.dashboard.confirmPopUp,
      totalToken: state.dashboard.selectedTokenToBuy,
    };
  });
  const [isOpen, setIsOpen] = useState(confrimPotPopDetail.isConfrim);

  useEffect(() => {
    setPotConfrimPotDetails((prev) => ({
      ...prev,
      isConfrim: confrimPotPopDetail.isConfrim,
      totalTokenToBuy: confrimPotPopDetail.totalToken,
    }));
  }, [confrimPotPopDetail.isConfrim, confrimPotPopDetail.totalToken]);

  const togglePopup = () => {
    // dispatch(newTokenBuy(!isOpen));
    dispatch(confrimPopUp(false));
    setIsOpen(!isOpen);
  };

  const [clickedButton, setClickedButton] = useState(null);

  const handleClick = (buttonIndex) => {
    setClickedButton(buttonIndex);
  };

  const cancelConfirmPopHandler = () => {
    dispatch(confrimPopUp(false));
  };

  const okConfirmPopHandler = () => {
    if (confrimPotPopDetail.totalToken) {
      setIsBtndisabled(true)
      dispatch(popupClose())
      dispatch(
        buyTocken("/accounts/buytokens", {
          tokens: confrimPotPopDetail.totalToken,
        })
      );
      dispatch(newTokenBuy(false))
    }
  };

  return (
    <div>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-30">
          <div className="absolute inset-0 bg-gray-800 opacity-75 p-8"></div>
          <div
            className="relative mobile-s:w-[90%] mobile:w-[95%] tablet:w-[75%] 
    laptop-s:w-[55%] laptop-l:w-[35%]
    desktop:w-[25%] bg-white p-8 shadow-lg rounded-[8px]"
          >
            <button
              className="absolute top-0 p-4 right-0 mt-2 mr-2 text-gray-600 hover:text-gray-800"
              onClick={togglePopup}
            >
              <img src={modelX} className=""/>
            </button>
            <div className="flex flex-col items-center mobile-s:mt-4 tablet:mt-2">
              <p className="font-semibold text-[24px] text-center">
                Are you sure you want buy{" "}
                {confrimPotDetails?.joinPotDetail?.numOfPick} Tokens?
              </p>
              <div className="flex justify-center gap-2">
                <Button
                  className={`sm:w-1/2 rounded-[8px] py-3 px-6 font-bold focus:outline-none focus:shadow-outline mt-6 mb-2 border border-gray-300 hover:border-blue-500 text-gray-500 ${
                    clickedButton === 1 ? "bg-blue-100" : ""
                  }`}
                  type="submit"
                  onClick={cancelConfirmPopHandler}
                >
                  Cancel
                </Button>
                <Button
                  className="bg-green-500 hover:bg-green-700 
                   text-white rounded-[8px] py-3 px-6 font-bold focus:outline-none focus:shadow-outline mt-6 mb-2 border]"
                  type="button"
                  onClick={okConfirmPopHandler}
                  disabled={isBtndisabled}
                >
                  Ok
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ConfrimPopUp;
