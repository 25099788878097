import { createSlice } from "@reduxjs/toolkit";

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    isBuyNewToken: false,
    choosePick: {
      choosePick: false,
      pickBtnType: "",
    },
    potType: "newpot",
    data: [],
    loading: false,
    error: null,
    succMsg: null,
    selectPick: false,
    selectedPotId: 0,
    joinPotDetail: {
      checkAndBuyToken: false,
      needToken: 0,
      potId: "",
      numOfPick: "",
    },
    addNewTokenToBuy: 0,
    confirmPopUp: false,
    isPaymentLoading: false,
    user: {},
  },
  reducers: {
    newTokenBuy: (state, action) => {
      state.isBuyNewToken = action.payload;
    },
    chooseNewPick: (state, action) => {
      state.choosePick.choosePick = action.payload.choosePick;
      state.choosePick.pickBtnType = action.payload.pickBtnType;
    },
    fetchDashboardStart: (state) => {
      state.loading = true;
      state.error = null;
      state.succMsg = null;
    },
    fetchDashboardSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.succMsg = null;
      state.data = action.payload;
      state.user = action?.payload?.user;
      state.potType = action.payload.potType;
      state.confirmPopUp = false;
    },
    fetchDashboardFailure: (state, action) => {
      state.loading = false;
      state.isPaymentLoading = false;
      state.error = action.payload;
      state.confirmPopUp = false;
    },
    selectedPicks: (state, action) => {
      state.loading = false;
      state.selectPick = action.payload.selectPick;
      state.selectedPotId = action.payload.potId;
    },
    newPotJoinNow: (state, action) => {
      state.joinPotDetail.needToken = action.payload.needTockenForNewPick;
      state.joinPotDetail.potId = action.payload.potId;
      state.joinPotDetail.numOfPick = action.payload.numOfPick;
      state.joinPotDetail.checkAndBuyToken = action.payload.checkAndBuyToken;
      state.selectPick = action.payload.selectPick;
    },
    closeNewPot: (state, action) => {
      state.joinPotDetail.checkAndBuyToken = action.payload.closeNewPot;
      state.confirmPopUp = action.payload.confrimPopUp;
    },
    confrimPopUp: (state, action) => {
      state.confirmPopUp = action.payload.popup;
      state.selectedTokenToBuy = action.payload.selectedTokenToBuy;
    },
    buyNewTokenUsingPayment: (state, action) => {
      state.confirmPopUp = false;
      state.isBuyNewToken = false;
      state.data.user.available_tokens =
        state.data.user.available_tokens + action.payload.add_token;
      state.addNewTokenToBuy = action.payload.add_token;
    },
    // update picked team details on "mypot"
    updateSelectedPickTeam: (state, action) => {
      state.succMsg = action.payload.message;
      let dashboardData = JSON.parse(JSON.stringify(state.data, null, 2));
      dashboardData.pots.forEach((pot) => {
        if (pot.id === action.payload.fixtureInfo.pot) {
          pot.pick_id.forEach((pick) => {
            if (pick.pick_id === action.payload.fixtureInfo.pick) {
              let isPrediction = pick.hasOwnProperty("prediction");
              if (isPrediction) {
                pick["prediction"].team = action.payload.fixtureInfo.prediction;
                pick["prediction"].short_name = action?.payload?.short_name;
                pick["prediction"].logo = action?.payload?.logo;
              } else {
                pick["waitlist"].team = action.payload.fixtureInfo.prediction;
                pick["prediction"].short_name = action.payload.short_name;
                pick["prediction"].logo = action?.payload?.logo;
              }
            }
          });
        }
      });
      state.data = dashboardData;
    },
    jackpotVoteUpdated: (state, action) => {
      const newData = JSON.parse(JSON.stringify(state.data));
      newData.pots.forEach((pot) => {
        if (pot.pot === Number(action.payload.potId)) {
          pot.voted.forEach((vote) => {
            if (vote.pick_id === action.payload.pick_id) {
              vote.vote = action.payload.vote;
            }
          });
        }
      });
      state.data = newData;
    },
    potUpdateWithSocket: (state, action) => {
      const potDetails = JSON.parse(JSON.stringify(state.data));
      potDetails?.pots?.forEach((pot) => {
        if (pot.id === action?.payload?.fields?.id) {
          let { current_pot_value, currency_code, status, active_picks_count } =
            action?.payload?.fields;
          pot.current_pot_value = current_pot_value;
          pot.pot_currency = currency_code;
          pot.status = status;
          pot.active_picks_count = active_picks_count;
        }
      });
      state.data = potDetails;
    },
    updateErrorValues: (state, action) => {
      state.error = null;
      state.succMsg = null;
    },
    resetTab: (state, action) => {
      state.potType = "newpot";
    },
    popupClose: (state, action) => {
      state.confirmPopUp = false;
      state.isPaymentLoading = true;
    },
    popupClosed: (state, action) => {
      state.isPaymentLoading = false;
      window.location.href = action?.payload?.url;
    },
  },
});

export const {
  initialToken,
  fetchDashboardStart,
  fetchDashboardSuccess,
  fetchDashboardFailure,
  newTokenBuy,
  chooseNewPick,
  joinNewPotSuccess,
  selectedPicks,
  newPotJoinNow,
  closeNewPot,
  confrimPopUp,
  buyNewTokenUsingPayment,
  updateSelectedPickTeam,
  jackpotVoteUpdated,
  potUpdateWithSocket,
  updateErrorValues,
  resetTab,
  popupClose,
  popupClosed,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
