import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.svg";
import { useState, useEffect } from "react";
import Footer, { FooterTab, MobileFooter } from "./Footer";
import winningImg from "../../assets/card/home-wining.png";
import useFindDevice from "../CustomHooks/FindDevice";
import { postSingUserSuccess } from "../Signup/signup.slice";
import { useDispatch } from "react-redux";
import "./home.css";

// tab-bg

let teamUrls = [
  {
    id: 1,
    teamName: "Bayern munich",
    url: "https://media.api-sports.io/football/teams/157.png",
  },
  {
    id: 10,
    teamName: "Juventus",
    url: "https://media.api-sports.io/football/teams/496.png",
  },
  {
    id: 2,
    teamName: "Manchester city",
    url: "https://media.api-sports.io/football/teams/50.png",
  },
  {
    id: 3,
    teamName: "Chelsea",
    url: "https://media.api-sports.io/football/teams/49.png",
  },
  {
    id: 4,
    teamName: "Barcelona",
    url: "https://media.api-sports.io/football/teams/529.png",
  },
  {
    id: 5,
    teamName: "Real Madrid",
    url: "https://media.api-sports.io/football/teams/541.png",
  },
  {
    id: 6,
    teamName: "Liverpool",
    // url: "https://media.api-sports.io/football/teams/40.png",
    url: "https://ssl.gstatic.com/onebox/media/sports/logos/nGfV05dipbAc7zzojivKew_64x64.png",
  },
  {
    id: 7,
    teamName: "Aresnal",
    url: "https://media.api-sports.io/football/teams/42.png",
  },
  {
    id: 8,
    teamName: "Athletico",
    url: "https://media.api-sports.io/football/teams/530.png",
  },
  {
    id: 9,
    teamName: "FC-Porto",
    url: "https://media.api-sports.io/football/teams/212.png",
  },
];

function GreenHome() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isMob, isTab, isLaptop, isLogedIn } = useFindDevice();

  const redirectToLogin = () => {
    navigate("/login");
  };

  const redirectToRegister = () => {
    dispatch(postSingUserSuccess(null));
    localStorage.removeItem("registerEmail");
    localStorage.removeItem("countdownExpiration");
    localStorage.removeItem("countdownEndTime");
    navigate("/signup");
  };

  const redirectHome = () => {
    if (isLogedIn) {
      navigate("/dashboard");
    } else {
      navigate("/home");
    }
  };

  return (
    <>
      {isMob === true ? (
        <div
          className={`overflow-auto font-interFont ${
            isMob
              ? "bg-[url('../public/card/home-screen-mobile-bg.svg')]"
              : "bg-[url('../public/card/home-bg.svg')]"
          } bg-cover bg-[#0c2310]`}
        >
          <div className="  w-full p-5 font-Inter">
            <div className="flex w-full p-3">
              <div className="w-[50%]">
                <img src={Logo} alt="pickNdPrizes" onClick={redirectHome}></img>
              </div>
              <div className={`w-[50%] ml-[10%] mt-[4%] flex float-right`}>
                {!isLogedIn ? (
                  <button
                    className=" border-[1px] border-[#00C05B] ml-2 rounded-[4px] w-[95px] h-[37px] text-[14px] cursor-pointer text-[#00C05B]"
                    onClick={redirectToRegister}
                  >
                    Register
                  </button>
                ) : null}

                <button
                  className={`w-[90px] h-[35px] text-[14px] font-[500] bg-[#00C05B] text-[#002427] ${
                    !isLogedIn ? "ml-5" : "ml-14"
                  } rounded-[4px]`}
                  onClick={redirectToLogin}
                >
                  {!isLogedIn ? "Login" : "Play now"}
                </button>
              </div>
            </div>

            <hr className="w-[95%] opacity-[.2] m-auto"></hr>

            <div className="flex items-center justify-center mt-8">
              <p className="text-[#FFFFFF] w-[90%] text-[32px] leading-[1.3] text-center font-semibold">
                Welcome to MoneyBall<br></br>Predict, Pick, Win !
              </p>
            </div>

            <div className="flex items-center justify-center mt-4">
              <p className="text-[#FFFFFF] w-[85%] text-[16px] font-[400] leading-[1.3] text-center opacity-[.7]">
                Ready to kick off the excitement? Log in and let the games
                begin!
              </p>
            </div>

            <div className="flex items-center justify-center mt-5 ml-5">
              {/* <img src={landingpageTeams} alt="teams"></img> */}
              {teamUrls.length > 0 &&
                teamUrls.map((team) => {
                  return (
                    <div className="flex items-center">
                      <img
                        src={team.url}
                        alt={team.teamName}
                        className={`px-2 ${
                          team.teamName === "Juventus"
                          // team.teamName === "Liverpool"
                            ? "w-[120px]"
                            : "w-[150px]"
                        }`}
                      />
                    </div>
                  );
                })}
            </div>

            <div className="flex items-center justify-center mt-2">
              <div className="border-dashed border-[1px] opacity-[.3] border-[#FFFFFF] w-[32%] mr-4 "></div>
              <p className="text-[18px] text-center text-[#FFFFFF] font-[600] w-[36%]">
                How to play
              </p>
              <div className="border-dashed border-[1px] opacity-[.3] border-[#FFFFFF] w-[32%] ml-4 "></div>
            </div>

            <div className="block mt-4 ml-5">
              <div className=" w-[95%] bg-[#FEFFF3] rounded-[8px] px-7 py-5 mb-4">
                <div className="w-[20%]">
                  <div
                    className="flex justify-center items-center h-[50px] w-[60px]  
                  bg-[url('../public/card/step-img-mb.svg')]"
                  >
                    <p className="text-[26px] font-semibold text-[#027D1D] font-bebasNeue">
                      01
                    </p>
                  </div>
                </div>
                <div className="mt-5">
                  <p className="text-[#027D1D] font-interFont font-bold text-[18px] items-center justify-center">
                    Login
                  </p>
                  <p className="text-[#000000]  text-[14px] font-[500]">
                    Register to create your profile & log in details.
                  </p>
                </div>
              </div>

              <div className="  w-[95%] bg-[#FEFFF3] rounded-[8px] px-7 py-5 ">
                <div className="w-[20%]">
                  <div
                    className="flex justify-center items-center h-[50px] w-[60px]  
                  bg-[url('../public/card/step-img-mb.svg')]"
                  >
                    <p className="text-[26px] font-semibold text-[#027D1D] font-bebasNeue">
                      02
                    </p>
                  </div>
                </div>
                <div className="mt-5">
                  <p className="text-[#027D1D] font-Inter font-bold text-[18px] items-center justify-center">
                    Choose to play the Round with 1 or 2 Picks
                  </p>

                  <p className="text-[#000000] font-interFont mt-2 text-[14px] font-[500]">
                    Decide if you are joining a New Round with 1 or 2 picks.
                    Each player can play a maximum of 2 picks per Round.
                  </p>
                </div>
              </div>
            </div>

            <div className="block mt-4  ml-5">
              <div className="  w-[95%] bg-[#FEFFF3] rounded-[8px] px-7 py-5 mb-4">
                <div className="w-[20%]">
                  <div
                    className="flex justify-center items-center h-[50px] w-[60px]  
                  bg-[url('../public/card/step-img-mb.svg')]"
                  >
                    <p className="text-[26px] font-semibold text-[#027D1D] font-bebasNeue">
                      03
                    </p>
                  </div>
                </div>
                <div className="mt-5">
                  <p className="text-[#027D1D] font-Inter font-bold text-[18px] items-center justify-center">
                    Buy or Redeem Token
                  </p>
                  <p className="text-[#000000] font-interFont mt-2 text-[14px] font-[500] w-[90%] ">
                    Tokens are needed to play in each Round. A min of 2 tokens
                    are required per Pick.
                  </p>
                </div>
              </div>

              <div className="  w-[95%] bg-[#FEFFF3] rounded-[8px] px-7 py-5 ">
                <div
                  className="flex justify-center items-center h-[50px] w-[60px]  
                  bg-[url('../public/card/step-img-mb.svg')]"
                >
                  <p className="text-[26px] font-semibold text-[#027D1D] font-bebasNeue">
                    04
                  </p>
                </div>
                <div className="mt-5">
                  <p className="text-[22px] mx-auto font-bold text-[#027D1D]">
                    Pick your Team
                  </p>
                  <p className="text-[#000000] font-interFont mt-2 text-[14px] font-[500] ">
                    Assign your picks to teams from the upcoming match week. You
                    progress if your team wins, if not you are eliminated.
                  </p>
                </div>
              </div>

              <div className="w-[95%] bg-[#FEFFF3] rounded-[8px] px-7 py-5 mt-5 ">
                <div
                  className="flex justify-center items-center h-[50px] w-[60px]  
                  bg-[url('../public/card/step-img-mb.svg')]"
                >
                  <p className="text-[26px] font-semibold text-[#027D1D] font-bebasNeue">
                    05
                  </p>
                </div>
                <div className="mt-5">
                  <p className="text-[22px] mx-auto font-bold text-[#027D1D]">
                    Stay Alive
                  </p>
                  <p className="text-[#000000] font-interFont mt-2 text-[14px] font-[500]">
                    While progressing in any Round, you cannot pick the same
                    teams from previous weeks.
                  </p>
                </div>
              </div>
              <div className="w-[95%] bg-[#FEFFF3] rounded-[8px] px-7 py-5 mt-5 ">
                <div
                  className="flex justify-center items-center h-[50px] w-[60px]  
                  bg-[url('../public/card/step-img-mb.svg')]"
                >
                  <p className="text-[26px] font-semibold text-[#027D1D] font-bebasNeue">
                    06
                  </p>
                </div>
                <div className="mt-5">
                  <p className="text-[22px] mx-auto font-bold text-[#027D1D]">
                    Win the Prize.
                  </p>
                  <p className="text-[#000000] font-interFont mt-2 text-[14px] font-[500]">
                    When there are 5 or fewer players, you will get a chance to
                    vote on splitting the jackpot, otherwise keep playing on to
                    be the last man standing.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <MobileFooter />
        </div>
      ) : isTab ? (
        <div className="overflow-auto bg-[url('../public/card/tab-bg.svg')]  bg-cover bg-[#0c2310]">
          <div className="h-[100vh] tablet:h-[1350px] w-full p-5 font-Inter">
            <div className="flex w-full px-3 pb-5 items-center font-interFont">
              <div className="w-[44%] ">
                <img src={Logo} alt="pickNdPrizes" onClick={redirectHome} />
              </div>
              <div className="w-[56%] justify-end mr-[1%] mt-[1%] flex float-right ">
                {!isLogedIn ? (
                  <>
                    {" "}
                    <p className="text-[#FFFFFF] mt-4">Not a member yet ?</p>
                    <p
                      className="text-[#00C05B] font-[18px] mt-4 ml-2 cursor-pointer"
                      onClick={redirectToRegister}
                    >
                      Register Now
                    </p>
                  </>
                ) : null}

                <button
                  className="w-[90px] h-[35px] text-[14px] font-[500] mt-2 bg-[#00C05B] text-[#002427] ml-5 rounded-[4px]"
                  onClick={redirectToLogin}
                >
                  {!isLogedIn ? "Login" : "Play now"}
                </button>
              </div>
            </div>

            <hr className="w-[95%] opacity-[.2] m-auto"></hr>

            <div className="flex items-center justify-center mt-8">
              <p className="text-[#FFFFFF] w-[60%] text-[40px] leading-[1] text-center">
                <span className="block font-interFont">
                  Welcome to MoneyBall
                </span>
                <span className="font-interFont">Predict, Pick, Win !</span>
              </p>
            </div>

            <div className="flex items-center justify-center mt-4">
              <p className="text-[#FFFFFF] font-[400] font-interFont leading-[1.3] text-center opacity-[.7] laptop-s:w-[85%] w-[90%] text-[18px]">
                Ready to kick off the excitement? Log in and let the games
                begin!
              </p>
            </div>

            <div className="flex items-center justify-center mt-5 ml-5">
              {teamUrls.length > 0 &&
                teamUrls.map((team) => {
                  return (
                    <div className="flex items-center">
                      <img
                        src={team.url}
                        alt={team.teamName}
                        className={`px-2 ${
                          team.teamName === "Juventus"
                            ? "w-[80px]"
                            // : team.teamName === "Liverpool"
                            // ? "w-[75px]"
                            : "w-[120px]"
                        }`}
                      />
                    </div>
                  );
                })}
            </div>

            <div className="flex items-center font-interFont justify-center mt-2">
              <div className="border-dashed border-[1px] opacity-[.3] border-[#FFFFFF] w-[35%] mr-4 "></div>
              <p className="text-[20px] text-[#FFFFFF] font-[600]">
                How to play
              </p>
              <div className="border-dashed border-[1px] opacity-[.3] border-[#FFFFFF] w-[35%] ml-4"></div>
            </div>

            <div className="flex mt-4 items-center justify-center">
              <div
                className="flex h-[135px] w-[698px] 
                bg-[url('../public/card/steps-card-tab.png')] items-center"
              >
                <div className="w-[20%]">
                  <div className="flex justify-center items-center h-[115px] ml-5 w-[140px]  bg-[url('../public/card/lading-page-role-step-bg.png')]">
                    <p className="text-[70px] font-semibold text-[#027D1D] font-bebasNeue">
                      01
                    </p>
                  </div>
                </div>
                <div className="ml-12">
                  <p className="text-[20px] font-interFont font-bold text-[#027D1D]">
                    Login
                  </p>
                  <p className="text-[#000000] font-interFont text-[18px] font-[500]">
                    Register to create your profile & log in details.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex mt-4 items-center justify-center">
              <div
                className="flex  h-[135px] w-[698px]
                bg-[url('../public/card/steps-card-tab.png')] items-center"
              >
                <div className="w-[20%]">
                  <div className="flex justify-center items-center h-[115px] ml-5 w-[140px]  bg-[url('../public/card/lading-page-role-step-bg.png')]">
                    <p className="text-[70px] font-semibold text-[#027D1D] font-bebasNeue">
                      02
                    </p>
                  </div>
                </div>
                <div className="ml-12">
                  <p className="text-[20px] font-interFont  font-bold text-[#027D1D]">
                    Choose to play the Round with 1 or 2 Picks
                  </p>
                  <p className="text-[#000000] font-interFont   text-[16px] font-[500]">
                    Decide if you are joining a New Round with 1 or 2 picks.
                    Each player can play a maximum of 2 picks per Round.
                  </p>
                </div>
              </div>
            </div>
            {/* </div> */}

            <div className="flex mt-4 items-center justify-center">
              <div
                className="flex  h-[135px] w-[698px] 
                bg-[url('../public/card/steps-card-tab.png')] items-center"
              >
                <div className="w-[28%]">
                  <div className="flex justify-center items-center h-[115px] ml-5 w-[140px]  bg-[url('../public/card/lading-page-role-step-bg.png')]">
                    <p className="text-[70px] font-semibold text-[#027D1D] font-bebasNeue">
                      03
                    </p>
                  </div>
                </div>

                <div className="text-[#000000] ml-6 font-[500] ">
                  <div>
                    <p className="text-[20px] font-interFont font-bold text-[#027D1D]">
                      Buy or Redeem Tokens
                    </p>
                    <p className="text-[16px] font-interFont">
                      Tokens are needed to play in each Round. A min of 2 tokens
                      are required per Pick.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex mt-4 items-center justify-center">
              <div
                className="flex h-[135px] w-[698px]
                bg-[url('../public/card/steps-card-tab.png')] items-center"
              >
                <div className="w-[24%]">
                  <div className="flex justify-center items-center h-[115px] ml-5 w-[140px]  bg-[url('../public/card/lading-page-role-step-bg.png')]">
                    <p className="text-[70px]  font-semibold text-[#027D1D] font-bebasNeue">
                      04
                    </p>
                  </div>
                </div>

                <div className="text-[#000000] font-interFont ml-6 mr-20 text-[16px] font-[500]">
                  <div>
                    <p className="text-[22px] mx-auto font-bold text-[#027D1D]">
                      Pick your Team
                    </p>
                    <p className="font-interFont">
                      Assign your picks to teams from the upcoming match week.
                      You progress if your team wins, if not you are eliminated.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex mt-4 items-center justify-center">
              <div
                className="flex h-[135px] w-[698px]
                bg-[url('../public/card/steps-card-tab.png')] items-center"
              >
                <div className="w-[24%]">
                  <div className="flex justify-center items-center h-[115px] ml-5 w-[140px]  bg-[url('../public/card/lading-page-role-step-bg.png')]">
                    <p className="text-[70px]  font-semibold text-[#027D1D] font-bebasNeue">
                      05
                    </p>
                  </div>
                </div>

                <div className="text-[#000000] font-interFont ml-6 mr-20 text-[16px] font-[500]">
                  <p className="text-[22px] mx-auto font-bold text-[#027D1D]">
                    Stay Alive
                  </p>
                  <div>
                    <p className="font-interFont">
                      While progressing in any Round, you cannot pick the same
                      teams from previous weeks.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex mt-4  items-center justify-center">
              <div
                className="flex h-[135px] w-[698px]
                bg-[url('../public/card/steps-card-tab.png')] items-center"
              >
                <div className="w-[33%]">
                  <div className="flex justify-center items-center h-[115px] ml-5 w-[140px]  bg-[url('../public/card/lading-page-role-step-bg.png')]">
                    <p className="text-[70px] font-semibold text-[#027D1D] font-bebasNeue">
                      06
                    </p>
                  </div>
                </div>
                <div className="text-[#000000] ml-6 mr-[2.7%] text-[16px] font-[500] ">
                  <div>
                    <div className="flex">
                      <div>
                        <p className="text-[22px] font-interFont mx-auto font-bold text-[#027D1D]">
                          Win the Prize
                        </p>
                        <p className="font-interFont">
                          When there are 5 or fewer players, you will get a
                          chance to vote on splitting the jackpot, otherwise
                          keep playing on to be the last man standing.
                        </p>
                      </div>
                      <img src={winningImg} className="h-15 w-15" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FooterTab />
        </div>
      ) : isLaptop ? (
        <div className="bg-[url('../public/card/home-bg.svg')] bg-cover w-full overflow-auto h-[100dvh] bg-[#0c2310]">
          <div className="p-5 font-Inter">
            <div className="flex w-full p-3">
              <div className="w-[75%] laptop-l:w-[60%] laptop-s:w-[48%] ml-7">
                <img
                  src={Logo}
                  alt="pickNdPrizes"
                  className="desktop:w-[250px] desktop:h-[150px]"
                  onClick={redirectHome}
                />
              </div>
              <div className="w-[25%] mr-[2%] justify-end desktop:ml-[30%] mt-[1%] flex float-right laptop-l:w-[40%] laptop-s:w-[52%]">
                {!isLogedIn ? (
                  <>
                    {window.innerWidth <= 500 ? (
                      ""
                    ) : (
                      <p className="text-[#FFFFFF] mt-2 desktop:text-[28px]">
                        Not a member yet ?
                      </p>
                    )}

                    {window.innerWidth <= 500 ? (
                      <button className=" border-[1px] desktop:text-[28px] border-[#00C05B] w-[95px] h-[37px] text-[14px] cursor-pointer text-[#00C05B]">
                        Register
                      </button>
                    ) : (
                      <p
                        className="text-[#00C05B] font-[18px] mt-2 ml-2 desktop:text-[28px] cursor-pointer"
                        onClick={redirectToRegister}
                      >
                        Register Now
                      </p>
                    )}
                  </>
                ) : null}
                <button
                  className="w-[90px] h-[35px] text-[18px] font-[500] desktop:w-[150px] desktop:h-[50px] desktop:text-[28px] bg-[#00C05B] text-[#002427] ml-5 rounded-[4px]"
                  onClick={redirectToLogin}
                >
                  {!isLogedIn ? "Login" : "Play now"}
                </button>
              </div>
            </div>

            <hr className="w-[95%] opacity-[.2] m-auto"></hr>

            <div className="flex items-center justify-center mt-8">
              <p
                className="text-[#FFFFFF] w-[50%] text-[48px] leading-[1.3] 
              text-center laptop-l:w-[60%] laptop-s:w-[85%] font-semibold desktop:text-[56px]"
              >
                Welcome to MoneyBall<br></br>Predict, Pick, Win !
              </p>
            </div>

            <div className="flex items-center justify-center mt-4">
              <p
                className="text-[#FFFFFF] w-[50%] text-[22px] font-[400]
               leading-[1.3] text-center opacity-[.7] laptop-s:w-[85%] desktop:text-[28px]"
              >
                Ready to kick off the excitement? Log in and let the games
                begin!
              </p>
            </div>

            <div className="flex items-center justify-center mt-5 ml-5">
              {teamUrls.length > 0 &&
                teamUrls.map((team) => {
                  return (
                    <div className="flex items-center">
                      <img
                        src={team.url}
                        alt={team.teamName}
                        className={`px-5 ${
                          team.teamName === "Juventus"
                            ? "laptop-s:w-[75px] laptop-l:w-[105px]"
                            // : team.teamName === "Liverpool"
                            // ? "laptop-s:w-[75px] laptop-l:w-[92px]"
                            : "laptop-s:w-[100px] laptop-l:!w-[135px]"
                        }`}
                      />
                    </div>
                  );
                })}
            </div>

            <div className="flex items-center justify-center mt-2">
              <div
                className="border-dashed border-[1px] opacity-[.3]
               border-[#FFFFFF] w-[19%] mr-4 laptop-s:w-[30%] "
              ></div>
              <p className="text-[22px] text-[#FFFFFF] font-[600] desktop:text-[28px]">
                How to play
              </p>
              <div className="border-dashed border-[1px] opacity-[.3] border-[#FFFFFF] w-[18%] ml-4 laptop-s:w-[30%] "></div>
            </div>

            {/* <div className="flex flex-col gap-5 mt-4 items-center justify-center"> */}
            <div className="flex mt-4 items-center justify-center">
              <div
                className="flex h-[135px] w-[1018px] 
              bg-[url('../public/card/lading-page-role-bg.svg')] items-center"
              >
                <div className="w-[20%]">
                  <div className="flex justify-center items-center h-[115px] ml-5 w-[140px]  bg-[url('../public/card/lading-page-role-step-bg.png')]">
                    <p className="text-[70px] font-semibold text-[#027D1D] font-bebasNeue">
                      01
                    </p>
                  </div>
                </div>
                <div className="ml-2">
                  <p className="text-[24px] font-bold text-[#027D1D]">Login</p>
                  <p className="text-[#000000] font-interFont text-[20px] font-[500]">
                    Register to create your profile & log in details.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex mt-4 items-center justify-center">
              <div
                className="flex h-[135px] w-[1018px] 
              bg-[url('../public/card/lading-page-role-bg.svg')] items-center"
              >
                <div className="w-[20%]">
                  <div className="flex justify-center items-center h-[115px] ml-5 w-[140px]  bg-[url('../public/card/lading-page-role-step-bg.png')]">
                    <p className="text-[70px] font-semibold text-[#027D1D] font-bebasNeue">
                      02
                    </p>
                  </div>
                </div>
                <div className="ml-12">
                  <p className="text-[24px] font-bold text-[#027D1D]">
                    Choose to play the Round with 1 or 2 Picks
                  </p>
                  <p className="text-[#000000] font-interFont text-[20px] font-[500]">
                    Decide if you are joining a New Round with 1 or 2 picks.
                    Each player can play a maximum of 2 picks per Round.
                  </p>
                </div>
              </div>
            </div>
            {/* </div> */}

            <div className="flex mt-4 items-center justify-center">
              <div
                className="flex h-[135px] w-[1018px] 
              bg-[url('../public/card/lading-page-role-bg.svg')] items-center"
              >
                <div className="w-[20%]">
                  <div className="flex justify-center items-center h-[115px] ml-5 w-[140px]  bg-[url('../public/card/lading-page-role-step-bg.png')]">
                    <p className="text-[70px] font-semibold text-[#027D1D] font-bebasNeue">
                      03
                    </p>
                  </div>
                </div>

                <div className="text-[#000000] mr-22 text-[14px] font-[500] ">
                  <div>
                    <p className="text-[24px] font-bold text-[#027D1D]">
                      Buy or Redeem Tokens
                    </p>
                    <p className="text-[20px] font-interFont">
                      Tokens are needed to play in each Round. A min of 2 tokens
                      are required per Pick.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex mt-4 items-center justify-center">
              <div
                className="flex h-[135px] w-[1018px] 
              bg-[url('../public/card/lading-page-role-bg.svg')] items-center"
              >
                <div className="w-[19%]">
                  <div className="flex justify-center items-center h-[115px] ml-5 w-[140px]  bg-[url('../public/card/lading-page-role-step-bg.png')]">
                    <p className="text-[70px] font-semibold text-[#027D1D] font-bebasNeue">
                      04
                    </p>
                  </div>
                </div>

                <div className="text-[#000000] ml-11 text-[1px] font-[500]">
                  <p className="text-[24px] mx-auto font-bold text-[#027D1D]">
                    Pick your Team
                  </p>
                  <p className="text-[#000000] font-interFont text-[20px] font-[500] ">
                    Assign your picks to teams from the upcoming match week. You
                    progress if your team wins, if not you are eliminated.
                  </p>
                </div>
              </div>
            </div>

            <div className="flex mt-4 items-center justify-center">
              <div
                className="flex h-[135px] w-[1018px] 
              bg-[url('../public/card/lading-page-role-bg.svg')] items-center"
              >
                {/* <div className="w-[28%]">
                  <div className="flex justify-center items-center h-[115px] ml-5 w-[140px]  bg-[url('../public/card/lading-page-role-step-bg.png')]">
                    <p className="text-[70px] font-semibold text-[#027D1D] font-bebasNeue">
                      05
                    </p>
                  </div>
                </div> */}
                <div className="w-[19%]">
                  <div className="flex justify-center items-center h-[115px] ml-5 w-[140px]  bg-[url('../public/card/lading-page-role-step-bg.png')]">
                    <p className="text-[70px] font-semibold text-[#027D1D] font-bebasNeue">
                      05
                    </p>
                  </div>
                </div>

                <div className="text-[#000000] ml-3 text-[1px] font-[500]">
                  <p className="text-[24px] mx-auto font-bold text-[#027D1D]">
                    Stay Alive
                  </p>
                  <p className="text-[#000000] font-interFont text-[20px] font-[500] ">
                    While progressing in any Round, you cannot pick the same
                    teams from previous weeks.
                  </p>
                </div>
              </div>
            </div>

            <div className="flex mt-4 items-center justify-center">
              <div
                className="flex h-[135px] w-[1018px] 
              bg-[url('../public/card/lading-page-role-bg.svg')] items-center"
              >
                <div className="w-[20%]">
                  <div className="flex justify-center items-center h-[115px] ml-5 w-[140px]  bg-[url('../public/card/lading-page-role-step-bg.png')]">
                    <p className="text-[70px] font-semibold text-[#027D1D] font-bebasNeue">
                      06
                    </p>
                  </div>
                </div>

                <div className="text-[#000000] ml-12 text-[18px] font-[500] ">
                  <div>
                    <div className="flex">
                      <div>
                        <p className="text-[24px] mx-auto font-bold text-[#027D1D]">
                          Win the Prize
                        </p>
                        <p className="text-[#000000] font-interFont text-[20px] font-[500]">
                          {" "}
                          When there are 5 or fewer players, you will get a
                          chance to vote on splitting the jackpot, otherwise
                          keep playing on to be the last man standing.
                        </p>
                      </div>
                      <img src={winningImg} className="h-15 w-15 mr-[2.8%]" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      ) : null}
    </>
  );
}

export default GreenHome;
