import { createSlice } from "@reduxjs/toolkit";
import { parse } from "postcss";

const myPickSlice = createSlice({
  name: "myPicks",
  initialState: {
    loading: false,
    myPicksInfo: [],
    myPicksMobils: [],
    pagination: {
      totalRecord: 0,
      pageSize: 10,
      currentPage: 1,
    },
    error: "",
  },
  reducers: {
    fetchMyPickStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchMypickInformation: (state, action) => {
      state.pagination.totalRecord = action.payload.count;
      state.myPicksInfo = [];
      state.myPicksInfo = [...state.myPicksInfo, ...action.payload.results];
      let concatmyPicks = [];
      let mobileData = JSON.parse(JSON.stringify(state.myPicksMobils));
      if (mobileData.length > 0) {
        concatmyPicks = [
          ...JSON.parse(JSON.stringify(state.myPicksMobils)),
          ...action.payload.results,
        ];
      } else {
        concatmyPicks = [...action.payload.results];
      }
      const res = concatmyPicks.reduce((acc, cru) => {
        if (!acc.some((ele) => ele.pick_id === cru.pick_id)) {
          acc.push(cru);
        }
        return acc;
      }, []);
      state.myPicksMobils = res;
    },
    updatePagination: (state, action) => {
      state.pagination.currentPage = action.payload.page;
    },
    fetchMyPickFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchMyPickStart,
  fetchMypickInformation,
  updatePagination,
  fetchMyPickFailure,
} = myPickSlice.actions;
export default myPickSlice.reducer;
