import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import PlayerLayout from "../../PlayerLayout/PlayerLayout";
import Input from "../../../Elements/Input";
import Button from "../../../Elements/Button";
import Card from "../../../Elements/Card";

import iIconPayment from "../../../assets/card/i-icon-payment.jpg";
import newBackArrow from "../../../assets/card/new-back-arrow.svg";

import paymentCup from "../../../assets/card/payment-cup.svg";

import ibanLogo from "../../../assets/card/iban-logo.jpg";
import { useSelect } from "@material-tailwind/react";
import useFindDevice from "../../CustomHooks/FindDevice";

import winingCupMobile from "../../../assets/card/wining-cup-mobile.svg";
import {
  fetchAccountDetail,
  withdrawOtpVerification,
} from "../../../services/payment/payment";
import { Flip, toast, ToastContainer } from "react-toastify";
import { updateWithdrawAmount } from "../payment.slice";

import CryptoJS from "crypto-js";

function encryptionBankInfo({
  accountNumber,
  bbanAccountName,
  bbanAddress,
  bankName,
  swiftCode,
  ibanNumber,
  address,
  accountName,
  ibanSwiftCode,
}) {
  let salt = process.env.REACT_APP_DEV_SOLT;
  let ivCode = process.env.REACT_APP_DEV_IVCODE;

  var derived_key = CryptoJS.enc.Base64.parse(salt);
  var iv = CryptoJS.enc.Utf8.parse(ivCode);

  var hashAccountNumber = CryptoJS.AES.encrypt(accountNumber, derived_key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
  }).toString();

  var hashBbanAccountName = CryptoJS.AES.encrypt(bbanAccountName, derived_key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
  }).toString();

  var hashSwiftCode = CryptoJS.AES.encrypt(swiftCode, derived_key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
  }).toString();

  var hashBbanAddress = CryptoJS.AES.encrypt(bbanAddress, derived_key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
  }).toString();

  var hashIbanNumber = CryptoJS.AES.encrypt(ibanNumber, derived_key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
  }).toString();

  var hashIbanAdress = CryptoJS.AES.encrypt(address, derived_key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
  }).toString();

  var hashAccountName = CryptoJS.AES.encrypt(accountName, derived_key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
  }).toString();

  var hashBankName = CryptoJS.AES.encrypt(bankName, derived_key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
  }).toString();

  var hashIbanSwiftCode = CryptoJS.AES.encrypt(ibanSwiftCode, derived_key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
  }).toString();

  return {
    hashAccountName,
    hashIbanAdress,
    hashIbanNumber,
    hashBbanAddress,
    hashSwiftCode,
    hashBankName,
    hashBbanAccountName,
    hashAccountNumber,
    hashIbanSwiftCode,
  };
}

function Withdraw() {
  const navigator = useNavigate();
  const dispatch = useDispatch();
  const [paymentType, setPaymentType] = useState("bban");
  const [isCreate, setIsCreate] = useState(true);
  const [accountCreateerror, setAccCreateError] = useState("");

  const winningAmount = useSelector(
    (state) => state.playerLayout.userTokenDetail
  );

  const profileSchema = Yup.object().shape({
    withdrawAmount: Yup.number()
      .required("Please enter a withdraw amount")
      .moreThan(0, "Withdraw amount must be greater than zero")
      .max(
        winningAmount.winningamount,
        `The withdrawal amount cannot exceed ${winningAmount.winningamount}`
      ),

    bban: Yup.object().shape({
      accountNumber: Yup.string().required("Please enter account number"),
      confirmAccountNumber: Yup.string()
        .oneOf([Yup.ref("accountNumber"), null], "Account number must match")
        .required("Please enter confirm account number"),
      swiftCode: Yup.string().required("Please enter swift code"),
      bankName: Yup.string().required("Please enter bankname"),
      accountName: Yup.string().required("Please enter account name"),
      address: Yup.string().required("Please enter address"),
    }),
    iban: Yup.object().shape({
      ibanNumber: Yup.string().required("Please enter iban number"),
      confirmIbanNumber: Yup.string()
        .oneOf([Yup.ref("ibanNumber"), null], "Account number must match")
        .required("Please enter confirm iban number"),
      ibanSwiftCode: Yup.string().required("Please enter swift code"),
      accountName: Yup.string().required("Please enter account name"),
      address: Yup.string().required("Please enter address"),
    }),
  });

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const formik = useFormik({
    initialValues: {
      withdrawAmount: "",
      totalInput: false,
      bban: {
        accountNumber: "",
        confirmAccountNumber: "",
        swiftCode: "",
        bankName: "",
        accountName: "",
        address: "",
      },
      iban: {
        ibanNumber: "",
        confirmIbanNumber: "",
        accountName: "",
        address: "",
        ibanSwiftCode: "",
      },
    },
    validationSchema: profileSchema,
    onSubmit: async (values) => {},
  });

  const handleRadioChange = (e) => {
    setPaymentType(e.target.value);
  };

  function paymentTypeRender() {
    if (paymentType === "iban") {
      return (
        <div className="iban-account-type">
          <div className="flex mobile-s:block tablet:w-full tablet:flex gap-4">
            <div className="flex flex-col gap-[5%] mobile-s:w-[100%] tablet:w-[45%]  tablet:h-[45px]  tablet:rounded-[5px] mobile-s:mt-[4%] tablet:mt-[4%] tablet:p-1">
              <div className="relative w-full">
                <input
                  type="text"
                  className="block px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-white border border-gray-300 dark:border-gray-600 focus:outline-none focus:ring-0 focus:border-blue-600 peer w-full rounded-[5px] input-focused"
                  placeholder="IBAN Number"
                  id="ibanNumber"
                  name="iban.ibanNumber"
                  autocomplete="off"
                  value={formik?.values?.iban?.ibanNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onPaste={(e) => e.preventDefault()} // Prevent default paste behavior
                  onSelectStart={(e) => e.preventDefault()} // Prevent text selection on start
                />
                <label
                  htmlFor="ibanNumber"
                  className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                >
                  IBAN Number
                </label>
              </div>
              {formik?.touched?.iban?.ibanNumber &&
                formik?.errors?.iban?.ibanNumber && (
                  <span className=" text-[#EF4444] tablet:text-[12px] laptop-s:!text-[14px] text-nowrap">
                    {formik?.errors?.iban?.ibanNumber}
                  </span>
                )}
            </div>

            <div className="flex flex-col gap-[5%] mb-1 mobile-s:w-[100%] tablet:w-[45%]  tablet:h-[45px]  tablet:rounded-[5px] mobile-s:mt-[4%] tablet:mt-[4%] tablet:p-1">
              <div className="relative w-full">
                <input
                  type="text"
                  className="block px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-white border border-gray-300 dark:border-gray-600 focus:outline-none focus:ring-0 focus:border-blue-600 peer w-full rounded-[5px] input-focused"
                  placeholder="Confirm IBAN Number"
                  id="confirmIbanNumber"
                  name="iban.confirmIbanNumber"
                  autocomplete="off"
                  value={formik?.values?.iban?.confirmIbanNumber}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  onPaste={(e) => e.preventDefault()} // Prevent default paste behavior
                  onSelectStart={(e) => e.preventDefault()} // Prevent text selection on start
                />
                <label
                  htmlFor="confirmIbanNumber"
                  className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                >
                  Confirm IBAN Number
                </label>
              </div>
              {formik?.touched?.iban?.confirmIbanNumber &&
                formik?.errors?.iban?.confirmIbanNumber && (
                  <span className=" text-[#EF4444] tablet:text-[12px] laptop-s:text-[14px] text-nowrap">
                    {formik?.errors?.iban?.confirmIbanNumber}
                  </span>
                )}
            </div>
          </div>
          <div className="flex mobile-s:block tablet:w-full tablet:mt-2  tablet:flex gap-4">
            <div className="flex flex-col gap-[5%]  mobile-s:w-[100%] tablet:w-[45%]  tablet:h-[45px]  tablet:rounded-[5px] mobile-s:mt-[4%] tablet:mt-[4%] tablet:p-1">
              <div className="relative w-full">
                <input
                  type="text"
                  id="accountName"
                  className="block px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-white border border-gray-300 dark:border-gray-600 focus:outline-none focus:ring-0 focus:border-blue-600 peer w-full rounded-[5px] input-focused"
                  placeholder="Account Name"
                  name="iban.accountName"
                  autocomplete="off"
                  maxlength={35}
                  value={formik?.values?.iban?.accountName}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                />
                <label
                  htmlFor="accountName"
                  className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                >
                  Account Name
                </label>
              </div>
              {formik?.touched?.iban?.accountName &&
                formik?.errors?.iban?.accountName && (
                  <span className=" text-[#EF4444] tablet:text-[12px] laptop-s:text-[14px] text-nowrap mt-1">
                    {formik.errors.iban.accountName}
                  </span>
                )}
            </div>
            <div className="flex mb-1 flex-col gap-[5%] mobile-s:w-[100%] tablet:w-[45%]  tablet:h-[45px] tablet:rounded-[5px] mobile-s:mt-[4%] tablet:mt-[4%] tablet:p-1">
              <div className="relative w-full">
                <input
                  type="text"
                  id="address"
                  className="block px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-white border border-gray-300 dark:border-gray-600 focus:outline-none focus:ring-0 focus:border-blue-600 peer w-full rounded-[5px] input-focused"
                  placeholder="Address"
                  name="iban.address"
                  autocomplete="off"
                  maxlength={110}
                  value={formik?.values?.iban?.address}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                />
                <label
                  htmlFor="address"
                  className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                >
                  Address
                </label>
              </div>
              {formik?.touched.iban?.address && formik.errors.iban?.address && (
                <span className=" text-[#EF4444] tablet:text-[12px] laptop-s:text-[14px] text-nowrap mt-2">
                  {formik?.errors?.iban?.address}
                </span>
              )}
            </div>
          </div>
          {formik?.values?.iban?.ibanNumber ? (
            <div className="flex mobile-s:block mt-4 tablet:w-full tablet:flex gap-4">
              <div className="flex flex-col gap-[5%] mobile-s:w-[100%] tablet:w-[45%]  tablet:h-[45px]  tablet:rounded-[5px] mobile-s:mt-[4%] tablet:mt-[4%] tablet:p-1">
                <div className="relative w-full">
                  <input
                    type="text"
                    id="ibanSwiftCode"
                    className="block px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-transparent border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer text-gray-[#00000099] w-full p-2 rounded-[5px] border border-solid text-[14px] outline-none input-focused"
                    placeholder="SWIFT Code"
                    name="iban.ibanSwiftCode"
                    autocomplete="off"
                    value={formik?.values?.iban?.ibanSwiftCode}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    onPaste={(e) => e.preventDefault()} // Prevent default paste behavior
                    onSelectStart={(e) => e.preventDefault()} // Prevent text selection on start
                  />
                  <label
                    htmlFor="ibanSwiftCode"
                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >
                    SWIFT Code
                  </label>
                </div>

                {formik.touched.iban?.ibanSwiftCode &&
                  formik.errors.iban?.ibanSwiftCode &&
                  !formik.values.iban?.ibanNumber
                    ?.toUpperCase()
                    .startsWith("AE") && (
                    <span className=" text-[#EF4444] tablet:text-[12px] laptop-s:!text-[14px] text-nowrap">
                      {formik.errors.iban.ibanSwiftCode}
                    </span>
                  )}
              </div>
            </div>
          ) : null}
        </div>
      );
    } else {
      return (
        <div className="bban-account-type">
          {isTab ? (
            <>
              <div className="flex mobile-s:block tablet:w-full tablet:flex gap-4">
                <div className="flex flex-col gap-[5%] mobile-s:w-[100%] tablet:w-[45%]  tablet:h-[45px]  tablet:rounded-[5px] mobile-s:mt-[4%] tablet:mt-[4%] tablet:p-1">
                  <div className="relative w-full">
                    <div className="relative w-full">
                      <input
                        type="text"
                        id="accountNumber"
                        className="block px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-transparent border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer text-gray-[#00000099] w-full p-2 rounded-[5px] border border-solid text-[14px] outline-none input-focused"
                        placeholder="Account Number"
                        name="bban.accountNumber"
                        autocomplete="off"
                        value={formik?.values?.bban?.accountNumber}
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                        onPaste={(e) => e.preventDefault()} // Prevent default paste behavior
                        onSelectStart={(e) => e.preventDefault()} // Prevent text selection on start
                      />
                      <label
                        htmlFor="accountNumber"
                        className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                      >
                        Account Number
                      </label>
                    </div>
                  </div>
                  {formik?.touched?.bban?.accountNumber &&
                    formik?.errors?.bban?.accountNumber && (
                      <span className=" text-[#EF4444] tablet:text-[12px] laptop-s:!text-[14px] text-nowrap">
                        {formik.errors.bban.accountNumber}
                      </span>
                    )}
                </div>
                <div className="flex flex-col  gap-[5%] mobile-s:w-[100%] tablet:w-[45%]  tablet:h-[45px]  tablet:rounded-[5px] mobile-s:mt-[4%] tablet:mt-[4%] tablet:p-1">
                  <div className="relative w-full">
                    <input
                      type="text"
                      id="confirmAccountNumber"
                      className="block px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-transparent border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer text-gray-[#00000099] w-full p-2 rounded-[5px] border border-solid text-[14px] outline-none input-focused"
                      placeholder="Confirm Number"
                      name="bban.confirmAccountNumber"
                      autocomplete="off"
                      value={formik?.values?.bban?.confirmAccountNumber}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      onPaste={(e) => e.preventDefault()} // Prevent default paste behavior
                      onSelectStart={(e) => e.preventDefault()} // Prevent text selection on start
                    />
                    <label
                      htmlFor="confirmAccountNumber"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      Confirm Number
                    </label>
                  </div>

                  {formik.touched.bban?.confirmAccountNumber &&
                    formik.errors.bban?.confirmAccountNumber && (
                      <span className=" text-[#EF4444] tablet:text-[12px] laptop-s:!text-[14px] text-nowrap">
                        {formik.errors.bban.confirmAccountNumber}
                      </span>
                    )}
                </div>
              </div>
              <div className="flex mobile-s:block mt-4 tablet:w-full tablet:flex gap-4">
                <div className="flex flex-col gap-[5%] mobile-s:w-[100%] tablet:w-[45%]  tablet:h-[45px]  tablet:rounded-[5px] mobile-s:mt-[4%] tablet:mt-[4%] tablet:p-1">
                  <div className="relative w-full">
                    <input
                      type="text"
                      id="swiftCode"
                      className="block px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-transparent border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer text-gray-[#00000099] w-full p-2 rounded-[5px] border border-solid text-[14px] outline-none input-focused"
                      placeholder="SWIFT Code"
                      name="bban.swiftCode"
                      autocomplete="off"
                      value={formik?.values?.bban?.swiftCode}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      onPaste={(e) => e.preventDefault()} // Prevent default paste behavior
                      onSelectStart={(e) => e.preventDefault()} // Prevent text selection on start
                    />
                    <label
                      htmlFor="swiftCode"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      SWIFT Code
                    </label>
                  </div>

                  {formik.touched.bban?.swiftCode &&
                    formik.errors.bban?.swiftCode && (
                      <span className=" text-[#EF4444] tablet:text-[12px] laptop-s:!text-[14px] text-nowrap">
                        {formik.errors.bban.swiftCode}
                      </span>
                    )}
                </div>
                <div className="flex flex-col gap-[5%] mobile-s:w-[100%] tablet:w-[45%]  tablet:h-[45px]  tablet:rounded-[5px] mobile-s:mt-[4%] tablet:mt-[4%] tablet:p-1">
                  <div className="relative w-full">
                    <input
                      type="text"
                      id="bankName"
                      className="block px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-transparent border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer text-gray-[#00000099] w-full p-2 rounded-[5px] border border-solid text-[14px] outline-none input-focused"
                      placeholder="Bank Name"
                      name="bban.bankName"
                      autocomplete="off"
                      value={formik?.values?.bban?.bankName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <label
                      htmlFor="bankName"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      Bank Name
                    </label>
                  </div>

                  {formik.touched.bban?.bankName &&
                    formik.errors.bban?.bankName && (
                      <span className=" text-[#EF4444] tablet:text-[12px] laptop-s:!text-[14px] text-nowrap">
                        {formik.errors.bban.bankName}
                      </span>
                    )}
                </div>
              </div>
              <div className="flex mobile-s:block tablet:w-full mt-4 tablet:flex gap-4">
                <div className="flex flex-col gap-[5%] mobile-s:w-[100%] tablet:w-[45%]  tablet:h-[45px]  tablet:rounded-[5px] mobile-s:mt-[4%] tablet:mt-[4%] tablet:p-1">
                  <div className="relative w-full">
                    <input
                      type="text"
                      id="accountName"
                      className="block px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-transparent border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer text-gray-[#00000099] w-full p-2 rounded-[5px] border border-solid text-[14px] outline-none input-focused"
                      placeholder=" Account Name"
                      name="bban.accountName"
                      autocomplete="off"
                      maxlength={35}
                      value={formik?.values?.bban?.accountName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <label
                      htmlFor="accountName"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      Account Name
                    </label>
                  </div>

                  {formik.touched.bban?.accountName &&
                    formik.errors.bban?.accountName && (
                      <span className=" text-[#EF4444] tablet:text-[12px] laptop-s:!text-[14px]">
                        {formik.errors.bban.accountName}
                      </span>
                    )}
                </div>
                <div className="flex flex-col gap-[5%] mobile-s:w-[100%] tablet:w-[45%]  tablet:h-[45px]  tablet:rounded-[5px] mobile-s:mt-[4%] tablet:mt-[4%] tablet:p-1">
                  <div className="relative w-full">
                    <input
                      type="text"
                      id="address"
                      className="block px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-transparent border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer text-gray-[#00000099] w-full p-2 rounded-[5px] border border-solid text-[14px] outline-none input-focused"
                      placeholder="Address"
                      name="bban.address"
                      autocomplete="off"
                      maxlength={110}
                      value={formik?.values?.bban?.address}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <label
                      htmlFor="address"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      Address
                    </label>
                  </div>

                  {formik.touched.bban?.address &&
                    formik.errors.bban?.address && (
                      <span className=" text-[#EF4444] tablet:text-[12px] laptop-s:!text-[14px]">
                        {formik.errors.bban.address}
                      </span>
                    )}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="flex mobile-s:block tablet:w-full tablet:flex gap-4">
                <div className="flex flex-col gap-[5%] mobile-s:w-[100%] tablet:w-[45%]  tablet:h-[45px]  tablet:rounded-[5px] mobile-s:mt-[4%] tablet:mt-[4%] tablet:p-1">
                  <div className="relative w-full">
                    <div className="relative w-full">
                      <input
                        type="text"
                        id="accountNumber"
                        className="block px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-white border border-gray-300 dark:border-gray-600 focus:outline-none focus:ring-0 focus:border-blue-600 peer w-full rounded-[5px] input-focused"
                        placeholder="Account Number"
                        name="bban.accountNumber"
                        autoComplete="off"
                        maxLength={35}
                        value={formik?.values?.bban?.accountNumber}
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                      />
                      <label
                        htmlFor="accountNumber"
                        className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                      >
                        Account Number
                      </label>
                    </div>
                  </div>
                  {formik?.touched?.bban?.accountNumber &&
                    formik?.errors?.bban?.accountNumber && (
                      <span className=" text-[#EF4444] tablet:text-[12px] laptop-s:!text-[14px] text-nowrap">
                        {formik.errors.bban.accountNumber}
                      </span>
                    )}
                </div>
                <div className="flex flex-col gap-[5%] mobile-s:w-[100%] tablet:w-[45%]  tablet:h-[45px]  tablet:rounded-[5px] mobile-s:mt-[4%] tablet:mt-[4%] tablet:p-1">
                  <div className="relative w-full">
                    <input
                      type="text"
                      id="confirmAccountNumber"
                      className="block px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-white border border-gray-300 dark:border-gray-600 focus:outline-none focus:ring-0 focus:border-blue-600 peer w-full rounded-[5px] input-focused"
                      placeholder="Confirm Account Number"
                      name="bban.confirmAccountNumber"
                      autocomplete="off"
                      value={formik?.values?.bban?.confirmAccountNumber}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      onPaste={(e) => e.preventDefault()} // Prevent default paste behavior
                      onSelectStart={(e) => e.preventDefault()} // Prevent text selection on start
                    />
                    <label
                      htmlFor="confirmAccountNumber"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      Confirm Account Number
                    </label>
                  </div>

                  {formik.touched.bban?.confirmAccountNumber &&
                    formik.errors.bban?.confirmAccountNumber && (
                      <span className=" text-[#EF4444] tablet:text-[12px] laptop-s:!text-[14px] text-nowrap">
                        {formik.errors.bban.confirmAccountNumber}
                      </span>
                    )}
                </div>
                <div className="flex flex-col gap-[5%] mobile-s:w-[100%] tablet:w-[45%]  tablet:h-[45px]  tablet:rounded-[5px] mobile-s:mt-[4%] tablet:mt-[4%] tablet:p-1">
                  <div className="relative w-full">
                    <input
                      type="text"
                      id="swiftCode"
                      className="block px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-white border border-gray-300 dark:border-gray-600 focus:outline-none focus:ring-0 focus:border-blue-600 peer w-full rounded-[5px] input-focused"
                      placeholder="SWIFT Code"
                      name="bban.swiftCode"
                      autocomplete="off"
                      value={formik?.values?.bban?.swiftCode}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      onPaste={(e) => e.preventDefault()} // Prevent default paste behavior
                      onSelectStart={(e) => e.preventDefault()} // Prevent text selection on start
                    />
                    <label
                      htmlFor="swiftCode"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      SWIFT Code
                    </label>
                  </div>

                  {formik.touched.bban?.swiftCode &&
                    formik.errors.bban?.swiftCode && (
                      <span className=" text-[#EF4444] tablet:text-[12px] laptop-s:!text-[14px] text-nowrap">
                        {formik.errors.bban.swiftCode}
                      </span>
                    )}
                </div>
              </div>
              <div className="flex mobile-s:block tablet:w-full mt-4 tablet:flex gap-4">
                <div className="flex flex-col gap-[5%] mobile-s:w-[100%] tablet:w-[45%]  tablet:h-[45px]  tablet:rounded-[5px] mobile-s:mt-[4%] tablet:mt-[4%] tablet:p-1">
                  <div className="relative w-full">
                    <input
                      type="text"
                      id="bankName"
                      className="block px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-white border border-gray-300 dark:border-gray-600 focus:outline-none focus:ring-0 focus:border-blue-600 peer w-full rounded-[5px] input-focused"
                      placeholder="Bank Name"
                      name="bban.bankName"
                      autocomplete="off"
                      value={formik?.values?.bban?.bankName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <label
                      htmlFor="bankName"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      Bank Name
                    </label>
                  </div>

                  {formik.touched.bban?.bankName &&
                    formik.errors.bban?.bankName && (
                      <span className=" text-[#EF4444] tablet:text-[12px] laptop-s:!text-[14px] text-nowrap">
                        {formik.errors.bban.bankName}
                      </span>
                    )}
                </div>
                <div className="flex flex-col gap-[5%] mobile-s:w-[100%] tablet:w-[45%]  tablet:h-[45px]  tablet:rounded-[5px] mobile-s:mt-[4%] tablet:mt-[4%] tablet:p-1">
                  <div className="relative w-full">
                    <input
                      type="text"
                      id="accountName"
                      className="block px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-white border border-gray-300 dark:border-gray-600 focus:outline-none focus:ring-0 focus:border-blue-600 peer w-full rounded-[5px] input-focused"
                      placeholder=" Account Name"
                      name="bban.accountName"
                      autocomplete="off"
                      maxlength={35}
                      value={formik?.values?.bban?.accountName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <label
                      htmlFor="accountName"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      Account Name
                    </label>
                  </div>

                  {formik.touched.bban?.accountName &&
                    formik.errors.bban?.accountName && (
                      <span className=" text-[#EF4444] tablet:text-[12px] laptop-s:text-[14px]">
                        {formik.errors.bban.accountName}
                      </span>
                    )}
                </div>
                <div className="flex flex-col gap-[5%] mobile-s:w-[100%] tablet:w-[45%]  tablet:h-[45px]  tablet:rounded-[5px] mobile-s:mt-[4%] tablet:mt-[4%] tablet:p-1">
                  <div className="relative w-full">
                    <input
                      type="text"
                      id="address"
                      className="block px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-white border border-gray-300 dark:border-gray-600 focus:outline-none focus:ring-0 focus:border-blue-600 peer w-full rounded-[5px] input-focused"
                      placeholder="Address"
                      name="bban.address"
                      autocomplete="off"
                      maxlength={110}
                      value={formik?.values?.bban?.address}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <label
                      htmlFor="address"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      Address
                    </label>
                  </div>

                  {formik.touched.bban?.address &&
                    formik.errors.bban?.address && (
                      <span className=" text-[#EF4444] tablet:text-[12px] laptop-s:text-[14px]">
                        {formik.errors.bban.address}
                      </span>
                    )}
                </div>
              </div>
            </>
          )}
        </div>
      );
    }
  }

  const { isMob, isTab, isLaptop, isLogedIn } = useFindDevice();

  useEffect(() => {
    fetchBankDetail();
  }, []);
  let { userId } = JSON.parse(localStorage.getItem("userInfo"));
  async function fetchBankDetail() {
    dispatch(fetchAccountDetail(`/accounts/bankdetails/${userId}`));
  }

  const payment = useSelector((state) => {
    return state.payment.paymentDetail;
  });

  useEffect(() => {
    if (Object.keys(payment).length > 0) {
      setPaymentType(payment?.bban ? "bban" : "iban");
      setIsCreate(payment?.bban ? true : payment?.iban ? true : false);
      let data = {
        withdrawAmount: "",
        bban: {
          accountNumber: payment?.bban || "",
          confirmAccountNumber: payment?.bban || "",
          swiftCode: payment?.swiftcode || "",
          bankName: payment?.bank_name || "",
          accountName: payment?.holder_name || "",
          address: payment?.address || "",
        },
        iban: {
          ibanNumber: payment?.iban || "",
          confirmIbanNumber: payment?.iban || "",
          accountName: payment?.holder_name || "",
          ibanSwiftCode: payment.swiftcode || "",
          address: payment?.address || "",
        },
      };
      formik.setValues(data);
    }
  }, [payment]);

  const backBtnHandler = () => {
    navigator("/dashboard");
  };

  const handleChange = async () => {
    let {
      withdrawAmount,
      bban: {
        accountName: bbanAccountName,
        accountNumber,
        confirmAccountNumber,
        address: bbanAddress,
        bankName,
        swiftCode,
      },
      iban: {
        accountName,
        address,
        ibanNumber,
        confirmIbanNumber,
        ibanSwiftCode,
      },
    } = formik.values;

    let checkAmountValid =
      withdrawAmount &&
      Number(winningAmount.winningamount) >= Number(withdrawAmount);
    let isIbanSwiftCodeCheck =
      paymentType === "iban" &&
      formik.values.iban?.ibanNumber?.toUpperCase().startsWith("AE")
        ? true
        : false;
    if (
      Number(withdrawAmount) &&
      checkAmountValid &&
      // Check for BBAN details
      ((bbanAccountName &&
        confirmAccountNumber &&
        accountNumber &&
        bbanAddress &&
        bankName &&
        swiftCode) ||
        // Check for IBAN details
        (accountName &&
          address &&
          ibanNumber &&
          confirmIbanNumber &&
          (isIbanSwiftCodeCheck || ibanSwiftCode)))
    ) {
 
      const encryptValue = encryptionBankInfo({
        accountNumber,
        bbanAccountName,
        bbanAddress,
        bankName,
        ibanNumber,
        address,
        accountName,
        swiftCode,
        ibanSwiftCode,
      });
      let sData;
      if (paymentType === "bban") {
        sData = {
          bban: encryptValue?.hashAccountNumber,
          swiftcode: encryptValue?.hashSwiftCode,
          country_code: encryptValue?.hashBankName,
          holder_name: encryptValue?.hashBbanAccountName,
          address: encryptValue?.hashBbanAddress,
          bank_name: (bankName && encryptValue?.hashBankName) || "",
        };
      } else {
        sData = {
          iban: encryptValue.hashIbanNumber,
          holder_name: encryptValue?.hashAccountName,
          address: encryptValue?.hashIbanAdress,
          swiftcode: encryptValue?.hashIbanSwiftCode,
        };
      }

      dispatch(updateWithdrawAmount(Number(withdrawAmount)));
      const res = await dispatch(
        withdrawOtpVerification(`/accounts/bankdetails/${userId}`, sData)
      );
      if (res.status === "Success") {
        localStorage.removeItem("countdownVerfication");
        navigator("/paymentOtpVerification", {
          state: { amount: formik.values.withdrawAmount },
        });
      } else {
        setAccCreateError(res?.data.non_field_errors[0]);
      }
    }
  };

  useEffect(() => {
    if (accountCreateerror) {
      toast.error(accountCreateerror, {
        // onClose: handleToastClose,
        autoClose: 2000,
      });
    }
  }, [accountCreateerror]);

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData("text/plain");
    const numericValue = pasteData.replace(/\D/g, ""); // Remove non-numeric characters
    document.execCommand("insertText", false, numericValue);
  };

  const wrapText = (text) => {
    if (!text) return text;
    const lengthLimit = isMob ? 15 : isLaptop ? 30 : 20;
    if (text.length > lengthLimit) {
      const match = text.match(new RegExp(`.{1,${lengthLimit}}`, "g"));
      return match ? match.join("\n") : text;
    }
    return text;
  };

  const text =
    paymentType === "bban"
      ? formik?.values?.bban?.accountNumber
      : formik?.values?.iban?.ibanNumber;

  function accountDetail() {
    return (
      <>
        {isCreate && (
          <>
            <h4 className="text-[#000000]">Account details</h4>
            <div className="mt-[2%] mobile-s:w-full tablet:w-full laptop-s:w-[80%] border border-[#0000003A] rounded-[6px]">
              <div className="flex justify-between pl-5 pt-3 pr-6 ">
                {paymentType === "iban" ? (
                  <img src={ibanLogo} className="h-6 w-14" />
                ) : (
                  "BBAN"
                )}
                <Button
                  className="text-[#007FFF] cursor-pointer"
                  onClick={(e) => {
                    setIsCreate(false);
                  }}
                  type="button"
                >
                  Change
                </Button>
              </div>
              <div className="p-6 pt-4">
                <div className="flex gap-2 mt-2">
                  <h4 className="w-[35%] mobile-s:text-[14px]">
                    {paymentType === "bban" ? "BBAN" : "IBAN"}
                  </h4>
                  :
                  <p className="mobile-s:text-[14px]">
                    {" "}
                    {/* {paymentType === "bban"
                      ? formik?.values?.bban?.accountNumber
                      : formik?.values?.iban?.ibanNumber} */}
                    <p
                      className="mobile-s:text-[14px]"
                      style={{ whiteSpace: "pre-wrap" }}
                    >
                      {wrapText(text || "")}
                    </p>
                  </p>
                </div>
                <div className="flex gap-2 mt-2">
                  <h4 className="w-[35%] tablet:text-nowrap mobile-s:text-[14px]">
                    Account Name
                  </h4>
                  :
                  <p className="mobile-s:text-[14px]">
                    {paymentType === "bban"
                      ? formik?.values?.bban?.accountName
                      : formik?.values?.iban?.accountName}
                  </p>
                </div>
                {/* {paymentType === "bban" && ( */}
                {/* {formik?.values?.bban?.swiftCode ||
                  (formik?.values?.iban?.swiftCode && ( */}
                    <div className="flex gap-2 mt-2">
                      <h4 className="w-[35%] tablet:text-nowrap mobile-s:text-[14px]">
                        Swift Code
                      </h4>
                      :
                      <p className="mobile-s:text-[14px]">
                        {paymentType === "bban"
                          ? formik?.values?.bban?.swiftCode
                          : formik?.values?.iban?.ibanSwiftCode}
                      </p>
                    </div>
                  {/* ))} */}
                <div className="flex gap-2 mt-2">
                  <h4 className="w-[35%] mobile-s:text-[14px]">Address</h4>:
                  <p className="mobile-s:text-[14px]">
                    {paymentType === "bban"
                      ? formik?.values?.bban?.address
                      : formik?.values?.iban?.address}
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }

  return (
    <PlayerLayout>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ zIndex: 9999 }}
        className="mobile-s:w-[70%] mobile-s:ml-[15%] tablet:w-[40%] laptop-s:w-[25%] mobile-s:text-[12px] mobile-m:!text-[14px] tablet:ml-[1%]"
      />
      <div className="flex ml-7 mt-10 gap-5 background-cover">
        <img
          src={newBackArrow}
          className="h-[20px] cursor-pointer w-[15px] ml-[3%] background-cover"
          onClick={backBtnHandler}
        />
        <p className="text-[#FFFFFF]" onClick={backBtnHandler}>
          Withdraw Amount
        </p>
      </div>
      <Card className="flex flex-col font-interFont m-auto overflow-auto mobile-s:my-[2%] bg-[#FFFFFF] shadow-md rounded-[12px] relative  laptop-s:w-[90%]  mobile-s:w-[90%] tablet:!w-[95%]">
        <div className="flex">
          {!isMob && (
            <div className="relative">
              <img
                src={paymentCup}
                className="tablet:h-[850px] laptop-s:h-[680px] laptop-min:h-[720px] laptop-l:h-[820px] desktop:h-[760px] w-full object-cover"
              />
              <div className="absolute top-20 w-full flex items-center justify-center">
                <div className="flex flex-col justify-center bg-opacity-75 p-4 rounded ">
                  <div className="flex flex-col justify-center">
                    <h2 className="text-center text-[#007FFF] tablet:text-[24px] laptop-s:text-[30px] font-semibold">
                      {winningAmount.winningamount} AED
                    </h2>
                    <p className="tablet:text-[16px] laptop:text-[18px] text-nowrap">
                      Winning Amount Available
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="">
            {isMob && (
              <>
                <div className="flex w-full basis-1/3 gap-4 px-6 py-6 items-center tablet:pt-8 tablet:pl-14 tablet:pb-6 tablet:pr-6">
                  <div className="w-[20%]">
                    <img src={winingCupMobile} className="w-20 h-20" />
                  </div>
                  <div></div>
                  <h4 className="mobile-s:text-[12px] mobile-m:!text-[14px] font-semibold w-[40%] font-interFont text-[#2F2938] text-[500]">
                    Winning Amount Available
                  </h4>
                  <h2 className="mobile-s:text-[18px] mobile-m:!text-[24px] w-[40%] text-[#007FFF] text-wrap font-semibold">
                    {winningAmount.winningamount} AED
                  </h2>
                </div>
                <hr className="border-1px solid"></hr>
              </>
            )}
            <div className="mobile-s:mt-5 tablet:mt-8 mobile-s:pl-8 mobile-s:pr-8 tablet:pl-12 tablet:pr-12">
              <form
                className="w-full"
                onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();
                  handleChange();
                }}
              >
                <div className="flex flex-col gap-2">
                  {/* <p className="pl-2">
                    Please enter the Withdrawal Amount in multiples of AED 100
                    (1 token value)
                  </p> */}

                  <div className="flex mobile-s:block mobile-s:w-[100%] tablet:w-[80%] tablet:flex gap-4">
                    <div className="flex items-center gap-[1%]  tablet:w-[100%] tablet:h-[45px]  tablet:rounded-[5px] tablet:mt-[2%] tablet:p-1">
                      <div className="relative w-full">
                        <input
                          type="text"
                          id="withdrawAmount"
                          className="block px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-transparent border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer text-gray-[#00000099] w-full p-2 rounded-[5px] border border-solid text-[14px] outline-none input-focused cursor-pointer"
                          placeholder="Enter withdraw amount"
                          name="withdrawAmount"
                          value={formik?.values?.withdrawAmount}
                          onChange={(event) => {
                            formik.handleChange(event);
                            setIsCheckboxChecked(false); // Uncheck the checkbox when the input value changes
                          }}
                          onBlur={formik.handleBlur}
                          autoComplete="off"
                          onPaste={handlePaste}
                          // onPaste={(e) => e.preventDefault()} // Prevent default paste behavior
                          onSelectStart={(e) => e.preventDefault()} // Prevent text selection on start
                        />
                        <label
                          htmlFor="withdrawAmount"
                          className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >
                          Enter withdraw amount
                        </label>
                      </div>
                      <div className="mobile-s:w-[15%] tablet:w-[12%] bg-[#0000000F] p-3 rounded-[5px] flex justify-center">
                        <p>AED</p>
                      </div>
                    </div>
                  </div>

                  {formik.touched.withdrawAmount &&
                    formik.errors.withdrawAmount && (
                      <div className="pl-2 text-[#EF4444] tablet:text-[12px] laptop-s:text-[14px] ">
                        {formik.errors.withdrawAmount}
                      </div>
                    )}
                  <div className="flex items-center  mt-4 mb-8 ml-2">
                    <input
                      id="default-checkbox"
                      type="checkbox"
                      name="totalInput"
                      value={formik.values.withdrawAmount}
                      checked={isCheckboxChecked}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 focus:outline-none"
                      onChange={(event) => {
                        const isChecked = event.target.checked;
                        setIsCheckboxChecked(isChecked); // Update the state variable
                        if (isChecked) {
                          formik.setFieldValue(
                            "withdrawAmount",
                            Number(winningAmount.winningamount)
                          );
                        } else {
                          formik.setFieldValue("withdrawAmount", "");
                        }
                      }}
                    />
                    <label
                      htmlFor="default-checkbox"
                      className="ms-2 text-[rgba(0,0,0,0.42)] 
                      text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Withdraw all
                    </label>
                  </div>
                  {!isCreate ? (
                    <div className="flex flex-col gap-5 ml-2">
                      <p className="text-[#0000006A]">Transfer to</p>
                      <div className="flex gap-10 items-center">
                        <div className="flex items-center mb-4">
                          <input
                            id="default-radio-1"
                            type="radio"
                            value="iban"
                            name="paymentType"
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            checked={paymentType === "iban"}
                            onChange={handleRadioChange}
                          />
                          <label
                            for="default-radio-1"
                            className="ms-2 text-sm font-medium text-[#0000006A] dark:text-gray-300"
                          >
                            IBAN
                          </label>
                        </div>
                        <div className="flex items-center mb-4">
                          <input
                            id="default-radio-2"
                            type="radio"
                            value="bban"
                            name="paymentType"
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            checked={paymentType === "bban"}
                            onClick={handleRadioChange}
                          />
                          <label
                            for="default-radio-2"
                            className="ms-2 text-sm font-medium text-[#0000006A] dark:text-gray-300"
                          >
                            SWIFT Transfer
                          </label>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {!isCreate ? paymentTypeRender() : null}
                </div>
                {accountDetail()}
                <div className="mt-[15%]">
                  <div className="flex gap-2">
                    <img
                      src={iIconPayment}
                      className={`h-6 w-6 mobile-s:mt-2 tablet:mt-0`}
                    />
                    <p className="">
                      For transfers outside the UAE, bank charges and taxes may
                      apply as per your country’s laws.
                    </p>
                  </div>
                </div>
                <div className=" ml-2 laptop-s:pb-6 laptop-s:pr-6">
                  <Button
                    className="bg-[#0081F8] hover:bg-[#0081F8] mobile-s:px-6 
                mobile-s:py-4  mobile-s:text-[12px] mobile-m:text-[12px] tablet:text-[16px] mobile-m:!px-8 mobile-m:!py-4 text-[#FFFFFF] font-bold rounded-[7px] focus:outline-none 
              focus:shadow-outline mt-[4%] mb-4"
                    type="submit"
                  >
                    Submit
                  </Button>

                  <Button
                    className="bg-[#B6B6B64D] hover:bg-[#B6B6B64D] ml-4 mobile-s:px-6 
                mobile-s:py-4  mobile-s:text-[12px] mobile-m:text-[12px] tablet:text-[16px] mobile-m:!px-8 mobile-m:!py-4 font-bold rounded-[7px] focus:outline-none focus:shadow-outline mt-[4%] mb-4 text-[#0000004A] text-500"
                    type="button"
                    onClick={() => {
                      navigator("/dashboard");
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Card>
    </PlayerLayout>
  );
}

export default Withdraw;
