import React from "react";
import { Link } from "react-router-dom";

export function MobileFooter() {
  return (
    <div className="h-[25%] footer bg-gradient-to-b from-[#6DC071] to-[#002E05] text-[#FFFFFF]">
      <div className="text-center p-1">
        <div className="block mr-[2%] gap-4">
          <p>
            <a href="/home" className="flex mt-[2%] mb-[4%] justify-center">
              How to play
            </a>
          </p>
          <p>
            <a href="/rules" className="mt-[2%]">
              Rules
            </a>
          </p>
          <p>
            <a href="/privacyPolicy">
              {" "}
              <p className="mt-[5%]">Privacy Policy</p>
            </a>
          </p>
          <p>
            <a href="/contactUs">
              <p className="mt-[5%]">Contact Us</p>
            </a>
          </p>
          <p>
            <a href="/termsAndConditions">
              <p className="mt-[5%]">Terms & Conditions</p>
            </a>
          </p>
        </div>
        <p className="mt-[5%]">
          © Copyrights Reserved{" "}
          <span className="text-[#00C05B]">@SureShotSports FZ-LLC</span>
        </p>
      </div>
    </div>
  );
}

export function FooterTab({ componnent }) {
  return (
    <div
      className={`flex flex-col footer items-center font-interFont justify-center tablet:text-[14px] ${
        componnent === "privacyPolicy" && "tablet:mt-[48px]"
      } ${componnent === "rules" && "tablet:mt-[106px]"} ${
        componnent === "termsAndConditions" && "laptop-l:mt-[150px]"
      } w-full h-24 bg-[#012504] text-[#FFFFFF]`}
    >
      <div className="flex mr-[2%] gap-4">
        <p className="text-nowrap">
          <a href="/home">How to play</a>
        </p>
        <p className="text-[#00C05B]">|</p>
        <p className="text-nowrap">
          <a href="/rules">Rules</a>
        </p>
        <p className="text-[#00C05B]">|</p>
        <p className="text-nowrap">
          <a href="/privacyPolicy">Privacy Policy</a>
        </p>
        <p className="text-[#00C05B]">|</p>
        <p className="text-nowrap">
          <a href="/contactUs">Contact Us</a>
        </p>
        <p className="text-[#00C05B]">|</p>
        <p className="text-nowrap">
          <a href="/termsAndConditions">Terms & Conditions</a>
        </p>
      </div>
      <div className="flex mt-[1%] flex-col mx-auto">
        <div className="mx-auto w-full">
          <p>
            © Copyrights Reserved
            <span className="text-[#00C05B]"> @SureShotSports FZ-LLC</span>
          </p>
        </div>
      </div>
    </div>
  );
}

function Footer({ componnent }) {
  return (
    <div
      className={`flex footer items-center tablet:text-[14px] ${
        componnent === "privacyPolicy" && "tablet:mt-[48px]"
      } ${componnent === "rules" && "tablet:mt-[106px]"} ${
        componnent === "termsAndConditions" && "laptop-l:mt-[150px]"
      } justify-between w-full h-24 bg-[#012504] text-[#FFFFFF]`}
    >
      <div className="flex ml-[2%]">
        <p>
          © Copyrights Reserved
          <span className="text-[#00C05B]"> @SureShotSports FZ-LLC</span>
        </p>
      </div>
      <div className="flex mr-[2%] gap-4">
        <p>
          <a href="/home">How to play</a>
        </p>
        <p className="text-[#00C05B]">|</p>
        <p>
          <a href="/rules">Rules</a>
        </p>
        <p className="text-[#00C05B]">|</p>
        <p>
          <a href="/privacyPolicy">Privacy Policy</a>
        </p>
        <p className="text-[#00C05B]">|</p>
        <p>
          <a href="/contactUs">Contact Us</a>
        </p>
        <p className="text-[#00C05B]">|</p>
        <p>
          <a href="/termsAndConditions">Terms & Conditions</a>
        </p>
      </div>
    </div>
  );
}

export default Footer;
