import { createSlice } from "@reduxjs/toolkit";

const playerLayoutSlice = createSlice({
  name: "playerLayout",
  initialState: {
    userTokenDetail: {},
    loading: false,
    error: null,
  },
  reducers: {
    fetchPlayLayOutStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchUserTokenDetail: (state, action) => {
      state.loading = false;
      state.userTokenDetail = action.payload;
    },
    updateTokenDetail: (state, action) => {
      let obj = JSON.parse(JSON.stringify(state.userTokenDetail));
      state.userTokenDetail.won_tokens = action.payload.won_tokens;
      state.userTokenDetail.available_tokens = action.payload.available_tokens;
    },

    fetchPlayLayOutFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchPlayLayOutStart,
  fetchUserTokenDetail,
  fetchPlayLayOutFailure,
  updateTokenDetail,
} = playerLayoutSlice.actions;
export default playerLayoutSlice.reducer;
