import { createSlice } from "@reduxjs/toolkit";

const myPurchasesSlice = createSlice({
  name: "myPurchases",
  initialState: {
    loading: false,
    myPurchases: [],
    myPurchasesMobiles: [],
    pagination: {
      totalRecord: 0,
      pageSize: 10,
      currentPage: 1,
    },
    error: "",
  },
  reducers: {
    fetchMyPurchasestart: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchMyPurchaseInformation: (state, action) => {
      state.pagination.totalRecord = action.payload.count;
      state.myPurchases = [];
      state.myPurchases = [...state.myPurchases, ...action.payload.results];

      let concatmyPurchase = [];
      let mobileData = JSON.parse(JSON.stringify(state.myPurchasesMobiles));
      if (mobileData.length > 0) {
        concatmyPurchase = [
          ...JSON.parse(JSON.stringify(state.myPurchasesMobiles)),
          ...action.payload.results,
        ];
      } else {
        concatmyPurchase = [...action.payload.results];
      }

      const res = [
        ...new Map(concatmyPurchase.map((ele) => [ele.id, ele])).values(),
      ];
      state.myPurchasesMobiles = res;
    },
    updatePurchasePagination: (state, action) => {
      state.pagination.currentPage = action.payload.page;
    },
    fetchMyPurchaseFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchMyPurchasestart,
  fetchMyPurchaseInformation,
  updatePurchasePagination,
  fetchMyPurchaseFailure,
} = myPurchasesSlice.actions;
export default myPurchasesSlice.reducer;
