import { createSlice } from "@reduxjs/toolkit";

const payment = createSlice({
  name: "payment",
  initialState: {
    loading: false,
    error: "",
    paymentDetail: {},
    withdrawAmount: 0,
  },
  reducers: {
    fetchPaymentStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchPlayerPaymentDetail: (state, action) => {
      state.loading = false;
      state.paymentDetail = action.payload.data;
    },
    updateWithdrawAmount: (state, action) => {
      state.withdrawAmount = action.payload;
    },
    fetchPaymentFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchPaymentStart,
  fetchPlayerPaymentDetail,
  fetchPaymentFailure,
  updateWithdrawAmount
} = payment.actions;
export default payment.reducer;
