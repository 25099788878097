import { createSlice } from "@reduxjs/toolkit";

const verifyOtpSlice = createSlice({
  name: "verifyOtp",
  initialState: {
    otp: "",
  },
  reducers: {
    otpValueChange: (state, action) => {
      state.otp = action.payload;
    },
  },
});

export const { otpValueChange } = verifyOtpSlice.actions;
export default verifyOtpSlice.reducer;
