import {
  fetchPaymentStart,
  fetchPlayerPaymentDetail,
  fetchPaymentFailure,
} from "../../Components/Payment/payment.slice";
import { postData, getData, putData } from "../api";

export const withdrawOtpVerification = (URL, tokenInfo) => async (dispatch) => {
  try {
    const response = await putData(URL, tokenInfo);
    return response;
  } catch (error) {
    dispatch(fetchPaymentFailure(error.message));
  }
};

export const fetchAccountDetail = (URL) => async (dispatch) => {
  try {
    dispatch(fetchPaymentStart());
    const res = await getData(URL);
    if (res?.status === "Success") {
      dispatch(fetchPlayerPaymentDetail(res));
    } else {
      dispatch(fetchPaymentFailure("payment error"));
    }
  } catch (error) {
    dispatch(fetchPaymentFailure(error.message));
    console.log(error);
  }
};

export const createPaymentRequest = (URL, data) => async (dispatch) => {
  try {
    const res = await postData(URL, data);
    return res;
  } catch (e) {
    console.log(e);
  }
};
