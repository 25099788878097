import React, { useState, useEffect } from "react";
import Button from "../../Elements/Button";
import { useDispatch, useSelector } from "react-redux";
import modelX from "../../assets/images/model-x.svg";
import money from "../../assets/images/moneys.png";
import {
  closeNewPot,
  newPotJoinNow,
  newTokenBuy,
  selectedPicks,
} from "../GreenDashboard/dashboard.slice";

import securityIcon from "../../assets/images/security-model.png";

function SelectPicks() {
  const dispatch = useDispatch();

  const [potId, setPotId] = useState("");
  const [allpots, setAllPots] = useState([]);
  const [selectedPot, setSelectedPot] = useState([]);
  const [availablePick, setAvailablePick] = useState(0);

  const choosePick = useSelector((state) => {
    return {
      pot: state.dashboard.data.pots,
      choosePick: state.dashboard.selectPick,
      choosePotId: state.dashboard.selectedPotId,
    };
  });

  const [isOpen, setIsOpen] = useState(choosePick);
  useEffect(() => {
    setIsOpen(choosePick.choosePick);
    setPotId(choosePick.choosePotId);
    setAllPots(choosePick.pot);
  }, [choosePick.choosePick]);

  useEffect(() => {
    if (allpots.length > 0) {
      let res = allpots.filter((pot) => pot.id === potId);
      setSelectedPot(res);
      setAvailablePick(res[0]?.available_picks);
    }
  }, [allpots]);

  const togglePopup = () => {
    dispatch(selectedPicks(!isOpen));
    setIsOpen(!isOpen);
  };

  const [clickedButton, setClickedButton] = useState(null);

  const handleClick = (numOfPick, potId) => {
    const needTockenForNewPick = numOfPick * selectedPot[0].pot_token;
    dispatch(
      newPotJoinNow({
        numOfPick,
        needTockenForNewPick,
        potId,
        selectPick: false,
        checkAndBuyToken: true,
      })
    );
    setClickedButton(numOfPick);
  };

  return (
    <div>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center">
          <div className="absolute inset-0 bg-gray-800 opacity-75 p-8"></div>
          <div
            className="relative mobile-s:w-[90%] mobile:w-[95%] tablet:w-[75%] 
        laptop-s:!w-[30%] laptop-l:!w-[25%]
        desktop:!w-[20%] bg-white p-8 shadow-lg rounded-[8px]"
          >
            <button
              className="absolute top-0 p-4 right-0 mt-2 mr-2 text-gray-600 hover:text-gray-800"
              onClick={togglePopup}
            >
              <img src={modelX} />
            </button>

            <div className="flex flex-col items-center">
              <p className="text-[14px] text-[#0000005A]">
                Choose any one below
              </p>
              <p className="font-semibold text-[20px] text-center">
                <span>Are you</span>
                <p>playing 1 Pick {availablePick === 1?null:'or 2 Picks'}  ?</p>
              </p>
              <div className="flex flex-wrap justify-center gap-2">
                {Array.from({ length: availablePick }, (_, i) => i + 1).map(
                  (btnCount) => {
                    return (
                      <Button
                        className={`sm:w-1/2 rounded-[8px] py-3 px-6 font-bold focus:outline-none focus:shadow-outline mt-6 mb-2 border border-gray-300 hover:border-blue-500 text-gray-500 ${
                          clickedButton === 1 ? "bg-blue-100" : "bg-[#0000001A]"
                        }`}
                        type="submit"
                        onClick={() => handleClick(btnCount, potId)}
                      >
                        {btnCount} Pick
                      </Button>
                    );
                  }
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SelectPicks;
