import { combineReducers } from "@reduxjs/toolkit";
import userReducer from "../Components/Login/login.slice";
import dashboardRouter from "../Components/GreenDashboard/dashboard.slice";
import signUp from "../Components/Signup/signup.slice";
import verificationOtp from "../Components/Verification/verification.slice";
import teams from "../Components/GreenDashboard/team.slice";
import myPot from "../Components/MyPotViewDetail/myPot.slice";
import jackPot from "../Components/JackPotViewDetail/jackpot.slice";
import myPicks from "../Components/MyPicks/myPick.slice";
import myPicksHistory from "../Components/HistoryOfPicks/history.slice";
import myPurchases from "../Components/myPurchases/myPurchases.slice";
import myWinnings from "../Components/MyWinnings/myWinnings.slice";

import playerLayout from "../Components/PlayerLayout/playerlayout.slice";
import payment from "../Components/Payment/payment.slice";
import transactionHistory from "../Components/TransactionHistory/transactionHistory.slice";

const rootReducer = combineReducers({
  user: userReducer,
  dashboard: dashboardRouter,
  signUp: signUp,
  verificationOtp,
  teams: teams,
  myPot: myPot,
  jackPot: jackPot,
  myPicks: myPicks,
  myPicksHistory: myPicksHistory,
  myPurchases: myPurchases,
  myWinnings: myWinnings,
  playerLayout: playerLayout,
  payment,
  transaction: transactionHistory,
});

export default rootReducer;
